<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>Configuração de Faturamento Automático</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar(clienteFaturamentoForm)">
        <mat-icon>save</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="md-dialog-content">
        <br />
        <form [formGroup]="clienteFaturamentoForm">
            <div class="card">
                <mat-tab-group>
                    <mat-tab label="Geral">
                        <br />
                        <div class="row">
                            <mat-form-field class="form-group col-md-4 col-xs-12">
                                <mat-label>Dia de Fechamento do Faturamento</mat-label>
                                <input type="number" matInput formControlName="diaFatura">
                            </mat-form-field>
                            <mat-form-field class="form-group col-md-4 col-xs-12">
                                <mat-label>Dias para Pagar</mat-label>
                                <input type="number" matInput formControlName="diaVencimento">
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-6 col-xs-12 form-group">
                                <mat-label>Categoria de Receita</mat-label>
                                <mat-select formControlName="grupoContaId">
                                    <mat-option *ngFor="let grupoConta of grupoContas"
                                        [value]="grupoConta.grupoContaId">
                                        {{grupoConta.descricao}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="col-md-6 col-xs-12 form-group">
                                <mat-label>Caixa (Fluxo de Caixa)</mat-label>
                                <mat-select formControlName="caixaId">
                                    <mat-option *ngFor="let caixa of caixas" [value]="caixa.caixaId">
                                        {{caixa.nome}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </form>
    </div>
</mat-dialog-content>