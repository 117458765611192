<mat-toolbar>
    <div class="md-toolbar-tools">
      <h1 mat-dialog-title>{{ data.title }}</h1>
      <span flex></span>
      <div class="posicionar-direita">
        <button (click)="fecharDialog()" class="botao-header" matTooltip="Cancelar">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>
  
  <mat-dialog-content>
    <form [formGroup]="data.formGroup">
      <div class="row">
        <h3>Por favor, escreva <span class="text-warn">REMOVER</span> para remover o registro.</h3>
        <hr>
      </div>
      <div class="row">
        <mat-form-field class="col-md-12 col-xs-12 form-group">
          <mat-label>REMOVER</mat-label>
          <input type="text" matInput formControlName="remover" required>
        </mat-form-field>
      </div>
      <hr>
      <div class="row text-right">
        <div class="col-md-12 col-xs-12 form-group">
          <button [disabled]="data.formGroup.get('remover').value !== 'REMOVER'" mat-raised-button color="warn" matTooltip="Remover" (click)="data.onConfirm()">
            <mat-icon>delete</mat-icon>Remover
          </button>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  