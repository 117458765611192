import { Directive, ElementRef, OnInit, Renderer2, Optional } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[appRequiredField]'
})
export class RequiredFieldDirective implements OnInit {
  private isTouched = false;

  constructor(
    private el: ElementRef,
    @Optional() private control: NgControl,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    if (!this.control) {
      console.error('NgControl not found for', this.el.nativeElement);
      return;
    }

    const control = this.control.control;
    if (control && control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        this.control.statusChanges?.subscribe(() => this.updateErrorMessage());
        this.renderer.listen(this.el.nativeElement, 'focus', () => this.onFocus());
        this.renderer.listen(this.el.nativeElement, 'blur', () => this.onBlur());

        const formElement = this.el.nativeElement.closest('form');
        if (formElement) {
          this.renderer.listen(formElement, 'reset', () => this.onFormReset());
        }

        this.updateErrorMessage();
      }
    }
  }

  private onFocus() {
    this.isTouched = true;
  }

  private onBlur() {
    this.updateErrorMessage();
  }

  private onFormReset() {
    this.isTouched = false;
    this.updateErrorMessage();
  }

  private updateErrorMessage() {
    const control = this.control.control;
    if (!control) return;

    const matFormField = this.el.nativeElement.closest('mat-form-field');
    if (matFormField) {
      const formFieldControl = matFormField.querySelector('.mat-form-field-subscript-wrapper');

      if (formFieldControl) {
        let errorElement = formFieldControl.querySelector('mat-error');

        // Remove the error if it already exists
        if (errorElement) {
          this.renderer.removeChild(formFieldControl, errorElement);
        }

        // Add the error message if the control is invalid, has the 'required' error, and has been touched
        if (this.isTouched && control.invalid && control.errors?.['required']) {
          errorElement = this.renderer.createElement('mat-error');
          const text = this.renderer.createText('Campo Obrigatório');
          this.renderer.appendChild(errorElement, text);

          // Add Angular Material class manually if necessary
          this.renderer.addClass(errorElement, 'mat-error');
          this.renderer.setAttribute(errorElement, 'aria-atomic', 'true');
          this.renderer.setAttribute(errorElement, 'aria-live', 'polite');
          this.renderer.appendChild(formFieldControl, errorElement);
        }
      }
    }
  }
}