<div class="login-page" style="background-image: url({{appImagemFundo}});">
  <!--<div class="card x_panel" id="login">-->
    <div class="card" id="login">
      <div class="imgcontainer">
        <img [src]="appLogo" />
      </div>
      <br />
      <br />
      <br />
      <button (click)="RealizarLogin()">Login</button>
    </div>
  </div>
