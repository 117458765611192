'use strict';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnexoService } from '../shared/anexo.service';
import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { Anexo } from '../shared/anexo.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmDialogComponent } from 'src/app/core/dialogs/confirm-dialog/confirm-dialog.component';

export interface DialogData {
    documentoIdReferenciado: number;
    documentoTipo: number;
    saguiClienteId: number;
}

@Component({
    selector: 'anexo',
    templateUrl: 'anexo.component.html',
})

export class AnexoComponent {

    @ViewChild('arquivoInput', { static: true }) arquivoInput;

    maximizado: boolean;
    anexos: any[];
    textoDocumentoTipo: string = '';
    anexo: Anexo = new Anexo();
    anexoForm: FormGroup;

    constructor(
        public dialog: MatDialog,
    private _snackBar: MatSnackBar,
        private anexoService: AnexoService,
        public dialogRef: MatDialogRef<AnexoComponent>,
        @Inject(MAT_DIALOG_DATA) public IAnexo: DialogData,
        private _formBuilder: FormBuilder) {
    }

    ngOnInit() {

        this.anexoForm = this._formBuilder.group(new Anexo());

        this.maximizado = false;

        this.visualizaAnexo();

        this.textoDocumentoTipo = this.preencheTipoAnexo(this.IAnexo.documentoTipo);

        this.restaurarDialog();
    }

    fecharDialog() {
        this.dialogRef.close();
    }

    maximizarDialog() {
        this.dialogRef.updateSize('100%', '100%');
        this.maximizado = true;
    }

    restaurarDialog() {
        let innerWidth = window.innerWidth;

        if (innerWidth < 1024) {
            this.dialogRef.updateSize('90%', '100vh');
        }
        else {
            this.dialogRef.updateSize('60%', '100vh');
        }
        this.maximizado = false;
    }

    download(anexo: any) {

        this.anexoService.getAnexoBaixar(anexo.anexoId).subscribe(data => {

            let link = document.createElement("a");

            link.setAttribute("target", "_blank");

            if (Blob !== undefined) {

                var blob = new Blob([data], { type: anexo.ContentType });

                link.setAttribute("href", URL.createObjectURL(blob));

            }

            link.setAttribute("download", anexo.nomeArquivo);

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);


        });

    }

    preencheTipoAnexo(tipoAnexoDestino: number) {

        var texto = "";

        switch (tipoAnexoDestino) {
            case 0:
                texto = "Compra";
                break;
            case 1:
                texto = "Venda";
                break;
            case 2:
                texto = "Devolução";
                break;
            case 3:
                texto = "Produção";
                break;
            case 4:
                texto = "Orçamento";
                break;
            case 5:
                texto = "NFe";
                break;
            case 6:
                texto = "Contrato";
                break;
            case 7:
                texto = "Remessa";
                break;
            case 8:
                texto = "Fatura de Venda";
                break;
            case 9:
                texto = "Fatura de Compra";
                break;
            case 10:
                texto = "Conta a Receber";
                break;
            case 11:
                texto = "Conta a Pagar";
                break;
            case 12:
                texto = "Caixa Movimento";
                break;
            case 13:
                texto = "Estoque Movimento";
                break;
        }
        return texto;
    }

    visualizaAnexo() {

        var parametros: any = {
            documentoTipo: this.IAnexo.documentoTipo,
            documentoIdReferenciado: this.IAnexo.documentoIdReferenciado
        };

        this.anexoService.postVisualizaAnexoPorFiltro(parametros).subscribe(data => {

            this.anexos = data.body;

        });
    }

    fileChanged(e: any) {

        this.anexo.descricao = this.anexoForm.get('descricao').value;
        this.anexo.nomeArquivo = e.target.files[0].name;
        this.anexo.documentoTipo = this.IAnexo.documentoTipo;
        this.anexo.documentoIdReferenciado = this.IAnexo.documentoIdReferenciado;
        this.anexo.saguiClienteId = this.IAnexo.saguiClienteId;
        this.anexo.corpoArquivo = e.target.files[0];

        let fileReader = new FileReader();

        fileReader.onload = (e) => {
            this.anexo.corpoArquivo = fileReader.result;
        }

        fileReader.readAsDataURL(e.target.files[0]);

    }

    uploadArquivo() {

        if (this.anexo.nomeArquivo.length > 0) {

            let formData = new FormData();
            formData.append('fileUpload', this.arquivoInput.nativeElement.files[0]);
            formData.append('descricao', this.anexoForm.get('descricao').value);
            formData.append('documentoIdReferenciado', this.IAnexo.documentoIdReferenciado.toString());
            formData.append('documentoTipo', this.IAnexo.documentoTipo.toString());
            formData.append('nomeArquivo', this.anexo.nomeArquivo);
            formData.append('saguiClienteId', this.IAnexo.saguiClienteId.toString());

            this.anexoService.postAnexo(formData).subscribe(data => {

                this.visualizaAnexo();

            });
        }
    }

    excluir(anexoId) {

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                titulo: "Confirmação",
                mensagem: "Deseja realmente excluir esse anexo?"
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                this.anexoService.delete(anexoId).subscribe(data => {

                    const dialogRef = this.dialog.open(InfoDialogComponent, {
                        data: {
                            titulo: "Sucesso",
                            mensagem: "Anexo Excluído"
                        }
                    });

                    this.visualizaAnexo();

                });
            }
        });
    }
}
