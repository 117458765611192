export class CaixaMovimento {

  caixaMovimentoId: number = 0;

  descricao: string = '';

  caixaMovimentoStatus: number = 0;

  direcao: number = 0;

  valor: number = 0;

  saldo: number = 0;

  tipoPagamento: number = 0;

  contaContabilId: number = 0;

  sequenciaId: number = 0;

  data: Date = new Date();

  grupoContaId: number = 0;

  caixaId: number = 0;

  saguiClienteId: number = 0;
}
