<mat-toolbar>
    <div class="md-toolbar-tools">
      <h1 mat-dialog-title>Descontos Adicionais</h1>
      <span flex></span>
      <div class="posicionar-direita">
        <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
          <mat-icon>fullscreen_exit</mat-icon>
        </button>
        <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
          <mat-icon>fullscreen</mat-icon>
        </button>
        <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <br>
</mat-toolbar>
<div mat-dialog-content>
    <form [formGroup]="Desconto">
        <div class="row ">
          <div class="col-md-12 form-group">
          <mat-form-field>
            <mat-label>Desconto em Porcentagem</mat-label>
         
      
            <input maxlength="3" min="0" max="100"  matInput
              formControlName="DescontoEmPorcentagem">
              <span matSuffix> %</span>
          </mat-form-field>

        </div>

          <mat-form-field #descontoReaisInput class="col-md-12 col-xs-12 form-group" appearance="outline"  >
            <mat-label>Desconto em Reais</mat-label>
            <input class="prefixErrorfix" type="number" matInput formControlName="DescontoEmReais">
            <span matPrefix>R$&nbsp;</span>
          </mat-form-field>

        </div>
  
         
   
    </form>

    <mat-card class="report-card darkblue-whiteblue-background">
 
        <mat-card-content >
          
          <span class="report-card-title">{{this.valorTotalNovo | currency:'BRL'}}</span>
          <p>Novo Valor Total Líquido</p>    
        
        </mat-card-content>
    </mat-card>
</div>
<div mat-dialog-actions class="md-toolbar-tools">
    <div class="posicionar-direita">
        <button mat-raised-button color="warn" (click)="onNoClick()">Cancelar</button>
        <button mat-raised-button color="primary" matTooltip="Salvar" (click)="onYesClick()">
            <mat-icon>save</mat-icon>
        </button>
    </div>
</div>
