export class NfeEvento {

    nfeEventoId: number = 0;

    tpEvento : string = '';

    idEvento : string = '';

    nSeqEvento : string = '';

    verEvento : string = '';

    versao : string = '';

    descEvento : string = '';

    nProt : string = '';

    xJust : string = '';

    ufEmitente : string = '';

    cnpjEmitente : string = '';

    tpAmb : number = 0;

    chNFe : string = '';

    xCorrecao : string = '';

    nfeId : number = 0;

    numLoteEvento : number = 0;

    saguiClienteId : number = 0;

  }
  