export class ClienteMetodoPagamento {

    clienteMetodoPagamentoId: number = 0;

    clienteId: number = 0;

    metodoPagamento: number = 0;

    nomeNoCartao: string = '';

    cartaoMarca: string = '';

    expiraMes: number = 0;

    expiraAno: number = 0;

    numeroCartao: string = '';

    codigoSeguranca: string = '';

    saguiClienteId: number = 0;
}