<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>Dados Bancários</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()">
        <mat-icon>save</mat-icon>
    </button>
</div>
<br>
<mat-dialog-content>
    <br />
    <mat-tab-group>
        <mat-tab label="Geral">
            <br>
            <form [formGroup]="dadoBancarioCadastroForm">
                <div class="row">
                    <mat-form-field class="col-md-6 col-xs-12 form-group">
                        <mat-label>Banco</mat-label>
                        <mat-select formControlName="bancoId">
                            <mat-option *ngFor="let banco of bancos" [value]="banco.bancoId">
                                {{banco.numero}} - {{banco.descricao}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-3 col-xs-12 form-group">
                        <mat-label>Tipo de Conta</mat-label>
                        <mat-select formControlName="dadoBancarioTipo">
                            <mat-option [value]="0">Conta Corrente</mat-option>
                            <mat-option [value]="1">Conta Poupança</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-6 col-xs-12 form-group">
                        <mat-label>Agência</mat-label>
                        <input type="text" matInput formControlName="agenciaNumero">
                    </mat-form-field>
                    <mat-form-field class="col-md-6 col-xs-12 form-group">
                        <mat-label>Número da Conta Corrente</mat-label>
                        <input type="text" matInput formControlName="contaCorrenteNumero">
                    </mat-form-field>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 form-group">
                        <mat-radio-group aria-label="Tipo de Pessoa" formControlName="favorecidoPessoaFisica">
                            <mat-radio-button [value]="true">Pessoa Física</mat-radio-button>
                            <mat-radio-button [value]="false">Pessoa Jurídica</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="row" *ngIf="dadoBancarioCadastroForm.value.favorecidoPessoaFisica">
                    <mat-form-field class="col-md-4 col-xs-12 form-group">
                        <mat-label>Cpf</mat-label>
                        <input type="text" matInput formControlName="favorecidoCpf">
                    </mat-form-field>
                </div>
                <div class="row" *ngIf="!dadoBancarioCadastroForm.value.favorecidoPessoaFisica">
                    <mat-form-field class="col-md-4 col-xs-12 form-group">
                        <mat-label>Cnpj</mat-label>
                        <input type="text" matInput formControlName="favorecidoCnpj">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-4 col-xs-12 form-group">
                        <mat-label>Nome do Favorecido</mat-label>
                        <input type="text" matInput formControlName="favorecidoNome">
                    </mat-form-field>
                </div>
            </form>

        </mat-tab>
        <mat-tab label="Chaves Pix" *ngIf="dadoBancarioCadastroForm.get('dadoBancarioId').value > 0">
            <br />
            <div class="row">
                <form [formGroup]="dadoBancarioChavePixForm">
                    <mat-form-field class="col-md-4 col-xs-12 form-group">
                        <mat-label>Tipo de Pix</mat-label>
                        <mat-select formControlName="chavePixTipo">
                            <mat-option [value]="0">Cpf</mat-option>
                            <mat-option [value]="1">Cnpj</mat-option>
                            <mat-option [value]="2">Celular</mat-option>
                            <mat-option [value]="3">Chave Aleatória</mat-option>
                        </mat-select>
                        <mat-hint>Para vincular novos veículos</mat-hint>
                    </mat-form-field>
                    <mat-form-field class="col-md-4 col-xs-12 form-group">
                        <mat-label>Chave Pix</mat-label>
                        <input type="text" matInput formControlName="chavePix">
                    </mat-form-field>
                    <div class="col-md-4 col-xs-12 form-group">
                        <button class="sagui-button" mat-mini-fab color="primary" (click)="incluirChavePix()" matTooltip="Adicionar Chave Pix">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </form>
            </div>
            <hr>
            <div id="list" class="row">
                <div class="table-responsive col-md-12">
                    <table class="table table-striped" cellspacing="0" cellpadding="0">
                        <thead>
                            <tr>
                                <th>Ações</th>
                                <th>Tipo</th>
                                <th>Chave Pix</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let dadoBancarioChavePix of dadoBancarioChavesPix">
                                <td class="actions">
                                    <a mat-mini-fab color="warn"
                                        (click)="confirmarExclusaoChavePix(dadoBancarioChavePix.dadoBancarioChavePixId)" matTooltip="Deletar">
                                        <mat-icon>delete</mat-icon>
                                    </a>
                                </td>
                                <td>{{retornarTipoChavePix(dadoBancarioChavePix.chavePixTipo)}}</td>
                                <td>{{dadoBancarioChavePix.chavePix}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
