'use strict'
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NfeService } from '../shared/nfe.service';
import { CfopService } from '../../cfop/shared/cfop.service';

import { debounceTime, switchMap } from "rxjs/operators";

import { NfeItem } from '../shared/nfe-item.model';
import { Produto } from '../../produto/shared/produto.model';
import { Cfop } from '../../cfop/shared/cfop.model';
import { Nfe } from '../shared/nfe.model';

export interface DialogData {
    nfeItemId: number;
    nfe: any;
    nfeManual: boolean;
}

@Component({
    selector: 'nfe-item',
    templateUrl: 'nfe-item.component.html',
    styleUrls: ['nfe-item.component.css']
})

export class NfeItemComponent {

    nfe: Nfe = new Nfe();
    listaServicos: any = [];
    nfeItemForm: FormGroup;
    maximizado: boolean;
    nfeItemId: number;
    nfeitem: any = {};
    cfops: Cfop[];
    produtos: Produto[];

    showIcmsTrib: boolean = false;
    showIcmsSTRet: boolean = false;
    showIcmsST: boolean = false;
    showPercentualReducaoBC: boolean = false;
    showPercentualCreditoSN: boolean = false;
    showValorCreditoIcmsSN: boolean = false;

    showIpiTributado: boolean = false;
    showIpiAliquota: boolean = false;
    showIpiPauta: boolean = false;

    showPisAliquota: boolean = false;
    showPisPauta: boolean = false;
    showValorPis: boolean = false;
    showPisST: boolean = false;
    showTipoCalculoPis: boolean = false;
    showTipoCalculoPisST: boolean = false;
    showPisSTPauta: boolean = false;
    showPisSTAliquota: boolean = false;
    showValorPisST: boolean = false;

    showCofinsAliquota: boolean = false;
    showCofinsPauta: boolean = false;
    showValorCofins: boolean = false;
    showValorCofinsST: boolean = false;
    showTipoCalculoCofins: boolean = false;
    showTipoCalculoCofinsST: boolean = false;
    showCofinsST: boolean = false;
    showCofinsSTAliquota: boolean = false;
    showCofinsSTPauta: boolean = false;

    constructor(
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<NfeItemComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public INfeItem: DialogData,
        private nfeService: NfeService,
        private cfopService: CfopService) {
    }

    ngOnInit() {

        this.maximizado = false;

        this.nfe = this.INfeItem.nfe;

        this.nfeItemId = this.INfeItem.nfeItemId;

        this.nfeItemForm = this._formBuilder.group(new NfeItem());

        this.nfeItemForm.addControl('produtoAutoComplete', new FormControl(''));

        this.nfeItemForm.get('nfeId').setValue(this.nfe.nfeId);

        this.buscaCfop();

        this.onChanges();

        this.buscarNfeItem();

        this.maximizarDialog();
    }

    onChanges(): void {

        this.nfeItemForm
            .get('produtoAutoComplete')
            .valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => value?.length >= 3 ?
                    this.nfeService.getProdutoPorNome({
                        id: value,
                        tipoProduto: this.nfeItemForm.get('produtoTipo').value
                    }) : []
                )
            )
            .subscribe(data =>
                this.produtos = data.body
            );

        this.nfeItemForm
            .get('icmsDifal')
            .valueChanges
            .subscribe(data => this.trataIcmsDifal(data));

        this.nfeItemForm
            .get('cstIcms')
            .valueChanges
            .subscribe(data => this.defineIcmsCst(data));

        this.nfeItemForm
            .get('cstIpi')
            .valueChanges
            .subscribe(data => this.defineIpiCst(data));

        this.nfeItemForm
            .get('tipoCalculoIPI')
            .valueChanges
            .subscribe(data => this.defineIpiTipoCalculo(data));

        this.nfeItemForm
            .get('cstPis')
            .valueChanges
            .subscribe(data => this.definePisCst(data));

        this.nfeItemForm
            .get('tipoCalculoPis')
            .valueChanges
            .subscribe(data => this.definePisTipoCalculo(data));

        this.nfeItemForm
            .get('tipoCalculoPisST')
            .valueChanges
            .subscribe(data => this.definePisStTipoCalculo(data));

        this.nfeItemForm
            .get('cstCofins')
            .valueChanges
            .subscribe(data => this.definecofinsCst(data));

        this.nfeItemForm
            .get('tipoCalculoCofins')
            .valueChanges
            .subscribe(data => this.definecofinsTipoCalculo(data));

        this.nfeItemForm
            .get('tipoCalculoCofinsST')
            .valueChanges
            .subscribe(data => this.definecofinsStTipoCalculo(data));

        this.nfeItemForm
            .get('vProd')
            .valueChanges
            .subscribe(data => this.calculaImpostos());
    }

    calculaImpostos(): void {

        let ncm = this.nfeItemForm.value.ncm;

        if (this.nfeItemForm.value.cfop != 5206 && 
            !this.INfeItem.nfeManual && 
            this.nfeItemForm.value.ncm != undefined &&
            this.nfeItemForm.value.ncm != null &&
            this.nfeItemForm.value.ncm != '') {

            this.nfeService.postCalculaImposto(this.nfeItemForm.value).subscribe(data => {

                if (data.body != null) {
                    //CST
                    this.nfeItemForm.get('cstIcms').setValue(data.body.cstIcms.toString());
                    this.nfeItemForm.get('cstIpi').setValue(data.body.cstIpi.toString());
                    this.nfeItemForm.get('cstPis').setValue(data.body.cstPis.toString());
                    this.nfeItemForm.get('cstCofins').setValue(data.body.cstCofins.toString());

                    //ICMS
                    this.nfeItemForm.get('icmsvBC').setValue(data.body.icmsvBC.toString());
                    this.nfeItemForm.get('icmspIcms').setValue(data.body.icmspIcms.toString());
                    this.nfeItemForm.get('icmsvIcms').setValue(data.body.icmsvIcms.toString());
                    this.nfeItemForm.get('icmsSTmodBCST').setValue(data.body.icmsSTmodBCST.toString());
                    this.nfeItemForm.get('icmsSTpMVAST').setValue(data.body.icmsSTpMVAST.toString());

                    this.nfeItemForm.get('icmsSTvBCST').setValue(data.body.icmsSTvBCST.toString());
                    this.nfeItemForm.get('icmsSTpIcmsST').setValue(data.body.icmsSTpIcmsST.toString());

                    this.nfeItemForm.get('icmsSTvIcmsST').setValue(data.body.icmsSTvIcmsST.toString());
                    this.nfeItemForm.get('icmspCredSN').setValue(data.body.icmspCredSN.toString());
                    this.nfeItemForm.get('icmsvCredIcmsSN').setValue(data.body.icmsvCredIcmsSN.toString());
                    this.nfeItemForm.get('icmspRedBC').setValue(data.body.icmspRedBC.toString());

                    //IPI
                    this.nfeItemForm.get('ipicEnq').setValue(data.body.ipicEnq.toString());
                    this.nfeItemForm.get('ipivBC').setValue(data.body.ipivBC.toString());

                    //infAdProd
                    this.nfeItemForm.get('infAdProd').setValue(data.body.infAdProd.toString());

                    //PIS
                    this.nfeItemForm.get('pisvBC').setValue(data.body.pisvBC.toString());
                    this.nfeItemForm.get('pispPis').setValue(data.body.pispPis.toString());
                    this.nfeItemForm.get('pisvPis').setValue(data.body.pisvPis.toString());


                    //cofins
                    this.nfeItemForm.get('cofinsvBC').setValue(data.body.cofinsvBC.toString());
                    this.nfeItemForm.get('cofinspCofins').setValue(data.body.cofinspCofins.toString());
                    this.nfeItemForm.get('cofinsvCofins').setValue(data.body.cofinsvCofins.toString());

                }

            });
        }
    }

    buscarNfeItem() {

        if (this.nfeItemId > 0) {

            this.nfeService.getNfeItem(this.nfeItemId).subscribe(data => {

                this.nfeItemForm.patchValue(data.body);

                this.nfeItemForm.get('produtoAutoComplete').setValue(data.body.descricao);

                data.body.nfeItemImpostos.forEach(imposto => {

                    if (imposto.impostoId == 1) {
                        this.nfeItemForm.get('icmsvBC').setValue(imposto.vBC);
                        this.nfeItemForm.get('icmspIcms').setValue(imposto.vlrAliquota);
                        this.nfeItemForm.get('icmsvIcms').setValue(imposto.vlrImposto);
                        this.nfeItemForm.get('icmspCredSN').setValue(imposto.pCredSN);
                        this.nfeItemForm.get('icmsvCredIcmsSN').setValue(imposto.vCredICMSSN);
                        this.nfeItemForm.get('icmSorig').setValue(imposto.orig);
                        this.nfeItemForm.get('icmsmodBC').setValue(imposto.modBC);
                        this.nfeItemForm.get('icmspRedBC').setValue(imposto.pRedBC);
                    }

                    if (imposto.impostoId == 2) {
                        this.nfeItemForm.get('icmsSTmodBCST').setValue(imposto.modBC);
                        this.nfeItemForm.get('icmsSTpMVAST').setValue(imposto.pMVA);
                        this.nfeItemForm.get('icmsSTvBCST').setValue(imposto.vBC);
                        this.nfeItemForm.get('icmsSTpIcmsST').setValue(imposto.vlrAliquota);
                        this.nfeItemForm.get('icmsSTvIcmsST').setValue(imposto.vlrImposto);
                        this.nfeItemForm.get('vBCSTRet').setValue(imposto.vBCSTRet);
                        this.nfeItemForm.get('pST').setValue(imposto.pST);
                        this.nfeItemForm.get('vICMSSubstituto').setValue(imposto.vICMSSubstituto);
                        this.nfeItemForm.get('vICMSSTRet').setValue(imposto.vICMSSTRet);
                        this.nfeItemForm.get('icmsSTpRedBCST').setValue(imposto.pRedBC);
                    }

                    if (imposto.impostoId == 3) {
                        this.nfeItemForm.get('ipicEnq').setValue(imposto.cEnq);
                        this.nfeItemForm.get('ipiclEnq').setValue(imposto.clEnq);
                        this.nfeItemForm.get('ipiCNPJProd').setValue(imposto.cNPJProd);
                        this.nfeItemForm.get('ipicSelo').setValue(imposto.cSelo);
                        this.nfeItemForm.get('ipivBC').setValue(imposto.vBC);
                        this.nfeItemForm.get('ipipIpi').setValue(imposto.vlrAliquota);
                        this.nfeItemForm.get('ipiqUnid').setValue(imposto.qUnid);
                        this.nfeItemForm.get('ipivUnid').setValue(imposto.vUnid);
                        this.nfeItemForm.get('ipivIpi').setValue(imposto.vlrImposto);
                    }

                    if (imposto.impostoId == 4) {
                        this.nfeItemForm.get('pisvBC').setValue(imposto.vBC);
                        this.nfeItemForm.get('pispPis').setValue(imposto.vlrAliquota);
                        this.nfeItemForm.get('pisqUnid').setValue(imposto.qUnid);
                        this.nfeItemForm.get('pisvUnid').setValue(imposto.vUnid);
                        this.nfeItemForm.get('pisvPis').setValue(imposto.vlrImposto);

                        if(imposto.vlrAliquota > 0)
                        {
                            this.nfeItemForm.get('tipoCalculoPis').setValue(1);
                        }else{
                            this.nfeItemForm.get('tipoCalculoPis').setValue(2);

                        }
                    }

                    if (imposto.impostoId == 5) {
                        this.nfeItemForm.get('pisSTvBC').setValue(imposto.vBC);
                        this.nfeItemForm.get('pisSTpPis').setValue(imposto.vlrAliquota);
                        this.nfeItemForm.get('pisSTvAliqProd').setValue(imposto.vUnid);
                        this.nfeItemForm.get('pisSTqBCProd').setValue(imposto.qUnid);
                        this.nfeItemForm.get('pisSTvPis').setValue(imposto.vlrImposto);

                        if(imposto.vlrAliquota > 0)
                        {
                            this.nfeItemForm.get('tipoCalculoPisST').setValue(1);
                        }else{
                            this.nfeItemForm.get('tipoCalculoPisST').setValue(2);

                        }
                    }

                    if (imposto.impostoId == 6) {
                        this.nfeItemForm.get('cofinsvBC').setValue(imposto.vBC);
                        this.nfeItemForm.get('cofinspCofins').setValue(imposto.vlrAliquota);
                        this.nfeItemForm.get('cofinsqUnid').setValue(imposto.qUnid);
                        this.nfeItemForm.get('cofinsvUnid').setValue(imposto.vUnid);
                        this.nfeItemForm.get('cofinsvCofins').setValue(imposto.vlrImposto);

                        if(imposto.vlrAliquota > 0)
                        {
                            this.nfeItemForm.get('tipoCalculoCofins').setValue(1);
                        }else{
                            this.nfeItemForm.get('tipoCalculoCofins').setValue(2);

                        }
                    }

                    if (imposto.impostoId == 7) {
                        this.nfeItemForm.get('cofinsSTvBC').setValue(imposto.vBC);
                        this.nfeItemForm.get('cofinsSTpPIS').setValue(imposto.vlrAliquota);
                        this.nfeItemForm.get('cofinsSTvAliqProd').setValue(imposto.vUnid);
                        this.nfeItemForm.get('cofinsSTqBCProd').setValue(imposto.qUnid);
                        this.nfeItemForm.get('cofinsSTvPIS').setValue(imposto.vlrImposto);

                        if(imposto.vlrAliquota > 0)
                        {
                            this.nfeItemForm.get('tipoCalculoCofinsST').setValue(1);
                        }else{
                            this.nfeItemForm.get('tipoCalculoCofinsST').setValue(2);

                        }
                    }
                });

            });
        } else {
            this.nfeItemForm.get('indTot').setValue(1);
            this.nfeItemForm.get('ipicEnq').setValue(999);
            this.nfeItemForm.get('cstIcms').setValue(201);
            this.nfeItemForm.get('cstIpi').setValue(99);
            this.nfeItemForm.get('cstPis').setValue(99);
            this.nfeItemForm.get('cstCofins').setValue(99);
            this.nfeItemForm.get('icmSorig').setValue(0);
            this.nfeItemForm.get('icmSorig').setValue(4);
        }
    }

    buscaCfop() {

        const parametros: any = {};

        parametros.empresaId = this.nfe.empresaId;

        if (this.nfe.tpNF == 1) {
            parametros.direcao = 1;
        }
        else {
            parametros.direcao = 2;
        }

        this.cfopService.getCfops(parametros).subscribe(data => {
            this.cfops = data.body;

        });
    }

    fecharDialog() {
        this.dialogRef.close();
    }

    salvar(NFeItem) {

        NFeItem.nfeItemImpostos = [
            {
                impostoId: 1,
                pRedBC: NFeItem.icmspRedBC,
                vBC: NFeItem.icmsvBC,
                vlrAliquota: NFeItem.icmspIcms,
                vlrImposto: NFeItem.icmsvIcms,
                orig: NFeItem.icmSorig,
                pCredSN: NFeItem.icmspCredSN,
                vCredICMSSN: NFeItem.icmsvCredIcmsSN
            },
            {
                impostoId: 2,
                modBC: NFeItem.icmsSTmodBCST,
                pRedBC: NFeItem.icmsSTpRedBCST,
                pMVA: NFeItem.icmsSTpMVAST,
                vBC: NFeItem.icmsSTvBCST,
                vlrAliquota: NFeItem.icmsSTpIcmsST,
                vlrImposto: NFeItem.icmsSTvIcmsST,
                vBCSTRet: NFeItem.vBCSTRet,
                pST: NFeItem.pST,
                vICMSSubstituto: NFeItem.vICMSSubstituto,
                vICMSSTRet: NFeItem.vICMSSTRet,
            },
            {
                impostoId: 3,
                clEnq: NFeItem.ipiclEnq,
                cEnq: NFeItem.ipicEnq,
                cNPJProd: NFeItem.ipiCNPJProd,
                cSelo: NFeItem.IPIcSelo,
                vBC: NFeItem.ipivBC,
                vlrAliquota: NFeItem.ipipIpi,
                qUnid: NFeItem.ipiqUnid,
                vUnid: NFeItem.ipivUnid,
                vlrImposto: NFeItem.ipivIpi
            },
            {
                impostoId: 4,
                vBC: NFeItem.pisvBC,
                vlrAliquota: NFeItem.pispPis,
                qUnid: NFeItem.pisqUnid,
                vUnid: NFeItem.pisvUnid,
                vlrImposto: NFeItem.pisvPis
            },
            {
                impostoId: 5,
                vBC: NFeItem.pisSTvBC,
                vlrAliquota: NFeItem.pisSTpPis,
                vUnid: NFeItem.PISSTvAliqProd,
                qUnid: NFeItem.PISSTqBCProd,
                vlrImposto: NFeItem.pisSTvPis
            },
            {
                impostoId: 6,
                vBC: NFeItem.cofinsvBC,
                vlrAliquota: NFeItem.cofinspCofins,
                qUnid: NFeItem.cofinsqUnid,
                vUnid: NFeItem.cofinsvUnid,
                vlrImposto: NFeItem.cofinsvCofins
            },
            {
                impostoId: 7,
                vBC: NFeItem.cofinsSTvBC,
                vlrAliquota: NFeItem.cofinsSTpPIS,
                vUnid: NFeItem.cofinsSTvAliqProd,
                qUnid: NFeItem.cofinsSTqBCProd,
                vlrImposto: NFeItem.cofinsSTvPIS
            }
        ];


        if (this.nfeItemId == 0) {

            NFeItem.nfeId = this.nfe.nfeId;

            this.nfeService.postIncluiNfeItem(NFeItem).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

                this.fecharDialog();

            });
        }
        else {

            this.nfeService.putAtualizaNfeItem(NFeItem, NFeItem.nfeItemId).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

                this.fecharDialog();

            });
        }
    };


    determinaprodutoId(produto: Produto) {

        this.nfeItemForm.get('produtoId').setValue(produto.produtoId);
        this.nfeItemForm.get('codProd').setValue(produto.codProd);
        this.nfeItemForm.get('descricao').setValue(produto.descricao);
        this.nfeItemForm.get('ncm').setValue(produto.ncm);
        this.nfeItemForm.get('ean').setValue(produto.ean);
        this.nfeItemForm.get('eanTrib').setValue(produto.eanTrib);
        this.nfeItemForm.get('unTributaria').setValue(produto.unTributaria);
        this.nfeItemForm.get('vlrTributario').setValue(produto.vlrTributario);
        this.nfeItemForm.get('unComercial').setValue(produto.unComercial);
        this.nfeItemForm.get('vlrComercial').setValue(produto.vlrComercial);
        this.nfeItemForm.get('extipi').setValue(produto.extipi);
        this.nfeItemForm.get('infAdProd').setValue(produto.infAdProd);
        this.nfeItemForm.get('pesoLiquido').setValue(produto.pesoLiquido);
        this.nfeItemForm.get('pesoBruto').setValue(produto.pesoBruto);
        this.nfeItemForm.get('cest').setValue(produto.cest);

    }

    onChangeValorComercial() {

        let vlrComercial = this.nfeItemForm.get('vlrComercial').value;
        let qtdComercial = this.nfeItemForm.get('qtdComercial').value;
        let vProd = Math.round((qtdComercial * vlrComercial) * 100) / 100;
        this.nfeItemForm.get('vProd').setValue(vProd.toFixed(2));
        this.nfeItemForm.get('qtdTributaria').setValue(qtdComercial);
        this.nfeItemForm.get('qtdComercial').setValue(qtdComercial);
        this.nfeItemForm.get('vlrTributario').setValue(vlrComercial);
        this.nfeItemForm.get('vlrComercial').setValue(vlrComercial);

        this.calculaImpostos();
    }

    maximizarDialog() {
        this.dialogRef.updateSize('100%', '100%');
        this.maximizado = true;
    }

    restaurarDialog() {
        this.dialogRef.updateSize('80vh', '100vh');
        this.maximizado = false;
    }

    TrataValorICMS() {

        let icmsvIcms = (this.nfeItemForm.get('icmsvBC').value * this.nfeItemForm.get('icmspIcms').value) / 100;

        this.nfeItemForm.get('icmsvIcms').setValue(icmsvIcms);
    }

    TrataValorICMSST() {

        let icmsSTvIcmsST = (this.nfeItemForm.get('icmsSTvBCST').value * this.nfeItemForm.get('icmsSTpIcmsST').value) / 100;

        this.nfeItemForm.get('icmsSTvIcmsST').setValue(icmsSTvIcmsST);
    }

    trataIcmsDifal(utilizaicmsDifal: boolean) {

        if (utilizaicmsDifal == true) {

            let valorBaseCalculo = this.nfeItemForm.get('vProd').value +
                this.nfeItemForm.get('vFrete').value +
                this.nfeItemForm.get('vSeg').value +
                this.nfeItemForm.get('vOutro').value +
                this.nfeItemForm.get('ipivIpi').value -
                this.nfeItemForm.get('vDesc').value;

            let diferencialAliquota = (this.nfeItemForm.get('icmsSTpIcmsST').value - this.nfeItemForm.get('icmspIcms').value) / 100;

            this.nfeItemForm.get('icmsvBC').setValue(valorBaseCalculo);
            this.nfeItemForm.get('icmsSTvBCST').setValue(valorBaseCalculo);
            this.nfeItemForm.get('icmsSTvIcmsST').setValue(valorBaseCalculo * diferencialAliquota);
        };

    };

    defineIcmsCst(cstIcms: string) {

        this.showPercentualCreditoSN = false;
        this.showValorCreditoIcmsSN = false;
        this.showIcmsST = false;
        this.showIcmsSTRet = false;
        this.showIcmsTrib = false;
        this.showPercentualReducaoBC = false;

        switch (cstIcms) {
            case '00':
                this.ExibeICMS();
                break;
            case '10':
                this.ExibeICMS();
                this.ExibeICMSST();
                break;
            case '20':
                this.ExibeICMS();
                this.showPercentualReducaoBC = true;
                break;
            case '30':
                this.ExibeICMSST();
                break;
            case '40':
            case '41':
            case '50':
                break;
            case '51':
                this.ExibeICMS();
                this.showPercentualReducaoBC = true;
                break;
            case '60':
                this.showIcmsSTRet = true;
                break;
            case '70':
                this.ExibeICMS();
                this.ExibeICMSST();
                this.showPercentualReducaoBC = true;
                break;
            case '90':
                this.ExibeICMS();
                this.ExibeICMSST();
                this.showPercentualReducaoBC = true;
                break;
            case 'ICMSPart':
                //Partilha de ICMS entre UF
                break;
            case 'ICMSST':
                //ICMS ST devido para a UF de destino, nas operações interestaduais de produtos que tiveram retenção antecipada de ICMS por ST na UF do remetente. Repasse via Substituto Tributário.
                break;
            case '101':
                //Tributada pelo Simples Nacional com permissão de crédito
                this.showPercentualCreditoSN = true;
                this.showValorCreditoIcmsSN = true;
                this.showIcmsST = false;
                break;
            case '102':
                //Tributada pelo Simples Nacional sem permissão de crédito.
                this.showPercentualCreditoSN = false;
                this.showValorCreditoIcmsSN = false;
                this.showIcmsST = false;
                break;
            case '103':
                //Isenção do ICMS no Simples Nacional para faixa de receita bruta.
                this.showPercentualCreditoSN = false;
                this.showValorCreditoIcmsSN = false;
                this.showIcmsST = false;
                break;
            case '201':
                //Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por Substituição Tributária.
                this.showPercentualCreditoSN = true;
                this.showValorCreditoIcmsSN = true;
                this.showIcmsST = true;
                break;
            case '202':
                //Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por Substituição Tributária.
                this.showPercentualCreditoSN = false;
                this.showValorCreditoIcmsSN = false;
                this.showIcmsST = true;
                break;
            case '203':
                //Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por Substituição Tributária.
                this.showPercentualCreditoSN = true;
                this.showValorCreditoIcmsSN = true;
                this.showIcmsST = true;
                break;
            case '300':
                //Imune.
                this.showPercentualCreditoSN = false;
                this.showValorCreditoIcmsSN = false;
                this.showIcmsST = false;
                break;
            case '400':
                //Não tributada pelo Simples Nacional.
                this.showPercentualCreditoSN = false;
                this.showValorCreditoIcmsSN = false;
                this.showIcmsST = false;
                break;
            case '500':
                //ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação.
                this.showPercentualCreditoSN = false;
                this.showValorCreditoIcmsSN = false;
                this.showIcmsST = false;
                break;
            case '900':
                //Outros
                this.showPercentualCreditoSN = true;
                this.showValorCreditoIcmsSN = true;
                this.ExibeICMS();
                this.ExibeICMSST();
                this.showPercentualReducaoBC = true;
                break;
        };
    };

    ExibeICMS() {
        this.showIcmsTrib = true;

        if (this.nfeItemForm.get('icmsvBC').value == 0) {
            this.nfeItemForm.get('icmsvBC').setValue(this.nfeItemForm.get('vProd').value);
        }
    }

    ExibeICMSST() {
        this.showIcmsST = true;

        if (this.nfeItemForm.get('icmsSTvBCST').value == 0) {
            this.nfeItemForm.get('icmsSTvBCST').setValue(this.nfeItemForm.get('vProd').value);
        }
    }


    defineIpiCst(cstIpi) {

        switch (cstIpi) {
            case '49':
            case '00':
            case '50':
            case '99':
                this.showIpiTributado = true;
                this.defineIpiTipoCalculo(0);
                break;
            case '01':
            case '02':
            case '03':
            case '04':
            case '05':
            case '51':
            case '52':
            case '53':
            case '54':
            case '55':
                this.showIpiTributado = false;
                break;
        };
    };

    defineIpiTipoCalculo(tipoCaculo: number) {

        switch (tipoCaculo) {
            case 0:
                this.showIpiAliquota = true;
                this.showIpiPauta = false;
                break;
            case 1:
                this.showIpiAliquota = false;
                this.showIpiPauta = true;
                break;
        };
    };

    definePisCst(cstPis: string) {

        switch (cstPis) {
            case '01':
            case '02':
                this.showTipoCalculoPis = false;
                this.showTipoCalculoPisST = false;
                this.showPisAliquota = true;
                this.showPisPauta = false;
                this.showPisST = false;
                this.showValorPis = true;
                this.showValorPisST = false;
                break;
            case '03':
                this.showTipoCalculoPis = false;
                this.showTipoCalculoPisST = false;
                this.showPisAliquota = false;
                this.showPisPauta = true;
                this.showPisST = false;
                this.showValorPis = true;
                this.showValorPisST = false;
                break;
            case '04':
            case '06':
            case '07':
            case '08':
            case '09':
                this.showTipoCalculoPis = false;
                this.showTipoCalculoPisST = false;
                this.showPisAliquota = false;
                this.showPisPauta = false;
                this.showPisST = false;
                this.showValorPis = false;
                this.showValorPisST = false;
                break;
            case '05':
                this.showTipoCalculoPis = false;
                this.showTipoCalculoPisST = true;
                this.showPisST = true;
                this.showPisAliquota = false;
                this.showPisPauta = false;
                this.showValorPis = false;
                this.showValorPisST = true;
                break;
            case '49':
            case '50':
            case '51':
            case '52':
            case '53':
            case '54':
            case '55':
            case '56':
            case '60':
            case '61':
            case '62':
            case '63':
            case '64':
            case '65':
            case '66':
            case '67':
            case '70':
            case '71':
            case '72':
            case '73':
            case '74':
            case '75':
            case '98':
            case '99':
                this.showTipoCalculoPis = true;
                this.showTipoCalculoPisST = false;
                this.showPisST = false;
                this.showPisAliquota = false;
                this.showPisPauta = false;
                this.showValorPis = true;
                this.showValorPisST = false;
                break;
        };

    }

    definePisTipoCalculo(pisTipoCalculo: number) {

        switch (pisTipoCalculo) {
            case 1:
                this.showPisAliquota = true;
                this.showPisPauta = false;
                break;
            case 2:
                this.showPisAliquota = false;
                this.showPisPauta = true;
                break;
        };

    }

    definePisStTipoCalculo(pisStTipoCalculo: number) {

        switch (pisStTipoCalculo) {
            case 1:
                this.showPisSTAliquota = true;
                this.showPisSTPauta = false;
                break;
            case 2:
                this.showPisSTAliquota = false;
                this.showPisSTPauta = true;
                break;
        };
    }

    definecofinsCst(cstCofins: string) {

        switch (cstCofins) {
            case '01':
            case '02':
                this.showTipoCalculoCofins = false;
                this.showTipoCalculoCofinsST = false;
                this.showCofinsAliquota = true;
                this.showCofinsPauta = false;
                this.showCofinsST = false;
                this.showValorCofins = true;
                this.showValorCofinsST = false;
                break;
            case '03':
                this.showTipoCalculoCofins = false;
                this.showTipoCalculoCofinsST = false;
                this.showCofinsAliquota = false;
                this.showCofinsPauta = true;
                this.showCofinsST = false;
                this.showValorCofins = true;
                this.showValorCofinsST = false;
                break;
            case '04':
            case '06':
            case '07':
            case '08':
            case '09':
                this.showTipoCalculoCofins = false;
                this.showTipoCalculoCofinsST = false;
                this.showCofinsAliquota = false;
                this.showCofinsPauta = false;
                this.showCofinsST = false;
                this.showValorCofins = false;
                this.showValorCofinsST = false;
                break;
            case '05':
                this.showTipoCalculoCofins = false;
                this.showTipoCalculoCofinsST = true;
                this.showCofinsST = true;
                this.showCofinsAliquota = false;
                this.showCofinsPauta = false;
                this.showValorCofins = false;
                this.showValorCofinsST = true;
                break;
            case '49':
            case '50':
            case '51':
            case '52':
            case '53':
            case '54':
            case '55':
            case '56':
            case '60':
            case '61':
            case '62':
            case '63':
            case '64':
            case '65':
            case '66':
            case '67':
            case '70':
            case '71':
            case '72':
            case '73':
            case '74':
            case '75':
            case '98':
            case '99':
                this.showTipoCalculoCofins = true;
                this.showTipoCalculoCofinsST = false;
                this.showCofinsST = false;
                this.showCofinsAliquota = false;
                this.showCofinsPauta = false;
                this.showValorCofins = true;
                this.showValorCofinsST = false;
                break;
        };
    };

    definecofinsTipoCalculo(cofinsTipoCalculo: number) {

        switch (cofinsTipoCalculo) {
            case 1:
                this.showCofinsAliquota = true;
                this.showCofinsPauta = false;
                break;
            case 2:
                this.showCofinsAliquota = false;
                this.showCofinsPauta = true;
                break;
        };

    }

    definecofinsStTipoCalculo(cofinsStTipoCalculo: number) {

        switch (cofinsStTipoCalculo) {
            case 1:
                this.showCofinsSTAliquota = true;
                this.showCofinsSTPauta = false;
                break;
            case 2:
                this.showCofinsSTAliquota = false;
                this.showCofinsSTPauta = true;
                break;
        };
    }

}
