'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, switchMap } from "rxjs/operators";

import { NfseService } from '../shared/nfse.service';
import { CfopService } from '../../cfop/shared/cfop.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';

import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';
import { ExportaXmlService } from '../../core/exporta-xml/exporta-xml.service';
import { UfService } from '../../uf/shared/uf.service';

import { NfseStatusComponent } from '../nfse-status/nfse-status.component';

import { Nfse } from '../shared/nfse.model';
import { MunicipioService } from 'src/app/municipio/shared/municipio.service';
import { Empresa } from 'src/app/empresa/shared/empresa.model';



export interface DialogData {
  nfseId: number;
}

@Component({
  selector: 'nfse-cadastro',
  templateUrl: 'nfse-cadastro.component.html',
})

export class NfseCadastroComponent {

  empresas: any = [];
  listaServicos: any = [];
  statusNfse: string = '';
  nfseCadastroForm: FormGroup;
  maximizado: boolean;
  autorizada: boolean = false;
  processar: boolean = false;
  cancelar: boolean = false;
  clientes: any[];
  ufs: any = [];
  municipios: any = [];
  isDisabled: boolean = false;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NfseCadastroComponent>,
    private _formBuilder: FormBuilder,
    private htmlPrinterService: HtmlPrinterService,
    private exportaXmlService: ExportaXmlService,
    @Inject(MAT_DIALOG_DATA) public INfse: DialogData,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private nfseService: NfseService,
    private cfopService: CfopService,
    private ufService: UfService,
    private municipioService: MunicipioService) {
  }

  ngOnInit() {

    this.nfseCadastroForm = this._formBuilder.group(new Nfse());
    this.nfseCadastroForm.addControl('clienteAutoComplete', new FormControl(''));

    if (this.INfse.nfseId > 0) {
      this.buscaNfse(this.INfse.nfseId);
    } else {
      this.nfseCadastroForm.get('tipoRps').setValue(1);
    }

    this.buscarEmpresas();
    this.buscarUFs();

    const ufId = this.data?.ufId ?? 0;
    const municipioId = this.data?.municipioId ?? 0;

    if (ufId !== 0 && municipioId !== 0) {
      this.nfseCadastroForm.get('localPrestacaoUfId').setValue(ufId);
      this.nfseCadastroForm.get('localPrestacaoMunicipioId').setValue(municipioId);
    }
    this.onChanges();
    this.restaurarDialog();

  }



  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  buscarUFs() {
    this.ufService.getUFs().subscribe(data => {
      this.ufs = data.body;
    });
  }

  buscarMunicipioPorUf(ufId: number) {
    this.municipioService.getMunicipioPorUf(ufId).subscribe(data => {
      this.municipios = data.body;
    });
  }

  buscarEmpresas() {
    this.nfseService.getEmpresa().subscribe(data => {
      this.empresas = data.body;
    });
  }

  buscaListaServico(empresaId) {
    this.nfseService.getListaServicoCadastrados(empresaId).subscribe(data => {
      this.listaServicos = data.body;
    });
  }

  onChanges(): void {
    this.nfseCadastroForm
      .get('clienteAutoComplete')
      .valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => value?.length >= 3 ?
          this.nfseService.getClientePorNome(value) : []
        )
      )
      .subscribe(data =>
        this.clientes = data.body
      );

    this.nfseCadastroForm
      .get('localPrestacaoUfId')
      .valueChanges
      .subscribe(data => this.buscarMunicipioPorUf(data));
  }

  buscaNfse(nfseId: number) {
    this.nfseService.getNfse(nfseId).subscribe(data => {
      this.insereDadosNfse(data.body);
      this.buscaListaServico(data.body.empresaId);
    }, (error: HttpErrorResponse) => {
      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          erro: error
        }
      });
    })


  } ''
  empresaSelecionada(empresa: any) {
    if (
      this.nfseCadastroForm.get('localPrestacaoUfId').value == undefined ||
      this.nfseCadastroForm.get('localPrestacaoUfId').value == null ||
      this.nfseCadastroForm.get('localPrestacaoUfId').value == 0
    ) {
      this.nfseCadastroForm.get('localPrestacaoUfId').setValue(empresa.municipio.ufId);
    }
    if (
      this.nfseCadastroForm.get('localPrestacaoMunicipioId').value == undefined ||
      this.nfseCadastroForm.get('localPrestacaoMunicipioId').value == null ||
      this.nfseCadastroForm.get('localPrestacaoMunicipioId').value == 0
    ) {
      this.nfseCadastroForm.get('localPrestacaoMunicipioId').setValue(empresa.municipio.municipioId);
      this.nfseCadastroForm.get('codigoMunicipioIbge').setValue(empresa.municipio.municipioId);
    }


    let ufId;
    let municipioId;
    let municipio;

    const intervalId = setInterval(() => {
      if (this.municipios && this.municipios.length > 0) {
        clearInterval(intervalId);

        ufId = this.data?.ufId ? 0 : empresa.municipio.ufId;
        municipioId = this.data?.municipioId ? 0 : empresa.municipio.municipioId;
        municipio = this.municipios.filter(municipio => municipio.municipioId == municipioId)[0];

        if (ufId !== 0 && municipioId !== 0) {
          this.nfseCadastroForm.get('localPrestacaoUfId').setValue(ufId);
          this.nfseCadastroForm.get('localPrestacaoMunicipioId').setValue(municipioId);
          this.nfseCadastroForm.get('codigoMunicipioIbge').setValue(municipio.codigoIBGE);
        }

        this.buscaListaServico(empresa.empresaId);
      }
    }, 100);
  }

  insereDadosNfse(data: any) {
    this.nfseCadastroForm.patchValue(data);
    console.log(this.nfseCadastroForm.value)

    this.nfseCadastroForm.get('clienteAutoComplete').setValue(data.cliente.nomeFantasia);

    if (this.nfseCadastroForm.value.statusProcessamento == "100") {
      this.autorizada = true;
    };

    if (this.nfseCadastroForm.value.statusProcessamento != "95" && this.nfseCadastroForm.value.statusProcessamento != "135" && this.nfseCadastroForm.value.nProt != null) {
      this.cancelar = true;
    };

    const statusInvalidos = ["100", "101", "102", "135", "105", "2"];

    if (!statusInvalidos.includes(this.nfseCadastroForm.value.statusProcessamento)) {
      this.processar = true;
    }


    this.atualizaStatus(this.nfseCadastroForm.value);
  }

  salvar(): void {

    if (this.nfseCadastroForm.value.nfseId == 0) {

      this.nfseService.postNfse(this.nfseCadastroForm.value).subscribe(data => {

        this.INfse.nfseId = data.body.nfseId;

        this._snackBar.open("Registro Salvo com Sucesso");

        this.buscaNfse(this.INfse.nfseId);

      }, (error: HttpErrorResponse) => {

        const dialogRef = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Erro",
            mensagem: error.error
          }
        });
      });

    }
    else {

      this.nfseService.putNfse(this.nfseCadastroForm.value, this.nfseCadastroForm.value.nfseId).subscribe(data => {

        this._snackBar.open("Registro Salvo com Sucesso");

        this.buscaNfse(this.nfseCadastroForm.value.nfseId);

      }, (error: HttpErrorResponse) => {

        const dialogRef = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Erro",
            mensagem: error.error
          }
        });
      });
    }
  };

  async verificaValorContaParcelaFaturaAposCalculoImpostoNfse(nfse, contaParcelaFaturaNfse): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      let msgBoletos = 'Não existe boleto para esta conta parcela, deseja criar um boleto?';
      let msgContaFatura = 'O valor da NFSe é diferente do valor da conta parcela/fatura. Deseja Atualizar os valores antes de continuar?';

      const boletoExists = contaParcelaFaturaNfse?.contaParcela?.boletoId > 0;
      const valoresIguais = contaParcelaFaturaNfse?.contaParcela?.valorBruto === nfse.valorLiquidoNfse;
      const contaParcelaExists = contaParcelaFaturaNfse?.contaParcela != null && contaParcelaFaturaNfse?.contaParcela != undefined;



      if (!boletoExists && contaParcelaExists) {

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            titulo: "Confirmação",
            mensagem: msgBoletos
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            if (!valoresIguais) {

              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                  titulo: "Confirmação",
                  mensagem: msgContaFatura
                }
              });
              dialogRef.afterClosed().subscribe(async result => {
                if (result) {
                  await this.atualizaValoresContaParcelaFatura(contaParcelaFaturaNfse, nfse, resolve, reject).then(() => {
                    this.geraBoleto(nfse.nfseId, resolve, reject);
                    resolve();
                  });
                } else {
                  this.geraBoleto(nfse.nfseId, resolve, reject);
                  resolve();
                }
              });
            } else {
              this.geraBoleto(nfse.nfseId, resolve, reject);
              resolve();
            }
          } else {
            if (!valoresIguais) {
              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                  titulo: "Confirmação",
                  mensagem: msgContaFatura
                }
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  this.atualizaValoresContaParcelaFatura(contaParcelaFaturaNfse, nfse, resolve, reject);
                  resolve();
                } else{
                  resolve();
                }
              });
            } else {
              resolve();
            }
          }
        });
      } else if (boletoExists && contaParcelaExists && !valoresIguais) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            titulo: "Confirmação",
            mensagem: msgContaFatura
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.atualizaValoresContaParcelaFatura(contaParcelaFaturaNfse, nfse, resolve, reject);
            resolve();
          }else{
            resolve();
          }
        });
      } else {
        resolve();
      }
    });
  }

  private geraBoleto(nfseId: number, resolve: () => void, reject: () => void): void {
    this.nfseService.postGerarBoletoByNfseId(nfseId).subscribe(data => {
    }, (error: HttpErrorResponse) => {
      this._snackBar.open('Erro ao gerar o boleto!', 'Fechar', {
        duration: 5000,
        panelClass: ['mat-toolbar', 'error']
      });
      reject();
    });
  }

  private atualizaValoresContaParcelaFatura(contaParcelaFaturaNfse, nfse, resolve: () => void, reject: () => void): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      contaParcelaFaturaNfse.contaParcela.valorParcela = nfse.valorLiquidoNfse;
      contaParcelaFaturaNfse.contaParcela.valorBruto = nfse.valorLiquidoNfse;

      let valorTotalParcelas = 0;
      if (contaParcelaFaturaNfse.fatura) {
        contaParcelaFaturaNfse.fatura.faturaParcelas.forEach((faturaParcela) => {
          faturaParcela.valorParcela = nfse.valorLiquidoNfse;
          faturaParcela.valorBruto = nfse.valorLiquidoNfse;
          valorTotalParcelas += faturaParcela.valorParcela;
        });
        contaParcelaFaturaNfse.fatura.valorDesconto = contaParcelaFaturaNfse.fatura.valorBruto - valorTotalParcelas;
        contaParcelaFaturaNfse.fatura.valorDesconto = Math.ceil(contaParcelaFaturaNfse.fatura.valorDesconto * 1000) / 1000;
        contaParcelaFaturaNfse.fatura.valorBruto = valorTotalParcelas;
      }

      this.nfseService.salvaContaParcelaEFatura(contaParcelaFaturaNfse).subscribe(data => {
        resolve();
      }, (error: HttpErrorResponse) => {
        this._snackBar.open('Erro ao atualizar os valores!', 'Fechar', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'error']
        });
        reject();
      }
      );
    });
  }

  async enviarSefaz() {

    this.isDisabled = true;

    const contaParcelaFaturaNfse = await this.nfseService.getContaParcelFaturaByNfseId(this.nfseCadastroForm.value.nfseId).toPromise();
    await this.verificaValorContaParcelaFaturaAposCalculoImpostoNfse(
      this.nfseCadastroForm.value,
      contaParcelaFaturaNfse.body
    );

    var data30DiasAtras = new Date(new Date().setDate(new Date().getDate() - 30));
    let dataEmissao = new Date(this.nfseCadastroForm.value.dtEmissao);

    if (dataEmissao < data30DiasAtras) {
      this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          mensagem: "Não é possível emitir NFS-e com competência superior a 30 dias atrás"
        }
      });
      return;
    }

    this.nfseService.postProcessaNfse(this.nfseCadastroForm.value, this.nfseCadastroForm.value.nfseId).subscribe(
      data => {
        if (data.body == 100) {
          this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Sucesso",
              mensagem: "NFS-e Autorizada"
            }
          });

          this.buscaNfse(this.nfseCadastroForm.value.nfseId);

        } else if(data.body == 2 || data.body == "A02" || data.body == "E4" || data.body == "CE95") {

          this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Processando...",
              mensagem: `O sistema da prefeitura está processando sua Nota Fiscal. Isso pode levar alguns minutos devido à lentidão momentânea. O status será atualizado em breve. Por favor, verifique o status da nota na tela de consulta.`,            }
          }).afterClosed().subscribe(() => {
            this.isDisabled = true;
            this.modalNfseStatus();
          });

        } else {
          this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro " + data.body,
              mensagem: "Erro ao autorizar o NFS-e. Verifique o Log."
            }
          });
          this.isDisabled = false;
        }
      },
      (error: HttpErrorResponse) => {
        this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Erro",
            mensagem: "Erro ao autorizar o NFS-e. Verifique o Log."
          }
        });
        this.isDisabled = false;
      }
    );
  }

  verificarRetornoTardio(){

    this.nfseService.getRetornoTardio(this.nfseCadastroForm.value).subscribe(data => {
      if(data.body){
        this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Erro",
            mensagem: "Nota Fiscal retornou com status de Retorno Tardio. Verifique o Log."
          }
        });
      }
      this.ngOnInit();
    });
  }

  modalNfseStatus(): void {

    const dialogRef = this.dialog.open(NfseStatusComponent, {
      data: {
        nfseId: this.nfseCadastroForm.value.nfseId
      },
      maxHeight: '100vh'
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  corstatusProcessamento(statusProcessamento) {
    let cor = "Erro";
    if (statusProcessamento == 100 || statusProcessamento == 101 || statusProcessamento == 102 || statusProcessamento == 135 || statusProcessamento == 95) {
      cor = "Sucesso";
    };
    if (statusProcessamento == 0 || statusProcessamento == 105) {
      cor = "Processando";
    };

    if (statusProcessamento == undefined) {
      cor = "Processando";
    };

    return cor;
  }

  cancelarNfse() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja realmente cancelar esse NFS-e?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.nfseService.postCancelar(this.nfseCadastroForm.value.nfseId).subscribe(data => {

          if (data.body == 101 || data.body == 102) {

            const dialogRef = this.dialog.open(InfoDialogComponent, {
              data: {
                titulo: "Sucesso",
                mensagem: "NFS-e cancelada com sucesso."
              }
            });
          }
          else {
            const dialogRef = this.dialog.open(InfoDialogComponent, {
              data: {
                titulo: "Erro",
                mensagem: "Erro ao cancelar o NFS-e. Verifique o Log."
              }
            });
          }

          this.buscaNfse(this.nfseCadastroForm.value.nfseId);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error.error
            }
          });
        });

      }
    });
  }

  atualizaStatus(nfseTemp) {

    switch (nfseTemp.statusProcessamento) {
      case "100":
        this.statusNfse = " - Status: Autorizada";
        break;
      case null:
      case "":
      case "0":
      case "105":
      case "2":
        this.statusNfse = " - Status: Em processamento";
        break;
      case "135":
        this.statusNfse = " - Status: Cancelada";
        break;
      default:
        this.statusNfse = " - Status: Erro, verifique o status";
    }
  }

  calculaImpostos() {

    if (this.nfseCadastroForm.value.valorServicos > 0 && this.nfseCadastroForm.value.listaServico != undefined && this.nfseCadastroForm.value.listaServico != null && this.nfseCadastroForm.value.listaServico != '')

      this.nfseService.postCalculaImposto(this.nfseCadastroForm.value).subscribe(data => {

        this.insereDadosNfse(data.body);

      }, (error: HttpErrorResponse) => {

        const dialogRef = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Erro",
            mensagem: error.error
          }
        });
      });
  }

  geraLinkImpressao() {

    this.nfseService.postGeraLinkImpressao(this.nfseCadastroForm.value).subscribe(data => {

      window.open(data.body, '_blank');

    }, (error: HttpErrorResponse) => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          mensagem: error.error
        }
      });
    });
  }

  calculavalorLiquido() {

    this.nfseCadastroForm.value.valorLiquidoNfse = this.nfseCadastroForm.value.valorServicos - this.nfseCadastroForm.value.valorPis - this.nfseCadastroForm.value.valorCofins - this.nfseCadastroForm.value.valorInss - this.nfseCadastroForm.value.valorIr - this.nfseCadastroForm.value.valorCsll - this.nfseCadastroForm.value.outrasRetencoes - this.nfseCadastroForm.value.valorIssRetido - this.nfseCadastroForm.value.descontoIncondicionado - this.nfseCadastroForm.value.descontoCondicionado;

    this.nfseCadastroForm.value.valorLiquidoNfse = Number((this.nfseCadastroForm.value.valorLiquidoNfse).toFixed(2));

  }

  determinaCliente(cliente: any) {

    this.nfseCadastroForm.get('clienteId').setValue(cliente.clienteId);

  }
}
