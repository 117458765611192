'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { NfeStatusService } from '../shared/nfe-status.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

export interface DialogData {
  nfeId: number;
}

@Component({
  selector: 'nfe-status',
  templateUrl: 'nfe-status.component.html',
})

export class NfeStatusComponent {

  maximizado: boolean;
  nfeStatus: any[];

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NfeStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public INfeStatus: DialogData,
    private nfeStatusService: NfeStatusService) {
  }

  ngOnInit() {

    this.maximizado = false;

    if (this.INfeStatus.nfeId > 0) {

      this.nfeStatusService.getNfeStatus(this.INfeStatus.nfeId).subscribe(data => {

        this.nfeStatus = data.body;

      })

    }

    this.restaurarDialog();
  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  modalStatusDetalhe(mensagem: string){

    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        titulo: "Detalhe",
        mensagem: mensagem
      }

    });

  }
}
