export class DocumentoRelacionado {
    documentoId: number = 0;
    descricao: number = 0;
    tipoDocumentoOrigem: TipoDocumento;
    idDocumentoOrigem: number = 0;
    tipoDocumentoDestino: TipoDocumento;
    idDocumentoDestino: number = 0;

}

export enum TipoDocumento {

    
    Compra = 0,
    Venda = 1,
    Devolucao = 2,
    Producao = 3,
    Orcamento = 4,
    Nfe = 5,
    Contrato = 6,
    Remessa = 7,
    FaturaVenda = 8,
    FaturaCompra = 9,
    ContaReceber = 10,
    ContaPagar = 11,
    CaixaMovimento = 12,
    EstoqueMovimento = 13,
    Entrega = 14,
    MDFe = 15,
    ContaReceberRecibo = 16,
    ContaPagarRecibo = 17,
    HistoricoEscolar = 18,
    FuncionarioFoto = 19,
    VeiculoFoto = 20,
    FuncionarioFicha = 21,
    VeiculoFicha = 22,
    Oportunidade = 23,
    OrdemServico = 24
}