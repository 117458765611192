import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContratoService } from '../shared/contrato.service';
import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { FaturaService } from '../../fatura/shared/fatura.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableComponent } from 'src/app/Components/table/table/table.component';
import * as moment from 'moment';
import { Fatura } from 'src/app/fatura/shared/fatura.model';
import { DocumentoRelacionado, TipoDocumento } from 'src/app/Components/documentos-relacionados/shared/documentos-relacionados.model';
import { Contrato } from '../shared/contrato.model';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

export interface DialogData {
  contrato: Contrato;
}

@Component({
  selector: 'contrato-fatura',
  templateUrl: 'contrato-fatura.component.html',
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },

    },
    [DatePipe]
  ],
  styleUrls: ['contrato-fatura.component.css']

})

export class ContratoFaturaComponent {
  contrato: any = {};
  empresa: any = {};
  empresas: any = [];
  configuracoesFatura: FormGroup;
  maximizado: boolean;
  lastSelection: Range | null = null;
  dataSource = new MatTableDataSource<any>([]);
  itensSelecionados: any = [];
  integracaoSOC: boolean = false;
  parcelasFiltradas: any[] = [];
  totalParcelas: any;
  faturaParcelas: any;
  contratoParcelasItensSelecionados: any[] = [];

  @ViewChild('stepper') stepper: MatStepper;

  columns = [
    { def: 'descricao', header: 'Item' },
    { def: 'quantidade', header: 'Quantidade.' },
    { def: 'valorTotalLiquido', header: 'Valor Total' }
  ];

  @ViewChild(TableComponent) tableComponent: TableComponent;
  grupoContas: any;
  centroCustos: any;
  caixas: any;
  faturas: Fatura[] = [];
  fatura: Fatura = new Fatura();

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ContratoFaturaComponent>,
    private _formBuilder: FormBuilder,
    private htmlPrinterService: HtmlPrinterService,
    @Inject(MAT_DIALOG_DATA) public IContrato: DialogData,
    private utilsService: UtilsService,
    private datePipe: DatePipe,
    private contratoService: ContratoService, private faturaService: FaturaService, private router: Router) {

    this.contrato = this.IContrato.contrato;


  }

  ngOnInit() {




    this.dataSource.data = this.contrato.contratoItens.map(item => {
      return {
        ...item,
        valorTotalLiquido: item.valorTotalLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      };
    })



    this.buscarIntegracaoSOC();
    this.maximizado = false;
    const date = new Date();

    this.configuracoesFatura = this._formBuilder.group({
      formArray: this._formBuilder.array([
        this._formBuilder.group({
          empresaId: ['', Validators.required]
        }),
        this._formBuilder.group({
          itens:  this._formBuilder.array([],this.utilsService.minArrayLengthValidator(1)),
        }),
        this._formBuilder.group({
          quaisParcelasFaturar: ['', Validators.required],
          dataInicio: [new Date(date.getFullYear(), date.getMonth(), 1)],
          dataFim: [new Date(date.getFullYear(), date.getMonth() + 1, 0)],
          parcelas:  this._formBuilder.array([],this.utilsService.minArrayLengthValidator(1)),
        }),
        this._formBuilder.group({ comoFaturar: ['', Validators.required] }),
        this._formBuilder.group({
          grupoContaId: [0],
          centroCustoId: [0],
          caixaId: [0]}),
        this._formBuilder.group({ substituirSoc: [true, Validators.required] })
      ])
    });

    this.buscarEmpresas();



    this.configuracoesFatura.get('formArray').get([2]).get('quaisParcelasFaturar').valueChanges.subscribe(value => {
      const dtInicio = this.configuracoesFatura.get('formArray').get([2]).get('dataInicio');
      const dtFim = this.configuracoesFatura.get('formArray').get([2]).get('dataFim');
      if (value === 'algumas') {
        dtInicio.setValidators([Validators.required]);
        dtFim.setValidators([Validators.required]);
      } else {
        dtInicio.clearValidators();
        dtFim.clearValidators();
      }
      dtInicio.updateValueAndValidity();
      dtFim.updateValueAndValidity();
    });

    this.configuracoesFatura.get('formArray').get([0]).get('empresaId').valueChanges.subscribe(value => {

      this.buscarCentroCustos(value);

      this.buscarGrupoContas(value);

      this.buscarCaixas(value);

    })

    this.configuracoesFatura.get('formArray').get([2]).get('quaisParcelasFaturar').valueChanges.subscribe(value => {
      this.definirParcelas();
    });
    this.configuracoesFatura.get('formArray').get([2]).get('dataInicio').valueChanges.subscribe(value => {
      this.definirParcelas();
    });
    this.configuracoesFatura.get('formArray').get([2]).get('dataFim').valueChanges.subscribe(value => {
      this.definirParcelas();
    });


  }


  onSelectionChange(event: any) {

    this.itensSelecionados = event;


   // Acessa o formArray do contratoForm
   const formArray =  this.configuracoesFatura.get('formArray') as FormArray;

   // Acessa o FormGroup na posição 1 do formArray
   const formGroup = formArray.at(1) as FormGroup;

   // Acessa o contratoItens FormArray dentro do FormGroup
   const itensArray = formGroup.get('itens') as FormArray;

   // Limpa o FormArray existente
   itensArray.clear();

   //para cada item selecionado de this.itensSelecionados, buscar o item pelo contratoItemId em this.contrato.contratoItens e salvar no array itensArray
    this.itensSelecionados.forEach(item => {
      const itemEncontrado = this.contrato.contratoItens.find(i => i.contratoItemId === item.contratoItemId);
      itensArray.push(new FormControl(itemEncontrado));
    });



  }

  salvarFaturas(){
    this.faturaService.postFaturas(this.faturas).subscribe(data => {
      this._snackBar.open('📑 Fatura(s) criadas com Sucesso! ', '✖', {
        duration: 5000, panelClass: ['mat-toolbar', 'succes']
      });

      //redirecionar para a tela de faturas
      this.router.navigate(['/faturavenda/visualizatodas']);


      this.dialogRef.close(true);


    });

  }

 IniciarProcesso(){

  this.fatura.faturaTipo = 0;
  this.totalParcelas = this.contrato.contratoParcelas?.length;
  this.fatura.faturaTipoDocumento = this.contrato.contratoItens[0].produto.produtoTipo == 0 ? 0 : 1;
  this.fatura.empresaId = this.configuracoesFatura.get('formArray').get([0]).get('empresaId').value;
  this.fatura.parceiroId = this.contrato.parceiroId;
  this.fatura.faturaStatus = 0;
  this.fatura.dataCriacao = new Date();
  this.fatura.caixaId = this.contrato.caixaId;
  this.fatura.grupoContaId = this.contrato.grupoContaId;
  this.fatura.centroCustoId = this.contrato.centroCustoId;
  this.fatura.contratoId = this.contrato.contratoId;



}

AdicionarItens(){

  this.fatura.faturaItens = this.configuracoesFatura.get('formArray').get([1]).get('itens').value.map(item => {
    return {

      produtoId: item.produtoId,
      codProd: item.codProd,
      qtdTributaria: item.quantidade,
      qtdComercial: item.quantidade,
      vlrTributario: item.valorUnitario,
      vlrComercial: item.valorUnitario,
      valorUnitario: item.valorUnitario,
      valorTotal: item.valorTotalLiquido,
      valorBruto: item.valorTotalBruto,
      valorDesconto: item.valorTotalBruto - item.valorTotalLiquido,
      produto: item.produto,
      descricao: item.descricao,
      extipi: item.produto.extipi,
      InfAdProd: item.produto.InfAdProd,


    };
  })

  this.recalculaParcelas();

}

recalculaParcelas(){

  if(this.contrato?.contratoParcelas?.length>0){


  //substitui
  const parcelasTemp:any[] = [];
  const numeroParcelas = this.totalParcelas;
  const valorLiquido = this.contrato.valorTotalLiquido;

  //somar todos os valores valorTotal dos itens presentes em this.fatura.faturaItens e salvar na variável const faturaValorLiquido
  const itensSelecioandosValorTotalLiquido = this.fatura.faturaItens.reduce((sum, item) => sum + item.valorTotal, 0);




  let i = 0;

  let diferenca = itensSelecioandosValorTotalLiquido;

  for (let parcela of this.contrato.contratoParcelas) {

const porcentagem = itensSelecioandosValorTotalLiquido / valorLiquido;
const valorParcela = parseFloat((parcela.valorLiquido * porcentagem).toFixed(2));
diferenca = diferenca - valorParcela;

let valorParcelaAjustada = i === numeroParcelas - 1 ? valorParcela + diferenca : valorParcela;
valorParcelaAjustada = parseFloat(valorParcelaAjustada.toFixed(2));

parcelasTemp.push({
  contratoParcelaId: parcela.contratoParcelaId,
  dataVencimento: parcela.dataVencimento,
  valorLiquido: valorParcelaAjustada,
  tipoPagamento: parcela.tipoPagamento
});

i++;


  }

  this.contratoParcelasItensSelecionados = parcelasTemp;

}else{
  this._snackBar.open('😖 Ops, não existem parcelas para esse contrato! Feche essa janela, volte para o contrato e ajuste a forma de pagamento para continuar', '✖', {
    duration: 5000, panelClass: ['mat-toolbar', 'warn']

  });

}}




// Exemplo de método para ir para um step específico
goToStep(stepIndex: number): void {
  this.stepper.selectedIndex = stepIndex;
}

definirParcelas(){

  if(this.configuracoesFatura.get('formArray').get([2]).get('quaisParcelasFaturar').value == 'todas'){

    this.faturaParcelas = this.contratoParcelasItensSelecionados.map(parcela => {
      return {
        dataVencimento: parcela.dataVencimento,
        valorParcela: parcela.valorLiquido,
        valorBruto: parcela.valorLiquido,
        saguiClienteId: parcela.saguiClienteId
      };
    });
  }else{

    let dataInicio = this.configuracoesFatura.get('formArray').get([2]).get('dataInicio').value;
    let dataFim = this.configuracoesFatura.get('formArray').get([2]).get('dataFim').value;

    //filtrar a propriedade dataVencimento de this.contrato.contratoParcelas por dataInicio e dataFim
    this.faturaParcelas = this.contratoParcelasItensSelecionados.filter(parcela => {
      const dataVencimento = moment(parcela.dataVencimento);
      return dataVencimento.isBetween(dataInicio, dataFim, 'days', '[]');
    }).map(parcela => {
      return {
        dataVencimento: parcela.dataVencimento,
        valorParcela: parcela.valorLiquido,
        valorBruto: parcela.valorLiquido,
        saguiClienteId: parcela.saguiClienteId
      };
    });

    if(!(this.faturaParcelas?.length > 0)){
      this._snackBar.open('😖 Ops, não existem parcelas no período selecionado para esse contrato! Insira um período de data válido para continuar', '✖', {
        duration: 5000, panelClass: ['mat-toolbar', 'warn']

      });


    }


  }

   // Acessa o formArray do contratoForm
   const formArray =  this.configuracoesFatura.get('formArray') as FormArray;

   // Acessa o FormGroup na posição 1 do formArray
   const formGroup = formArray.at(2) as FormGroup;

   // Acessa o contratoItens FormArray dentro do FormGroup
   const parcelasArray = formGroup.get('parcelas') as FormArray;

   // Limpa o FormArray existente
   parcelasArray.clear();

   // Adiciona novos FormControls para cada item
   this.faturaParcelas.forEach(parcela => {
    parcelasArray.push(new FormControl(parcela));
   });

}

definirFinanceiro(){
  this.fatura.caixaId = this.configuracoesFatura.get('formArray').get([4]).get('caixaId').value;
  this.fatura.grupoContaId = this.configuracoesFatura.get('formArray').get([4]).get('grupoContaId').value;
  this.fatura.centroCustoId = this.configuracoesFatura.get('formArray').get([4]).get('centroCustoId').value;
}

definirListaFaturas(){

  this.faturas = [];

  this.fatura.isSocCancela = this.configuracoesFatura.get('formArray').get([5]).get('substituirSoc').value;

  //se this.configuracoesFatura.get('formArray').get([3]).get('comoFaturar').value == 'separado' para cada parcela do contrato, será criada uma fatura
 //se this.configuracoesFatura.get('formArray').get([3]).get('comoFaturar').value == 'unica' será criada uma fatura com todas as parcelas do contrato
  if(this.configuracoesFatura.get('formArray').get([3]).get('comoFaturar').value == 'separado'){

    this.faturaParcelas.forEach(parcela => {
       // Criar uma nova instância de fatura para cada parcela
       let novaFatura = { ...this.fatura };

       novaFatura.faturaParcelas = [{
        dataVencimento: parcela.dataVencimento,
        valorParcela: parcela.valorParcela,
        valorBruto: parcela.valorBruto,
        saguiClienteId: parcela.saguiClienteId,
        faturaParcelaId: 0, // ou algum valor padrão
        valorDesconto: parcela.descontos, // ou algum valor padrão
        valorLiquido: parcela.valorParcela, // ou algum valor calculado
        textoAdicional: '', // ou algum valor padrão
        faturaId: parcela.faturaId // ou algum valor padrão
      }];

        // Supondo que novaFatura já esteja definido
        novaFatura.faturaItens = [];

        const itensSelecionadosValorTotal = (this.fatura.faturaItens.reduce((sum, item) => sum + item.valorTotal, 0));
        const itensSelecionadosValorUnitario = this.fatura.faturaItens.reduce((sum, item) => sum + item.valorUnitario, 0);
        const itensSelecionadosValorBruto = this.fatura.faturaItens.reduce((sum, item) => sum + item.valorBruto, 0);
        const itensSelecionadosDesconto = this.fatura.faturaItens.reduce((sum, item) => sum + item.valorDesconto, 0);


        // Iterar sobre os itens de this.fatura.faturaItens para ajustar o valor do item para correesponder ao valor da parcela
        this.fatura.faturaItens.forEach(item => {
          const valorTotal = itensSelecionadosValorTotal >0? (item.valorTotal / itensSelecionadosValorTotal) * parcela.valorParcela:0;
          const valorBruto = itensSelecionadosValorBruto>0 ?(item.valorBruto / itensSelecionadosValorBruto) * parcela.valorParcela:0;
          const valorDesconto = itensSelecionadosDesconto>0 ? (item.valorDesconto / itensSelecionadosDesconto) * parcela.valorParcela: 0;
          const valorUnitario = valorTotal / item.qtdComercial;

          const novoItem = {
            ...item, // Copia todas as outras propriedades do item original
            vlrTributario: valorUnitario,
            vlrComercial: valorUnitario,
            valorUnitario: valorUnitario,
            valorTotal: valorTotal,
            valorBruto: valorBruto,
            valorDesconto: valorDesconto
          };

          // Adicionar o novo item ao array novaFatura.faturaItens
          novaFatura.faturaItens.push(novoItem);
        });

       novaFatura.diaVencimento = new Date(parcela.dataVencimento).getDate();;
       novaFatura.numeroParcelas = 1;
       novaFatura.valorBruto = parcela.valorParcela;
       novaFatura.valorTotal = parcela.valorParcela;;
       novaFatura.valorLiquido = parcela.valorParcela;
       novaFatura.valorDesconto = this.contrato.somaDeTodosOsDescontos / this.totalParcelas;
       novaFatura.descricao = 'ContratoId: ' + this.contrato.contratoId + ' - Fatura referente a parcela de venc. em: ' + this.datePipe.transform(parcela.dataVencimento, 'dd/MM/yyyy');

       this.faturas.push(novaFatura);
    });
  }else{
    this.fatura.faturaParcelas = this.faturaParcelas;


    this.fatura.diaVencimento = new Date(this.faturaParcelas[0].dataVencimento).getDate();
    this.fatura.numeroParcelas = this.totalParcelas;
    this.fatura.valorBruto = this.contrato.valorTotalBruto;
    this.fatura.valorTotal = this.contrato.valorTotalBruto;
    //inserir this.fatura.valorLiquido como a soma de todos valores de this.faturaParcelas
    this.fatura.valorLiquido = this.faturaParcelas.reduce((sum, item) => sum + item.valorParcela, 0);

    this.fatura.valorDesconto = this.contrato.somaDeTodosOsDescontos;
    this.fatura.descricao = 'ContratoId: ' + this.contrato.contratoId + ' Fatura referente a todas as parcelas do contrato';



    this.faturas.push(this.fatura);
  }

 }

  buscarIntegracaoSOC() {
    this.faturaService.getIntegracoesSOC().subscribe(data => {
      if (data.body) {
        this.integracaoSOC = true;
      }
    });
  }

  buscarGrupoContas(empresaId:any) {

    this.faturaService.getgrupoContas(0, empresaId).subscribe(data => {

      this.grupoContas = data.body;

    });
  }

  buscarCaixas(empresaId:any) {

    this.faturaService.getCaixas(0, empresaId).subscribe(data => {

      this.caixas = data.body;

    });
  }

  buscarCentroCustos(empresaId:any) {

    this.faturaService.getCentroCustos(0, empresaId).subscribe(data => {

      this.centroCustos = data.body;

    });
  }



  buscarEmpresas() {
    this.contratoService.getEmpresa().subscribe(data => {
      this.empresas = data.body;
      if (this.contrato.Empresas > 0) {
        this.configuracoesFatura.get('formArray').get([0]).get('empresaId').setValue(this.contrato.ContratoEmpresa[0].empresaId);
      } else {
        this.configuracoesFatura.get('formArray').get([0]).get('empresaId').setValue(this.contrato.empresaId);
      }
    });
  }

  verificaErro(){
    if(!(this.faturaParcelas?.length > 0)){
      return true;
    }

    return false;

  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;
    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    } else {
      this.dialogRef.updateSize('70%', '100vh');
    }
    this.maximizado = false;
  }

  getSelectedRows() {
    this.itensSelecionados = this.tableComponent.selection.selected;
    return this.itensSelecionados;
  }

  isItemSelected(): boolean {
    if (this.tableComponent && this.tableComponent.selection) {
      return this.getSelectedRows().length > 0;
    }
    return false;
  }



  /*onProximaEtapa() {
    const quaisParcelasFaturar = this.configuracoesFatura.get('formArray').get([2]).get('quaisParcelasFaturar').value;
    const dataInicio = this.configuracoesFatura.get('formArray').get([2]).get('dataInicio').value;
    const dataFim = this.configuracoesFatura.get('formArray').get([2]).get('dataFim').value;

    if (quaisParcelasFaturar === 'todas') {
      this.parcelasFiltradas = this.contrato.ContratoParcelas;
    } else {
      this.parcelasFiltradas = this.contrato.ContratoParcelas.filter(parcela => {
        const dataVencimento = moment(parcela.dataVencimento);
        return dataVencimento.isBetween(dataInicio, dataFim, 'days', '[]');
      });
    }
    // Agora você tem as parcelas filtradas em `this.parcelasFiltradas`.
    console.log(this.parcelasFiltradas);
  }*/

  /** Returns a FormArray with the name 'formArray'. */
  get formArray(): AbstractControl | null { return this.configuracoesFatura.get('formArray'); }

  onStepChange(event: StepperSelectionEvent): void {
    const currentIndex = event.previouslySelectedIndex;
    const nextIndex = event.selectedIndex;

    // Função para rodar quando sair de um step
    this.onStepExit(currentIndex, nextIndex);
  }


  centroCusto() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/financeiro/centrocusto']));
    window.open(url, '_blank');
  }

  categoriasFinanceiras() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/financeiro/categoriacontareceber']));
    window.open(url, '_blank');
  }

  caixaFinanceiro() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/financeiro/fluxocaixa']));
    window.open(url, '_blank');
  }

  onStepExit(currentIndex: number, nextIndex: number): void {

    if(currentIndex === 0){
      this.IniciarProcesso();
    }

    if(currentIndex === 1){
      this.AdicionarItens();
    }

    if(currentIndex === 4){
      this.definirFinanceiro();
    }


    if (nextIndex === 6 && this.integracaoSOC) {
      this.definirListaFaturas();
    }else{
      if (nextIndex === 5){
        this.definirListaFaturas();
      }

    }

  }

}
