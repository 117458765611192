<h3 mat-dialog-title>Confirmação de Retenção</h3>
<div mat-dialog-content>
    <p>{{data.mensagem}}</p>
</div>
<div mat-dialog-actions class="dialog-actions" fxLayout="column" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-raised-button color="primary" (click)="onSimDescontarRetencaoClick()">Sim, descontar retenções</button>
        <button mat-raised-button color="accent" (click)="onNaoDescontarRetencaoClick()">Não, não descontar retenções</button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-raised-button color="warn" (click)="onCancelarOperacaoClick()">Cancelar o processo</button>
    </div>
</div>
