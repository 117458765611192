export class ContratoItem {

    contratoItemId: number = 0;

    contratoId: number = 0;

    produtoId: number = 0;

    valorFrete: number = 0;

    valorSeguro: number = 0;

    descontoUnitario: number = 0;

    valorOutrasDespesas: number = 0;

    valorUnitario: number = 0;

    unidade: number = 0;

    quantidade: number = 0;

    valorTotalBruto: number = 0;

    valorTotalLiquido: number = 0;

    informacoesAdicionais: string = '';

    produtoTipo: number = 0;

    descricao: string = '';

    codProd: string = '';

}
