'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

import { ContaCorrenteVendaParcela } from '../shared/conta-corrente-venda-parcela.model';
import { ContaCorrenteVendaParcelaService } from '../shared/conta-corrente-venda-parcela.service';

export interface DialogData {
  contaCorrenteVendaParcelaId: number;
  contaCorrenteVendaId: number;
}

@Component({
  selector: 'conta-corrente-venda-parcela',
  templateUrl: 'conta-corrente-venda-parcela.component.html',
})

export class ContaCorrenteVendaParcelaComponent {

  contaCorrenteVendaParcelaForm: FormGroup;
  maximizado: boolean;
  contaCorrenteVendaParcelaParcelas: any = [];
  cartao: any = {};

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ContaCorrenteVendaParcelaComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public IContaCorrenteVendaParcela: DialogData,
    private contaCorrenteVendaParcelaservice: ContaCorrenteVendaParcelaService) {
  }

  ngOnInit() {

    this.maximizado = false;

    this.contaCorrenteVendaParcelaForm = this._formBuilder.group(new ContaCorrenteVendaParcela());

    if (this.IContaCorrenteVendaParcela.contaCorrenteVendaParcelaId > 0) {

      this.buscarContaCorrenteVendaParcela(this.IContaCorrenteVendaParcela.contaCorrenteVendaParcelaId);

    } else {

      this.contaCorrenteVendaParcelaForm.get('.contaCorrenteVendaId').setValue(this.IContaCorrenteVendaParcela.contaCorrenteVendaId);

    }

    this.onChanges();

    this.restaurarDialog();
  }

  onChanges(): void {

  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {

    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  buscarContaCorrenteVendaParcela(contaCorrenteVendaParcelaId: number) {

    this.contaCorrenteVendaParcelaservice.getPorId(contaCorrenteVendaParcelaId).subscribe(data => {

      this.contaCorrenteVendaParcelaForm.patchValue(data.body);

    }, (error: HttpErrorResponse) => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          erro: error
        }
      });
    })
  }

  salvar(): void {

    if (this.contaCorrenteVendaParcelaForm.value.contaCorrenteVendaParcelaId == 0) {

      this.contaCorrenteVendaParcelaservice.post(this.contaCorrenteVendaParcelaForm.value).subscribe(data => {

        this.buscarContaCorrenteVendaParcela(data.body.contaCorrenteVendaParcelaId);

      });

    }
    else {
      this.contaCorrenteVendaParcelaservice.put(this.contaCorrenteVendaParcelaForm.value, this.contaCorrenteVendaParcelaForm.value.contaCorrenteVendaParcelaId).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

        this.buscarContaCorrenteVendaParcela(this.contaCorrenteVendaParcelaForm.value.contaCorrenteVendaParcelaId);

      });
    }
  };
}
