import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  mensagem: string;
}

@Component({
  selector: 'app-descontar-retencao-dialog',
  templateUrl: './descontar-retencao-dialog.component.html',
  styleUrls: ['./descontar-retencao-dialog.component.css']
})
export class DescontarRetencaoDialogComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DescontarRetencaoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit() {

    let innerWidth = window.innerWidth;


  }


  onSimDescontarRetencaoClick(): void {
    this.dialogRef.close(1);
  }

  onNaoDescontarRetencaoClick(): void {
    this.dialogRef.close(2);
  }


  onCancelarOperacaoClick(): void {
    this.dialogRef.close(3);
  }

}
