'use strict';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

import { DocumentoTemplateService } from '../shared/documento-template.service';
import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';
import { exportExcelService } from '../../core/export-excel/export-excel.service';

import { DocumentoTemplateCadastroComponent } from '../documento-template-cadastro/documento-template-cadastro.component';

import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { DocumentoTemplate } from '../shared/documento-template.model';

import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'documento-template-all',
    templateUrl: 'documento-template.component.html',
})
export class DocumentoTemplateComponent implements OnInit {

    documentoTemplates: any[];
    documentoTemplateForm: FormGroup;

    constructor(
        public dialog: MatDialog,
    private _snackBar: MatSnackBar,
        private documentoTemplateService: DocumentoTemplateService,
        private exportExcelService: exportExcelService,
        private htmlPrinterService: HtmlPrinterService,
        private _formBuilder: FormBuilder) {

    }

    ngOnInit() {

        this.documentoTemplateForm = this._formBuilder.group({
            ativo: true,
            razaoSocial: ['']
        });

        this.buscarDocumentoTemplates();

    }

    buscarDocumentoTemplates() {

        this.documentoTemplateService.getDocumentoTemplates().subscribe(data => {

            this.documentoTemplates = data.body;

        });
    };

    confirmarExclusao(cancelarId: number) {

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                titulo: "Confirmação",
                mensagem: "Deseja realmente excluir esse documentoTemplate?"
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                this.documentoTemplateService.deleteDocumentoTemplate(cancelarId).subscribe(data => {

                    this._snackBar.open("Registro excluído com Sucesso");

                    this.buscarDocumentoTemplates();

                });
            }
        });
    }


    DocumentoTemplateModal(id) {

        const dialogRef = this.dialog.open(DocumentoTemplateCadastroComponent, {
            data: {
                documentoTemplateId: id,
            },
            maxHeight: '100vh',
            maxWidth: '100vw'
        });

        dialogRef.afterClosed().subscribe(result => {

            this.buscarDocumentoTemplates();

        });
    };

    imprimirDiv(divName: string) {

        let titulo = "Templates";

        this.htmlPrinterService.printDiv(divName, titulo);
    }

    exportExcel(divName: string) {

        this.exportExcelService.SalvarArquivo(divName, 'DocumentoTemplate');

    };

    retornaTextodocumentoTipo(documentoTipo) {

        let texto = "";

        switch (documentoTipo) {
            case 4:
                texto = "Orçamento";
                break;
            case 8:
                texto = "Fatura de Venda";
                break;
            case 16:
                texto = "Recibo do Contas a Receber";
                break;
            case 17:
                texto = "Recibo do Contas a Pagar";
                break;
            case 18:
                texto = "Histórico Escolar";
                break;
            case 21:
                texto = "Ficha de Funcionário";
                break;
            case 22:
                texto = "Ficha de Veículo";
                break;
            case 24:
                texto = "Ordem de Serviço";
                break;
        }

        return texto;
    }
}
