'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ClienteHistoricoService } from '../shared/cliente-historico.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

import { ClienteHistorico } from '../shared/cliente-historico.model';

export interface DialogData {
    clienteHistoricoId: number;
    clienteId: number;
}

@Component({
    selector: 'cliente-historico',
    templateUrl: 'cliente-historico.component.html',
})

export class ClienteHistoricoComponent {

    clienteHistoricoForm: FormGroup;
    maximizado: boolean;

    constructor(
        public dialog: MatDialog,
    private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<ClienteHistoricoComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public IClienteHistorico: DialogData,
        private clienteHistoricoService: ClienteHistoricoService) {
    }

    ngOnInit() {

        this.maximizado = false;

        this.clienteHistoricoForm = this._formBuilder.group(new ClienteHistorico());

        this.clienteHistoricoForm.get('clienteHistoricoId').setValue(this.IClienteHistorico.clienteHistoricoId);
        this.clienteHistoricoForm.get('clienteId').setValue(this.IClienteHistorico.clienteId);

        if (this.IClienteHistorico.clienteHistoricoId > 0) {

            this.buscarClienteHistorico(this.IClienteHistorico.clienteHistoricoId);
        }

        this.restaurarDialog();
    }

    fecharDialog() {
        this.dialogRef.close();
    }

    maximizarDialog() {
        this.dialogRef.updateSize('100%', '100%');
        this.maximizado = true;
    }

    restaurarDialog() {
        let innerWidth = window.innerWidth;

        if (innerWidth < 1024) {
            this.dialogRef.updateSize('90%', '100vh');
        }
        else {
            this.dialogRef.updateSize('60%', '100vh');
        }
        this.maximizado = false;
    }

    buscarClienteHistorico(clienteHistoricoId: number) {

        this.clienteHistoricoService.getClienteHistorico(clienteHistoricoId).subscribe(data => {

            this.InsereDados(data.body);

        })
    }

    InsereDados(data: any) {

        this.clienteHistoricoForm.patchValue(data);

    }

    salvar(clienteHistoricoForm): void {

        if (this.IClienteHistorico.clienteHistoricoId == 0) {

            this.clienteHistoricoService.postClienteHistorico(clienteHistoricoForm.value).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.fecharDialog();


            }, (error: HttpErrorResponse) => {

                const dialogRef = this.dialog.open(InfoDialogComponent, {
                    data: {
                        titulo: "Erro",
                        mensagem: error.error
                    }
                });
            });
        }
    };
}
