export class Produto {

  produtoId: number = 0;

  ativo: boolean = true;

  produtoTipo: number = 0;

  codProd: string = '';

  descricao: string = '';

  ncm: string = '';

  ean: string = '';

  eanTrib: string = '';

  unTributaria: string = '';

  vlrTributario: number = 0;

  unComercial: string = '';

  vlrComercial: number = 0;

  orig: number = 0;

  unidadeCompra: string = '';

  valorCompra: number = 0;

  indServico: boolean = true;

  cListServ: string = '';

  servicoDescricao: string = '';

  cServico: string = '';

  servicoDiscriminacaoPadrao: string = '';

  servicoListaId: number = 0;

  contaReceberGrupoContaId: number = 0;

  contaPagarGrupoContaId: number = 0;

  contaReceberCaixaId: number = 0;

  contaPagarCaixaId: number = 0;

  extipi: number = 0;

  codBarras: string = '';

  infAdProd: string = '';

  numeroPatrimonio: string = '';

  cest: number = 0;

  saguiClienteId: number = 0;

  produtoGrupoId: number = 0;

  embalagemId: number = 0;

  embalagemCapacidade: number = 0;

  servicoLista: any = {};

  empresaId: number = 0;

  recorrencia: number = 0;

  utilizaGetNetPlano: boolean = false;

  getNetPlanId: string = '00000000-0000-0000-0000-000000000000';

  caixaId: number = 0;

  grupoContaId: number = 0;

  pesoLiquido: number = 0;

  pesoBruto: number = 0;
  codSoc: string = '';

}
