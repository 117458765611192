'use strict'
import { Component, EventEmitter, Inject, Input, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContratoService } from '../shared/contrato.service';
import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { ContratoSignatario } from '../shared/contrato-signatario.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { MatTableDataSource } from '@angular/material/table';



@Component({
    selector: 'app-cadastro-signatario',
    templateUrl: 'cadastro-signatario.component.html',
})

export class CadastroSignatarioComponent {

    @Input() metodoAssinatura: any;
    @Input() contratoSignatariosAnterior:any[]=[];
    @Input() panelOpenState: boolean = true;

    @Output() contratoSignatariosNovos = new EventEmitter<any[]>();

    signatarioForm: FormGroup;
    acaoTexto: any = '';
    contratosSignatariosFormatado: any[] = [];
    signatariosContrato = new MatTableDataSource<ContratoSignatario>([]);
    contratoSignatarios: any[] = [];


    optionsAcao = [
        { value: 1, viewValue: 'Assinar' },
        { value: 2, viewValue: 'Aprovar' },
        { value: 3, viewValue: 'Reconhecer' },
        { value: 4, viewValue: 'Assinar como parte' },
        { value: 5, viewValue: 'Assinar como testemunha' },
        { value: 6, viewValue: 'Assinar como interveniente' },
        { value: 7, viewValue: 'Acusar recebimento' },
        { value: 8, viewValue: 'Assinar como Emissor, Endossante e Avalista' }
      ];

      columnsSignatario = [];

    constructor(
         private _snackBar: MatSnackBar,
        private contratoService: ContratoService, 
        private _formBuilder: FormBuilder,
        private utilsService: UtilsService
    ) {
    }

    ngAfterViewInit() {

        this.signatarioForm.get('estrangeiro').setValue(false);
        this.signatarioForm.get('acao').setValue(1);
    
    }

    ngOnInit() {

        this.initializeColumns();

        this.signatarioForm= this._formBuilder.group(new ContratoSignatario());

        this.signatarioForm.get('nome').setValidators(Validators.required);
        this.signatarioForm.get('acao').setValidators(Validators.required);
        this.signatarioForm.get('email').setValidators(Validators.required);
        this.signatarioForm.get('email').setValidators(this.utilsService.emailValidator())
        this.signatarioForm?.get('acao').updateValueAndValidity();
        this.signatarioForm?.get('nome').updateValueAndValidity();
        this.signatarioForm?.get('email').updateValueAndValidity();

          //se o email for diferente de vazio, inserir o validator de e-mail
        this.signatarioForm.get('email').valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(data => {
            if(data?.length > 0){
            this.signatarioForm.get('email').setValidators(this.utilsService.emailValidator());
            }
        });

        
        this.signatarioForm.get('acao')?.valueChanges.pipe(
        startWith(this.signatarioForm.get('acao')?.value)
        ).subscribe(value => {
            const selectedOption = this.optionsAcao.find(option => option.value === value);
            this.acaoTexto = selectedOption ? selectedOption.viewValue : null;
        });

        this.signatarioForm.get('estrangeiro').valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
          ).subscribe(data => {
            if(data!==true){
              this.signatarioForm.get('cpf').setValidators(Validators.required);
              this.signatarioForm.get('rg').setValidators(Validators.required);
          
              this.signatarioForm.get('cpf').setValidators(this.utilsService.cpfValidator());
          
          
            }else{
              this.signatarioForm.get('cpf').clearValidators();
              this.signatarioForm.get('rg').clearValidators();
          
            }
          
            this.signatarioForm.get('cpf').updateValueAndValidity();
            this.signatarioForm.get('rg').updateValueAndValidity();
          });
        

    }


    initializeColumns() {
        this.columnsSignatario = [
          { def: 'acao', header: 'Tipo' },
          { def: 'nome', header: 'Nome' },
          { def: 'email', header: 'email' },
          { def: 'acaoTable', header: 'Ações' },
        ];
    
        if (this.metodoAssinatura) {
          this.columnsSignatario.unshift({ def: 'assinado', header: 'Assinou' });
        }
      }

    ngOnChanges(changes: SimpleChanges) {
        
        //Insere o validator de obrigatorio no email apenas se o Método de Assinatura for por email
     
        if (changes.metodoAssinatura && changes.metodoAssinatura.currentValue!==changes.metodoAssinatura.previousValue) {
            if( changes.metodoAssinatura.currentValue !== 2){
                this.signatarioForm?.get('email').setValidators(Validators.required);
                this.signatarioForm?.get('assinado').disable();
            
            }else{
                this.signatarioForm?.get('email').clearValidators();
                this.signatarioForm?.get('assinado').enable();
            }
            
            this.signatarioForm?.get('email').updateValueAndValidity();
            this.signatarioForm?.get('assinado').updateValueAndValidity();
        }
             if(changes.contratoSignatariosAnterior && changes.contratoSignatariosAnterior.currentValue!==changes.contratoSignatariosAnterior.previousValue){
             
                this.contratoSignatarios = this.contratoSignatariosAnterior;
                if(this.contratoSignatariosAnterior?.length>0){
                    this.contratoSignatariosAnterior.forEach(element => {
                        this.contratosSignatariosFormatado.push({
                            nome: element.nome, 
                            email: element.email,
                            acao: this.optionsAcao[element.acao-1].viewValue,
                            assinado: element.assinado ? 'Sim' : 'Não'
                        });
                    }); 
                }
                this.signatariosContrato.data = this.contratosSignatariosFormatado;
  
            }
    }

    addSignatario() {
  
        this.contratoSignatarios.push(this.signatarioForm.value);
        this.contratosSignatariosFormatado.push({
          nome: this.signatarioForm.get('nome').value, 
          email: this.signatarioForm.get('email').value,
          //retorna o texto do campo Ação do formilário SignatarioForm ao invés do valor
          acao: this.acaoTexto,
          assinado: this.signatarioForm.get('assinado').value ? 'Sim' : 'Não'
          });
      
        // Atribuição dos valores ao MatTableDataSource
        this.signatariosContrato.data = this.contratosSignatariosFormatado;

        //enviar os novos signatários para o componente pai
        this.contratoSignatariosNovos.emit(this.contratoSignatarios);

        this.panelOpenState = false;
    
        //limpar todos os campos do formulario signarioForm após a inserção sem remover as funções onChanges
        this.signatarioForm.reset();
    
    
    
    }

    onExcluirSignatario(idToRemove: any): void {
     
        // Encontrar o índice do item a ser removido
        const index = this.contratoSignatarios.findIndex(item => item.nome === idToRemove);
      
        // Remover o item se encontrado
        if (index !== -1) {
            this.contratoSignatarios.splice(index,1);
            this.contratosSignatariosFormatado.splice(index,1);
          
    
        }
      
        // Atualizar os dados da tabela
        this.signatariosContrato.data = this.contratosSignatariosFormatado;

         //enviar os novos signatários para o componente pai
         this.contratoSignatariosNovos.emit(this.contratoSignatarios);
     
      }

     
}
