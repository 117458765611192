export class OrcamentoParcela {
    orcamentoParcelaId: number = 0;
    dataVencimento: Date = new Date();
    valorParcela: number = 0;
    valorDesconto: number = 0;
    valorBruto: number = 0;
    valorLiquido: number = 0;
    tipoPagamento: number = 0;
    textoAdicional: string = '';
    orcamentoId: number = 0;
    produtoTipo: number = 0;
    saguiClienteId: number = 0;
}