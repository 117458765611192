import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpClientModule } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class MunicipioService {

    getMunicipio(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/municipio/visualiza/' + itemId, { observe: 'response' })
    }

    getMunicipioPorUf(ufId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/municipio/buscaporuf/' + ufId, { observe: 'response' })
    }


    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
