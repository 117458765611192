export class ContaCorrenteVendaParcela {
    contaCorrenteVendaParcelaId: number = 0;
    contaCorrenteVendaId: number = 0;
    dataVencimento: string = '';
    valorVenda: number = 0;
    valorTaxa: number = 0;
    valorLiquido: number = 0;
    contaCorrenteFinanceiroId: number = 0;
    saguiClienteId: number = 0;
    sequencia: number = 0;
}