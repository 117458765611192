<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>Parcela da Venda</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button color="primary" mat-raised-button matTooltip="Salvar" (click)="salvar()">
    <mat-icon>save</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="md-dialog-content">
    <br />
    <form [formGroup]="contaCorrenteVendaParcelaForm">
      <div class="row">
        <div class="col-md-6">
          <label
            class="{{contaCorrenteVendaParcelaForm.value.contaCorrenteFinanceiroId > 0 ? 'Sucesso': 'Processando'}}">
            Status: {{contaCorrenteVendaParcelaForm.value.contaCorrenteFinanceiroId > 0 ? 'Pago': 'Previsto'}}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Data do Vencimento</mat-label>
            <input matInput [matDatepicker]="picker1" placeholder="Data do Vencimento" formControlName="dataVencimento">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <mat-form-field class="col-md-3 col-xs-12 form-group">
          <mat-label>Valor da Venda</mat-label>
          <input currencyMask matInput placeholder="Valor da Venda" formControlName="valorVenda">
        </mat-form-field>
        <mat-form-field class="col-md-3 col-xs-12 form-group">
          <mat-label>Valor da Taxa</mat-label>
          <input currencyMask matInput placeholder="Valor da Taxa" formControlName="valorTaxa">
        </mat-form-field>
        <mat-form-field class="col-md-3 col-xs-12 form-group">
          <mat-label>Valor Líquido</mat-label>
          <input currencyMask matInput formControlName="valorLiquido">
        </mat-form-field>
        <mat-form-field class="col-md-3 col-xs-12 form-group">
          <mat-label>Parcela</mat-label>
          <input type="text" matInput placeholder="Parcela" formControlName="sequencia">
        </mat-form-field>
      </div>
    </form>
  </div>
</mat-dialog-content>