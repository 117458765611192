<section id="main-content">
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="x_panel">
        <div class="x_title">
          <h2>Templates de Documentos para Impressão</h2>
          <ul class="nav navbar-right panel_toolbox">
            <button mat-raised-button (click)="DocumentoTemplateModal(0)" matTooltip="Adicionar Template">
              <mat-icon>add</mat-icon>
            </button>
          </ul>
          <div class="clearfix"></div>
        </div>
        <div class="x_content">
          <div class="row">
            <div class="col-md-12">
                <div class="nav navbar-right panel_toolbox">
                    <button mat-mini-fab (click)="imprimirDiv('imprimetabela')" matTooltip="Imprimir"><mat-icon>print</mat-icon></button>
                    <button mat-mini-fab (click)="exportExcel('imprimetabela')" matTooltip="Baixar"><mat-icon>download</mat-icon></button>
                </div>
            </div>
        </div>
          <div id="list" class="row">
            <div class="table-responsive col-md-12">
              <table class="table table-striped" cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <th class="actions">
                      Ações
                    </th>
                    <th>ID</th>
                    <th>Tipo de Documento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let documentoTemplate of documentoTemplates">
                    <td class="actions">
                      <a mat-mini-fab
                        (click)="DocumentoTemplateModal(documentoTemplate.documentoTemplateId)" matTooltip="Editar Template"><mat-icon>edit</mat-icon></a>
                      <a mat-mini-fab (click)="confirmarExclusao(documentoTemplate.documentoTemplateId)" matTooltip="Deletar Template"
                        color="warn"><mat-icon>delete</mat-icon></a>
                    </td>
                    <td>{{documentoTemplate.documentoTemplateId}}</td>
                    <td>{{retornaTextodocumentoTipo(documentoTemplate.documentoTipo)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="imprimetabela" class="row"
      style="display:flex; justify-content: center; background-color: #c3c3c3; display: none">
      <h2>Templates</h2>
      <hr />
      <div class="table-responsive col-md-12">
        <table class="table table-striped imprimetabela" cellspacing="0" cellpadding="0">
          <thead>
            <tr>
              <th>ID</th>
              <th>Tipo de Documento</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let documentoTemplate of documentoTemplates">
              <td>{{documentoTemplate.documentoTemplateId}}</td>
              <td>{{retornaTextodocumentoTipo(documentoTemplate.documentoTipo)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>
