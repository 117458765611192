'use strict'
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompraService } from '../shared/compra.service';
import { CfopService } from '../../cfop/shared/cfop.service';

import { debounceTime, switchMap } from "rxjs/operators";

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

import { CompraItem } from '../shared/compra-item.model';
import { Produto } from '../../produto/shared/produto.model';
import { Cfop } from '../../cfop/shared/cfop.model';
import { Estoque } from '../../estoque/shared/estoque.model';


export interface DialogData {
    compraItemId: number;
    compra: any;
}

@Component({
    selector: 'compra-item',
    templateUrl: 'compra-item.component.html',
})

export class CompraItemComponent {

    compra: any = {};
    compraItemForm: FormGroup;
    maximizado: boolean;
    compraItemId: number;
    compraitem: any = {};
    cfops: Cfop[];
    produtos: Produto[];
    estoques: Estoque[];

    constructor(
        public dialog: MatDialog,
    private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<CompraItemComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
        private compraService: CompraService,
        private cfopService: CfopService) {
    }

    ngOnInit() {

        this.maximizado = false;

        this.compra = this.dialogData.compra;

        this.compraItemId = this.dialogData.compraItemId;

        this.compraItemForm = this._formBuilder.group(new CompraItem());

        this.compraItemForm.addControl('produtoAutoComplete', new FormControl(''));

        this.compraItemForm.get('compraId').setValue(this.compra.compraId);

        this.compraItemForm
            .get('produtoAutoComplete')
            .valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => value?.length >= 3 ?
                    this.compraService.getProdutoPorNome({
                        id: value,
                        tipoProduto: this.compraItemForm.get('produtoTipo').value
                    }) : []
                )
            )
            .subscribe(data =>
                this.produtos = data.body
            );

        this.buscarCfop();

        this.buscarCompraItem();

        this.restaurarDialog();

    }

    buscarCompraItem() {
        if (this.compraItemId > 0) {

            this.compraService.getCompraItem(this.compraItemId).subscribe(data => {
                this.compraItemForm.patchValue(data.body);

                this.compraItemForm.get('produtoAutoComplete').setValue(data.body.descricao);
                this.SearchEstoquesByprodutoId(data.body.produtoId);

            });
        }
    }

    buscarCfop() {
        const parametros: any = {};

        parametros.empresaId = this.compra.empresaId;
        parametros.direcao = 0;

        this.cfopService.getCfops(parametros).subscribe(data => {
            this.cfops = data.body;

        });
    }

    fecharDialog() {
        this.dialogRef.close();
    }

    salvar() {

        if (this.compraItemForm.value.compraItemId == 0) {

            this.compraItemForm.get('compraId').setValue(this.compra.compraId);

            this.compraService.postIncluiCompraItem(this.compraItemForm.value).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.fecharDialog();

            });
        }
        else {

            this.compraService.putAtualizaCompraItem(this.compraItemForm.value, this.compraItemForm.value.compraItemId).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.fecharDialog();

            });
        }
    };


    DeterminaprodutoId(produto: Produto) {

        this.compraItemForm.get('produtoId').setValue(produto.produtoId);
        this.compraItemForm.get('codProd').setValue(produto.codProd);
        this.compraItemForm.get('descricao').setValue(produto.descricao);
        this.compraItemForm.get('ncm').setValue(produto.ncm);
        this.compraItemForm.get('ean').setValue(produto.ean);
        this.compraItemForm.get('eanTrib').setValue(produto.eanTrib);
        this.compraItemForm.get('unTributaria').setValue(produto.unidadeCompra);
        this.compraItemForm.get('vlrTributario').setValue(produto.valorCompra);
        this.compraItemForm.get('unComercial').setValue(produto.unidadeCompra);
        this.compraItemForm.get('vlrComercial').setValue(produto.valorCompra);
        this.compraItemForm.get('extipi').setValue(produto.extipi);
        this.compraItemForm.get('infAdProd').setValue(produto.infAdProd);

        this.SearchEstoquesByprodutoId(produto.produtoId);

    }

    SearchEstoquesByprodutoId(produtoId: number) {
        this.compraService.getEstoqueByProduto(produtoId).subscribe(data => {
            this.estoques = data.body;
        });
    }

    OnChangeqtdComercial() {

        let qtdComercial = this.compraItemForm.get('qtdComercial').value;
        let vlrComercial = this.compraItemForm.get('vlrComercial').value;
        let vProd = qtdComercial * vlrComercial;
        this.compraItemForm.get('vProd').setValue(vProd);
        this.compraItemForm.get('qtdTributaria').setValue(qtdComercial);
    }

    OnChangevlrComercial() {

        let qtdComercial = this.compraItemForm.get('qtdComercial').value;
        let vlrComercial = this.compraItemForm.get('vlrComercial').value;
        let vProd = qtdComercial * vlrComercial;
        this.compraItemForm.get('vProd').setValue(vProd);
        this.compraItemForm.get('vlrTributario').setValue(vlrComercial);
    }

    maximizarDialog() {
        this.dialogRef.updateSize('100%', '100%');
        this.maximizado = true;
    }

    restaurarDialog() {
        this.dialogRef.updateSize('80vh', '100vh');
        this.maximizado = false;
    }
}
