import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClienteContadorService {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
  }

    getClienteContadorByClienteId(clienteId: number): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/clienteContador/' + clienteId, { observe: 'response' })
    }

    postClienteContador(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/clienteContador', item, { observe: 'response' })
    };

    patchClienteContador(item: any): Observable<HttpResponse<any>> {
        return this.http.patch(
            environment.webApiSaguiControlUrl + '/api/clienteContador', item, { observe: 'response' })
    };

}
