import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class CfopService {

    getCfops(parametros: any): Observable<HttpResponse<any>> {

        const params = {
            id: parametros.empresaId,
            direcao: parametros.direcao,
        }

        return this.http.get(environment.webApiSaguiControlUrl + '/api/cfop/visualiza', { observe: 'response', params })
    }


    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
