'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, switchMap } from "rxjs/operators";

import { NfeService } from '../shared/nfe.service';
import { NfeItemComponent } from '../nfe-item/nfe-item.component';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';

import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';

import { Nfe } from '../shared/nfe.model';
import { Transportadora } from '../../transportadora/shared/transportadora.model';
import { Cfop } from 'src/app/cfop/shared/cfop.model';
import { CfopService } from 'src/app/cfop/shared/cfop.service';
import { NfeStatusComponent } from '../nfe-status/nfe-status.component';
import { Produto } from 'src/app/produto/shared/produto.model';
import { NfeItem } from '../shared/nfe-item.model';
import { NfeDanfeComponent } from '../nfe-danfe/nfe-danfe.component';
import { NfeEventoComponent } from '../nfe-evento/nfe-evento.component';
import { Router } from '@angular/router';
import { NfePedidoCompraComponent } from '../nfe-pedido-compra/nfe-pedido-compra.component';
import { _MatTabGroupBase } from '@angular/material/tabs';
import { ExportaXmlService } from 'src/app/core/exporta-xml/exporta-xml.service';
import { NfePedidoVendaComponent } from '../nfe-pedido-venda/nfe-pedido-venda.component';

export interface DialogData {
  nfeId: number;
  discriminacao: string;
}

@Component({
  selector: 'nfe-cadastro',
  templateUrl: 'nfe-cadastro.component.html',
  styleUrls: ['nfe-cadastro.component.css']
})

export class NfeCadastroComponent {

  nfeInbound: boolean = false;
  nfe: Nfe = new Nfe();
  nfeItens: NfeItem[];
  empresa: any = {};
  transportadora: Transportadora;
  cliente: any = {};
  localEntrega: any = {};
  empresas: any = [];
  nfes: any = [];
  transportadoras: Transportadora[];
  clientes: any[];
  locaisEntrega: any[];
  nfeCadastroForm: FormGroup;
  nfeItemForm: FormGroup;
  cfops: Cfop[];
  maximizado: boolean;
  possuiReferencia: boolean = false;
  permiteExportarXml: boolean = false;
  permiteExibirEventos: boolean = false;
  autorizada: boolean = false;
  cancelar: boolean = false;
  permiteImprimir: boolean = false;
  processar: boolean = false;
  permiteSalvar: boolean = false;
  panelOpenState = false;
  produtos: Produto[] = [];

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NfeCadastroComponent>,
    private _formBuilder: FormBuilder,
    private htmlPrinterService: HtmlPrinterService,
    @Inject(MAT_DIALOG_DATA) public INfe: DialogData,
    private nfeService: NfeService,
    private cfopService: CfopService,
    private exportaXmlService: ExportaXmlService,
    private router: Router) {
  }

  ngOnInit() {

    if (this.router.url.indexOf('/inbound') > -1) {
      this.nfeInbound = true;
    }

    this.maximizado = false;

    this.nfeCadastroForm = this._formBuilder.group(new Nfe());

    this.nfeItemForm = this._formBuilder.group(new NfeItem());

    this.nfeCadastroForm.addControl('clienteAutoComplete', new FormControl(''));

    this.nfeCadastroForm.addControl('localEntregaAutoComplete', new FormControl(''));

    this.nfeCadastroForm.addControl('fornecedorRazaoSocial', new FormControl(''));

    this.nfeItemForm.addControl('produtoAutoComplete', new FormControl(''));

    this.nfeItemForm.addControl('produtoTipo', new FormControl(0));

    this.getEmpresas();

    this.getTransportadoras();

    if (this.INfe.nfeId > 0) {

      this.buscaNfe(this.INfe.nfeId);

    } else {
      this.permiteSalvar = true;
      this.nfe.nfeId = 0;
      this.nfe.dhEmi = new Date();
      this.nfe.dhSaiEnt = new Date();
      this.nfeCadastroForm.get('tpNF').setValue(1);
      this.nfeCadastroForm.get('indPag').setValue(2);
      this.nfeCadastroForm.get('idDest').setValue(1);
      this.nfeCadastroForm.get('tpImp').setValue(1);
      this.nfeCadastroForm.get('finNFe').setValue(1);
      this.nfeCadastroForm.get('indFinal').setValue(0);
      this.nfeCadastroForm.get('indPres').setValue(0);
      this.nfeCadastroForm.get('modFrete').setValue(0);
      this.nfeCadastroForm.get('mod').setValue(55);
      this.nfeCadastroForm.get('especie').setValue('PACOTES');
      this.nfeCadastroForm.get('tpEmis').setValue(1);
    }

    this.onChanges();

    this.maximizarDialog();
  }

  onChanges(): void {

    this.nfeCadastroForm
      .get('clienteAutoComplete')
      .valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => value?.length >= 3 ?
          this.nfeService.getClientePorNome(value) : []
        )
      )
      .subscribe(data =>
        this.clientes = data.body
      );

    this.nfeCadastroForm
      .get('localEntregaAutoComplete')
      .valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => value?.length >= 3 ?
          this.nfeService.getClientePorNome(value) : []
        )
      )
      .subscribe(data => this.locaisEntrega = data.body);

    this.nfeItemForm
      .get('produtoAutoComplete')
      .valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => value?.length >= 3 ?
          this.nfeService.getProdutoPorNome({
            id: value,
            tipoProduto: this.nfeItemForm.get('produtoTipo').value 
          }) : []
        )
      )
      .subscribe(data => this.produtos = data.body);

    this.nfeCadastroForm
      .get('empresaId')
      .valueChanges
      .subscribe(data => this.defineEmpresa(data));

  }

  defineEmpresa(empresaId: number) {

    this.empresas.forEach(_empresa => {

      if (_empresa.empresaId == empresaId) {
        this.nfeCadastroForm.get('cMunFG').setValue(_empresa.municipio.codIBGE);;
      }

    });

    if (empresaId > 0) {

      let filtro = {
        empresaId: empresaId,
        direcao: this.nfeCadastroForm.get('tpNF').value,
      };

      this.cfopService.getCfops(filtro).subscribe(data => {
        this.cfops = data.body;

        if (this.cfops.length > 1 && this.nfeCadastroForm.get('nfeId').value == 0) {
          this.defineCfop(this.cfops[0]);
        }

      });

      this.nfeService.getNfeConfigPorEmpresa(empresaId).subscribe(data => {

        this.nfeCadastroForm.get('infAdFisco').setValue(data.body.infAdFisco);
        this.nfeCadastroForm.get('infCpl').setValue(data.body.infCpl);

      }, (error: HttpErrorResponse) => {

        const dialogRef = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Erro",
            erro: "Erro ao carregar Informações Adicionais. Verifique as configurações de NF-e"
          }
        });
      });
    }
  }


  defineCfop(cfopCompleto: any) {
    this.nfeCadastroForm.get('cfop').setValue(cfopCompleto.codCfop);
    let naturezaOperacao = cfopCompleto.descricao.substring(0, 60);
    this.nfeCadastroForm.get('natOp').setValue(naturezaOperacao);
  }

  definenaturezaOperacao(codCfop: any)
  {
    this.cfops.forEach(cfop => {

      if(cfop.codCfop == codCfop){
        let naturezaOperacao = cfop.descricao.substring(0, 60);
        this.nfeCadastroForm.get('natOp').setValue(naturezaOperacao);
        this.nfeItemForm.get('cfop').setValue(cfop.codCfop);
      }

    });
  }

  getEmpresas() {

    this.nfeService.getEmpresa().subscribe(data => {

      this.empresas = data.body;

    });
  }

  getTransportadoras() {

    this.nfeService.getTransportadoras().subscribe(data => {

      this.transportadoras = data.body;

    });
  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }


  buscaNfe(nfeId: number) {

    this.nfeService.getNfe(nfeId).subscribe(data => {
      
      this.InsereDadosNfe(data.body);

    }, (error: HttpErrorResponse) => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          erro: error
        }
      });
    })
  }


  InsereDadosNfe(data: any) {

    this.permiteExportarXml = false;
    this.permiteExibirEventos = false;
    this.autorizada = false;
    this.cancelar = false;
    this.permiteImprimir = false;
    this.processar = false;
    this.permiteSalvar = false;

    this.nfeCadastroForm.patchValue(data.nfe);


    this.nfeItemForm.get('nfeId').setValue(data.nfe.nfeId);

    if (data.cliente.nomeFantasia != null) {
      this.nfeCadastroForm.get('clienteAutoComplete').setValue(data.cliente.nomeFantasia);
    }

    if (data.localEntrega.nomeFantasia != null) {
      this.localEntrega = data.localEntrega;
      this.nfeCadastroForm.get('localEntregaAutoComplete').setValue(this.localEntrega.nomeFantasia);
    }

    this.nfe = data.nfe;
    this.nfeItens = data.nfe.nfeItens;
    this.empresa = data.empresa;
    this.transportadora = data.transportadora;
    this.nfes = [];
    this.nfes.push(this.nfe);

    if(data.fornecedor != null)
    {
      this.nfeCadastroForm.get('fornecedorRazaoSocial').setValue(data.fornecedor.razaoSocial)
    }

    if (data.nfe.refNFe != null && data.nfe.refNFe != '') {
      this.possuiReferencia = true;
    };

    if (data.nfe.statusProcessamento == 100) {
      this.permiteExportarXml = true;
    };

    if (data.nfe.statusProcessamento == 100 || data.nfe.statusProcessamento == 135 || data.nfe.nProt != null) {
      this.permiteExibirEventos = true;
    }

    if (data.nfe.statusProcessamento == 100 && data.nfe.inbound == false) {
      this.autorizada = true;
    };

    if (data.nfe.statusProcessamento != 101 && 
      data.nfe.statusProcessamento != 102 && 
      data.nfe.inbound == false && 
      data.nfe.statusProcessamento != 301 && 
      data.nfe.statusProcessamento != 302 && 
      data.nfe.statusProcessamento != 303) {
      this.cancelar = true;
    };

    if (data.nfe.statusProcessamento != 101 && data.nfe.statusProcessamento != 102 && data.nfe.inbound == false && data.nfe.statusProcessamento != 301 && data.nfe.statusProcessamento != 302 && data.nfe.statusProcessamento != 303) {
      this.permiteImprimir = true;
    };

    if (data.nfe.statusProcessamento != 100 && data.nfe.statusProcessamento != 101 && data.nfe.statusProcessamento != 102 && data.nfe.statusProcessamento != 135 && data.nfe.inbound == false && data.nfe.statusProcessamento != 301 && data.nfe.statusProcessamento != 302 && data.nfe.statusProcessamento != 303 && data.nfe.inbound == false) {
      this.processar = true;
    };

    if (data.nfe.statusProcessamento != 100 && data.nfe.statusProcessamento != 101 && data.nfe.statusProcessamento != 102 && data.nfe.statusProcessamento != 135 && data.nfe.inbound == false && data.nfe.statusProcessamento != 301 && data.nfe.statusProcessamento != 302 && data.nfe.statusProcessamento != 303 && data.nfe.inbound == false) {
      this.permiteSalvar = true;
    }

    if (data.nfe.inbound == true && data.nfe.possuiPedidoCompra == false && data.nfe.finNFe != 4) {
      this.buscaPedidoCompra();
    }

    if (data.nfe.inbound == true && data.nfe.possuiPedidoCompra == false && data.nfe.finNFe == 4) {
      this.buscaDevolucaoVenda();
    }

  }

  atualizaStatus(statusProcessamento: number) {

    switch (statusProcessamento) {
      case 301:
      case 302:
      case 303:
        return " - Status: Denegada";
      case 135:
        return " - Status: Autorizada - Evento Vinculado";

      case 100:
        return " - Status: Autorizada";

      case 101:
        return " - Status: Cancelada";
      case 102:
        return " - Status: Inutilizada";
      case 0:
        return " - Status: Em processamento";
      default:
        return " - Status: Erro, verifique o status";
    }
  }

  buscaPedidoCompra() {

    const dialogRef = this.dialog.open(NfePedidoCompraComponent, {
      data: {
        nfeId: this.nfeCadastroForm.value.nfeId,
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      //this.buscaNfe(this.nfe.nfeId);
    });

  }

  buscaDevolucaoVenda() {

    const dialogRef = this.dialog.open(NfePedidoVendaComponent, {
      data: {
        nfeId: this.nfeCadastroForm.value.nfeId,
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscaNfe(this.nfe.nfeId);
    });

  }

  determinaCliente(cliente: any) {

    if (cliente.inscEstadual == null || cliente.inscEstadual == "" || cliente.inscEstadual == "ISENTO") {
      this.nfeCadastroForm.get('indFinal').setValue(1);
    }
    this.nfeCadastroForm.get('clienteId').setValue(cliente.clienteId);
  }

  DeterminalocalEntregaId(id: number) {
    this.nfeCadastroForm.get('idlocalEntrega').setValue(id);
  }

  determinaprodutoId(produto: Produto) {

    this.nfeItemForm.get('produtoId').setValue(produto.produtoId);
    this.nfeItemForm.get('codProd').setValue(produto.codProd);
    this.nfeItemForm.get('descricao').setValue(produto.descricao);
    this.nfeItemForm.get('ncm').setValue(produto.ncm);
    this.nfeItemForm.get('ean').setValue(produto.ean);
    this.nfeItemForm.get('eanTrib').setValue(produto.eanTrib);
    this.nfeItemForm.get('unTributaria').setValue(produto.unTributaria);
    this.nfeItemForm.get('vlrTributario').setValue(produto.vlrTributario);
    this.nfeItemForm.get('unComercial').setValue(produto.unComercial);
    this.nfeItemForm.get('vlrComercial').setValue(produto.vlrComercial);
    this.nfeItemForm.get('extipi').setValue(produto.extipi);
    this.nfeItemForm.get('infAdProd').setValue(produto.infAdProd);
    this.nfeItemForm.get('pesoLiquido').setValue(produto.pesoLiquido);
    this.nfeItemForm.get('pesoBruto').setValue(produto.pesoBruto);
    this.nfeItemForm.get('cest').setValue(produto.cest);
    this.nfeItemForm.get('indTot').setValue(1);
  }

  cancelarNfe() {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja realmente enviar essa NF-e para cancelamento?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.nfeService.postCancelar(this.nfeCadastroForm.value).subscribe(data => {

          let status = data.body;

          let texto = "Erro no processo de cancelamento. Verifique o status";

          if (status == 101) {
              texto = "NF-e cancelada com sucesso.";
          }

          if (status == 102) {
              texto = "NF-e inutilizada com sucesso.";
          }

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Informação",
              mensagem: texto
            }
          });

          this.buscaNfe(this.nfeCadastroForm.value.nfeId);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  };

  processa(): void {

    this.nfeService.postProcessaNfe(this.nfeCadastroForm.value).subscribe(data => {

      let texto = "NF-e rejeitada. Verifique o status";

      if (data.body == 100) {
        texto = "NF-e Autorizada com Sucesso";
      }

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Informação",
          mensagem: texto
        }
      });

      this.buscaNfe(this.nfeCadastroForm.get('nfeId').value);

    });

  }

  imprime() {

    const dialogRef = this.dialog.open(NfeDanfeComponent, {
      data: {
        nfeId: this.nfeCadastroForm.value.nfeId
      },
      maxHeight: '100vh'
    });


  }

  modalNfeStatus() {

    const dialogRef = this.dialog.open(NfeStatusComponent, {
      data: {
        nfeId: this.nfeCadastroForm.value.nfeId
      },
      maxHeight: '100vh'
    });

  }

  exportaXml() {

    if (this.nfeCadastroForm.value.statusProcessamento == 100) {

      this.nfeService.postExportarXml(this.nfeCadastroForm.value).subscribe(data => {

        this.exportaXmlService.exporta(data.body.xmlProcNfe, data.body.fileName);

      }, (error: HttpErrorResponse) => {

        const dialogRef = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Erro",
            erro: error.error
          }
        });
      });

    }
  }

  salvar(): void {

    if (this.nfeCadastroForm.value.nfeId == 0) {

      this.nfeService.postNfe(this.nfeCadastroForm.value).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

        this.buscaNfe(data.body.nfeId);

      });

    }
    else {
      this.nfeService.putNfe(this.nfeCadastroForm.value, this.nfeCadastroForm.value.nfeId).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

        this.buscaNfe(this.nfeCadastroForm.value.nfeId);

      });
    }

  };

  imprimir(): void {

    let titulo = "SaguiControl - Pedido de Nfe";

    this.htmlPrinterService.printDiv('printNfe', titulo);
  }

  PreencherTabela(cliente: any) {

    if (cliente != undefined) {
      this.nfe.clienteId = cliente.clienteId;
    }
  }

  PreencherTabelalocalRetirada(localRetirada: any) {

    if (localRetirada != undefined) {
      this.nfe.idlocalRetirada = localRetirada.clienteId;
    }
  }

  PreencherTabelalocalEntrega(localEntrega) {

    if (localEntrega != undefined) {
      this.nfe.idlocalEntrega = localEntrega.clienteId;
    }
  }

  modalNfeItem(nfeItemId: number, nfeManual: boolean): void {

    const dialogRef = this.dialog.open(NfeItemComponent, {
      data: {
        nfeItemId: nfeItemId,
        nfe: this.nfeCadastroForm.value,
        nfeManual: nfeManual
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscaNfe(this.nfe.nfeId);
    });

  }

  deleteNfeItem(nfeItemId: number) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja realmente excluir esse item?"
      }
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.nfeService.deleteNfeItem(nfeItemId).subscribe(data => {

          this.buscaNfe(this.nfe.nfeId);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  };

  onChangeValorComercial() {

    let vlrComercial = this.nfeItemForm.get('vlrComercial').value;
    let qtdComercial = this.nfeItemForm.get('qtdComercial').value;
    let vProd = Math.round((qtdComercial * vlrComercial) * 100) / 100;
    this.nfeItemForm.get('vProd').setValue(vProd.toFixed(2));
    this.nfeItemForm.get('qtdTributaria').setValue(qtdComercial);
    this.nfeItemForm.get('qtdComercial').setValue(qtdComercial);
    this.nfeItemForm.get('vlrTributario').setValue(vlrComercial);
    this.nfeItemForm.get('vlrComercial').setValue(vlrComercial);
  }

  salvarNfeItem(_nfeItem) {

    this.nfeService.postIncluiNfeItem(_nfeItem).subscribe(data => {

      this.buscaNfe(this.nfeCadastroForm.value.nfeId);

    });

  }

  calculaImposto() {

    if (this.nfeItemForm.value.cfop != 5206) {

      this.nfeService.postCalculaImposto(this.nfeItemForm.value).subscribe(data => {

        if (data.body != null) {
          //CST
          this.nfeItemForm.get('cstIcms').setValue(data.body.cstIcms.toString());
          this.nfeItemForm.get('cstIpi').setValue(data.body.cstIpi.toString());
          this.nfeItemForm.get('cstPis').setValue(data.body.cstPis.toString());
          this.nfeItemForm.get('cstCofins').setValue(data.body.cstCofins.toString());

          //ICMS
          this.nfeItemForm.get('icmsvBC').setValue(data.body.icmsvBC.toString());
          this.nfeItemForm.get('icmspIcms').setValue(data.body.icmspIcms.toString());
          this.nfeItemForm.get('icmsvIcms').setValue(data.body.icmsvIcms.toString());
          this.nfeItemForm.get('icmsSTmodBCST').setValue(data.body.icmsSTmodBCST.toString());
          this.nfeItemForm.get('icmsSTpMVAST').setValue(data.body.icmsSTpMVAST.toString());
          this.nfeItemForm.get('icmspRedBC').setValue(data.body.icmspRedBC.toString());

          this.nfeItemForm.get('icmsSTvBCST').setValue(data.body.icmsSTvBCST.toString());
          this.nfeItemForm.get('icmsSTpIcmsST').setValue(data.body.icmsSTpIcmsST.toString());

          this.nfeItemForm.get('icmsSTvIcmsST').setValue(data.body.icmsSTvIcmsST.toString());
          this.nfeItemForm.get('icmspCredSN').setValue(data.body.icmspCredSN.toString());
          this.nfeItemForm.get('icmsvCredIcmsSN').setValue(data.body.icmsvCredIcmsSN.toString());
          this.nfeItemForm.get('icmspRedBC').setValue(data.body.icmspRedBC.toString());

          //ICMS Simples
          this.nfeItemForm.get('icmspCredSN').setValue(data.body.icmspCredSN.toString());
          this.nfeItemForm.get('icmsvCredIcmsSN').setValue(data.body.icmsvCredIcmsSN.toString());

          //IPI
          this.nfeItemForm.get('ipicEnq').setValue(data.body.ipicEnq.toString());
          this.nfeItemForm.get('ipivBC').setValue(data.body.ipivBC.toString());

          //infAdProd
          this.nfeItemForm.get('infAdProd').setValue(data.body.infAdProd.toString());

          //PIS
          this.nfeItemForm.get('pisvBC').setValue(data.body.pisvBC.toString());
          this.nfeItemForm.get('pispPis').setValue(data.body.pispPis.toString());
          this.nfeItemForm.get('pisvPis').setValue(data.body.pisvPis.toString());

          //cofins
          this.nfeItemForm.get('cofinsvBC').setValue(data.body.cofinsvBC.toString());
          this.nfeItemForm.get('cofinspCofins').setValue(data.body.cofinspCofins.toString());
          this.nfeItemForm.get('cofinsvCofins').setValue(data.body.cofinsvCofins.toString());

        }

        let nfeItemTemp = this.nfeItemForm.value;

        nfeItemTemp.nfeItemImpostos = [
          {
            impostoId: 1,
            pRedBC: nfeItemTemp.icmspRedBC,
            vBC: nfeItemTemp.icmsvBC,
            vlrAliquota: nfeItemTemp.icmspIcms,
            vlrImposto: nfeItemTemp.icmsvIcms,
            orig: nfeItemTemp.icmSorig,
            pCredSN: nfeItemTemp.icmspCredSN,
            vCredICMSSN: nfeItemTemp.icmsvCredIcmsSN
          },
          {
            impostoId: 2,
            modBC: nfeItemTemp.icmsSTmodBCST,
            pRedBC: nfeItemTemp.icmsSTpRedBCST,
            pMVA: nfeItemTemp.icmsSTpMVAST,
            vBC: nfeItemTemp.icmsSTvBCST,
            vlrAliquota: nfeItemTemp.icmsSTpIcmsST,
            vlrImposto: nfeItemTemp.icmsSTvIcmsST,
            vBCSTRet: nfeItemTemp.vBCSTRet,
            pST: nfeItemTemp.pST,
            vICMSSubstituto: nfeItemTemp.vICMSSubstituto,
            vICMSSTRet: nfeItemTemp.vICMSSTRet,
          },
          {
            impostoId: 3,
            clEnq: nfeItemTemp.ipiclEnq,
            cEnq: nfeItemTemp.ipicEnq,
            cNPJProd: nfeItemTemp.ipiCNPJProd,
            cSelo: nfeItemTemp.IPIcSelo,
            vBC: nfeItemTemp.ipivBC,
            vlrAliquota: nfeItemTemp.ipipIpi,
            qUnid: nfeItemTemp.ipiqUnid,
            vUnid: nfeItemTemp.ipivUnid,
            vlrImposto: nfeItemTemp.ipivIpi
          },
          {
            impostoId: 4,
            vBC: nfeItemTemp.pisvBC,
            vlrAliquota: nfeItemTemp.pispPis,
            qUnid: nfeItemTemp.pisqUnid,
            vUnid: nfeItemTemp.pisvUnid,
            vlrImposto: nfeItemTemp.pisvPis
          },
          {
            impostoId: 5,
            vBC: nfeItemTemp.pisSTvBC,
            vlrAliquota: nfeItemTemp.pisSTpPis,
            vUnid: nfeItemTemp.PISSTvAliqProd,
            qUnid: nfeItemTemp.PISSTqBCProd,
            vlrImposto: nfeItemTemp.pisSTvPis
          },
          {
            impostoId: 6,
            vBC: nfeItemTemp.cofinsvBC,
            vlrAliquota: nfeItemTemp.cofinspCofins,
            qUnid: nfeItemTemp.cofinsqUnid,
            vUnid: nfeItemTemp.cofinsvUnid,
            vlrImposto: nfeItemTemp.cofinsvCofins
          },
          {
            impostoId: 7,
            vBC: nfeItemTemp.cofinsSTvBC,
            vlrAliquota: nfeItemTemp.cofinsSTpPIS,
            vUnid: nfeItemTemp.cofinsSTvAliqProd,
            qUnid: nfeItemTemp.cofinsSTqBCProd,
            vlrImposto: nfeItemTemp.cofinsSTvPIS
          }
        ];

        this.salvarNfeItem(nfeItemTemp);

      });
    };
  }

  exibeEventos(){
    
    const dialogRef = this.dialog.open(NfeEventoComponent, {
      data: {
        nfeId: this.nfeCadastroForm.value.nfeId,
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscaNfe(this.nfe.nfeId);
    });

  }

}
