import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpClientModule } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class UnidadeMedidaService {

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
    get(): Observable<HttpResponse<any>> {
        return this.http.get(
          environment.webApiSaguiControlUrl + '/api/unidademedida/visualizatodas', { observe: 'response' })
    }

}
