<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>Assinatura {{clienteServicoForm.value.clienteServicoId}}</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()"
        *ngIf="clienteServicoForm.value.clienteServicoStatus != 99">
        <mat-icon>save</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="md-dialog-content">
        <br />
        <mat-tab-group>
            <mat-tab label="Geral">
                <br />
                <form [formGroup]="clienteServicoForm">
                    <div class="row">
                        <mat-form-field class="col-md-6 col-xs-12 form-group">
                            <mat-label>Emitente</mat-label>
                            <mat-select formControlName="empresaId">
                                <mat-option *ngFor="let empresa of empresas" [value]="empresa.empresaId">
                                    {{empresa.nomeFantasia}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="col-md-12 col-xs-12 form-group">
                            <mat-label>Digite o Nome do Serviço</mat-label>
                            <input type="text" matInput [matAutocomplete]="auto"
                                formControlName="produtoAutoComplete">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let produto of produtos" [value]="produto.descricao"
                                    (click)="DeterminaprodutoId(produto)">
                                    {{produto.descricao}} - R$ {{produto.vlrComercial | number:'1.2-2'}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="form-group col-md-4 col-xs-12">
                            <mat-label>Quantidade</mat-label>
                            <input type="number" matInput formControlName="quantidade" (blur)="onChangeValor()">
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-4 col-xs-12">
                            <mat-label>Valor Unitário</mat-label>
                            <input currencyMask matInput formControlName="valorUnitario" (blur)="onChangeValor()">
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-4 col-xs-12">
                            <mat-label>Valor Total</mat-label>
                            <input currencyMask matInput formControlName="valorTotal" (blur)="onChangeValor()"
                                (disabled)="true">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Data Próxima Renovação</mat-label>
                                <input matInput [matDatepicker]="picker1" formControlName="dataProximaRenovacao">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <mat-form-field class="col-md-4 col-xs-12 form-group">
                            <mat-label>Tipo de Recorrência</mat-label>
                            <mat-select formControlName="clienteServicoRecorrencia">
                                <mat-option [value]="0">Não Recorrente</mat-option>
                                <mat-option [value]="1">Semanal</mat-option>
                                <mat-option [value]="2">Quinzenal</mat-option>
                                <mat-option [value]="3">Mensal</mat-option>
                                <mat-option [value]="4">Trimestral</mat-option>
                                <mat-option [value]="5">Semestral</mat-option>
                                <mat-option [value]="6">Anual</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </form>
                <div class="row">
                    <div class="col-md-12">
                        <button mat-raised-button (click)="modalClienteMetodoPagamento(0)">
                            <mat-icon>add</mat-icon> Forma de Pagamento
                        </button>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <mat-card *ngFor="let clienteMetodoPagamento of clienteMetodoPagamentos"
                        class="col-md-6 col-xs-6 form-group sagui-button">
                        <mat-card-title-group>
                            <mat-card-title></mat-card-title>
                            <mat-card-subtitle>{{clienteMetodoPagamento.descricao}}</mat-card-subtitle>
                        </mat-card-title-group>
                        <mat-card-content>
                            <div class="row">
                                <mat-label>Nome no Cartão: {{clienteMetodoPagamento.nomeNoCartao}}</mat-label>
                            </div>
                            <div class="row">
                                <mat-label>Número do Cartão: {{clienteMetodoPagamento.numeroCartao.substring(0, 4)}}
                                    XXXX XXXX {{clienteMetodoPagamento.numeroCartao.substring(12, 16)}}</mat-label>
                            </div>
                            <div class="row">
                                <mat-label>Expira:
                                    {{clienteMetodoPagamento.expiraMes}}/{{clienteMetodoPagamento.expiraAno}}
                                </mat-label>
                            </div>
                            <div class="row"
                                *ngIf="clienteServicoForm.value.clienteMetodoPagamentoId == clienteMetodoPagamento.clienteMetodoPagamentoId">
                                <label>Padrão</label>
                            </div>
                        </mat-card-content>
                        <mat-card-actions>
                            <button mat-mini-fab color="warn"
                                (click)="excluirClienteMetodoPagamento(clienteMetodoPagamento.clienteMetodoPagamentoId)">
                                <mat-icon>delete_forever</mat-icon>
                            </button>
                            <button mat-raised-button color="accent"
                                *ngIf="clienteServicoForm.value.clienteMetodoPagamentoId != clienteMetodoPagamento.clienteMetodoPagamentoId"
                                (click)="definirComoPadrao(clienteMetodoPagamento.clienteMetodoPagamentoId)">
                                Definir como Padrão
                            </button>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </mat-tab>
            <mat-tab label="Histórico" *ngIf="clienteServicoForm.value.clienteServicoId > 0">
                <br />
                <div id="list" class="row">
                    <div class="table-responsive col-md-12">
                        <table class="table table-striped" cellspacing="0" cellpadding="0">
                            <thead>
                                <tr>
                                    <th>Usuário</th>
                                    <th>Data</th>
                                    <th>Histórico</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let clienteServicoHistorico of clienteServicoHistoricos">
                                    <td>{{clienteServicoHistorico.Usuario}}</td>
                                    <td>{{clienteServicoHistorico.data | date:'dd/MM/yyyy'}}</td>
                                    <td>{{clienteServicoHistorico.descricao}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</mat-dialog-content>