<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>Movimento de Caixa</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()">
    <mat-icon>save</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="md-dialog-content">
    <br />
    <div class="row">
      <form [formGroup]="caixaMovimentoForm">
        <div class="col-md-12">
          <div class="card">
            <br />
            <div class="row">
              <mat-form-field class="col-md-3 col-xs-12 form-group">
                <mat-label>Direção</mat-label>
                <mat-select formControlName="direcao">
                  <mat-option [value]="0">Entrada</mat-option>
                  <mat-option [value]="1">Saída</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-md-6 col-xs-12 form-group">
                <mat-label>Categoria</mat-label>
                <mat-select formControlName="grupoContaId">
                  <mat-option *ngFor="let grupoConta of grupoContas" [value]="grupoConta.grupoContaId">
                    {{grupoConta.tpGrupoConta == 0 ? 'Receitas': 'Despesas'}} - {{grupoConta.descricao}} </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col-md-12 col-xs-12 form-group">
                <mat-label>Descrição</mat-label>
                <input type="text" matInput formControlName="descricao">
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col-md-3 col-xs-12 form-group">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="tipoPagamento">
                  <mat-option [value]="0">Dinheiro</mat-option>
                  <mat-option [value]="1">Débito</mat-option>
                  <mat-option [value]="2">Crédito</mat-option>
                  <mat-option [value]="3">Boleto</mat-option>
                  <mat-option [value]="11">PIX</mat-option>
                  <mat-option [value]="4">Transferência</mat-option>
                  <mat-option [value]="5">Cheque</mat-option>
                  <mat-option [value]="6">Vale Refeição</mat-option>
                  <mat-option [value]="7">Banco</mat-option>
                  <mat-option [value]="10">Transferência entre Caixas</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col-md-6 col-xs-12 form-group">
                <mat-label>Valor</mat-label>
                <input currencyMask matInput formControlName="valor">
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="form-group col-md-3 col-xs-12">
                <mat-label>Data da Lançamento</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="data">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>