import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class NfeEventoService {

  postCartaCorreca(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/nfe/cartacorrecao/', item, { observe: 'response' })
  };

  getNfeEventos(itemId: number): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/nfeevento/visualizapornfeid/' + itemId, { observe: 'response' })
  };

  getNfeEvento(itemId: number): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/nfeevento/visualiza/' + itemId, { observe: 'response' })
  }; 


  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
