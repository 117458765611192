import { Injectable } from '@angular/core';

@Injectable()
export class ExportaXmlService {

  exporta(data, filename) {

    let link = document.createElement("a");

    link.setAttribute("target", "_blank");

    if (Blob !== undefined) {

      var blob = new Blob([data], { type: "text/xml" });

      link.setAttribute("href", URL.createObjectURL(blob));

    } else {

      link.setAttribute("href", "data:text/xml," + encodeURIComponent(data));
    }

    link.setAttribute("download", filename);

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }

  constructor() {

  }
}
