import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { ServicoService } from '../shared/servico.service';
import { UnidadeMedidaService } from 'src/app/unidade-medida/shared/unidade-medida.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UnidadeMedida } from 'src/app/unidade-medida/shared/unidade-medida.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';
import { exportExcelService } from '../../core/export-excel/export-excel.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Produto } from 'src/app/produto/shared/produto.model';

@Component({
  selector: 'app-cad-servico',
  templateUrl: './produtoeservico-cadastro.component.html',
  styleUrls: ['./produtoeservico-cadastro.component.css'],
})
export class CadServicoComponent implements OnInit {
  servicoId: number;
  servico: any;
  servicos: any = [];
  grupoContas: any = [];
  produtoGrupos: any = [];
  recorrencia: number;
  listaServicos: any = [];
  empresas: any = [];
  filteredEmpresas: any[] = [];
  caixas: any = [];
  editGeral: FormGroup;
  editFinanceiro: FormGroup;
  editNfs: FormGroup;
  editGetnet: FormGroup;
  editSoc: FormGroup;
  filtroform: FormGroup;
  integracao: boolean;
  title: string;
  pacoteServico: boolean;
  duplicar: boolean = false;
  maximizado: boolean;
  unidadesMedida: UnidadeMedida[] = [];
  comercialFilteredOptions: Observable<UnidadeMedida[]>;
  tributariaFilteredOptions: Observable<UnidadeMedida[]>;
  compraFilteredOptions: Observable<UnidadeMedida[]>;
  dataSource = new MatTableDataSource<any>([]);
  dataSourcePacoteServicos = new MatTableDataSource<any>([]);

  constructor(private dialogRef: MatDialogRef<CadServicoComponent>,public dialog: MatDialog, private exportExcelService: exportExcelService,private htmlPrinterService: HtmlPrinterService, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private servicoService: ServicoService, private unidadeMedidaService: UnidadeMedidaService, private _snackBar: MatSnackBar){
    this.integracao = data.integracao;
    this.title = data.title;
    this.servicoId = data.servicoId;
    this.pacoteServico = data.pacoteServico;
    this.duplicar = data.duplicar;
  }

  ngOnInit(): void {
    this.buscaEmpresas();
    this.buscarUnidadesMedida();
    this.editGeral = this.fb.group({
      pacoteServico: this.pacoteServico,
      produtoEmpresa: [[], Validators.required],
      duplicar: [false], 
      produtoId: [0],
      produtoTipo: [1],
      codProd: ['', Validators.required],
      descricao: ['', Validators.required],
      ncm: [''],
      ean: [''],
      eanTrib: [''],
      unTributaria: [''],
      vlrTributario: [0],
      unComercial: [''],
      vlrComercial: [0],
      orig: [0],
      unidadeCompra: [''],
      valorCompra: [0],
      indServico: [true],
      cListServ: [''],
      servicoDescricao: [''],
      cServico: [''],
      servicoDiscriminacaoPadrao: [''],
      contaReceberGrupoContaId: [0],
      contaPagarGrupoContaId: [0],
      contaReceberCaixaId: [0],
      contaPagarCaixaId: [0],
      extipi: [0],
      codBarras: [''],
      infAdProd: [''],
      numeroPatrimonio: [''],
      cest: [0],
      saguiClienteId: [0],
      produtoGrupoId: [0],
      embalagemId: [0],
      embalagemCapacidade: [0],
      recorrencia: [0],
      utilizaGetNetPlano: [false],
      grupoContaId: [0],
      pesoLiquido: [0],
      pesoBruto: [0],
      comportamentoPacote: [0]
    });
    this.editFinanceiro = this.fb.group({
      grupoContaId: [0], 
      unidadeCompra: [''], 
      valorCompra:[0], 
      unComercial: [''], 
      vlrComercial: [0], 
      contasareceber: [1]
    });
    this.editNfs = this.fb.group({ 
      servicoListaId: [0], 
      servicoDiscriminacaoPadrao: [''], 
      caixaId: [0],
      contasareceber: [1]
    });
    this.editGetnet = this.fb.group({
      utilizaGetNetPlano: [false], 
      getNetPlanId: ['00000000-0000-0000-0000-000000000000'],
  });
    this.editSoc = this.fb.group({ codSoc: [''], tipoSoc: [0]});

    //Apenas se existir um ID do serviço
    if (this.servicoId) {
      this.recuperaServico(this.servicoId);
    }
    this.buscarGrupodeServicos(this.editGeral.get('produtoEmpresa').value);
    this.buscargrupoContas();
    this.buscarListaServico();
    this.buscaCaixas();
    this.verificaIntegracaoSoc();
  }

  onChanges(): void {
    this.editNfs.get('servicoListaId').valueChanges.subscribe(data => this.PreencheServico(data));
  }

  salvarServico(): void {
    if (this.formulariosSaoValidos()) {
      const servico = this.criarObjetoServico();
      this.servicoId ? this.atualizarServico(servico) : this.criarServico(servico);
    } else {
      this._snackBar.open('Erro ao criar o serviço! Entre em contato com o suporte.', 'Fechar', {
        duration: 5000,
        panelClass: ['mat-toolbar', 'warn']
      });
    }
  }

  formulariosSaoValidos(): boolean {
    return (
      this.editGeral.valid &&
      this.editFinanceiro.valid &&
      this.editNfs.valid &&
      this.editGetnet.valid &&
      this.editSoc.valid
    );
  }

  criarObjetoServico(): any {
    return {
      ...this.editGeral.value,
      ...this.editFinanceiro.value,
      ...this.editNfs.value,
      ...this.editGetnet.value,
      ...this.editSoc.value
    };
  }

  atualizarServico(servico: any): void {
    if(servico.duplicar === false){
      this.servicoService.atualizaServico(servico, this.servicoId).subscribe(
        response => this.onSucesso(response, 'atualizado'),
        error => this.onErro(error, 'atualizar')
      );
    }else{
      this.servicoService.criaServico(servico).subscribe(
        response => this.onSucesso(response, 'criado'),
        error => this.onErro(error, 'criar')
      );
    }
  }

  criarServico(servico: any): void {
    this.servicoService.criaServico(servico).subscribe(
      response => this.onSucesso(response, 'criado'),
      error => this.onErro(error, 'criar')
    );
  }

  onSucesso(response: any, acao: string): void {
    this._snackBar.open(`Serviço ${acao} com sucesso!`, 'Fechar', {
      duration: 5000,
      panelClass: ['mat-toolbar', acao === 'criado' || 'atualizado' ? 'sucess' : 'warn']
    });
    this.dialogRef.close(response);
    this.buscarServicos();
  }

  onErro(error: any, acao: string): void {
    let errorMessage = 'Erro ao ' + acao + ' o serviço.';
  
    if (error.error && error.error.title) {
      errorMessage += ' ' + error;
    }
    
    if (error.error && error.error.errors) {
      errorMessage += ' Detalhes do erro:';
      for (const key in error.error.errors) {
        if (error.error.errors.hasOwnProperty(key)) {
          errorMessage += ` ${key}: ${error.error.errors[key]}`;
        }
      }
    }
    
    errorMessage += ' Erro: ' + error.error;
  
    this._snackBar.open(errorMessage, 'Fechar', {
      duration: 500000000000,
      panelClass: ['mat-toolbar', 'warn']
    });
    console.error(`Erro ao ${acao} serviço:`, error);
    this.buscarServicos();
  }
  
  

  // Recupera os dados do serviço
  recuperaServico(servicoId): void {
    this.servicoService.recuperaServico(servicoId).subscribe(data => {
      this.servico = data.body; 
      if(this.servico.grupoContaId > 0 || this.servico.grupoContaId !== null){
        this.servico.contasareceber = 0;
      } else {
        this.servico.contasareceber = 1;
      }
      if(this.duplicar == true){
        this.editGeral.patchValue({descricao: this.servico.descricao+'Copia', codProd:this.servico.codProd+'Copia'})
      } else {
        this.editGeral.patchValue({descricao: this.servico.descricao})
      }
      this.listaEmpresa();
      this.editGeral.patchValue({
        duplicar: this.duplicar,
        produtoEmpresa: this.filteredEmpresas,
        produtoGrupoId: this.servico.produtoGrupoId, 
        comportamentoPacote:  this.servico.comportamentoPacote,
        codProd: this.servico.codProd, 
        recorrencia: this.servico.recorrencia, 
        pacoteServico: this.servico.pacoteServico,
        unTributaria: this.editFinanceiro.value.unComercial,
        vlrTributario: this.editFinanceiro.value.vlrComercial,
        saguiClienteId: this.servico.saguiClienteId      
      });
      this.editFinanceiro.patchValue({ grupoContaId: this.servico.grupoContaId, unidadeCompra: this.servico.unidadeCompra, valorCompra: this.servico.valorCompra, vlrComercial: this.servico.vlrComercial, unComercial: this.servico.unComercial, contasareceber: this.servico.contasareceber});
      this.editNfs.patchValue({ servicoListaId: this.servico.servicoListaId, servicoDiscriminacaoPadrao: this.servico.servicoDiscriminacaoPadrao,  contasareceber: this.servico.contasareceber});
      this.editGetnet.patchValue({ utilizaGetNetPlano: this.servico.utilizaGetNetPlano, getNetPlanId: this.servico.getNetPlanId, empresaId: this.servico.empresaId});
      this.editSoc.patchValue({ codSoc: this.servico.codSoc, tipoSoc: this.servico.tipoSoc});
    });
  }
  //busca os grupos de serviços
  buscarGrupodeServicos(item: any) {
    this.servicoService.getServicoGrupos(item).subscribe(data => {
        this.produtoGrupos = data.body;
    });
  };
  // busca os grupos de contas do tipo Contas a receber (0)
  buscargrupoContas() {
    this.servicoService.getgrupoContas(0).subscribe(data => {
        this.grupoContas = data.body;
    });
  }
  //busca unidades
  buscarUnidadesMedida() {
    this.unidadeMedidaService.get().subscribe(data => {
        this.unidadesMedida = data.body;
    });
  };
  //lista os serviços em Nfs
  buscarListaServico() {
    this.servicoService.getListaServicos().subscribe(data => {
      this.listaServicos = data.body;
    });
  }
  //recupera listas de empresas
  buscaEmpresas() {
    this.servicoService.getEmpresas().subscribe(data=>{
      this.empresas = data.body;
    })
  }
  listaEmpresa(){
    this.filteredEmpresas = this.empresas.filter(empresa =>
      this.servico.produtoEmpresa.some(pe => pe.empresaId === empresa.empresaId)
    );
  }
  //Recupera as caixas
  buscaCaixas() {
    this.servicoService.getCaixas().subscribe(data => {
        this.caixas = data.body;
    });
  }
  //Formato do serviço 00 - descricao
  PreencheServico(servico: any) {
    if (servico != null) {
        this.editNfs.get('servicoListaId').setValue(servico.servicoListaId);
        this.editNfs.get('cListServ').setValue(servico.codigo);
        this.editNfs.get('servicoDescricao').setValue(servico.descricao);
    }
  }

  //Fechar Dialogo
  fecharDialog() {
    this.dialogRef.close();
  }

  //Maximiza Dialogo
  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  //Restaura Dialogo
  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  //Faz filtro das unidades
  private _filter(value: string) {
    const filterValue = value;
    return this.unidadesMedida.filter(option => option.unidade.includes(filterValue));
  }
 
  //verifica se existe integracao
   verificaIntegracaoSoc(): void {
    const ids = [0, 2, 3, 5, 6];
    this.integracao = false;
    for (const id of ids) {
      this.servicoService.buscaIntegracaoSoc(id).toPromise().then(data => {
        const resultado = data.body;
        if (resultado) {
          this.integracao = true;
          console.log(this.integracao);
          return;
        }
      }).catch(error => {
        console.error('Erro ao buscar integração do SOC:', error);
      });
    }
  }

    //busca servicos e separa Serviços de Pacotes de Serviços
    buscarServicos(): void {
      const filtros = this.filtroform.value;
      
      this.servicoService.buscaServicos(filtros).subscribe(
        (data) => { 
          this.servicos = data.body;
          this.dataSource.data = this.servicos.filter(item => !item.pacoteServico); 
          this.dataSourcePacoteServicos.data = this.servicos.filter(item => item.pacoteServico == 1);
  
          this.filtroform.reset();
        },
        (error: HttpErrorResponse) => {
          this.dialog.open(InfoDialogComponent, {
            data: { titulo: 'Erro', erro: error.message }
          });
        }
      );
    }
}
