import { ChangeDetectorRef, Component, ElementRef, Inject, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  valorTotal: number;
  descontosAtual: number;
}

@Component({
  selector: 'desconto-dialog',
  templateUrl: 'desconto-dialog.component.html',
  styleUrls: ['./desconto-dialog.component.css'],
})
export class DescontoDialogComponent {

  valorTotalAnterior: number = this.data.valorTotal;
  valorTotalNovo: number = this.data.valorTotal;
  Desconto: FormGroup;
  maximizado: boolean = false;
  @ViewChild('descontoReaisInput', { static: true }) descontoReaisInput!: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<DescontoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private cdr: ChangeDetectorRef, private renderer: Renderer2,private el: ElementRef) {
  }

  ngOnInit() {
    this.Desconto = new FormGroup({
      DescontoEmPorcentagem: new FormControl('0', [Validators.required]),
      DescontoEmReais: new FormControl('0', [Validators.required]),
    });

    this.Desconto.get('DescontoEmPorcentagem')?.valueChanges.subscribe(value => this.changePorcentagem());
    this.Desconto.get('DescontoEmReais')?.valueChanges.subscribe(value => this.changeEmReal());

    this.data.descontosAtual? this.Desconto.get('DescontoEmReais').setValue(this.data.descontosAtual):'';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    const result = {
        descontoEmPorcentagem: this.Desconto.get('DescontoEmPorcentagem')?.value,
        descontoEmReais: this.Desconto.get('DescontoEmReais')?.value,
        valorTotalNovo: this.valorTotalNovo
      };
      this.dialogRef.close(result);
  }

  changePorcentagem() {
    const descontoEmPorcentagem = this.Desconto.get('DescontoEmPorcentagem')?.value;
    if (descontoEmPorcentagem !== null && descontoEmPorcentagem !== undefined) {
      const descontoEmReais = (this.valorTotalAnterior * descontoEmPorcentagem) / 100;
      this.Desconto.get('DescontoEmReais')?.setValue(descontoEmReais.toFixed(2), { emitEvent: false });
      this.valorTotalNovo = this.valorTotalAnterior - descontoEmReais;
    }
  }

  changeEmReal() {
    const descontoEmReais = this.Desconto.get('DescontoEmReais')?.value;
    if (descontoEmReais !== null && descontoEmReais !== undefined) {
      const descontoEmPorcentagem = (descontoEmReais / this.valorTotalAnterior) * 100;
      this.Desconto.get('DescontoEmPorcentagem')?.setValue(descontoEmPorcentagem.toFixed(2), { emitEvent: false });
      this.valorTotalNovo = this.valorTotalAnterior - descontoEmReais;
    }
  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }


  ngAfterViewInit() {
 //setar dinamicamente esse css .resolver .mat-form-field-outline-start {width: 25.45px !important;  }
 const elements = this.el.nativeElement.querySelectorAll('.resolver .mat-form-field-outline-start');
 elements.forEach((element: any) => {
   this.renderer.setStyle(element, 'width', '25.45px');
   this.renderer.setStyle(element, 'important', 'true'); // Adiciona !important
 });
  }
}
