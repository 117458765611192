'use strict'
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrcamentoService } from '../shared/orcamento.service';
import { CfopService } from '../../cfop/shared/cfop.service';

import { debounceTime, switchMap } from "rxjs/operators";

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

import { OrcamentoItem } from '../shared/orcamento-item.model';
import { Produto } from '../../produto/shared/produto.model';
import { Cfop } from '../../cfop/shared/cfop.model';

export interface DialogData {
    orcamentoItemId: number;
    Orcamento: any;
}

@Component({
    selector: 'orcamento-item',
    templateUrl: 'orcamento-item.component.html',
})

export class OrcamentoItemComponent {

    Orcamento: any = {};
    orcamentoItemForm: FormGroup;
    maximizado: boolean;
    orcamentoItemId: number;
    orcamentoitem: any = {};
    cfops: Cfop[];
    produtos: Produto[] = [];

    constructor(
        public dialog: MatDialog,
    private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<OrcamentoItemComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
        private orcamentoService: OrcamentoService,
        private cfopService: CfopService) {
    }

    ngOnInit() {

        this.maximizado = false;

        this.Orcamento = this.dialogData.Orcamento;

        this.orcamentoItemId = this.dialogData.orcamentoItemId;

        this.orcamentoItemForm = this._formBuilder.group(new OrcamentoItem());

        this.orcamentoItemForm.addControl('produtoAutoComplete', new FormControl(''));

        this.orcamentoItemForm.get('orcamentoId').setValue(this.Orcamento.orcamentoId);

        this.onChanges();

        this.buscarOrcamentoItem();

        this.restaurarDialog();

        this.orcamentoItemForm.get('produtoTipo').setValue(1);
    }

    onChanges(): void {

        let produtoTipo = 0;

        this.orcamentoItemForm
            .get('produtoAutoComplete')
            .valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => value?.length >= 3 ?
                    this.orcamentoService.getProdutoPorNome({
                        id: value,
                        tipoProduto: this.orcamentoItemForm.get('produtoTipo').value
                    }) : []
                )
            )
            .subscribe(data =>
                this.produtos = data.body
            );
    }

    buscarOrcamentoItem() {
        if (this.orcamentoItemId > 0) {

            this.orcamentoService.getOrcamentoItem(this.orcamentoItemId).subscribe(data => {

                this.orcamentoItemForm.patchValue(data.body);

                this.orcamentoItemForm.get('produtoAutoComplete').setValue(data.body.descricao);

            });
        }
    }

    fecharDialog() {
        this.dialogRef.close();
    }

    salvar(OrcamentoItemForm) {

        if (this.orcamentoItemId == 0) {

            OrcamentoItemForm.orcamentoId = this.Orcamento.orcamentoId;

            this.orcamentoService.postIncluiOrcamentoItem(OrcamentoItemForm.value).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.fecharDialog();

            });
        }
        else {

            this.orcamentoService.putAtualizaOrcamentoItem(OrcamentoItemForm.value, OrcamentoItemForm.value.orcamentoItemId).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.fecharDialog();

            });
        }
    };


    DeterminaprodutoId(produto: Produto) {

        this.orcamentoItemForm.get('produtoId').setValue(produto.produtoId);
        this.orcamentoItemForm.get('codProd').setValue(produto.codProd);
        this.orcamentoItemForm.get('descricao').setValue(produto.descricao);
        this.orcamentoItemForm.get('unidade').setValue(produto.unTributaria);
        this.orcamentoItemForm.get('valorUnitario').setValue(produto.vlrTributario);
        this.orcamentoItemForm.get('informacoesAdicionais').setValue(produto.infAdProd);
        this.orcamentoItemForm.get('ncm').setValue(produto.ncm);
        this.orcamentoItemForm.get('grupoContaId').setValue(produto.grupoContaId);
    }

    OnChangeqtdComercial(qtdComercial: any) {

        let vlrComercial = this.orcamentoItemForm.get('valorUnitario').value;
        let valorTotal = Math.round((qtdComercial * vlrComercial) * 100) / 100;
        this.orcamentoItemForm.get('valorTotal').setValue(valorTotal.toFixed(2));
        this.orcamentoItemForm.get('quantidade').setValue(qtdComercial);
    }

    OnChangevlrComercial(vlrComercial: any) {

        let qtdComercial = this.orcamentoItemForm.get('quantidade').value;
        let valorTotal = Math.round((qtdComercial * vlrComercial) * 100) / 100;
        this.orcamentoItemForm.get('valorTotal').setValue(valorTotal.toFixed(2));
        this.orcamentoItemForm.get('valorUnitario').setValue(vlrComercial);
    }

    maximizarDialog() {
        this.dialogRef.updateSize('100%', '100%');
        this.maximizado = true;
    }

    restaurarDialog() {
        this.dialogRef.updateSize('80vh', '100vh');
        this.maximizado = false;
    }
}
