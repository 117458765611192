<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>Anexos relacionados com {{textoDocumentoTipo}} - {{IAnexo.documentoIdReferenciado}}</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<mat-dialog-content>
    <div class="md-dialog-content">
        <br>
        <form [formGroup]="anexoForm">
            <div class="row">
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                    <mat-label>Descrição Anexo</mat-label>
                    <input type="text" matInput formControlName="descricao">
                </mat-form-field>
                <div class="col-md-6">
                    <label>Importar</label>
                    <input #arquivoInput type="file" onclick="this.value=null" (change)="fileChanged($event)"
                        accept="*" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-xs-12 form-group">
                    <button color="primary" mat-raised-button (click)="uploadArquivo()" matTooltip="Carregar Arquivo">
                        Importar
                    </button>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="table-responsive col-md-12">
                <table class="table table-striped" cellspacing="0" cellpadding="0">
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Descrição</th>
                            <th>Nome</th>
                            <th class="actions">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let anexo of anexos">
                            <td>{{anexo.dataCriacao | date:'dd/MM/yyyy'}}</td>
                            <td>{{anexo.descricao}}</td>
                            <td>{{anexo.nomeArquivo}}</td>
                            <td class="actions">
                                <a mat-mini-fab (click)="download(anexo)" matTooltip="Baixar">
                                    <mat-icon>download</mat-icon></a>
                                <a mat-mini-fab (click)="excluir(anexo.anexoId)" color="warn" matTooltip="Deletar">
                                    <mat-icon>delete</mat-icon></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</mat-dialog-content>
