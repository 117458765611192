<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>{{ servicoGrupoCadastroForm.value.descricao }} Grupo de Serviços</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<mat-dialog-content>
    <br>
    <form [formGroup]="servicoGrupoCadastroForm" (ngSubmit)="salvarGrupoServico()">
        <div class="row">
            <mat-form-field class="col-md-4 col-xs-12 form-group">
                <mat-label>Empresa/Filial</mat-label>
                <mat-select formControlName="produtoGrupoEmpresa" multiple required>
                    <mat-option *ngFor="let empresa of empresas" [value]="empresa">
                        {{ empresa.nomeFantasia }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-6 col-xs-12 form-group">
                <mat-label>Descrição</mat-label>
                <input type="text" matInput formControlName="descricao">
            </mat-form-field>
        </div>
        <hr>
        <mat-dialog-actions class="text-left mt-15px">
            <button mat-raised-button color="primary" type="submit" style="margin-left: auto;" [disabled]="!servicoGrupoCadastroForm.valid">
                <mat-icon>save</mat-icon> Salvar
            </button>
        </mat-dialog-actions>
    </form>
</mat-dialog-content>