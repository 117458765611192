export class Contato {

    contatoId: number = 0;

    ativo: boolean = true;

    nome: string = '';

    sobreNome: string = '';

    dataNascimento: Date = new Date();

    cargo: string = '';

    departamento: string = '';

    responsavelPor: string = '';

    whatsApp: string = '';

    celular: string = '';

    telefone: string = '';

    logradouro: string = '';

    numero: string = '';

    complemento: string = '';

    bairro: string = '';

    cep: string = '';    

    email: string = '';

    paisId: number = 0;

    ufId: number = 0 ;

    municipioId: number = 0;

    idOrigem: number = 0;

    contatoTipo: number = 0;

    saguiClienteId: number = 0;
}