import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class VendaConfigService {

  gets(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/vendaconfig/visualizatodas/', { observe: 'response' })
  }

  get(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/vendaconfig/visualiza/' + itemId, { observe: 'response' })
  }
  getByEmpresaId(empresaId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/vendaconfig/visualizaporidempresa/' + empresaId, { observe: 'response' })
  }

  post(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/vendaconfig/inclui/', item, { observe: 'response' })
  };

  put(item: any, itemId: number): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/vendaconfig/atualiza/' + itemId, item, { observe: 'response' })
  };

  getCfopPorEmpresa(empresaId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/vendaconfig/visualizacfopporempresa/' + empresaId, { observe: 'response' })
  }

  getgrupoContas(tpFatura: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/vendaconfig/visualizatodosgruposconta/' + tpFatura, { observe: 'response' })
  }

  getCaixas(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/vendaconfig/visualizatodoscaixas', { observe: 'response' })
  }

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
