export class ClienteServico {
    
    clienteServicoId: number = 0;

    clienteServicoRecorrencia: number = 0;

    clienteServicoStatus: number = 0;

    quantidade: number = 0;

    valorUnitario: number = 0;

    valorTotal: number = 0;

    dataContratacao: Date = new Date();

    dataUltimaRenovacao: Date = new Date();

    dataProximaRenovacao: Date = new Date();

    dataUltimaAtualizacao: Date = new Date();

    diaVencimento: number = 0;

    diasParaPagar: number = 0;

    empresaId: number = 0;

    clienteId: number = 0;

    produtoId: number = 0;

    saguiClienteId: number = 0;

    clienteMetodoPagamentoId: number = 0;

    getNetSubscriptionId: string = '00000000-0000-0000-0000-000000000000';
}