export class Cliente {

    clienteId: number = 0;

    codigo: number = 0;

    clienteTipo: number = 0;

    ativo: boolean = true;

    cnpj: string = '';

    cpf: string = '';

    dataNascimento: Date = new Date();

    rg: string = '';

    razaoSocial: string = '';

    nomeFantasia: string = '';

    inscEstadual: string = '';

    inscMunicipial: string = '';

    inscSuframa: string = '';

    municipioId: number = 0;

    ufId: number = 0;

    paisId: number = 0;

    logradouroEntrega: string = '';

    numeroEntrega: string = '';

    complementoEntrega: string = '';

    bairroEntrega: string = '';

    cepEntrega: string = '';

    telefoneEntrega: string = '';

    emailEntrega: string = '';

    codRegimeTributario: number = 0;

    textoNFSe: string = '';

    municipioEntregaId: number = 0;

    ufEntregaId: number = 0;

    saguiClienteId: number = 0;

    indicacao: string = '';

    nomeEmpresa: string = '';

    nacionalidade: string = '';

    estadoCivil: string = '';

    profissao: string = '';

    retidoIssqn: boolean = false;

    retidoPis: boolean = false;

    retidoCofins: boolean = false;

    retidoCsll: boolean = false;

    retidoIrrf: boolean = false;

    retidoInss: boolean = false;

    numeroFuncionarios: number = 0;

    receitaAnual: number = 0;
    codigoUnidadeSoc: number = 0;
    contaContabilId: number = 0;

    parceiroId: number = 0;

    logradouro: string = '';

    numero: string = '';

    complemento: string = '';

    bairro: string = '';

    cep: string = '';

    telefone: string = '';

    email: string = '';

    isento: boolean = false;

    localEntregaAtivo: boolean = false;

    pessoaFisica: boolean = false;

    //Consultar Sintegra
    ufSigla: string = 'SP';

    getNetCustomerId: string = '00000000-0000-0000-0000-000000000000';
    clienteEmpresas: any[] = [];
    clienteImpostosEmpresas: ClienteImpostosEmpresas[] = [];

}

export enum TipoNota {
  NFSE = 0,
  NFE = 1
}

export enum TipoImposto {
  PIS = 'PIS',
  COFINS = 'COFINS',
  CSLL = 'CSLL',
  ISSQN = 'ISSQN',
  IRRF = 'IRRF',
  INSS = 'INSS',
  ICMS = 'ICMS',
  ICMS_ST = 'ICMS_ST',
  PIS_ST = 'PIS_ST',
  COFINS_ST = 'COFINS_ST',
  IPI = 'IPI'
}
export const TipoImpostoFrontendToBackend = {
  'PIS': 0,
  'COFINS': 1,
  'CSLL': 2,
  'ISSQN': 3,
  'IRRF': 4,
  'INSS': 5,
  'ICMS': 6,
  'ICMS_ST': 7,
  'PIS_ST': 8,
  'COFINS_ST': 9,
  'IPI': 10
};

export const TipoImpostoBackendToFrontend = {
  0: 'PIS',
  1: 'COFINS',
  2: 'CSLL',
  3: 'ISSQN',
  4: 'IRRF',
  5: 'INSS',
  6: 'ICMS',
  7: 'ICMS_ST',
  8: 'PIS_ST',
  9: 'COFINS_ST',
  10: 'IPI'
};
export class ClienteImpostosEmpresas {
  ClienteImpostosEmpresasId: number;
  TipoNota: TipoNota;
  TipoImposto: TipoImposto;
  Retido: boolean = false;
  ClienteId: number = 0;
  EmpresaId: number = 0;
  SaguiClienteId: number = 0;
}

export interface ClienteImpostoAgrupado {
  TipoImposto: TipoImposto;
  Retido: boolean;
  Empresas: number[];
}
