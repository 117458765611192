import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdicionaContratoDocumentoDto } from '../shared/adiciona-contrato-documento-dto.model';
import { ContratoService } from '../shared/contrato.service';


export interface DialogData {
  contratoId: number;
}

@Component({
  selector: 'app-adiciona-relacao-contrato-dialog',
  templateUrl: './adiciona-relacao-contrato-dialog.component.html',
  styleUrls: ['./adiciona-relacao-contrato-dialog.component.css']
})
export class AdicionaRelacaoContratoDialogComponent implements OnInit {
  maximizado: boolean;
  adicionaContratoDocumentoDtoForm: FormGroup;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private contratoSerivce: ContratoService,
    public dialogRef: MatDialogRef<AdicionaRelacaoContratoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public IRelacao: DialogData) {

  }

  ngOnInit(): void {
    this.maximizado = false;
    this.adicionaContratoDocumentoDtoForm = this._formBuilder.group(new AdicionaContratoDocumentoDto());
    this.adicionaContratoDocumentoDtoForm.value.contratoId.setValue(this.IRelacao.contratoId);
  }

  fecharDialog() {
    this.dialogRef.close();
}

maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
}

restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
        this.dialogRef.updateSize('90%', '100vh');
    }
    else {
        this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
}
salvar(){
  this.adicionaContratoDocumentoDtoForm.value.contratoId = this.IRelacao.contratoId;
  this.contratoSerivce.adicionaRelacaoContrato(this.adicionaContratoDocumentoDtoForm.value).subscribe(
    () => {
      this._snackBar.open('Relação adicionada com sucesso!', 'Fechar', {
        duration: 2000,
      });
      this.dialogRef.close();
    },
    (error) => {
      this._snackBar.open('Erro ao adicionar relação!', 'Fechar', {
        duration: 2000,
      });
    }
  );
}

}
