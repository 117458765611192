<h1 mat-dialog-title>Gerar Devolução</h1>
<div mat-dialog-content>
    <p>Esse processo irá gerar uma devolução de Venda</p>
    <div class="row" *ngIf="IVenda.vendaTipo != 1">
        <mat-form-field class="col-md-12 col-xs-12 form-group">
            <mat-label>CFOP</mat-label>
            <mat-select [(ngModel)]="cfop">
                <mat-option *ngFor="let cfop of cfops" [value]="cfop.codCfop">
                    {{cfop.codCfop + ' - ' + cfop.descricao}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button class="btn btn-default" mat-button (click)="onNoClick()" matTooltip="Cancelar">Cancelar</button>
    <button mat-raised-button color="primary" mat-button matTooltip="Salvar" (click)="salvar(cfop)">Salvar</button>
</div>
