'use strict';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogService } from '../shared/log.service';
import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { Log } from '../shared/log.model';
import { FormBuilder, FormGroup } from '@angular/forms';

export interface DialogData {
    entidadeId: number;
    logTipo: number;
}

@Component({
    selector: 'log',
    templateUrl: 'log.component.html',
})

export class LogComponent {

    maximizado: boolean;
    logs: any[];
    textoDocumentoTipo: string = '';
    log: Log = new Log();
    logForm: FormGroup;

    constructor(
        public dialog: MatDialog,
    private _snackBar: MatSnackBar,
        private logService: LogService,
        public dialogRef: MatDialogRef<LogComponent>,
        @Inject(MAT_DIALOG_DATA) public ILog: DialogData,
        private _formBuilder: FormBuilder) {
    }

    ngOnInit() {

        this.logForm = this._formBuilder.group(new Log());

        this.maximizado = false;

        this.visualizaLog();

        this.restaurarDialog();
    }

    fecharDialog() {
        this.dialogRef.close();
    }

    maximizarDialog() {
        this.dialogRef.updateSize('100%', '100%');
        this.maximizado = true;
    }

    restaurarDialog() {
        let innerWidth = window.innerWidth;

        if (innerWidth < 1024) {
            this.dialogRef.updateSize('90%', '100vh');
        }
        else {
            this.dialogRef.updateSize('60%', '100vh');
        }
        this.maximizado = false;
    }

    visualizaLog() {

        this.logService.getLogs(this.ILog.entidadeId, this.ILog.logTipo).subscribe(data => {

            this.logs = data.body;

        });
    }

    retornaTipoLog(logTipo: number) {
        switch (logTipo) {
            case 0:
                return 'Conta Parcela';
            case 1:
                return 'Boleto';
            case 2:
                return 'Compra';
            case 3:
                return 'CT-e';
            case 4:
                return 'Encomenda';
        }
    }

    modalStatusDetalhe(mensagem: string){

        const dialogRef = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Detalhe",
            mensagem: mensagem
          }
    
        });
    
      }
}
