import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class DocumentoTemplateService {

    getDocumentoTemplates(): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/documentotemplate/visualizatodas', { observe: 'response' })
    }

    getDocumentoTemplate(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/documentotemplate/visualiza/' + itemId, { observe: 'response' })
    }

    postDocumentoTemplate(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/documentotemplate/inclui/', item, { observe: 'response' })
    };

    putDocumentoTemplate(item: any, itemId: any): Observable<HttpResponse<any>> {
        return this.http.put(
            environment.webApiSaguiControlUrl + '/api/documentotemplate/atualiza/' + itemId, item, { observe: 'response' })
    };

    deleteDocumentoTemplate(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
            environment.webApiSaguiControlUrl + '/api/documentotemplate/exclui/' + itemId, { observe: 'response' })
    }

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
