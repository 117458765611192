'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, switchMap } from "rxjs/operators";

import { ClienteFaturamentoService } from '../shared/cliente-faturamento.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';

import { ClienteFaturamento } from '../shared/cliente-faturamento.model';
import { FaturaService } from '../../fatura/shared/fatura.service';

export interface DialogData {
    clienteFaturamentoId: number;
    clienteId: number;
}

@Component({
    selector: 'cliente-faturamento',
    templateUrl: 'cliente-faturamento.component.html',
})

export class ClienteFaturamentoComponent {

    clienteFaturamentoForm: FormGroup;
    maximizado: boolean;
    grupoContas: any = [];
    caixas: any = [];

    constructor(
        public dialog: MatDialog,
    private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<ClienteFaturamentoComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public IClienteFaturamento: DialogData,
        private clienteFaturamentoService: ClienteFaturamentoService,
        private faturaService: FaturaService) {
    }

    ngOnInit() {

        this.maximizado = false;

        this.clienteFaturamentoForm = this._formBuilder.group(new ClienteFaturamento());

        this.clienteFaturamentoForm.get('clienteFaturamentoId').setValue(this.IClienteFaturamento.clienteFaturamentoId);
        this.clienteFaturamentoForm.get('clienteId').setValue(this.IClienteFaturamento.clienteId);

        if (this.IClienteFaturamento.clienteFaturamentoId > 0) {

            this.buscarClienteFaturamento(this.IClienteFaturamento.clienteFaturamentoId);
        }

        this.getCaixas();

        this.getgrupoContas();

        this.restaurarDialog();

        this.onChanges();
    }

    onChanges(): void {

    }

    fecharDialog() {
        this.dialogRef.close();
    }

    maximizarDialog() {
        this.dialogRef.updateSize('100%', '100%');
        this.maximizado = true;
    }

    restaurarDialog() {
        let innerWidth = window.innerWidth;

        if (innerWidth < 1024) {
            this.dialogRef.updateSize('90%', '100vh');
        }
        else {
            this.dialogRef.updateSize('60%', '100vh');
        }
        this.maximizado = false;
    }

    buscarClienteFaturamento(clienteFaturamentoId: number) {

        this.clienteFaturamentoService.get(clienteFaturamentoId).subscribe(data => {

            this.InsereDados(data.body);

        })
    }

    InsereDados(data: any) {

        this.clienteFaturamentoForm.patchValue(data);
    }

    salvar(clienteFaturamentoForm): void {

        if (this.IClienteFaturamento.clienteFaturamentoId == 0) {

            this.clienteFaturamentoService.post(clienteFaturamentoForm.value).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.fecharDialog();


            }, (error: HttpErrorResponse) => {

                const dialogRef = this.dialog.open(InfoDialogComponent, {
                    data: {
                        titulo: "Erro",
                        mensagem: error.error
                    }
                });
            });
        }
        else {

            this.clienteFaturamentoService.put(clienteFaturamentoForm.value, clienteFaturamentoForm.value.clienteFaturamentoId).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.fecharDialog();


            }, (error: HttpErrorResponse) => {

                const dialogRef = this.dialog.open(InfoDialogComponent, {
                    data: {
                        titulo: "Erro",
                        mensagem: error.error
                    }
                });
            });
        }
    };

    getgrupoContas() {

        let faturaTipo = 0;

        this.faturaService.getgrupoContas(faturaTipo).subscribe(data => {

            this.grupoContas = data.body;

        });
    }

    getCaixas() {

        let faturaTipo = 0;

        this.faturaService.getCaixas(faturaTipo).subscribe(data => {

            this.caixas = data.body;

        });
    }
}
