export class NfeItem {

  nfeItemId: number = 0;

  produtoId: number = 0;

  produtoTipo: number = 0;

  nItem: number = 0;

  cfop: number = 0;

  vProd: number = 0;

  vFrete: number = 0;

  vSeg: number = 0;

  vDesc: number = 0;

  vOutro: number = 0;

  indTot: number = 0;

  codProd: string = '';

  descricao: string = '';

  ncm: string = '';

  ean: string = '';

  eanTrib: string = '';

  unTributaria: string = '';

  qtdTributaria: number = 0;

  vlrTributario: number = 0;

  unComercial: string = '';

  qtdComercial: number = 0;

  vlrComercial: number = 0;

  extipi: number = 0;

  infAdProd: string = '';

  cstIcms: string = '';

  cstIpi: string = '';

  cstCofins: string = '';

  cstPis: string = '';

  xPed: string = '';

  nItemPed: number = 0;

  icmsDifal: boolean = false;

  cest: string = '';

  nfeId: number = 0;
  icmsmodBC: number = 0;
  icmspRedBC: number = 0;
  icmsvBC: number = 0;
  icmspIcms: number = 0;
  icmsvIcms: number = 0;
  icmSorig: number = 0;
  icmspCredSN: number = 0;
  icmsvCredIcmsSN: number = 0;
  icmsSTmodBCST: number = 0;
  icmsSTpRedBCST: number = 0;
  icmsSTpMVAST: number = 0;
  icmsSTvBCST: number = 0;
  icmsSTpIcmsST: number = 0;
  icmsSTvIcmsST: number = 0;
  vBCSTRet: number = 0;
  pST: number = 0;
  vICMSSubstituto: number = 0;
  vICMSSTRet: number = 0;
  ipiclEnq: string = '';
  ipicEnq: string = '';
  ipiCNPJProd: string = '';
  ipicSelo: number = 0;
  ipiqSelo: number = 0;
  ipivBC: number = 0;
  ipipIpi: number = 0;
  ipiqUnid: number = 0;
  ipivUnid: number = 0;
  ipivIpi: number = 0;
  pisvBC: number = 0;
  pispPis: number = 0;
  pisqBCProd: number = 0;
  pisqUnid: number = 0;
  pisvUnid: number = 0;
  pisvPis: number = 0;
  pisvAliqProd: number = 0;
  pisSTvBC: number = 0;
  pisSTpPis: number = 0;
  pisSTvAliqProd: number = 0;
  pisSTqBCProd: number = 0;
  pisSTvPis: number = 0;
  cofinsvBC: number = 0;
  cofinspCofins: number = 0;
  cofinsqUnid: number = 0;
  cofinsvUnid: number = 0;
  cofinsvCofins: number = 0;
  cofinsSTvBC: number = 0;
  cofinsSTpPIS: number = 0;
  cofinsSTvAliqProd: number = 0;
  cofinsSTqBCProd: number = 0;
  cofinsSTvPIS: number = 0;
  cofinsvAliqProd: number = 0;
  cofinsqBCProd: number = 0;
  tipoCalculoIPI: number = 0;
  tipoCalculoPis: number = 1;
  tipoCalculoPisST: number = 1;
  tipoCalculoCofinsST: number = 1;
  tipoCalculoCofins: number = 1;

  pesoLiquido: number = 0;

  pesoBruto: number = 0;
}
