import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class PaisService {

    getPaises(): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/pais/visualizatodas', { observe: 'response' })
    }

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
