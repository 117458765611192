<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>Item do Orcamento</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar(orcamentoItemForm)">
        <mat-icon>save</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="md-dialog-content">
        <br />
        <form [formGroup]="orcamentoItemForm">
            <div class="row">
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                    <mat-label>Tipo de Produto</mat-label>
                    <mat-select formControlName="produtoTipo" name="produtoTipo">
                        <mat-option [value]="0">Produto Acabado</mat-option>
                        <mat-option [value]="1">Serviço</mat-option>
                        <mat-option [value]="2">Uso e Consumo</mat-option>
                        <mat-option [value]="3">Ativo Imobilizado</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                    <mat-label>Produto/Serviço</mat-label>
                    <input matInput type="text"[matAutocomplete]="auto" formControlName="produtoAutoComplete">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let produto of produtos" [value]="produto.descricao"
                            (click)="DeterminaprodutoId(produto)">
                            {{produto.descricao}} - R$ {{produto.vlrComercial | number:'1.2-2'}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="form-group col-md-4">
                    <mat-label>Quantidade Comercial</mat-label>
                    <input matInput type="number" step="0.01" formControlName="quantidade" #qtdComercial (blur)="OnChangeqtdComercial(qtdComercial.value)">
                </mat-form-field>
                <mat-form-field class="form-group col-md-4">
                    <mat-label>Valor Comercial</mat-label>
                    <input matInput type="number" formControlName="valorUnitario"
                        #vlrComercial (blur)="OnChangevlrComercial(vlrComercial.value)">
                </mat-form-field>
                <mat-form-field class="form-group col-md-4">
                    <mat-label>Valor Total</mat-label>
                    <input matInput currencyMask formControlName="valorTotal">
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="form-group col-md-6">
                    <mat-label>Código do Produto</mat-label>
                    <input matInput formControlName="codProd">
                </mat-form-field>
                <mat-form-field class="form-group col-md-6">
                    <mat-label>Descrição</mat-label>
                    <input matInput formControlName="descricao">
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="form-group col-md-4">
                    <mat-label>Unidade Comercial</mat-label>
                    <input matInput formControlName="unidade">
                </mat-form-field>
                <mat-form-field class="form-group col-md-4">
                    <mat-label>Valor Comercial</mat-label>
                    <input matInput currencyMask formControlName="valorUnitario">
                </mat-form-field>
                <mat-form-field class="form-group col-md-4">
                    <mat-label>Quantidade Comercial</mat-label>
                    <input matInput type="number" formControlName="quantidade">
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="form-group col-md-12">
                    <mat-label>Informações Adicionais do Produto</mat-label>
                    <input matInput formControlName="informacoesAdicionais">
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="form-group col-md-4">
                    <mat-label>Valor Total</mat-label>
                    <input currencyMask matInput formControlName="valorTotal">
                </mat-form-field>
                <mat-form-field class="form-group col-md-4">
                    <mat-label>Valor Frete</mat-label>
                    <input currencyMask matInput formControlName="valorFrete">
                </mat-form-field>
                <mat-form-field class="form-group col-md-4">
                    <mat-label>Valor do Seguro</mat-label>
                    <input currencyMask matInput formControlName="valorSeguro">
                </mat-form-field>
                <mat-form-field class="form-group col-md-4">
                    <mat-label>Valor do Desconto</mat-label>
                    <input currencyMask matInput formControlName="valorDesconto">
                </mat-form-field>
                <mat-form-field class="form-group col-md-4">
                    <mat-label>Outras despesas acessórias</mat-label>
                    <input currencyMask matInput formControlName="valorOutrasDespesas">
                </mat-form-field>
            </div>
        </form>
    </div>
</mat-dialog-content>
