export class Remessa {
    remessaId: number = 0;
    remessaStatus: number = 0;
    nfeId: number = 0;
    dataVenda: Date = new Date();
    dataCriacao:  Date = new Date();
    dataPrevisaoCarregamento: Date = new Date();
    dataPrevisaoEntrega: Date = new Date();
    dadosAdicionais: string = '';
    idlocalEntrega: number = 0;
    idlocalRetirada: number = 0;
    modFrete: number = 0;
    transportadoraId: number = 0;
    quantidadevolumesTransportados: number = 0;
    especieVolumesTransportados: string = '';
    tipoEntrega: number = 0;
    pesoBruto: number = 0;
    pesoLiquido: number = 0;
    empresaId: number = 0;
    clienteId: number = 0;
    dataPicking: Date = new Date();
    usuarioPicking: string = '';
    dataPacking: Date = new Date();
    usuarioPacking: string = '';
    saguiClienteId: number = 0;
}