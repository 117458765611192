export class Compra {

    compraId: number = 0;

    indPag: number = 0;

    idlocalEntrega: number = 0;

    idlocalRetirada: number = 0;

    saguiClienteId: number = 0;

    compraStatus: string = '';

    empresaId: number = 0;

    fornecedorId: number = 0;

    fornecedor: string = '';

    faturaId: number = 0;

    nfeId: number = 0;

    solicitante: string = '';

    dadosAdicionais: string = '';

    dataCompra: Date = new Date();

    dataPrevista: Date = new Date();

    modFrete: number = 0;

    transportadoraId: number = 0;

    volumesTransportados: number = 0;

    especieVolumesTransportados: string = '';

    tipoEntrega: number = 0;

    pesoBrutoTransportado: number = 0;

    pesoLiquidoTransportado: number = 0;

    dataAprovacao: Date = new Date();

    aprovadorId: number = 0;

    usuarioAprovador: string = '';
}