import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE, NO_ERRORS_SCHEMA } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppMaterialModule } from './app-material/app-material.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DocumentoModule } from './documento/documento.module';
import { InfoDialogComponent } from './core/dialogs/info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from './core/dialogs/confirm-dialog/confirm-dialog.component';
import { LoaderComponent } from './core/loader/loader.component';
import { HttpClientModule } from '@angular/common/http';
import { HeaderService } from './header/shared/header.service';
import { LoaderService } from './core/loader/loader.service';
import { exportExcelService } from './core/export-excel/export-excel.service';
import { HtmlPrinterService } from './core/html-printer/html-printer.service';
import { CfopService } from './cfop/shared/cfop.service';
import { CepService } from './cep/shared/cep.service';
import { MunicipioService } from './municipio/shared/municipio.service';
import { PaisService } from './pais/shared/pais.service';
import { UfService } from './uf/shared/uf.service';

import { MsalGuard, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { LogLevel, IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { ExportaXmlService } from './core/exporta-xml/exporta-xml.service';
import { ExportaZipService } from './core/exporta-zip/exporta-zip.service';

import { environment } from '../environments/environment';
import pt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { interceptorProviders } from './app-interceptors';
import { ServiceWorkerModule } from '@angular/service-worker';

import { HomeModule } from './home/home.module';
import { AnexoModule } from './anexo/anexo.module';

import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { UtilsService } from './core/utils/utils.service';
import { ExportaCsvService } from './core/exporta-csv/exporta-csv.service';
import { FormComponent } from './Components/form/form.component';
import { ServicosComponent } from './produtoeservico/produtoeservico/servicos.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DialogComponent } from './Components/dialog/dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DeleteComponent } from './Components/delete/delete.component';
import { CadServicoComponent } from './produtoeservico/produtoeservico-cadastro/produtoeservico-cadastro.component';
import { CadGrupoComponent } from './produtoeservico/produtoeservico-grupo-cadastro/cad-grupo.component';
import { NotificationComponent } from './notificacao/notification.component';
import { LogsEmailComponent } from './auditoria/logs-email/logs-email.component';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.saguiControlClientId,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login'
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
//  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set('https://localhost:44327', ['https://saguitech.com.br/saguicontrolapi/user_impersonation']);
  protectedResourceMap.set('https://localhost:7297', ['https://saguitech.com.br/geradordechavesecertificados/user_impersonation']);
  protectedResourceMap.set('https://localhost:44329', ['https://saguitech.com.br/saguilogapi/user_impersonation']);
  protectedResourceMap.set('https://localhost:44330', ['https://saguitech.com.br/saguieduapi/user_impersonation']);
  protectedResourceMap.set('https://saguicontrolapi-homologacao.azurewebsites.net', ['https://saguitech.com.br/saguicontrolapi/user_impersonation']);
  protectedResourceMap.set('https://saguilogapi-homologacao.azurewebsites.net', ['https://saguitech.com.br/saguilogapi/user_impersonation']);
  protectedResourceMap.set('https://saguieduapi-homologacao.azurewebsites.net', ['https://saguitech.com.br/saguieduapi/user_impersonation']);
  protectedResourceMap.set('https://saguicontrolapi-beta.azurewebsites.net', ['https://saguitech.com.br/saguicontrolapi/user_impersonation']);
  protectedResourceMap.set('https://saguilogapi-beta.azurewebsites.net', ['https://saguitech.com.br/saguilogapi/user_impersonation']);
  protectedResourceMap.set('https://saguieduapi-beta.azurewebsites.net', ['https://saguitech.com.br/saguieduapi/user_impersonation']);
  protectedResourceMap.set('https://saguicontrolapi.azurewebsites.net', ['https://saguitech.com.br/saguicontrolapi/user_impersonation']);
  protectedResourceMap.set('https://saguilogapi.azurewebsites.net', ['https://saguitech.com.br/saguilogapi/user_impersonation']);
  protectedResourceMap.set('https://saguieduapi.azurewebsites.net', ['https://saguitech.com.br/saguieduapi/user_impersonation']);
  protectedResourceMap.set('https://geradordechavesecertficados.azurewebsites.net', ['https://saguitech.com.br/geradordechavesecertificados/user_impersonation']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}


export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
    align: "right",
    allowNegative: true,
    decimal: ",",
    precision: 2,
    prefix: "R$ ",
    suffix: "",
    thousands: "."
};

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

registerLocaleData(pt);

@NgModule({
  declarations: [
    AppComponent,
    InfoDialogComponent,
    ConfirmDialogComponent,
    LoaderComponent,
    DeleteComponent,
    CadServicoComponent,
    CadGrupoComponent,
    NotificationComponent
    
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MsalModule,
    AppRoutingModule,
    AppMaterialModule,
    AnexoModule,
    DocumentoModule,
    HomeModule,
    CurrencyMaskModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    BrowserModule,
    FormsModule,

  ],
  providers: [
    interceptorProviders,
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: CustomCurrencyMaskConfig
    },
    {
      provide: LOCALE_ID,
      useValue: "pt"
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'pt'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    CfopService,
    CepService,
    MunicipioService,
    HeaderService,
    PaisService,
    UfService,
    LoaderService,
    exportExcelService,
    HtmlPrinterService,
    ExportaXmlService,
    ExportaZipService,
    ExportaCsvService,
    UtilsService
  ],

  bootstrap: [AppComponent, MsalRedirectComponent],
  entryComponents: [DialogComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
