'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentoService } from '../shared/documento.service';
import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { VendaCadastroComponent } from '../../venda/venda-cadastro/venda-cadastro.component';
import { CompraCadastroComponent } from '../../compra/compra-cadastro/compra-cadastro.component';
import { OrcamentoCadastroComponent } from '../../venda/orcamento-cadastro/orcamento-cadastro.component';
import { NfeCadastroComponent } from '../../fiscal/nfe-cadastro/nfe-cadastro.component';
import { ContratoCadastroComponent } from '../../juridico/contrato-cadastro/contrato-cadastro.component';
import { RemessaCadastroComponent } from '../../remessa/remessa-cadastro/remessa-cadastro.component';
import { FaturaCadastroComponent } from '../../fatura/fatura-cadastro/fatura-cadastro.component';
import { ContaCadastroComponent } from '../../financeiro/conta-cadastro/conta-cadastro.component';
import { CaixaMovimentoComponent } from '../../financeiro/caixa-movimento/caixa-movimento.component';
import { EstoqueMovimentoComponent } from '../../estoque/estoque-movimento/estoque-movimento.component';

export interface DialogData {
    idDocumento: number;
    tipoOrigem: number;
}

@Component({
    selector: 'documento',
    templateUrl: 'documento-cadastro.component.html',
})

export class DocumentoCadastroComponent {

    nomeTipoDocumento: string = "";
    idDocumento: number;
    tipoOrigem: number;
    maximizado: boolean;
    documentos: any[];

    constructor(
        public dialog: MatDialog,
    private _snackBar: MatSnackBar,
        private documentoService: DocumentoService,
        public dialogRef: MatDialogRef<DocumentoCadastroComponent>,
        @Inject(MAT_DIALOG_DATA) public IDocumento: DialogData,
        private location: Location) {

    }

    ngOnInit() {

        this.idDocumento = this.IDocumento.idDocumento;
        this.tipoOrigem = this.IDocumento.tipoOrigem;

        this.maximizado = false;

        switch (this.tipoOrigem) {
            case 0:
                this.nomeTipoDocumento = "Compra";
                break;
            case 1:
                this.nomeTipoDocumento = "Venda";
                break;
            case 2:
                this.nomeTipoDocumento = "Devolução";
                break;
            case 3:
                this.nomeTipoDocumento = "Produção";
                break;
            case 4:
                this.nomeTipoDocumento = "Orcamento";
                break;
            case 5:
                this.nomeTipoDocumento = "NFe";
                break;
            case 6:
                this.nomeTipoDocumento = "Contrato";
                break;
            case 7:
                this.nomeTipoDocumento = "Remessa";
                break;
            case 8:
                this.nomeTipoDocumento = "Fatura de Venda";
                break;
            case 9:
                this.nomeTipoDocumento = "Fatura de Compra";
                break;
            case 10:
                this.nomeTipoDocumento = "Conta a Receber";
                break;
            case 11:
                this.nomeTipoDocumento = "Conta a Pagar";
                break;
            case 12:
                this.nomeTipoDocumento = "Movimento de Caixa";
                break;
            case 13:
                this.nomeTipoDocumento = "Movimento de Estoque";
                break;
            case 23:
                this.nomeTipoDocumento = "Oportunidade";
                break;
            case 24:
                this.nomeTipoDocumento = "Ordem de Serviço";
                break;
        }

        this.visualizaDocumento();

        this.restaurarDialog();
    }

    fecharDialog() {
        this.dialogRef.close();
    }

    maximizarDialog() {
        this.dialogRef.updateSize('100%', '100%');
        this.maximizado = true;
    }

    restaurarDialog() {
        let innerWidth = window.innerWidth;

        if (innerWidth < 1024) {
            this.dialogRef.updateSize('90%', '100vh');
        }
        else {
            this.dialogRef.updateSize('60%', '100vh');
        }
        this.maximizado = false;
    }

    navegar(documentoRelacionado) {
        var link = "";

        switch (documentoRelacionado.tipoDocumentoDestino) {
            case 0:
                this.compraModal(documentoRelacionado.idDocumentoDestino);
                break;
            case 1:
                this.vendaModal(documentoRelacionado.idDocumentoDestino);
                break;
            case 2:
                this.vendaModal(documentoRelacionado.idDocumentoDestino);
                break;
            case 3:
                //Produção foi descontinuada
                break;
            case 4:
                this.orcamentoModal(documentoRelacionado.idDocumentoDestino);
                break;
            case 5:
                this.nfeModal(documentoRelacionado.idDocumentoDestino);
                break;
            case 6:
                this.contratoModal(documentoRelacionado.idDocumentoDestino);
                break;
            case 7:
                this.remessaModal(documentoRelacionado.idDocumentoDestino);
                break;
            case 8:
                this.faturaModal(documentoRelacionado.idDocumentoDestino, 0);
                break;
            case 9:
                this.faturaModal(documentoRelacionado.idDocumentoDestino, 1);
                break;
            case 10:
                this.contaModal(0, documentoRelacionado.idDocumentoDestino, 0);
                break;
            case 11:
                this.contaModal(1, documentoRelacionado.idDocumentoDestino, 1);
                break;
            case 12:
                this.caixaMovimentoModal(documentoRelacionado.idDocumentoDestino);
                break;
            case 13:
                this.estoqueMovimentoModal(documentoRelacionado.idDocumentoDestino);
                break;
        }

        return link;
    }


    vendaModal(id) {

        const dialogRef = this.dialog.open(VendaCadastroComponent, {
            data: {
                vendaId: id,
                vendaTipo: 0
            },
            maxHeight: '100vh',
            maxWidth: '100vw'
        });
    };

    compraModal(id) {

        const dialogRef = this.dialog.open(CompraCadastroComponent, {
            data: { compraId: id },
            maxHeight: '100vh'
        });

    };

    orcamentoModal(id) {

        const dialogRef = this.dialog.open(OrcamentoCadastroComponent, {
            data: {
                orcamentoId: id
            },
            maxHeight: '100vh',
            maxWidth: '100vw'
        });
    };

    nfeModal(id: number) {

        const dialogRef = this.dialog.open(NfeCadastroComponent, {
            data: {
                nfeId: id,
            },
            maxHeight: '100vh',
            maxWidth: '100vw'
        });

    };

    contratoModal(id) {

        const dialogRef = this.dialog.open(ContratoCadastroComponent, {
            data: {
                contratoId: id,
            },
            maxHeight: '100vh',
            maxWidth: '100vw'
        });
    };

    remessaModal(id) {

        const dialogRef = this.dialog.open(RemessaCadastroComponent, {
            data: { remessaId: id },
            maxHeight: '100vh',
            maxWidth: '100vw'
        });

    };

    faturaModal(id: number, faturaTipo: number) {

        const dialogRef = this.dialog.open(FaturaCadastroComponent, {
            data: {
                faturaId: id,
                faturaTipo: faturaTipo
            },
            maxHeight: '100vh',
            maxWidth: '100vw'
        });
    };

    contaModal(tpConta: number, contaId: number, tipoParceiro: number) {

        const dialogRef = this.dialog.open(ContaCadastroComponent, {
            data: {
                contaId: contaId,
                tpConta: tpConta,
                tipoParceiro: tipoParceiro
            },
            maxHeight: '100vh',
            maxWidth: '100vw'
        });

    }

    caixaMovimentoModal(caixaMovimentoId) {

        const dialogRef = this.dialog.open(CaixaMovimentoComponent, {
            data: {
                caixaMovimentoId: caixaMovimentoId,
            },
            maxHeight: '100vh',
            maxWidth: '100vw'
        });

    };

    estoqueMovimentoModal(estoqueMovimentoId) {

        const dialogRef = this.dialog.open(EstoqueMovimentoComponent, {
            data: {
                estoqueMovimentoId: estoqueMovimentoId,
            },
            maxHeight: '100vh',
            maxWidth: '100vw'
        });

    };

    PreencheTipoDocumento(tipoDocumentoDestino) {
        var texto = "";

        switch (tipoDocumentoDestino) {
            case 0:
                texto = "Compra";
                break;
            case 1:
                texto = "Venda";
                break;
            case 2:
                texto = "Devolução";
                break;
            case 3:
                texto = "Produção";
                break;
            case 4:
                texto = "Orçamento";
                break;
            case 5:
                texto = "NFe";
                break;
            case 6:
                texto = "Contrato";
                break;
            case 7:
                texto = "Remessa";
                break;
            case 8:
                texto = "Fatura de Venda";
                break;
            case 9:
                texto = "Fatura de Compra";
                break;
            case 10:
                texto = "Conta a Receber";
                break;
            case 11:
                texto = "Conta a Pagar";
                break;
            case 12:
                texto = "Caixa Movimento";
                break;
            case 13:
                texto = "Estoque Movimento";
                break;
            case 23:
                texto = "Oportunidade";
                break;
            case 24:
                texto = "Ordem de Serviço";
                break;
        }
        return texto;
    }

    visualizaDocumento() {

        var parametros: any = {
            tipoOrigem: this.tipoOrigem,
            idOrigem: this.idDocumento
        };

        this.documentoService.getVisualizaDocumento(parametros).subscribe(data => {

            this.documentos = data.body;

        });
    }
}
