<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>Item da NFe</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>

<div mat-dialog-actions>
    <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar(nfeItemForm.value)">
        <mat-icon>save</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="md-dialog-content">
        <br />
        <form [formGroup]="nfeItemForm">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <mat-tab-group>
                            <mat-tab label="Geral">
                                <br />
                                <div class="row" *ngIf="!INfeItem.nfeManual">
                                    <mat-form-field class="col-md-2 col-xs-12 form-group">
                                        <mat-label>Tipo de Produto</mat-label>
                                        <mat-select formControlName="produtoTipo">
                                            <mat-option [value]="0">Produto Acabado</mat-option>
                                            <mat-option [value]="2">Uso e Consumo</mat-option>
                                            <mat-option [value]="3">Ativo Imobilizado</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="col-md-10 col-xs-12 form-group">
                                        <mat-label>Digite o Nome do Produto</mat-label>
                                        <input type="text" matInput [matAutocomplete]="auto"
                                            formControlName="produtoAutoComplete">
                                        <mat-autocomplete #auto="matAutocomplete">
                                            <mat-option *ngFor="let produto of produtos" [value]="produto.descricao"
                                                (click)="determinaprodutoId(produto)">
                                                {{produto.descricao}} - R$ {{produto.vlrComercial | number:'1.2-2'}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="col-md-6 col-xs-12 form-group">
                                        <mat-label>CFOP</mat-label>
                                        <mat-select formControlName="cfop">
                                            <mat-option *ngFor="let cfop of cfops" [value]="cfop.codCfop">
                                                {{cfop.codCfop + ' - ' + cfop.descricao}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Quantidade Comercial"</mat-label>
                                        <input type="number" matInput formControlName="qtdComercial"
                                            (blur)="onChangeValorComercial()">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Valor Comercial</mat-label>
                                        <input type="number" matInput formControlName="vlrComercial"
                                            (blur)="onChangeValorComercial()">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Valor Total</mat-label>
                                        <input type="number" matInput formControlName="vProd">
                                    </mat-form-field>
                                </div>
                                <hr />
                                <div class="row">
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Código do Produto</mat-label>
                                        <input type="text" matInput formControlName="codProd">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Descrição</mat-label>
                                        <input type="text" matInput formControlName="descricao">
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>NCM</mat-label>
                                        <input type="text" matInput formControlName="ncm" pattern="[0-9]+$"
                            maxlength="8">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>EAN</mat-label>
                                        <input type="text" matInput formControlName="ean">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>EAN Tributário</mat-label>
                                        <input type="text" matInput formControlName="eanTrib">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>EX_TIPI</mat-label>
                                        <input type="text" matInput formControlName="extipi">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>CEST</mat-label>
                                        <input type="text" matInput formControlName="cest">
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Unidade Comercial</mat-label>
                                        <input type="text" matInput formControlName="unComercial">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Valor Comercial</mat-label>
                                        <input type="number" matInput formControlName="vlrComercial">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Quantidade Comercial"</mat-label>
                                        <input type="number" matInput formControlName="qtdComercial">
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Unidade Tributária</mat-label>
                                        <input type="text" matInput formControlName="unTributaria">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Valor Tributário </mat-label>
                                        <input type="number" matInput formControlName="vlrTributario">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Quantidade Tributaria</mat-label>
                                        <input type="number" matInput formControlName="qtdTributaria">
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Peso Bruto (KG)</mat-label>
                                        <input type="number" matInput formControlName="pesoBruto">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Peso Líquido (KG)</mat-label>
                                        <input type="number" matInput formControlName="pesoLiquido">
                                    </mat-form-field>
                                </div>


                            </mat-tab>
                            <mat-tab label="Impostos">
                                <br />
                                <div class="row">
                                    <div class="form-group col-md-3">
                                        <button mat-raised-button color="primary" (click)="calculaImpostos()" matTooltip="Calcular Impostos">
                                            Recalcular Impostos
                                        </button>
                                    </div>
                                </div>
                                <h3>ICMS</h3>
                                <hr />
                                <div class="row">
                                    <mat-form-field class="form-group col-md-12 col-xs-12">
                                        <mat-label>Situação Tributária</mat-label>
                                        <mat-select matInput formControlName="cstIcms">
                                            <mat-option [value]="'00'">00 - Tributada integralmente</mat-option>
                                            <mat-option [value]="'10'">
                                                10 - Tributada e com cobrança do ICMS por substituição tributária
                                            </mat-option>
                                            <mat-option [value]="'20'">
                                                20 - Com redução de base de cálculo
                                            </mat-option>
                                            <mat-option [value]="'30'">
                                                30 - Isenta ou não tributada e com cobrança do ICMS por substituição
                                                tributária
                                            </mat-option>
                                            <mat-option [value]="'40'">
                                                40 - Isenta
                                            </mat-option>
                                            <mat-option [value]="'41'">
                                                41 - Não tributada
                                            </mat-option>
                                            <mat-option [value]="'50'">
                                                50 - Suspensão
                                            </mat-option>
                                            <mat-option [value]="'51'">
                                                51 - Diferimento
                                            </mat-option>
                                            <mat-option [value]="'60'">
                                                60 - ICMS cobrado anteriormente por substituição tributária
                                            </mat-option>
                                            <mat-option [value]="'70'">
                                                70 - Com redução de base de cálculo e cobrança do ICMS por substituição
                                                tributária
                                            </mat-option>
                                            <mat-option [value]="'90'">
                                                90 - Outros
                                            </mat-option>
                                            <mat-option [value]="'101'">101 - Tributada pelo Simples Nacional com
                                                permissão de crédito</mat-option>
                                            <mat-option [value]="'102'">102 - Tributada pelo Simples Nacional sem
                                                permissão de crédito</mat-option>
                                            <mat-option [value]="'103'">103 - Isenção do ICMS no Simples Nacional para
                                                faixa de
                                                receita bruta</mat-option>
                                            <mat-option [value]="'201'">201 - Tributada pelo Simples Nacional com
                                                permissão de
                                                crédito e com cobrança do ICMS por Substituição Tributária</mat-option>
                                            <mat-option [value]="'202'">202 - Tributada pelo Simples Nacional sem
                                                permissão de
                                                crédito e com cobrança do ICMS por Substituição Tributária</mat-option>
                                            <mat-option [value]="'203'">203 - Isenção do ICMS nos Simples Nacional para
                                                faixa de
                                                receita bruta e com cobrança do ICMS por Substituição Tributária
                                            </mat-option>
                                            <mat-option [value]="'300'">300 - Imune</mat-option>
                                            <mat-option [value]="'400'">400 - Não tributada pelo Simples Nacional
                                            </mat-option>
                                            <mat-option [value]="'500'">500 - ICMS cobrado anteriormente por
                                                substituição
                                                tributária (substituído) ou por antecipação</mat-option>
                                            <mat-option [value]="'900'">900 - Outros</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12"
                                        *ngIf="nfeitem.cstIcms == '10' || nfeitem.cstIcms == '70'">
                                        <mat-label for="cstIcms">&nbsp;</mat-label>
                                        <div class="checkbox">
                                            <mat-label>
                                                <input type="checkbox" matInput formControlName="icmsDifal">
                                                ICMS DIFAL
                                            </mat-label>
                                        </div>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="form-group col-md-12">
                                        <mat-label>Origem da mercadoria</mat-label>
                                        <mat-select id="orig" formControlName="icmSorig">
                                            <mat-option [value]="0">0 - Nacional</mat-option>
                                            <mat-option [value]="1">1 - Estrangeira - Importação direta</mat-option>
                                            <mat-option [value]="2">2 - Estrangeira - Adquirida no mercado interno
                                            </mat-option>
                                            <mat-option [value]="3">3 - Nacional, mercadoria ou bem com Conteúdo de
                                                Importação
                                                superior a 40% e inferior ou igual a 70%</mat-option>
                                            <mat-option [value]="4">4 - Nacional, cuja produção tenha sido feita em
                                                conformidade com os processos produtivos básicos de que tratam as
                                                legislações citadas nos Ajustes</mat-option>
                                            <mat-option [value]="5">5 - Nacional, mercadoria ou bem com Conteúdo de
                                                Importação
                                                inferior ou igual a 40%</mat-option>
                                            <mat-option [value]="6">6 - Estrangeira - Importação direta, sem similar
                                                nacional,
                                                constante em lista da CAMEX e gás natural</mat-option>
                                            <mat-option [value]="7">7 - Estrangeira - Adquirida no mercado interno, sem
                                                similar nacional, constante lista CAMEX e gás natural</mat-option>
                                            <mat-option [value]="8">8 - Nacional, mercadoria ou bem com Conteúdo de
                                                Importação
                                                superior a 70%</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="row" *ngIf="showIcmsTrib">
                                    <mat-form-field class="form-group col-md-4">
                                        <mat-label>Modalidade de determinação da BC do ICMS</mat-label>
                                        <mat-select formControlName="icmsmodBC">
                                            <mat-option [value]="0">Margem Valor Agregado (%)</mat-option>
                                            <mat-option [value]="1">Pauta (Valor)</mat-option>
                                            <mat-option [value]="2">Preço Tabelado Máx. (valor)</mat-option>
                                            <mat-option [value]="3">Valor da operação</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4">
                                        <mat-label>Valor da BC do ICMS</mat-label>
                                        <input type="text" currencyMask matInput formControlName="icmsvBC">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4">
                                        <mat-label>Alíquota do imposto do ICMS</mat-label>
                                        <input type="number" matInput formControlName="icmspIcms" (blur)="TrataValorICMS()">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4">
                                        <mat-label>Valor do ICMS</mat-label>
                                        <input type="text" currencyMask matInput formControlName="icmsvIcms">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4" *ngIf="showPercentualReducaoBC">
                                        <mat-label>Percentual da Redução de BC do ICMS</mat-label>
                                        <input type="number" matInput formControlName="icmspRedBC">
                                    </mat-form-field>
                                </div>
                                <div class="row" *ngIf="showIcmsSTRet">
                                    <mat-form-field class="form-group col-md-4">
                                        <mat-label for="vBCSTRet">Valor da BC do ICMS ST retido</mat-label>
                                        <input type="text" currencyMask id="vBCSTRet"
                                            matInput formControlName="vBCSTRet">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4">
                                        <mat-label for="pST">Alíquota suportada pelo Consumidor Final</mat-label>
                                        <input type="number" id="pST" matInput
                                            formControlName="pST">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4">
                                        <mat-label for="vICMSSubstituto">Valor do ICMS próprio do Substituto</mat-label>
                                        <input currencyMask matInput formControlName="vICMSSubstituto">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4">
                                        <mat-label for="vICMSSTRet">Valor do ICMS ST retido</mat-label>
                                        <input currencyMask id="vICMSSTRet"
                                            matInput formControlName="vICMSSTRet">
                                    </mat-form-field>
                                </div>
                                <div *ngIf="showIcmsST">
                                    <hr />
                                    <h3>ICMS ST</h3>
                                    <hr />
                                    <div class="row">
                                        <mat-form-field class="form-group col-md-4">
                                            <mat-label for="modBCST">Modalidade de determinação da BC do ICMS ST
                                            </mat-label>
                                            <mat-select formControlName="icmsSTmodBCST">
                                                <mat-option [value]="0">0 - Preço tabelado ou máximo sugerido
                                                </mat-option>
                                                <mat-option [value]="1">1 - Lista Negativa</mat-option>
                                                <mat-option [value]="2">2 - Lista Positiva</mat-option>
                                                <mat-option [value]="3">3 - Lista Neutra</mat-option>
                                                <mat-option [value]="4">4 - Margem Valor Agregado</mat-option>
                                                <mat-option [value]="5">5 - Pauta</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="form-group col-md-4">
                                            <mat-label for="pRedBCST">Percentual da Redução de BC do ICMS ST</mat-label>
                                            <input type="number" id="pRedBCST"
                                                matInput formControlName="icmsSTpRedBCST">
                                        </mat-form-field>
                                        <mat-form-field class="form-group col-md-4">
                                            <mat-label for="pMVAST">Percentual da MVA Adicionado do ICMS ST</mat-label>
                                            <input type="number" id="pMVAST"
                                                matInput formControlName="icmsSTpMVAST">
                                        </mat-form-field>
                                        <mat-form-field class="form-group col-md-4">
                                            <mat-label for="vBCST">Valor da BC do ICMS ST</mat-label>
                                            <input type="text" currencyMask id="vBCST"
                                                matInput formControlName="icmsSTvBCST">
                                        </mat-form-field>
                                        <mat-form-field class="form-group col-md-4">
                                            <mat-label for="pIcmsST">Alíquota do imposto do ICMS ST</mat-label>
                                            <input type="number"
                                                matInput formControlName="icmsSTpIcmsST" (blur)="TrataValorICMSST()">
                                        </mat-form-field>
                                        <mat-form-field class="form-group col-md-4">
                                            <mat-label for="vICMSST">Valor do ICMS ST</mat-label>
                                            <input currencyMask matInput formControlName="icmsSTvIcmsST">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <mat-form-field class="form-group col-md-3" *ngIf="showPercentualCreditoSN">
                                        <mat-label>Alíquota aplicável de cálculo do crédito</mat-label>
                                        <input type="number" matInput formControlName="icmspCredSN">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4" *ngIf="showValorCreditoIcmsSN">
                                        <mat-label>Valor crédito do ICMS que pode ser aproveitado
                                        </mat-label>
                                        <input type="text" currencyMask matInput formControlName="icmsvCredIcmsSN">
                                    </mat-form-field>
                                </div>
                                <hr />
                                <h3>IPI</h3>
                                <hr />
                                <div class="row">
                                    <mat-form-field class="form-group col-md-12">
                                        <mat-label for="cstIpi">Código da situação tributária</mat-label>
                                        <mat-select id="cstIpi" formControlName="cstIpi">
                                            <mat-option [value]="'00'">00 - Entrada com recuperação de crédito
                                            </mat-option>
                                            <mat-option [value]="'01'">01 - Entrada tributada com alíquota zero
                                            </mat-option>
                                            <mat-option [value]="'02'">02 - Entrada isenta</mat-option>
                                            <mat-option [value]="'03'">03 - Entrada não tributada</mat-option>
                                            <mat-option [value]="'04'">04 - Entrada imune</mat-option>
                                            <mat-option [value]="'05'">05 - Entrada com suspensão</mat-option>
                                            <mat-option [value]="'49'">49 - Outras entradas</mat-option>
                                            <mat-option [value]="'50'">50 - Saída tributada</mat-option>
                                            <mat-option [value]="'51'">51 - Saída tributada com alíquota zero
                                            </mat-option>
                                            <mat-option [value]="'52'">52 - Saída isenta</mat-option>
                                            <mat-option [value]="'53'">53 - Saída não tributada</mat-option>
                                            <mat-option [value]="'54'">54 - Saída imune</mat-option>
                                            <mat-option [value]="'55'">55 - Saída com suspensão</mat-option>
                                            <mat-option [value]="'99'">99 - Outras saídas</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="form-group col-md-4">
                                        <mat-label>Classe de enquadramento</mat-label>
                                        <input type="text" matInput formControlName="ipiclEnq">
                                        <mat-hint>Classe de enquadramento do IPI para Cigarros e
                                            Bebidas</mat-hint>
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-3">
                                        <mat-label>Código de Enquadramento</mat-label>
                                        <input type="text" matInput formControlName="ipicEnq" required>
                                        <mat-hint>Código de Enquadramento Legal do IPI</mat-hint>
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-3">
                                        <mat-label for="CNPJProd">CNPJ do produtor</mat-label>
                                        <input type="number" matInput formControlName="ipiCNPJProd">
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="form-group col-md-3">
                                        <mat-label for="cSelo">Código do selo de controle IPI</mat-label>
                                        <input type="text" id="cSelo" matInput formControlName="ipicSelo">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-3">
                                        <mat-label for="qSelo">Quantidade de selo de controle</mat-label>
                                        <input type="number" id="qSelo" matInput formControlName="ipiqSelo">
                                    </mat-form-field>
                                </div>
                                <div *ngIf="showIpiTributado">
                                    <div class="row">
                                        <mat-form-field class="form-group col-md-3">
                                            <mat-label for="tipoCalculoIPI">Tipo de Cálculo</mat-label>
                                            <mat-select formControlName="tipoCalculoIPI">
                                                <mat-option [value]="0">Percentual</mat-option>
                                                <mat-option [value]="1">Em Quantidade (pauta)</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="row" *ngIf="showIpiAliquota">
                                        <mat-form-field class="form-group col-md-3">
                                            <mat-label for="ipivBC">Valor da Base de Cálculo</mat-label>
                                            <input type="text" currencyMask
                                                matInput formControlName="ipivBC">
                                        </mat-form-field>
                                        <mat-form-field class="form-group col-md-3">
                                            <mat-label for="pIPI">Alíquota do IPI</mat-label>
                                            <input type="number"
                                                matInput formControlName="ipipIpi">
                                        </mat-form-field>
                                    </div>
                                    <div class="row" *ngIf="showIpiPauta">
                                        <mat-form-field class="form-group col-md-3">
                                            <mat-label for="qUnid">Quantidade total na unidade padrão</mat-label>
                                            <input type="number" matInput formControlName="ipiqUnid">
                                        </mat-form-field>
                                        <mat-form-field class="form-group col-md-3">
                                            <mat-label for="vUnid">Valor por Unidade Tributável</mat-label>
                                            <input type="text" currencyMask matInput formControlName="ipivUnid">
                                        </mat-form-field>
                                    </div>
                                    <div class="row">
                                        <mat-form-field class="form-group col-md-6">
                                            <mat-label for="vIPI">Valor do IPI </mat-label>
                                            <input type="text" currencyMask matInput formControlName="ipivIpi">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <h3>PIS</h3>
                                <hr />
                                <div class="row">
                                    <mat-form-field class="form-group col-md-12">
                                        <mat-label for="cstPis">Situação Tributária</mat-label>
                                        <mat-select formControlName="cstPis">
                                            <mat-option [value]="'01'">01 - Operação Tributável (base de cálculo = valor
                                                da
                                                operação alíquota normal (cumulativo/não cumulativo))</mat-option>
                                            <mat-option [value]="'02'">02 - Operação Tributável (base de cálculo = valor
                                                da
                                                operação (alíquota diferenciada))</mat-option>
                                            <mat-option [value]="'03'">03 - Operação Tributável (base de cálculo =
                                                quantidade
                                                vendida x alíquota por unidade de produto)</mat-option>
                                            <mat-option [value]="'04'">04 - Operação Tributável (tributação monofásica
                                                (alíquota
                                                zero))</mat-option>
                                            <mat-option [value]="'05'">05 - Operação Tributável (Substituição
                                                Tributária)
                                            </mat-option>
                                            <mat-option [value]="'06'">06 - Operação Tributável (alíquota zero)
                                            </mat-option>
                                            <mat-option [value]="'07'">07 - Operação Isenta da Contribuição</mat-option>
                                            <mat-option [value]="'08'">08 - Operação Sem Incidência da Contribuição
                                            </mat-option>
                                            <mat-option [value]="'09'">09 - Operação com Suspensão da Contribuição
                                            </mat-option>
                                            <mat-option [value]="'49'">49 - Outras Operações de Saída</mat-option>
                                            <mat-option [value]="'50'">50 - Operação com Direito a Crédito - Vinculada
                                                Exclusivamente a Receita Tributada no Mercado Interno</mat-option>
                                            <mat-option [value]="'51'">51 - Operação com Direito a Crédito - Vinculada
                                                Exclusivamente a Receita Não Tributada no Mercado Interno</mat-option>
                                            <mat-option [value]="'52'">52 - Operação com Direito a Crédito - Vinculada
                                                Exclusivamente a Receita de Exportação</mat-option>
                                            <mat-option [value]="'53'">53 - Operação com Direito a Crédito - Vinculada a
                                                Receitas Tributadas e Não-Tributadas no Mercado Interno</mat-option>
                                            <mat-option [value]="'54'">54 - Operação com Direito a Crédito - Vinculada a
                                                Receitas Tributadas no Mercado Interno e de Exportação</mat-option>
                                            <mat-option [value]="'55'">55 - Operação com Direito a Crédito - Vinculada a
                                                Receitas NãoTributadas no Mercado Interno e de Exportação</mat-option>
                                            <mat-option [value]="'56'">56 - Operação com Direito a Crédito - Vinculada a
                                                Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação
                                            </mat-option>
                                            <mat-option [value]="'60'">60 - Crédito Presumido - Operação de Aquisição
                                                Vinculada
                                                Exclusivamente a Receita Tributada no Mercado Interno</mat-option>
                                            <mat-option [value]="'61'">61 - Crédito Presumido - Operação de Aquisição
                                                Vinculada
                                                Exclusivamente a Receita Não-Tributada no Mercado Interno</mat-option>
                                            <mat-option [value]="'62'">62 - Crédito Presumido - Operação de Aquisição
                                                Vinculada
                                                Exclusivamente a Receita de Exportação</mat-option>
                                            <mat-option [value]="'63'">63 - Crédito Presumido - Operação de Aquisição
                                                Vinculada
                                                a Receitas Tributadas e Não-Tributadas no Mercado Interno</mat-option>
                                            <mat-option [value]="'64'">64 - Crédito Presumido - Operação de Aquisição
                                                Vinculada
                                                a Receitas Tributadas no Mercado Interno e de Exportação</mat-option>
                                            <mat-option [value]="'65'">65 - Crédito Presumido - Operação de Aquisição
                                                Vinculada
                                                a Receitas Não-Tributadas no Mercado Interno e de Exportação
                                            </mat-option>
                                            <mat-option [value]="'66'">66 - Crédito Presumido - Operação de Aquisição
                                                Vinculada
                                                a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de
                                                Exportação</mat-option>
                                            <mat-option [value]="'67'">67 - Crédito Presumido - Outras Operações
                                            </mat-option>
                                            <mat-option [value]="'70'">70 - Operação de Aquisição sem Direito a Crédito
                                            </mat-option>
                                            <mat-option [value]="'71'">71 - Operação de Aquisição com Isenção
                                            </mat-option>
                                            <mat-option [value]="'72'">72 - Operação de Aquisição com Suspensão
                                            </mat-option>
                                            <mat-option [value]="'73'">73 - Operação de Aquisição a Alíquota Zero
                                            </mat-option>
                                            <mat-option [value]="'74'">74 - Operação de Aquisição; sem Incidência da
                                                Contribuição</mat-option>
                                            <mat-option [value]="'75'">75 - Operação de Aquisição por Substituição
                                                Tributária
                                            </mat-option>
                                            <mat-option [value]="'98'">98 - Outras Operações de Entrada</mat-option>
                                            <mat-option [value]="'99'">99 - Outras Operações</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="form-group col-md-3" *ngIf="showTipoCalculoPis">
                                        <mat-label>Tipo de Cálculo</mat-label>
                                        <mat-select formControlName="tipoCalculoPis">
                                            <mat-option [value]="1">Percentual</mat-option>
                                            <mat-option [value]="2">Em valor (pauta)</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="row" *ngIf="showPisAliquota">
                                    <mat-form-field class="form-group col-md-3">
                                        <mat-label for="vBC">Valor da Base de Cálculo do PIS</mat-label>
                                        <input type="text" currencyMask matInput
                                            formControlName="pisvBC">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-3">
                                        <mat-label for="pPIS">Alíquota do PIS (em percentual)</mat-label>
                                        <input type="number" matInput
                                            formControlName="pispPis">
                                    </mat-form-field>
                                </div>
                                <div class="row" *ngIf="showPisPauta">
                                    <mat-form-field class="form-group col-md-6">
                                        <mat-label for="vAliqProd">Alíquota do PIS (em reais)</mat-label>
                                        <input type="number" matInput
                                            formControlName="pisvAliqProd">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-6">
                                        <mat-label for="qBCProd">Quantidade Vendida</mat-label>
                                        <input type="number" matInput formControlName="piSqBCProd">
                                    </mat-form-field>
                                </div>
                                <div class="row" *ngIf="showValorPis">
                                    <mat-form-field class="form-group col-md-3">
                                        <mat-label for="vPIS">Valor do PIS</mat-label>
                                        <input type="text" currencyMask matInput formControlName="pisvPis">
                                    </mat-form-field>
                                </div>
                                <div *ngIf="showPisST">
                                    <hr />
                                    <h3>PIS ST</h3>
                                    <hr />
                                    <div class="row" *ngIf="showTipoCalculoPisST">
                                        <mat-form-field class="form-group col-md-3">
                                            <mat-label>Tipo de Cálculo</mat-label>
                                            <mat-select formControlName="tipoCalculoPisST">
                                                <mat-option [value]="1">Percentual</mat-option>
                                                <mat-option [value]="2">Em valor (pauta)</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="row" *ngIf="showPisSTAliquota">
                                        <mat-form-field class="form-group col-md-3">
                                            <mat-label for="vBC">Valor da Base de Cálculo do PIS</mat-label>
                                            <input type="text" currencyMask
                                                matInput formControlName="pisSTvBC">
                                        </mat-form-field>
                                        <mat-form-field class="form-group col-md-3">
                                            <mat-label for="pPIS">Alíquota do PIS (em percentual)</mat-label>
                                            <input type="number"
                                                matInput formControlName="pisSTpPis">
                                        </mat-form-field>
                                    </div>
                                    <div class="row" *ngIf="showPisSTPauta">
                                        <div class="col-md-6">
                                            <mat-label for="vAliqProd">Alíquota do PIS (em reais)</mat-label>
                                            <input type="number"
                                                matInput formControlName="pisSTvAliqProd">
                                        </div>
                                        <div class="col-md-6">
                                            <mat-label>Quantidade Vendida</mat-label>
                                            <input type="number" matInput formControlName="pisSTqBCProd">
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="showValorPis">
                                        <mat-form-field class="form-group col-md-3">
                                            <mat-label for="vPIS">Valor do PIS</mat-label>
                                            <input type="text" currencyMask matInput formControlName="pisSTvPis">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <hr />
                                <h3>COFINS</h3>
                                <hr />
                                <div class="row">
                                    <mat-form-field class="form-group col-md-12">
                                        <mat-label for="cstCofins">Situação Tributária</mat-label>
                                        <mat-select formControlName="cstCofins">
                                            <mat-option [value]="'01'">01 - Operação Tributável (base de cálculo = valor
                                                da
                                                operação alíquota normal (cumulativo/não cumulativo))</mat-option>
                                            <mat-option [value]="'02'">02 - Operação Tributável (base de cálculo = valor
                                                da
                                                operação (alíquota diferenciada))</mat-option>
                                            <mat-option [value]="'03'">03 - Operação Tributável (base de cálculo =
                                                quantidade
                                                vendida x alíquota por unidade de produto)</mat-option>
                                            <mat-option [value]="'04'">04 - Operação Tributável (tributação monofásica
                                                (alíquota
                                                zero))</mat-option>
                                            <mat-option [value]="'05'">05 - Operação Tributável (Substituição
                                                Tributária)
                                            </mat-option>
                                            <mat-option [value]="'06'">06 - Operação Tributável (alíquota zero)
                                            </mat-option>
                                            <mat-option [value]="'07'">07 - Operação Isenta da Contribuição</mat-option>
                                            <mat-option [value]="'08'">08 - Operação Sem Incidência da Contribuição
                                            </mat-option>
                                            <mat-option [value]="'09'">09 - Operação com Suspensão da Contribuição
                                            </mat-option>
                                            <mat-option [value]="'49'">49 - Outras Operações de Saída</mat-option>
                                            <mat-option [value]="'50'">50 - Operação com Direito a Crédito - Vinculada
                                                Exclusivamente a Receita Tributada no Mercado Interno</mat-option>
                                            <mat-option [value]="'51'">51 - Operação com Direito a Crédito - Vinculada
                                                Exclusivamente a Receita Não Tributada no Mercado Interno</mat-option>
                                            <mat-option [value]="'52'">52 - Operação com Direito a Crédito - Vinculada
                                                Exclusivamente a Receita de Exportação</mat-option>
                                            <mat-option [value]="'53'">53 - Operação com Direito a Crédito - Vinculada a
                                                Receitas Tributadas e Não-Tributadas no Mercado Interno</mat-option>
                                            <mat-option [value]="'54'">54 - Operação com Direito a Crédito - Vinculada a
                                                Receitas Tributadas no Mercado Interno e de Exportação</mat-option>
                                            <mat-option [value]="'55'">55 - Operação com Direito a Crédito - Vinculada a
                                                Receitas NãoTributadas no Mercado Interno e de Exportação</mat-option>
                                            <mat-option [value]="'56'">56 - Operação com Direito a Crédito - Vinculada a
                                                Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação
                                            </mat-option>
                                            <mat-option [value]="'60'">60 - Crédito Presumido - Operação de Aquisição
                                                Vinculada
                                                Exclusivamente a Receita Tributada no Mercado Interno</mat-option>
                                            <mat-option [value]="'61'">61 - Crédito Presumido - Operação de Aquisição
                                                Vinculada
                                                Exclusivamente a Receita Não-Tributada no Mercado Interno</mat-option>
                                            <mat-option [value]="'62'">62 - Crédito Presumido - Operação de Aquisição
                                                Vinculada
                                                Exclusivamente a Receita de Exportação</mat-option>
                                            <mat-option [value]="'63'">63 - Crédito Presumido - Operação de Aquisição
                                                Vinculada
                                                a Receitas Tributadas e Não-Tributadas no Mercado Interno</mat-option>
                                            <mat-option [value]="'64'">64 - Crédito Presumido - Operação de Aquisição
                                                Vinculada
                                                a Receitas Tributadas no Mercado Interno e de Exportação</mat-option>
                                            <mat-option [value]="'65'">65 - Crédito Presumido - Operação de Aquisição
                                                Vinculada
                                                a Receitas Não-Tributadas no Mercado Interno e de Exportação
                                            </mat-option>
                                            <mat-option [value]="'66'">66 - Crédito Presumido - Operação de Aquisição
                                                Vinculada
                                                a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de
                                                Exportação</mat-option>
                                            <mat-option [value]="'67'">67 - Crédito Presumido - Outras Operações
                                            </mat-option>
                                            <mat-option [value]="'70'">70 - Operação de Aquisição sem Direito a Crédito
                                            </mat-option>
                                            <mat-option [value]="'71'">71 - Operação de Aquisição com Isenção
                                            </mat-option>
                                            <mat-option [value]="'72'">72 - Operação de Aquisição com Suspensão
                                            </mat-option>
                                            <mat-option [value]="'73'">73 - Operação de Aquisição a Alíquota Zero
                                            </mat-option>
                                            <mat-option [value]="'74'">74 - Operação de Aquisição; sem Incidência da
                                                Contribuição</mat-option>
                                            <mat-option [value]="'75'">75 - Operação de Aquisição por Substituição
                                                Tributária
                                            </mat-option>
                                            <mat-option [value]="'98'">98 - Outras Operações de Entrada</mat-option>
                                            <mat-option [value]="'99'">99 - Outras Operações</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="form-group col-md-3" *ngIf="showTipoCalculoCofins">
                                        <mat-label>Tipo de Cálculo</mat-label>
                                        <mat-select formControlName="tipoCalculoCofins">
                                            <mat-option [value]="1">Percentual</mat-option>
                                            <mat-option [value]="2">Em valor (pauta)</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="row" *ngIf="showCofinsAliquota">
                                    <mat-form-field class="form-group col-md-3">
                                        <mat-label for="vBC">Valor da Base de Cálculo do cofins</mat-label>
                                        <input type="text" currencyMask matInput
                                            formControlName="cofinsvBC">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-3">
                                        <mat-label for="pcofins">Alíquota do cofins (em percentual)</mat-label>
                                        <input type="number"
                                            matInput formControlName="cofinspCofins">
                                    </mat-form-field>
                                </div>
                                <div class="row" *ngIf="showCofinsPauta">
                                    <mat-form-field class="form-group col-md-6">
                                        <mat-label for="vAliqProd">Alíquota do cofins (em reais)</mat-label>
                                        <input type="number"
                                            matInput formControlName="cofinsvAliqProd">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-6">
                                        <mat-label for="qBCProd">Quantidade Vendida</mat-label>
                                        <input type="number" matInput formControlName="cofinsqBCProd">
                                    </mat-form-field>
                                </div>
                                <div class="row" *ngIf="showValorCofins">
                                    <mat-form-field class="form-group col-md-3">
                                        <mat-label>Valor do cofins</mat-label>
                                        <input type="text" currencyMask
                                            matInput formControlName="cofinsvCofins">
                                    </mat-form-field>
                                </div>
                                <div *ngIf="showCofinsST">
                                    <hr />
                                    <h3>COFINS ST</h3>
                                    <hr />
                                    <div class="row" *ngIf="showTipoCalculoCofinsST">
                                        <mat-form-field class="form-group col-md-3">
                                            <mat-label>Tipo de Cálculo</mat-label>
                                            <mat-select formControlName="tipoCalculoCofinsST">
                                                <mat-option [value]="1">Percentual</mat-option>
                                                <mat-option [value]="2">Em valor (pauta)</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="row" *ngIf="showCofinsSTAliquota">
                                        <mat-form-field class="form-group col-md-3">
                                            <mat-label for="vBC">Valor da Base de Cálculo do cofins</mat-label>
                                            <input type="text" currencyMask
                                                matInput formControlName="cofinsSTvBC">
                                        </mat-form-field>
                                        <mat-form-field class="form-group col-md-3">
                                            <mat-label for="pcofins">Alíquota do cofins (em percentual)</mat-label>
                                            <input type="number" matInput
                                                formControlName="cofinsSTpCofins">
                                        </mat-form-field>
                                    </div>
                                    <div class="row" *ngIf="showCofinsSTPauta">
                                        <mat-form-field class="form-group col-md-6">
                                            <mat-label for="vAliqProd">Alíquota do cofins (em reais)</mat-label>
                                            <input type="number"
                                                matInput formControlName="cofinsSTvAliqProd">
                                        </mat-form-field>
                                        <mat-form-field class="form-group col-md-6">
                                            <mat-label for="qBCProd">Quantidade Vendida</mat-label>
                                            <input type="number" matInput
                                                formControlName="cofinsSTqBCProd">
                                        </mat-form-field>
                                    </div>
                                    <div class="row" *ngIf="showValorCofinsST">
                                        <mat-form-field class="form-group col-md-3">
                                            <mat-label for="vcofins">Valor do Cofins</mat-label>
                                            <input type="text" currencyMask
                                                matInput formControlName="cofinsSTvCofins">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab label="Detalhes">
                                <br />
                                <div class="row">
                                    <mat-form-field class="form-group col-md-12 col-xs-12">
                                        <mat-label>Informações Adicionais</mat-label>
                                        <input type="text" matInput formControlName="infAdProd">
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="form-group col-md-6 col-xs-12">
                                        <mat-label>Valor Total</mat-label>
                                        <input type="number" matInput formControlName="vProd">
                                    </mat-form-field>
                                    <mat-form-field class="col-md-6 col-xs-12 form-group">
                                        <mat-label>Valor do Produto compõe Total da Nota</mat-label>
                                        <mat-select formControlName="indTot" name="indTot">
                                            <mat-option [value]="0">Não</mat-option>
                                            <mat-option [value]="1">Sim</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Valor do Frete</mat-label>
                                        <input type="number" matInput formControlName="vFrete">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Valor do Seguro</mat-label>
                                        <input type="number" matInput formControlName="vSeg">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Valor do Desconto</mat-label>
                                        <input type="number" matInput formControlName="vDesc">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Outras despesas acessórias</mat-label>
                                        <input type="number" matInput formControlName="vOutro">
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="form-group col-md-4 col-xs-12">
                                        <mat-label>Número do Pedido de Compra</mat-label>
                                        <input type="text" matInput formControlName="xPed">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-6 col-xs-12">
                                        <mat-label>Item do Pedido de Compra</mat-label>
                                        <input type="number" matInput formControlName="nItemPed">
                                    </mat-form-field>
                                </div>

                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
