import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';


@Injectable()
export class exportExcelService {

    SalvarArquivo(divName, titulo) {

        var elt = document.getElementById(divName);
        var wb = XLSX.utils.table_to_book(elt, { cellDates: true, raw: true });
        XLSX.writeFile(wb, titulo + '.xlsx');
    }

    constructor() {

    }
}
