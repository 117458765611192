'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ContatoService } from '../shared/contato.service';
import { UfService } from '../../uf/shared/uf.service';
import { PaisService } from '../../pais/shared/pais.service';
import { MunicipioService } from '../../municipio/shared/municipio.service';
import { CepService } from '../../cep/shared/cep.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

import { Contato } from '../shared/contato.model';
import { UtilsService } from 'src/app/core/utils/utils.service';

export interface DialogData {
  contatoId: number;
  idOrigem: number;
  contatoTipo: number;
  saguiClienteId: number;
}

@Component({
  selector: 'contato-cadastro',
  templateUrl: 'contato-cadastro.component.html',
})

export class ContatoCadastroComponent {

  contatoCadastroForm: FormGroup;
  maximizado: boolean;

  ufs: any = [];
  paises: any = [];
  municipios: any = [];
  cepPesquisado: any = {};

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ContatoCadastroComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public IContato: DialogData,
    private contatoService: ContatoService,    
    private municipioService: MunicipioService,
    private ufService: UfService,
    private paisService: PaisService,
    private cepService: CepService,
    private utilsService: UtilsService) {
  }

  ngOnInit() {

    this.maximizado = false;

    this.contatoCadastroForm = this._formBuilder.group(new Contato());

    if (this.IContato.contatoId > 0) {

      this.buscarContato(this.IContato.contatoId);

    } else {

      this.contatoCadastroForm.get('idOrigem').setValue(this.IContato.idOrigem);
      this.contatoCadastroForm.get('contatoTipo').setValue(this.IContato.contatoTipo);
      this.contatoCadastroForm.get('saguiClienteId').setValue(this.IContato.saguiClienteId);
    }


    this.buscarPaises();

    this.buscarUFs();

    this.onChanges();

    this.restaurarDialog();
  }

  onChanges(): void {

    this.contatoCadastroForm
      .get('ufId')
      .valueChanges
      .subscribe(data => this.buscarMunicipioPorUf(data));
  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  buscarUFs() {

    this.ufService.getUFs().subscribe(data => {

      this.ufs = data.body;


    });
  }

  buscarPaises() {

    this.paisService.getPaises().subscribe(data => {

      this.paises = data.body;

    });
  }

  buscarMunicipioPorUf(ufId: number) {

    if (ufId > 0) {

      this.municipioService.getMunicipioPorUf(ufId).subscribe(data => {

        let municipiosTemp = data.body;

        if (this.cepPesquisado.ibge > 0) {
          let municipioSelecionado = municipiosTemp.find(municipioTemp => municipioTemp.codIBGE == this.cepPesquisado.ibge);
          this.contatoCadastroForm.get('municipioId').setValue(municipioSelecionado.municipioId);
        }
        
        this.municipios = data.body;       

      });
    }
  }

  buscarContato(contatoId: number) {

    this.contatoService.getContato(contatoId).subscribe(data => {

      this.inserirDadosContato(data.body);

    });
  }

  inserirDadosContato(data: any) {

    this.contatoCadastroForm.patchValue(data);

  }

  salvar(): void {

    let contatoForm = this.contatoCadastroForm;

    if (contatoForm.value.contatoId == 0) {

      this.contatoService.postContato(contatoForm.value).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

        this.fecharDialog();

      });

    }
    else {
      this.contatoService.putContato(contatoForm.value, contatoForm.value.contatoId).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

        this.buscarContato(contatoForm.value.contatoId);

      });
    }
  };
  
  consultarCep(valor) {

    if (valor != undefined) {

      this.cepService.getDadosEndereco(valor).subscribe(data => {

        this.contatoCadastroForm.get('paisId').setValue(30);

          this.contatoCadastroForm.get('logradouro').setValue(data.body.logradouro);
          this.contatoCadastroForm.get('bairro').setValue(data.body.bairro);
          let uf = this.ufs.find(ufTemp => ufTemp.siglaUF == data.body.uf);
          this.contatoCadastroForm.get('ufId').setValue(uf.ufId);
          this.cepPesquisado = data.body;


      }, (error: HttpErrorResponse) => {

        const dialogRef = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Erro",
            erro: "Erro ao consultar CEP, preencha o endereço manualmente"
          }
        });
      });
    }
  };

  definieUf(uf: any) {

    this.contatoCadastroForm.get('ufId').setValue(uf.ufId);

  }

  tratarCampos(){

    let cnpj = this.utilsService.removeCaracteresEspeciais(this.contatoCadastroForm.get('cnpj').value);
    cnpj = this.utilsService.removerEspacosComecoFim(cnpj);
    this.contatoCadastroForm.get('cnpj').setValue(cnpj);

    let cpf = this.utilsService.removeCaracteresEspeciais(this.contatoCadastroForm.get('cpf').value);
    cpf = this.utilsService.removerEspacosComecoFim(cpf);
    this.contatoCadastroForm.get('cpf').setValue(cpf);

  }
}
