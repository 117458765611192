'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

import { NfeEvento } from '../shared/nfe-evento.model';
import { NfeEventoService } from '../shared/nfe-evento.service';
import { NfeCartaCorrecaoComponent } from '../nfe-carta-correcao/nfe-carta-correcao.component';

export interface DialogData {
  nfeId: number;
}

@Component({
  selector: 'nfe-evento',
  templateUrl: 'nfe-evento.component.html',
})

export class NfeEventoComponent {

  nfeEventoForm: FormGroup;

  maximizado: boolean;
  nfeEventos: NfeEvento[];

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NfeEventoComponent>,
    @Inject(MAT_DIALOG_DATA) public INfe: DialogData,
    private nfeEventoService: NfeEventoService) {
  }

  ngOnInit() {

    this.maximizado = false;

    this.onChanges();

    this.restaurarDialog();

    this.buscaEventos();
  }

  onChanges(): void {

  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  buscaEventos() {

    this.nfeEventoService.getNfeEventos(this.INfe.nfeId).subscribe(data => {

      this.nfeEventos = data.body;

    }, (error: HttpErrorResponse) => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          erro: error
        }
      });
    })
  }

  modalCartaCorrecao(nfeEventoId: number){

    const dialogRef = this.dialog.open(NfeCartaCorrecaoComponent, {
      data: {
        nfeId: this.INfe.nfeId,
        nfeEventoId: nfeEventoId
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscaEventos();
    });

  }

}
