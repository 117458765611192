<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>Documentos relacionados com {{nomeTipoDocumento}} - {{idDocumento}}</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
        </div>
    </div>
</mat-toolbar>
<mat-dialog-content>
    <div class="md-dialog-content">
        <div class="row">
            <div class="table-responsive col-md-12">
                <table class="table table-striped" cellspacing="0" cellpadding="0">
                    <thead>
                        <tr>
                            <th>Ações</th>
                            <th>Tipo Destino</th>
                            <th>Id Documento Destino</th>
                            <th>Observações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let documentoRelacionado of documentos">
                            <td class="actions">
                                <a mat-mini-fab (click)="navegar(documentoRelacionado)" matTooltip="Editar">
                                    <mat-icon>edit</mat-icon>
                                </a>
                            </td>
                            <td>{{PreencheTipoDocumento(documentoRelacionado.tipoDocumentoDestino)}}</td>
                            <td>{{documentoRelacionado.idDocumentoDestino}}</td>
                            <td>{{documentoRelacionado.descricao}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</mat-dialog-content>
