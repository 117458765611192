export class ContaCorrenteVenda {
    contaCorrenteVendaId: number = 0;
    descricao: string = '';
    contaCorrenteVendaStatus:  number = 0;
    dataCriacao: string = '';
    dataDaVenda: string = '';
    dataPrimeiroVencimento: string = '';
    contaParcelaTipoPagamento:  number = 0;
    valorVenda: number = 0;
    valorTaxa: number = 0;
    valorLiquido: number = 0;
    numeroParcelas: number = 0;
    numeroDoComprovanteDeVenda: string = '';
    codigoAutenticacao: string = '';
    contaCorrenteTerminalCartaoId: number = 0;
    contaCorrenteVendaParcelas: any = [];
    saguiClienteId: number = 0;
}