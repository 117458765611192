import { Produto } from "src/app/produto/shared/produto.model";

export class FaturaItem {

  faturaItemId: number = 0;

  cfop: number = 0;

  produtoId: number = 0;

  vFrete: number = 0;

  vSeg: number = 0;

  vOutro: number = 0;

  indTot: number = 0;

  codProd: string = '';

  descricao: string = '';

  ncm: string = '';

  ean: string = '';

  eanTrib: string = '';

  unTributaria: string = '';

  qtdTributaria: number = 0;

  vlrTributario: number = 0;

  valorUnitario: number = 0;

  unComercial: string = '';

  qtdComercial: number = 0;

  vlrComercial: number = 0;

  extipi: number = 0;

  infAdProd: string = '';

  xPed: string = '';

  nItemPed: number = 0;

  orig: number = 0;

  faturaId: number = 0;

  valorServicos: number = 0;

  valorDeducoes: number = 0;

  issRetido: number = 0;

  baseCalculo: number = 0;

  valorPis: number = 0;

  valorCofins: number = 0;

  valorInss: number = 0;

  valorIr: number = 0;

  valorCsll: number = 0;

  valorIss: number = 0;

  outrasRetencoes: number = 0;

  aliquotaIssqn: number = 0;

  valorLiquidoNfse: number = 0;

  valorIssRetido: number = 0;

  descontoCondicionado: number = 0;

  descontoIncondicionado: number = 0;

  listaServico: string = '';

  saguiClienteId: number = 0;

  valorTotal: number = 0;
  
  valorDesconto: number = 0;
  
  valorBruto: number = 0;

  produto: Produto = new Produto();
}
