'use strict';
import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css'],
})
export class LoginComponent implements OnInit {

  appLogo: string = '';
  appImagemFundo: string = '';
  appSagui: string = 'Sagui';

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router) {
  }

  ngOnInit() {

    let urlApp = window.location.hostname;

    if (urlApp.indexOf('saguilog') > 0) {
      this.appLogo = './assets/images/saguilog-logo-login.png';
      this.appImagemFundo = './assets/images/saguilog-polygon.jpg';
      this.appSagui = 'SaguiLog';
    }
    else {
      
      if (urlApp.indexOf('saguiedu') > 0) {
        this.appLogo = './assets/images/saguiedu-logo-login.png';
        this.appImagemFundo = './assets/images/saguiedu-polygon.jpg';
        this.appSagui = 'SaguiEdu';
      }

      else {
        this.appLogo = './assets/images/saguicontrol-logo-login.png';
        this.appImagemFundo = './assets/images/saguicontrol-polygon.jpg';
        this.appSagui = 'SaguiControl';

      }
    }

    let autenticado = this.msalService.instance.getAllAccounts().length > 0;

    if(autenticado){

      if(this.appSagui == 'SaguiEdu')
      {
        this.router.navigate(['/pedagogico/matriculometro']);
      } else{
        this.router.navigate(['/home']);
      }
    }

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {

        const payload = result.payload as AuthenticationResult;

        if (payload?.account) {
          this.msalService.instance.setActiveAccount(payload.account);
          this.router.navigate(['/home']);
        }
      });

  }

  RealizarLogin() {

    let loginRequest = {
      scopes: ["user.read"]
    };

    this.msalService.loginRedirect(loginRequest);

    this.msalService.handleRedirectObservable()
    .subscribe({
      next: (result) => {
        
        const account = result.account;

        if(!account){
          this.msalService.loginRedirect(loginRequest);
        }
        else{
          this.router.navigate(['/home']);
        }
      },
      error: (error) => {
        console.log(error);
      }
    });

  }
  
}
