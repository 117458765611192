'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, switchMap } from "rxjs/operators";

import { ContaService } from '../shared/conta.service';
import { grupoContaService } from '../shared/grupo-conta.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

import { Conta } from '../shared/conta.model';
import { ContaContabilService } from '../../contabil/shared/conta-contabil.service';
import { ContaParcelaComponent } from '../conta-parcela/conta-parcela.component';
import { ContaParcelaRecorrenteComponent } from '../conta-parcela-recorrente/conta-parcela-recorrente.component';
import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';
import { ContaParcelaService } from '../shared/conta-parcela.service';

export interface DialogData {
  contaId: number;
  tpConta: number;
  tipoParceiro: number;
}

@Component({
  selector: 'conta-cadastro',
  templateUrl: 'conta-cadastro.component.html',
})

export class ContaCadastroComponent {

  empresas: any = [];
  caixas: any = [];
  grupoContas: any = [];
  centroCustos: any = [];
  contaCadastroForm: FormGroup;
  maximizado: boolean;
  permiteEdicao: boolean = true;
  textoAdiantamento: string = '';
  textoTpConta: string = '';
  textoTpContaView: string = '';
  textoParceiro: string = '';
  tipoOperacao: string = '';
  contaParcela: any = {};
  contaParcelas: any = [];
  contaItens: any = [];
  contasContabeis: any = [];
  parceiros: any = [];

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ContaCadastroComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public IConta: DialogData,
    private contaService: ContaService,
    private grupoContaService: grupoContaService,
    private contaContabilService: ContaContabilService,
    private contaParcelaService: ContaParcelaService) {
  }

  ngOnInit() {

    this.maximizado = false;

    this.contaCadastroForm = this._formBuilder.group(new Conta());
    this.contaCadastroForm.addControl('contaContabilDebitoAutoComplete', new FormControl(''));
    this.contaCadastroForm.addControl('contaContabilCreditoAutoComplete', new FormControl(''));
    this.contaCadastroForm.addControl('parceiroAutoComplete', new FormControl(''));

    if (this.IConta.contaId > 0) {

      this.buscarConta(this.IConta.contaId);

    } else {
      this.contaCadastroForm.get('tpConta').setValue(this.IConta.tpConta);
      this.tipoOperacao = "Incluir";
      this.contaParcela = {};
      this.contaParcela.MovimentaCaixa = true;
    }

    switch (this.IConta.tpConta) {
      case 0:
        this.textoTpConta = 'contareceber';
        this.textoTpContaView = 'Conta a Receber';
        this.textoParceiro = 'cliente';
        break;
      case 1:
        this.textoTpConta = 'contapagar';
        this.textoTpContaView = 'Conta a Pagar';
        this.textoParceiro = 'fornecedor';
        break;
      case 2:
        this.textoTpConta = 'adiantamento';
        this.textoTpContaView = 'Adiantamento';
        this.textoParceiro = 'funcionario';

        switch (this.IConta.tipoParceiro) {
          case 0:
            this.textoAdiantamento = "para Cliente";
            break;
          case 1:
            this.textoAdiantamento = "para Fornecedor";
            break;
          case 2:
            this.textoAdiantamento = "para Funcionário";
            break;
        }
        break;
    }

    this.getEmpresas();

    this.buscarCentroCustos();

    this.buscarGrupoContas();

    this.buscarCaixas();

    this.onChanges();

    this.restaurarDialog();
  }

  onChanges(): void {

    this.contaCadastroForm
      .get('contaContabilDebitoAutoComplete')
      .valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => value?.length >= 3 ?
          this.contaContabilService.postVisualizaContaContabilPorFiltro(value) : []
        )
      )
      .subscribe(data =>
        this.contasContabeis = data.body
      );

    this.contaCadastroForm
      .get('contaContabilCreditoAutoComplete')
      .valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => value?.length >= 3 ?
          this.contaContabilService.postVisualizaContaContabilPorFiltro(value) : []
        )
      )
      .subscribe(data =>
        this.contasContabeis = data.body
      );

    this.contaCadastroForm
      .get('parceiroAutoComplete')
      .valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => value?.length >= 3 && value?.length <= 10 ?
          this.contaService.getParceiroPorNome(value, this.IConta.tipoParceiro) : []
        )
      )
      .subscribe(data =>
        this.parceiros = data.body
      );

  }

  getEmpresas() {

    this.contaService.getEmpresas(this.IConta.tpConta).subscribe(data => {

      this.empresas = data.body;

    });
  }

  buscarGrupoContas() {

    this.grupoContaService.getgrupoContas(this.IConta.tpConta).subscribe(data => {

      this.grupoContas = data.body;

    });
  }

  buscarCaixas() {

    this.contaService.getCaixas(this.IConta.tpConta).subscribe(data => {

      this.caixas = data.body;

    });
  }

  buscarCentroCustos() {

    this.contaService.getCentroCustos(this.IConta.tpConta).subscribe(data => {

      this.centroCustos = data.body;

    });
  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {

    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  buscarConta(contaId: number) {

    this.tipoOperacao = "Atualizar";

    this.contaService.getConta(contaId).subscribe(data => {

      this.contaCadastroForm.patchValue(data.body.conta);

      this.contaCadastroForm.get('parceiroAutoComplete').setValue(data.body.conta.nomeParceiro);

      this.contaParcelas = data.body.contaParcelas;

      this.contaItens = data.body.contaItens;

      this.carregarContaContabil();

    }, (error: HttpErrorResponse) => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          erro: error
        }
      });
    })
  }

  carregarContaContabil() {

    if (this.contaCadastroForm.value.contaContabilDebitoId > 0) {

      this.contaContabilService.getContaContabil(this.contaCadastroForm.value.contaContabilDebitoId).subscribe(data => {
        this.contaCadastroForm.get('contaContabilDebitoAutoComplete').setValue(data.body.descricao);
      });
    }

    if (this.contaCadastroForm.value.contaContabilCreditoId > 0) {

      this.contaContabilService.getContaContabil(this.contaCadastroForm.value.contaContabilCreditoId).subscribe(data => {
        this.contaCadastroForm.get('contaContabilCreditoAutoComplete').setValue(data.body.descricao);
      });

    }

  }

  salvar(): void {

    this.contaCadastroForm.get('tipoAdiantamento').setValue(this.IConta.tipoParceiro);

    if (this.tipoOperacao == "Incluir") {

      this.contaCadastroForm.get('dataCriacao').setValue(new Date());

      this.contaService.postConta(this.contaCadastroForm.value).subscribe(data => {

        this.buscarConta(data.body.contaId);

        this.abreModalContaParcela(0, data.body.contaId);

      });

    }
    else {
      this.contaService.putConta(this.contaCadastroForm.value.contaId, this.contaCadastroForm.value).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

        this.buscarConta(this.contaCadastroForm.value.contaId);

      });
    }

  };

  deletarContaParcela(parcela) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja Cancelar essa Parcela?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        let parcelas = [];
        parcelas.push(parcela);

        this.contaParcelaService.postCancelarContaParcelas(parcelas).subscribe(data => {

          this.buscarConta(this.contaCadastroForm.get('contaId').value);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });

      }
    });

  };

  determinagrupoConta(grupoConta) {

    if (grupoConta.grupoContaId > 0) {

      switch (this.IConta.tpConta) {
        case 0:
          this.contaCadastroForm.get('contaContabilCreditoId').setValue(grupoConta.contaContabilId)
          break;
        case 1:
          this.contaCadastroForm.get('contaContabilDebitoId').setValue(grupoConta.contaContabilId)
          break;
        case 2:
          switch (this.IConta.tipoParceiro) {
            case 0:
              this.contaCadastroForm.get('contaContabilCreditoId').setValue(grupoConta.contaContabilId)
              break;
            case 1:
              this.contaCadastroForm.get('contaContabilDebitoId').setValue(grupoConta.contaContabilId)
              break;
            case 2:
              this.contaCadastroForm.get('contaContabilDebitoId').setValue(grupoConta.contaContabilId)
              break;
          }
          break;
      }

      this.carregarContaContabil();
    }

  }

  determinaCaixa(caixa: any) {

    if (caixa.caixaId > 0) {

      switch (this.IConta.tpConta) {
        case 0:
          this.contaCadastroForm.get('contaContabilDebitoId').setValue(caixa.contaContabilId);
          break;
        case 1:
          this.contaCadastroForm.get('contaContabilCreditoId').setValue(caixa.contaContabilId);
          break;
        case 2:
          switch (this.IConta.tipoParceiro) {
            case 0:
              this.contaCadastroForm.get('contaContabilDebitoId').setValue(caixa.contaContabilId);
              break;
            case 1:
              this.contaCadastroForm.get('contaContabilCreditoId').setValue(caixa.contaContabilId);
              break;
            case 2:
              this.contaCadastroForm.get('contaContabilCreditoId').setValue(caixa.contaContabilId);
              break;
          }
          break;
      }
      this.carregarContaContabil();
    }

  }

  atualizaDebito(contaDebitoTemp) {

    this.contaCadastroForm.get('contaContabilDebitoId').setValue(contaDebitoTemp.contaContabilId);

  };

  atualizaCredito(contaCreditoTemp) {

    this.contaCadastroForm.get('contaContabilCreditoId').setValue(contaCreditoTemp.contaContabilId);

  };

  abreModalContaParcela(contaParcelaId: number, contaId: number) {

    const dialogRef = this.dialog.open(ContaParcelaComponent, {
      data: {
        contaId: contaId,
        contaParcelaId: contaParcelaId,
        tpConta: this.IConta.tpConta,
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscarConta(this.contaCadastroForm.get('contaId').value);
    });
  }

  determinaParceiro(parceiro: any) {

    this.contaCadastroForm.get('nomeParceiro').setValue(parceiro.razaoSocial);
    this.contaCadastroForm.get('parceiroAutoComplete').setValue(parceiro.razaoSocial);

    switch (this.IConta.tpConta) {
      case 0:
        this.contaCadastroForm.get('parceiroId').setValue(parceiro.clienteId);

        if (parceiro.contaContabilId > 0) {
          this.contaCadastroForm.get('contaContabilCreditoId').setValue(parceiro.contaContabilId);
        }

        break;
      case 1:
        this.contaCadastroForm.get('parceiroId').setValue(parceiro.fornecedorId);

        if (parceiro.contaContabilId > 0) {
          this.contaCadastroForm.get('contaContabilDebitoId').setValue(parceiro.contaContabilId);
        }
        break;
      case 2:
        switch (this.IConta.tipoParceiro) {
          case 0:
            this.contaCadastroForm.get('parceiroId').setValue(parceiro.clienteId);
            break;
          case 1:
            this.contaCadastroForm.get('parceiroId').setValue(parceiro.fornecedorId);
            break;
          case 2:
            this.contaCadastroForm.get('parceiroId').setValue(parceiro.funcionarioId);
            break;
        }
        break;
    }

    this.carregarContaContabil();

  }

  incluirParcelaRecorrente() {

    const dialogRef = this.dialog.open(ContaParcelaRecorrenteComponent, {
      data: {
        contaId: this.contaCadastroForm.get('contaId').value,
        descricaoConta: this.contaCadastroForm.get('descricaoConta').value
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscarConta(this.contaCadastroForm.get('contaId').value);
    });

  }

  getTextoTipoPagametno(tipoPagamento: number) {

    return this.contaService.getTextoTipoPagametno(tipoPagamento);
  }

  determinaContaContabilDebito(conta) {

    if (conta != undefined) {
      this.contaCadastroForm.get('contaContabilDebitoId').setValue(conta.contaContabilId);
    }

  };

  determinaContaContabilCredito(conta) {

    if (conta != undefined) {
      this.contaCadastroForm.get('contaContabilCreditoId').setValue(conta.contaContabilId);
    }

  };

}
