'use strict';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ContaParcelaService } from '../shared/conta-parcela.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

import { ContaParcela } from '../shared/conta-parcela.model';
import { ContaService } from '../shared/conta.service';
import { OperacaoContabilService } from '../../contabil/shared/operacao-contabil.service';
import { Anexo } from 'src/app/anexo/shared/anexo.model';
import { AnexoService } from 'src/app/anexo/shared/anexo.service';
import { ContaCorrenteTerminalService } from 'src/app/conta-corrente/shared/conta-corrente-terminal.service';

export interface DialogData {
  contaParcelaId: number
}

@Component({
  selector: 'conta-parcela-baixa',
  templateUrl: 'conta-parcela-baixa.component.html',
  styleUrls: ['conta-parcela-baixa.css'],
})

export class ContaParcelaBaixaComponent {

  @ViewChild('arquivoInput', { static: true }) arquivoInput;

  contaParcelaBaixaForm: FormGroup;
  maximizado: boolean;
  valorPagamentoComJuros: number = 0;
  adiantamentos: any = [];
  operacoesContabeis: any = [];
  caixas: any = [];
  arquivo: any = {
    contaCorrenteId: 0,
    nomeArquivo: '',
    corpoArquivo: ''
  };
  anexo: Anexo = new Anexo();
  contaCorrenteTerminais: any = [];
  contaCorrenteTerminalCartoes: any = [];
  selectContaCorrenteTerminal: any = {};

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ContaParcelaBaixaComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public IContaParcelaBaixa: DialogData,
    private contaParcelaService: ContaParcelaService,
    private contaService: ContaService,
    private operacaoContabilService: OperacaoContabilService,
    private contaCorrenteTerminalService: ContaCorrenteTerminalService,
    private anexoService: AnexoService) {
  }

  ngOnInit() {

    this.maximizado = false;

    this.contaParcelaBaixaForm = this._formBuilder.group(new ContaParcela);
    this.contaParcelaBaixaForm.addControl('comjuros', new FormControl(false));
    this.contaParcelaBaixaForm.addControl('liquidar', new FormControl(true));

    if (this.IContaParcelaBaixa.contaParcelaId > 0) {

      this.contaParcelaService.getContaParcela(this.IContaParcelaBaixa.contaParcelaId).subscribe(data => {

        let contaParcela = data.body;

        contaParcela.dataPagamento = new Date();

        if (contaParcela.dataVencimento.Date < contaParcela.dataPagamento.Date) {

          let valorMulta = (contaParcela.valorParcela * contaParcela.contaCorrente.percMultaAtraso) / 100;

          let dataAtual: any = new Date();
          let diasEmAtraso = (dataAtual - contaParcela.dataVencimento) / 1000 / 60 / 60 / 24;

          diasEmAtraso = Math.floor(diasEmAtraso);

          let valorMora = 0;

          if (contaParcela.contaCorrente.valorMultaAoDia > 0) {

            valorMora = contaParcela.contaCorrente.valorMultaAoDia * diasEmAtraso;
          }
          else {
            let aliquotaMultaDia = contaParcela.contaCorrente.percMultaAoMes / 30;

            let aliquotaAplicada = aliquotaMultaDia * diasEmAtraso;

            valorMora = contaParcela.valorParcela * aliquotaAplicada;
          }

          contaParcela.valorPagamento = contaParcela.valorParcela + valorMulta + valorMora;
        }
        else {
          contaParcela.valorPagamento = contaParcela.valorParcela;
        }

        this.contaParcelaBaixaForm.patchValue(contaParcela);

        this.onChanges();

        this.valorPagamentoComJuros = contaParcela.valorPagamento;
        this.contaParcelaBaixaForm.get('statusPagamento').setValue(1);
        this.contaParcelaBaixaForm.get('valorPagamento').setValue(contaParcela.valorParcela);
        this.contaParcelaBaixaForm.get('valorAteVenc').setValue(contaParcela.valorParcela);
        this.contaParcelaBaixaForm.get('caixaId').setValue(contaParcela.caixaId);

        this.buscarCaixas();

      });
    };

    this.restaurarDialog();

  }

  onChanges(): void {

    this.contaParcelaBaixaForm
      .get('comjuros')
      .valueChanges
      .subscribe(data => {
        if (data === true) {
          this.contaParcelaBaixaForm.get('valorPagamento').setValue(this.valorPagamentoComJuros.toFixed(2));
        } else {
          this.contaParcelaBaixaForm.get('valorPagamento').setValue(this.contaParcelaBaixaForm.value.valorParcela.toFixed(2));
        }
      });

    this.contaParcelaBaixaForm
      .get('tipoPagamento')
      .valueChanges
      .subscribe(data => {

        switch (data) {

          case 8:

            this.contaParcelaBaixaForm.get('contaParcelaTipo').setValue(2);

            this.contaService.getAdiantamentoPorContaId(this.contaParcelaBaixaForm.value.contaId).subscribe(data => {

              this.adiantamentos = data.body;

            }, (error: HttpErrorResponse) => {

              const dialogRef = this.dialog.open(InfoDialogComponent, {
                data: {
                  titulo: "Erro",
                  erro: error
                }
              });
            });

            this.operacaoContabilService.getOperacoesContabeis().subscribe(data => {

              this.operacoesContabeis = data.body;

            }, (error: HttpErrorResponse) => {

              const dialogRef = this.dialog.open(InfoDialogComponent, {
                data: {
                  titulo: "Erro",
                  erro: error
                }
              });
            });

            break;

          case 1:
          case 2:
            this.contaParcelaBaixaForm.get('contaParcelaTipo').setValue(this.contaParcelaBaixaForm.value.tipoConta);

            break;

          default:
            this.contaParcelaBaixaForm.get('contaParcelaTipo').setValue(this.contaParcelaBaixaForm.value.tipoConta);

            break;

        }
      });

    this.contaParcelaBaixaForm
      .get('caixaId')
      .valueChanges
      .subscribe(data => {

        this.buscarContaCorrenteTerminais(data);

      });

  }

  buscarCaixas() {

    this.contaService.getCaixas(this.contaParcelaBaixaForm.value.tipoConta).subscribe(data => {

      this.caixas = data.body;

    });
  }

  fecharDialog(statusPagamento: number) {
    this.dialogRef.close(statusPagamento);
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {

    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  salvar(): void {

    if (this.contaParcelaBaixaForm.value.tipoPagamento == 2 && this.contaParcelaBaixaForm.value.tipoConta == 0) {

      if (this.contaParcelaBaixaForm.value.numeroParcelas < 1) {

        const dialogRef = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Erro",
            mensagem: "Para pagamento no cartão de crédito é obrigatório selecionar um número de parcelas maior que zero"
          }
        });

        return;

      }

      if (this.contaParcelaBaixaForm.value.codigoAutenticacao.length == 0) {

        const dialogRef = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Erro",
            mensagem: "Para pagamento no cartão de crédito é obrigatório preencher o código de autenticação do comprovante de pagamento"
          }
        });

        return;
      }


    }

    if (this.contaParcelaBaixaForm.value.liquidar == false) {

      if (this.contaParcelaBaixaForm.value.valorQueFicaraAtivo == 0) {

        const dialogRef = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Erro",
            mensagem: "É necessário preencher o valor que ficará ativo se não for liquidar integralmente"
          }
        });

        return;
        
      }
      
    }

    this.contaParcelaService.putContaParcela(this.contaParcelaBaixaForm.value.contaParcelaId, this.contaParcelaBaixaForm.value).subscribe(data => {

      this.uploadArquivo();

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Sucesso",
          mensagem: "Baixa realizada"
        }
      });

      this.fecharDialog(this.contaParcelaBaixaForm.value.statusPagamento);

    });

  }

  uploadArquivo() {


    if (this.anexo.nomeArquivo.length > 0) {

      let formData = new FormData();
      formData.append('fileUpload', this.arquivoInput.nativeElement.files[0]);
      formData.append('descricao', this.anexo.descricao);
      formData.append('documentoIdReferenciado', this.anexo.documentoIdReferenciado.toString());
      formData.append('documentoTipo', this.anexo.documentoTipo.toString());
      formData.append('nomeArquivo', this.anexo.nomeArquivo);
      formData.append('saguiClienteId', this.anexo.saguiClienteId.toString());

      this.anexoService.postAnexo(formData).subscribe(data => {


      });
    }
  }

  fileChanged(e: any) {

    this.anexo.descricao = "Comprovante de Pagamento";
    this.anexo.nomeArquivo = e.target.files[0].name;
    this.anexo.documentoTipo = 10;
    this.anexo.documentoIdReferenciado = this.contaParcelaBaixaForm.value.contaParcelaId;
    this.anexo.saguiClienteId = this.contaParcelaBaixaForm.value.saguiClienteId;
    this.anexo.corpoArquivo = e.target.files[0];

    let fileReader = new FileReader();

    fileReader.onload = (e) => {
      this.anexo.corpoArquivo = fileReader.result;
    }

    fileReader.readAsDataURL(e.target.files[0]);

  }

  buscarContaCorrenteTerminais(caixaId: number) {

    this.contaCorrenteTerminalService.getPorcaixaId(caixaId).subscribe(data => {

      this.contaCorrenteTerminais = data.body;

      if (this.contaCorrenteTerminais.length > 0) {

        this.selectContaCorrenteTerminal = this.contaCorrenteTerminais[0];

        this.defineContaCorrenteTerminal(this.contaCorrenteTerminais[0]);
      }

    });

  }

  defineContaCorrenteTerminal(contaCorrenteTerminal) {

    this.contaParcelaBaixaForm.get('contaCorrenteTerminalId').setValue(contaCorrenteTerminal.contaCorrenteTerminalId);

    this.contaCorrenteTerminalCartoes = contaCorrenteTerminal.contaCorrenteTerminalCartoes;

    if (this.contaCorrenteTerminalCartoes != null && this.contaCorrenteTerminalCartoes.length > 0) {
      this.contaParcelaBaixaForm.get('contaCorrenteTerminalCartaoId').setValue(this.contaCorrenteTerminalCartoes[0].contaCorrenteTerminalCartaoId);
    }

  }

  atualizarValorAPagar(valorQueFicaraAtivo: number){

    let valorParcela = this.contaParcelaBaixaForm.get('valorParcela').value;

    let valorPagamentoAposCalculo = valorParcela - valorQueFicaraAtivo;

    this.contaParcelaBaixaForm.get('valorPagamento').setValue(valorPagamentoAposCalculo);

  }

}
