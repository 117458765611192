<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>Compra {{compra.compraId}}</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<div mat-dialog-actions>
    <button *ngIf="compra.compraId > 0 && compra.compraStatus == 1" mat-raised-button color="primary"
        (click)="aprovar()"><mat-icon>check</mat-icon> Aprovar
    </button>
    <button *ngIf="permiteEdicao" mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar(compraCadastroForm)"
        [disabled]="compra.compraStatus == 5"><mat-icon>save</mat-icon>
    </button>
    <button mat-raised-button (click)="imprimir()" matTooltip="Imprimir" *ngIf="compra.compraId > 0" matTooltip="Imprimir">
        <mat-icon>print</mat-icon>
    </button>
    <button mat-raised-button [matMenuTriggerFor]="opcoes" matTooltip="Opções">
        <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #opcoes>
        <button *ngIf="compra.compraId > 0" (click)="abreModalAnexo()" mat-menu-item>
            <mat-icon>attach_file</mat-icon> Anexos
        </button>
        <button mat-menu-item *ngIf="compra.compraId > 0" (click)="abrirLogs()">
            <mat-icon>history</mat-icon> Logs
        </button>
        <button *ngIf="exibirMovimentarEstoque" (click)="MovimentarEstoque(compra)" mat-menu-item>Entrada de
            Estoque</button>
        <button *ngIf="exibirFaturar" (click)="Fatura(compra)" mat-menu-item>Faturar</button>
        <button *ngIf="exibirNfeInbound" (click)="receberNfe()" mat-menu-item>Receber NF-e</button>
        <button *ngIf="compra.compraId > 0 && compra.compraStatus == 3" (click)="definirRecebido()"
            mat-menu-item>Definir como Recebido</button>
        <button *ngIf="compra.nfeId > 0 " (click)="desvincularNFe(compra.compraId)"
            mat-menu-item>Desvincular NF-e</button>
        <button *ngIf="compra.compraId > 0 && compra.faturaId > 0"
            (click)="VisualizaFatura(compra.faturaId)" mat-menu-item>
            <mat-icon>edit</mat-icon> Fatura
        </button>
        <button *ngIf="compra.compraId > 0 && compra.nfeId > 0 " (click)="VisualizaNFe()"
            mat-menu-item><mat-icon>edit</mat-icon> NF-e
        </button>
        <button *ngIf="compra.compraId > 0 && compra.compraStatus == 0" (click)="solicitarAprovacao()"
            mat-menu-item>Solicitar Aprovação
        </button>
        <button *ngIf="compra.compraId > 0 && compra.compraStatus == 2" (click)="confirmarCompra()"
            mat-menu-item>Confirmar Compra para Fornecedor
        </button>
        <button *ngIf="compra.compraId > 0 && compra.compraStatus > 0" (click)="definirComoemCriacao()"
            mat-menu-item>Desbloquear para Edições
        </button>
        <button *ngIf="compra.compraId > 0 && compra.compraStatus >= 5"
            (click)="CancelarCompra(compra.compraId)" mat-menu-item>Cancelar
        </button>
    </mat-menu>
</div>
<mat-dialog-content>
    <div class="md-dialog-content">
        <br />
        <div class="row">
            <form [formGroup]="compraCadastroForm">
                <div class="col-md-12">
                    <mat-tab-group>
                        <mat-tab label="Geral">
                            <br />
                            <div class="row">
                                <mat-form-field class="col-md-6 col-xs-12 form-group">
                                    <mat-label>Emitente</mat-label>
                                    <mat-select formControlName="empresaId">
                                        <mat-option *ngFor="let empresa of empresas" [value]="empresa.empresaId">
                                            {{empresa.nomeFantasia}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field class="col-md-6 col-xs-12 form-group">
                                    <mat-label>Fornecedor</mat-label>
                                    <input type="text" matInput [matAutocomplete]="auto"
                                        formControlName="fornecedorAutoComplete">
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <mat-option *ngFor="let parceiro of fornecedores"
                                            [value]="parceiro.nomeFantasia"
                                            (click)="DeterminafornecedorId(parceiro.fornecedorId)">
                                            CNPJ: {{parceiro.cnpj}} - {{parceiro.nomeFantasia}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field>
                                        <mat-label>Data da Compra</mat-label>
                                        <input matInput [matDatepicker]="picker1"
                                            formControlName="dataCompra" name="dataCompra">
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field>
                                        <mat-label>Data Prevista para Entrega</mat-label>
                                        <input matInput [matDatepicker]="picker2" formControlName="dataPrevista">
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <mat-form-field class="col-md-6 col-xs-12 form-group">
                                    <mat-label>Status</mat-label>
                                    <mat-select formControlName="compraStatus">
                                        <mat-option [value]="0">Em Criação</mat-option>
                                        <mat-option [value]="1">Aguardando Aprovação</mat-option>
                                        <mat-option [value]="2">Aprovado</mat-option>
                                        <mat-option [value]="3">Aguardando Mercadoria</mat-option>
                                        <mat-option [value]="4">Recebida</mat-option>
                                        <mat-option [value]="5">Cancelada</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="col-md-6 col-xs-12 form-group">
                                    <mat-label>Indicador de Pagamento</mat-label>
                                    <mat-select formControlName="indPag" name="indPag">
                                        <mat-option [value]="0">Pagamento à vista</mat-option>
                                        <mat-option [value]="1">Pagamento a prazo</mat-option>
                                        <mat-option [value]="2">Outros</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field class="form-group col-md-6">
                                    <mat-label>Solicitante</mat-label>
                                    <input matInput formControlName="solicitante">
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field class="col-md-6 col-xs-12 form-group">
                                    <mat-label>Aprovador</mat-label>
                                    <mat-select formControlName="aprovadorId">
                                        <mat-option *ngFor="let aprovador of aprovadores"
                                            [value]="aprovador.funcionarioId">
                                            {{aprovador.nomeFantasia}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint>Para cadastrar aprovadores acesse RH > Funcionários > E selecione a
                                        função 'Aprovador de Compras'</mat-hint>
                                </mat-form-field>
                                <div class="form-group col-md-6" *ngIf="compra.compraStatus > 1">
                                    <label>Usuário Aprovador: {{compra.usuarioAprovador}}</label><br>
                                    <label>Data Aprovação: {{compra.dataAprovacao | date:'dd/MM/yyyy HH:mm:ss'}}</label>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Entrega e Retirada">
                            <br />
                            <div class="row">
                                <mat-form-field class="col-md-6 col-xs-12 form-group">
                                    <mat-label>Local de Entrega (Cliente)</mat-label>
                                    <input type="text" matInput
                                        [matAutocomplete]="localEntregaMatAutoComplete" formControlName="localEntregaAutoComplete">
                                    <mat-autocomplete #localEntregaMatAutoComplete="matAutocomplete">
                                        <mat-option *ngFor="let localEntrega of locaisEntrega"
                                            [value]="localEntrega.nomeFantasia"
                                            (click)="DeterminalocalEntregaId(localEntrega.clienteId)">
                                            CNPJ: {{localEntrega.cnpj}} - {{localEntrega.nomeFantasia}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-hint>Usado para revenda para clientes</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field class="col-md-6 col-xs-12 form-group">
                                    <mat-label>Local de Retirada</mat-label>
                                    <input type="text"
                                        matInput [matAutocomplete]="localRetiradaMatAutoComplete"
                                        formControlName="localRetiradaAutoComplete">
                                    <mat-autocomplete #localRetiradaMatAutoComplete="matAutocomplete">
                                        <mat-option *ngFor="let localRetirada of locaisRetirada"
                                            [value]="localRetirada.nomeFantasia"
                                            (click)="DeterminalocalRetiradaId(localRetirada.fornecedorId)">
                                            CNPJ: {{localRetirada.cnpj}} - {{localRetirada.nomeFantasia}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-hint>Se diferente do cadastro do Fornecedor</mat-hint>
                                </mat-form-field>
                            </div>
                        </mat-tab>
                        <mat-tab label="Inf. Adicionais">
                            <br />
                            <div class="row">
                                <mat-form-field class="form-group col-md-6">
                                    <mat-label>Dados Adicionais</mat-label>
                                    <textarea matInput rows="5" formControlName="dadosAdicionais"></textarea>
                                </mat-form-field>
                            </div>
                        </mat-tab>
                        <mat-tab label="Tranportes">
                            <br />
                            <div class="row">
                                <mat-form-field class="col-md-6 col-xs-12 form-group">
                                    <mat-label>Transportadora</mat-label>
                                    <mat-select formControlName="transportadoraId">
                                        <mat-option *ngFor="let transportadora of transportadoras"
                                            [value]="transportadora.transportadoraId">
                                            {{transportadora.nomeFantasia}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field class="col-md-6 col-xs-12 form-group">
                                    <mat-label>Tipo de Entrega</mat-label>
                                    <mat-select formControlName="tipoEntrega" name="tipoEntrega">
                                        <mat-option [value]="1">Transportadora</mat-option>
                                        <mat-option [value]="2">Veículo Próprio</mat-option>
                                        <mat-option [value]="3">Cliente Retira</mat-option>
                                        <mat-option [value]="4">Correios</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field class="col-md-6 col-xs-12 form-group">
                                    <mat-label>Tipo de Frente</mat-label>
                                    <mat-select formControlName="modFrete" name="modFrete">
                                        <mat-option [value]="0">Contratação do frete por conta do remetente (CIF)
                                        </mat-option>
                                        <mat-option [value]="1">Contratação do frete por conta do destinatário (FOB)
                                        </mat-option>
                                        <mat-option [value]="2">Transporte próprio por conta do remetente
                                        </mat-option>
                                        <mat-option [value]="3">Transporte próprio por conta do destinatário
                                        </mat-option>
                                        <mat-option [value]="9">Sem ocorrência de transporte</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="form-group col-md-3">
                                    <mat-label>Quantidade de Volumes Transportados</mat-label>
                                    <input type="number" matInput formControlName="volumesTransportados">
                                </mat-form-field>
                                <mat-form-field class="form-group col-md-3">
                                    <mat-label>Espécie dos Volumes Transportados</mat-label>
                                    <input type="text" matInput formControlName="especieVolumesTransportados">
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field class="form-group col-md-6 col-xs-12">
                                    <mat-label>Peso Líquido (KG)</mat-label>
                                    <input type="number" matInput formControlName="pesoLiquidoTransportado">
                                </mat-form-field>
                                <mat-form-field class="form-group col-md-6 col-xs-12">
                                    <mat-label>Peso Bruto (KG)</mat-label>
                                    <input type="number" matInput formControlName="pesoBrutoTransportado">
                                </mat-form-field>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </form>
        </div>
        <hr>
        <div class="row" *ngIf="compra.compraId > 0">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Itens</h2>
                        <ul class="nav navbar-right panel_toolbox"></ul>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content">
                        <br />
                        <div id="itens">
                            <div class="row">
                                <div class="col-md-12">
                                    <button mat-raised-button color="accent" (click)="ModalItem(0)" *ngIf="permiteEdicao" matTooltip="Adicionar Item"><mat-icon>add</mat-icon> Item</button>
                                </div>
                            </div>
                            <hr />
                            <div id="list" class="row">
                                <div class="table-responsive col-md-12">
                                    <table class="table table-striped" cellspacing="0" cellpadding="0">
                                        <thead>
                                            <tr>
                                                <th class="actions">Ações</th>
                                                <th>Código</th>
                                                <th>Descrição</th>
                                                <th>Lote</th>
                                                <th>NCM</th>
                                                <th>CFOP</th>
                                                <th>Unid.</th>
                                                <th>Qtde.</th>
                                                <th>Valor Un.</th>
                                                <th>Valor Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let compraitem of compra.compraItens">
                                                <td class="actions">
                                                    <a *ngIf="permiteEdicao" mat-mini-fab
                                                        (click)="ModalItem(compraitem.compraItemId)" matTooltip="Editar Item">
                                                        <mat-icon>edit</mat-icon></a>
                                                    <a *ngIf="permiteEdicao" mat-mini-fab
                                                        (click)="DeleteCompraItem(compraitem.compraItemId)" color="warn" matTooltip="Deletar Item">
                                                        <mat-icon>delete</mat-icon></a>
                                                </td>
                                                <td>{{compraitem.codProd}}</td>
                                                <td>{{compraitem.descricao}}</td>
                                                <td>{{compraitem.numLote}}</td>
                                                <td>{{compraitem.ncm}}</td>
                                                <td>{{compraitem.cfop}}</td>
                                                <td>{{compraitem.unComercial}}</td>
                                                <td>{{compraitem.qtdComercial}}</td>
                                                <td>R$ {{compraitem.vlrComercial | number:'1.2-2'}}</td>
                                                <td>R$ {{compraitem.vProd | number:'1.2-2'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="display:flex; justify-content: center; background-color: #c3c3c3; display: none">
            <div class="danfe" id="printCompra">
                <div class="page" *ngFor="let compratemp of compras">
                    <div class="bloco">
                        <table id="dados-nfe" class="tabelaDanfe">
                            <thead>
                                <tr>
                                    <td class="campo">
                                        <img [src]="'data:image/JPEG;base64,'+empresa.logoByte" id="logoCliente">
                                    </td>
                                    <td rowspan="4" class="campo quadro-identificacao-emitente">
                                        <div class="conteudoPedido negrito">
                                            <h3>Pedido de Compra</h3>
                                            <h5>{{empresa.nomeFantasia}}</h5>
                                        </div>
                                        <div class="conteudoPedido">
                                            {{empresa.logradouro}}, {{empresa.numero}} , {{empresa.bairro}} <br />
                                            {{empresa.municipio?.descricao}} - {{empresa.municipio?.uf.siglaUF}}, CEP:
                                            {{empresa.cep}} <br />
                                            Tel: {{empresa.telefone}} <br />
                                            e-mail: {{empresa.emailCompra}} <br />
                                            IE: {{empresa.inscEstadual}} - CNPJ: {{empresa.cnpj}}
                                        </div>
                                    </td>
                                    <td class="campo data-emissao">
                                        <div class="conteudoPedido negrito">EMISSÃO</div>
                                        <div class="conteudoPedido">
                                            <h5>{{compratemp.dataCompra | date:'dd/MM/yyyy'}}</h5>
                                        </div>
                                        <div class="conteudoPedido negrito">PREVISÃO DE ENTREGA</div>
                                        <div class="conteudoPedido">
                                            <h5>{{compratemp.dataPrevista | date:'dd/MM/yyyy'}}</h5>
                                        </div>
                                        <div class="conteudoPedido negrito">PEDIDO</div>
                                        <div class="conteudoPedido negrito">
                                            <h5>{{compratemp.compraId}}</h5>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="bloco">
                        <div class="conteudoPedido negrito fonte-7-pontos">DESTINATÁRIO/REMETENTE</div>
                        <table>
                            <tr>
                                <td colspan="3" class="campo razao-social">
                                    <div class="conteudoPedido fonte-5-pontos">NOME / RAZÃO SOCIAL</div>
                                    <div class="conteudoPedido">
                                        {{fornecedor.razaoSocial}}
                                    </div>
                                </td>
                                <td class="campo cnpj-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">CNPJ / CPF</div>
                                    <div class="conteudoPedido">
                                        {{fornecedor.cnpj != undefined ? fornecedor.cnpj : fornecedor.cpf}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="campo endereco-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">ENDEREÇO</div>
                                    <div class="conteudoPedido">
                                        {{fornecedor.logradouro}} &nbsp;
                                        {{fornecedor.numero}}
                                    </div>
                                </td>
                                <td class="campo bairro-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">BAIRRO / DISTRITO</div>
                                    <div class="conteudoPedido">
                                        {{fornecedor.bairro}}
                                    </div>
                                </td>
                                <td class="campo cep-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">CEP</div>
                                    <div class="conteudoPedido">
                                        {{fornecedor.cep}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="campo municipio-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">MUNICÍPIO</div>
                                    <div class="conteudoPedido">
                                        {{fornecedor.municipio?.descricao}}
                                    </div>
                                </td>
                                <td class="campo fone-fax-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">TELEFONE</div>
                                    <div class="conteudoPedido">
                                        {{fornecedor.telefone}}
                                    </div>
                                </td>
                                <td class="campo uf-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">UF</div>
                                    <div class="conteudoPedido">
                                        {{fornecedor.municipio?.uf.siglaUF}}
                                    </div>
                                </td>
                                <td class="campo inscricao-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">INSCRIÇÃO ESTADUAL</div>
                                    <div class="conteudoPedido">
                                        {{fornecedor.inscEstadual}}
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="bloco">
                        <div class="conteudoPedido negrito fonte-7-pontos">TRANSPORTE</div>
                        <table>
                            <tr>
                                <td colspan="3" class="campo razao-social">
                                    <div class="conteudoPedido fonte-5-pontos">NOME / RAZÃO SOCIAL</div>
                                    <div class="conteudoPedido" *ngIf="transportadora != undefined">
                                        {{transportadora.razaoSocial}}
                                    </div>
                                </td>
                                <td class="campo endereco-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">CNPJ / CPF</div>
                                    <div class="conteudoPedido" *ngIf="transportadora != undefined">
                                        {{transportadora.cnpj}}
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <table class="sem-borda-superior">
                            <tr>
                                <td class="campo municipio-destinatario">
                                    <div class="conteudo fonte-5-pontos">MODALIDADE DO FRETE</div>
                                    <div class="conteudo">
                                        {{compratemp.modalidadeFrete}}
                                    </div>
                                </td>
                                <td class="campo quantidade-transportador">
                                    <div class="conteudo fonte-5-pontos">QUANTIDADE</div>
                                    <div class="conteudo">
                                        {{compratemp.volumesTransportados}}
                                    </div>
                                </td>
                                <td colspan="2" class="campo especie-transportador">
                                    <div class="conteudo fonte-5-pontos">ESPÉCIE</div>
                                    <div class="conteudo">
                                        {{compratemp.especieVolumesTransportados}}
                                    </div>
                                </td>
                                <td class="campo peso-bruto-transportador">
                                    <div class="conteudo fonte-5-pontos">PESO BRUTO</div>
                                    <div class="conteudo">
                                        {{compratemp.pesoBrutoTransportado}}
                                    </div>
                                </td>
                                <td class="campo peso-liquido-transportador">
                                    <div class="conteudo fonte-5-pontos">PESO LÍQUIDO</div>
                                    <div class="conteudo">
                                        {{compratemp.pesoLiquidoTransportado}}
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="bloco">
                        <div class="conteudoPedido negrito fonte-7-pontos">VALORES TOTAIS</div>
                        <table>
                            <tr>
                                <td class="campo base-calculo-icms">
                                    <div class="conteudoPedido fonte-5-pontos">TOTAL DA COMPRA</div>
                                    <div class="conteudoPedido">
                                        R$ {{compratemp.totalCompra | number:'1.2-2'}}
                                    </div>
                                </td>
                                <td class="campo valor-do-icms">
                                    <div class="conteudoPedido fonte-5-pontos">DESCONTOS</div>
                                    <div class="conteudoPedido">
                                        R$ {{compratemp.totalDescontos | number:'1.2-2'}}
                                    </div>
                                </td>
                                <td class="campo base-de-cálculo-do-icms-st">
                                    <div class="conteudoPedido fonte-5-pontos">TOTAL GERAL</div>
                                    <div class="conteudoPedido">
                                        R$ {{compratemp.totalGeral| number:'1.2-2'}}
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="bloco">
                        <div class="conteudoPedido negrito fonte-7-pontos">DADOS DOS PRODUTOS / SERVIÇOS</div>
                        <table id="dados-produtos">
                            <thead>
                                <tr>
                                    <th class="campo">
                                        <div class="conteudoPedido fonte-5-pontos">CÓDIGO</div>
                                    </th>
                                    <th class="campo">
                                        <div class="conteudoPedido fonte-5-pontos">DESCRIÇÃO DOS PRODUTOS / SERVIÇOS
                                        </div>
                                    </th>
                                    <th class="campo">
                                        <div class="conteudoPedido fonte-5-pontos">NCM</div>
                                    </th>
                                    <th class="campo">
                                        <div class="conteudoPedido fonte-5-pontos">LOTE</div>
                                    </th>
                                    <th class="campo">
                                        <div class="conteudoPedido fonte-5-pontos">UNID</div>
                                    </th>
                                    <th class="campo">
                                        <div class="conteudoPedido fonte-5-pontos">QUANT</div>
                                    </th>
                                    <th class="campo">
                                        <div class="conteudoPedido fonte-5-pontos">VLR.UNIT.</div>
                                    </th>
                                    <th class="campo">
                                        <div class="conteudoPedido fonte-5-pontos">VLR.TOTAL</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of compratemp.compraItens">
                                    <!--<td class="campo sem-borda-superior-inferior">-->
                                    <td class="campo ">
                                        <div class="conteudoPedido fonte-7-pontos">
                                            {{item.codProd}}
                                        </div>
                                    </td>
                                    <!--<td class="campo sem-borda-superior-inferior">-->
                                    <td class="campo ">
                                        <div class="conteudoPedido fonte-7-pontos">
                                            {{item.descricao}}
                                        </div>
                                    </td>
                                    <!--<td class="campo sem-borda-superior-inferior">-->
                                    <td class="campo ">
                                        <div class="conteudoPedido fonte-7-pontos">
                                            {{item.ncm}}
                                        </div>
                                    </td>
                                    <td class="campo ">
                                        <div class="conteudoPedido fonte-7-pontos">
                                            {{item.numLote}}
                                        </div>
                                    </td>
                                    <!--<td class="campo sem-borda-superior-inferior">-->
                                    <td class="campo ">
                                        <div class="conteudoPedido fonte-7-pontos">
                                            {{item.unComercial}}
                                        </div>
                                    </td>
                                    <!--<td class="campo sem-borda-superior-inferior">-->
                                    <td class="campo ">
                                        <div class="conteudoPedido fonte-7-pontos">
                                            {{item.qtdComercial}}
                                        </div>
                                    </td>
                                    <!--<td class="campo sem-borda-superior-inferior">-->
                                    <td class="campo ">
                                        <div class="conteudoPedido fonte-7-pontos">
                                            R$ {{item.vlrComercial | number:'1.2-2'}}
                                        </div>
                                    </td>
                                    <!--<td class="campo sem-borda-superior-inferior">-->
                                    <td class="campo ">
                                        <div class="conteudoPedido fonte-7-pontos">
                                            R$ {{item.vProd | number:'1.2-2'}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="bloco rodape">
                        <!--ng-if="page.isLastPage"-->
                        <table id="dados_adicionais">
                            <tr>
                                <td class="campo">
                                    <div class="conteudoPedido negrito">DADOS ADICIONAIS</div>
                                </td>
                                <td class="campo">
                                    <div class="conteudoPedido negrito">END. ENTREGA</div>
                                </td>
                                <td class="campo">
                                    <div class="conteudoPedido negrito">END. RETIRADA</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="campo dados-adicionais">
                                    <div class="conteudoPedido">
                                        {{compratemp.dadosAdicionais}}
                                    </div>
                                </td>
                                <td class="campo reservado-fisco">
                                    <div class="conteudoPedido" *ngIf="localEntrega != null">
                                        {{localEntrega.logradouro}} {{localEntrega.numero}} <br />
                                        {{localEntrega.bairro}}<br />
                                        {{localEntrega.municipio?.descricao}},
                                        {{localEntrega.municipio?.uf.siglaUF}}<br />
                                        {{localEntrega.cep}} <br />
                                    </div>
                                </td>
                                <td class="campo reservado-fisco">
                                    <div class="conteudoPedido" *ngIf="localRetirada != null">
                                        {{localRetirada.logradouro}}{{localRetirada.numero}} <br />
                                        {{localRetirada.bairro}}<br />
                                        {{localRetirada.municipio?.descricao}},
                                        {{localRetirada.municipio?.uf.siglaUF}}<br />
                                        {{localRetirada.cep}} <br />
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <br />Aprovação: _____________________________
                        <br />Solicitante: {{compratemp.solicitante}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
