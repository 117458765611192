<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>
      Orcamento {{orcamentoCadastroForm.value.orcamentoId}} - {{descricaoStatus(orcamentoCadastroForm.value.status)}}
    </h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<mat-dialog-actions>
  <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar(orcamentoCadastroForm)" [disabled]="
    orcamentoCadastroForm.value.remessaId > 0 ||
    orcamentoCadastroForm.value.nfeId > 0 ||
    orcamentoCadastroForm.value.OrcamentoStatus == 4">
    <mat-icon>save</mat-icon>
  </button>
  <button mat-raised-button (click)="imprimir()" *ngIf="orcamentoCadastroForm.value.orcamentoId > 0"
    matTooltip="imprimir">
    <mat-icon>print</mat-icon>
  </button>
  <button mat-raised-button [matMenuTriggerFor]="opcoes" matTooltip="Opções">
    <mat-icon>more_horiz</mat-icon>
  </button>
  <mat-menu #opcoes>
    <button *ngIf="
        orcamentoCadastroForm.value.orcamentoId > 0 &&
        orcamentoCadastroForm.value.status == 0 &&
        orcamentoCadastroForm.value.tipoOrcamento != 1
      " (click)="definirComoenviadoAoCliente()" mat-menu-item>
      Definir como Enviado ao Cliente
    </button>
    <button *ngIf="
        orcamentoCadastroForm.value.orcamentoId > 0 &&
        orcamentoCadastroForm.value.status == 1 &&
        orcamentoCadastroForm.value.tipoOrcamento != 1
      " (click)="geraVenda()" mat-menu-item>
      Gerar Venda
    </button>
    <button *ngIf="
        orcamentoCadastroForm.value.orcamentoId > 0 &&
        orcamentoCadastroForm.value.status == 1 &&
        orcamentoCadastroForm.value.tipoOrcamento != 1
      " (click)="definirStatus(3)" mat-menu-item>
      Substituído por outro Orçamento
    </button>
    <button *ngIf="
        orcamentoCadastroForm.value.orcamentoId > 0 &&
        orcamentoCadastroForm.value.status == 1 &&
        orcamentoCadastroForm.value.tipoOrcamento != 1
      " (click)="definirStatus(4)" mat-menu-item>
      Cliente Recusou
    </button>
    <button *ngIf="
        orcamentoCadastroForm.value.orcamentoId > 0 &&
        orcamentoCadastroForm.value.status < 3
          ? true
          : false
      " (click)="visualizaFluxoDocumentos()" mat-menu-item>
      Documentos Relacionados
    </button>
    <button *ngIf="orcamentoCadastroForm.value.orcamentoId > 0 && orcamentoCadastroForm.value.status != 99"
      (click)="definirStatus(99)" mat-menu-item>
      Cancelar
    </button>
  </mat-menu>
</mat-dialog-actions>
<br>
<mat-dialog-content>
  <br>
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <form [formGroup]="orcamentoCadastroForm">
        <mat-tab-group>
          <mat-tab label="Geral">
            <br />
            <div class="row">
              <mat-form-field class="col-md-6 col-xs-12 form-group">
                <mat-label>Emitente</mat-label>
                <mat-select formControlName="empresaId">
                  <mat-option *ngFor="let empresa of empresas" [value]="empresa.empresaId">
                    {{ empresa.nomeFantasia }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-md-6 col-xs-12 form-group">
                <mat-label>Vendedor</mat-label>
                <mat-select formControlName="vendedorId">
                  <mat-option *ngFor="let vendedor of vendedores" [value]="vendedor.funcionarioId">
                    {{vendedor.nomeFantasia}}
                  </mat-option>
                </mat-select>
                <mat-hint>Para cadastrar vendedores acesse RH > Funcionários > E
                  selecione o Tipo de Função 'Vendedor'</mat-hint>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col-md-6 col-xs-12 form-group">
                <mat-label>Parceiro</mat-label>
                <input type="text" matInput [matAutocomplete]="auto" formControlName="parceiroAutoComplete" />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let parceiro of parceiros" [value]="parceiro.nomeFantasia"
                    (click)="determinaParceiro(parceiro)">
                    CNPJ: {{ parceiro.cnpj }} - {{ parceiro.nomeFantasia }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="row" *ngIf="parceirosEncontrados == false">
              <div class="col-md-6 col-xs-12 form-group">
                <p>Nenhum cliente com o nome "{{orcamentoCadastroForm.value.parceiroAutoComplete}}" foi encontrado.</p>
                <a (click)="clienteModal(0, orcamentoCadastroForm.value.parceiroAutoComplete)" style="color: #F37431; text-decoration: underline; cursor: pointer;">
                  Criar um novo cliente com esse nome
                </a>
              </div>
            </div>

            <div class="row">
              <mat-form-field class="form-group col-md-4">
                <mat-label>Data do Orcamento</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="dataOrcamento" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="form-group col-md-4">
                <mat-label>Validade do Orçamento</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="dataValidade" />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="col-md-4 col-xs-12 form-group">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                  <mat-option [value]="0">Em Criação</mat-option>
                  <mat-option [value]="1">Enviado ao Cliente</mat-option>
                  <mat-option [value]="2">Venda Efetivada</mat-option>
                  <mat-option [value]="3">Substituído</mat-option>
                  <mat-option [value]="4">Recusado</mat-option>
                  <mat-option [value]="99">Cancelado</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <div class="col-md-12 col-xs-12 form-group">
                <label id="example-radio-group-label">Selecione o tipo de Venda:</label>
                <mat-radio-group aria-label="Selecione uma opção" class="col-md-12 col-xs-12 form-group"
                  formControlName="natureza">
                  <mat-radio-button [value]="0">Nova Venda</mat-radio-button>
                  <mat-radio-button [value]="1">Renovação</mat-radio-button>
                </mat-radio-group>
            </div>
            </div>
          </mat-tab>
          <mat-tab label="Observações">
            <br />
            <div class="row">
              <mat-form-field class="form-group col-md-6">
                <mat-label>Comunicação Interna</mat-label>
                <textarea matInput rows="5" formControlName="comunicacaoInterna"></textarea>
              </mat-form-field>
              <mat-form-field class="form-group col-md-6">
                <mat-label>Observações</mat-label>
                <textarea matInput rows="5" formControlName="observacoes"></textarea>
                <mat-hint>Somente aparecerá na impressão</mat-hint>
              </mat-form-field>
            </div>
          </mat-tab>
          <mat-tab label="Totais">
            <br />
            <div id="list" class="row">
              <div class="table-responsive col-md-12">
                <table class="table table-striped" cellspacing="0" cellpadding="0">
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Valor Total</th>
                      <th>Desconto</th>
                      <th>Valor Após Desconto</th>
                      <!--<th>Valor da Imposto Retido</th>
                          <th>Valor Após Retenções</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Produtos</td>
                      <td>{{orcamentoCadastroForm.get('valorTotalProdutos').value | currency}}</td>
                      <td>{{orcamentoCadastroForm.get('valorDescontoProdutos').value | currency}}</td>
                      <td>{{orcamentoCadastroForm.get('valorBrutoProdutos').value | currency}}</td>
                      <!-- <td>{{orcamentoCadastroForm.get('valorImpostosRetidosProdutos').value | currency}}</td>
                              <td>{{orcamentoCadastroForm.get('valorAposRetencoesProdutos').value | currency}}</td> -->
                    </tr>
                    <tr>
                      <td>Serviços</td>
                      <td>{{orcamentoCadastroForm.get('valorTotalServicos').value | currency}}</td>
                      <td>{{orcamentoCadastroForm.get('valorDescontoServicos').value | currency}}</td>
                      <td>{{orcamentoCadastroForm.get('valorBrutoServicos').value | currency}}</td>
                      <!-- <td>{{orcamentoCadastroForm.get('valorImpostosRetidosServicos').value | currency}}</td>
                              <td>{{orcamentoCadastroForm.get('valorAposRetencoesServicos').value | currency}}</td> -->
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>{{orcamentoCadastroForm.get('valorTotal').value | currency}}</td>
                      <td>{{orcamentoCadastroForm.get('valorDesconto').value | currency}}</td>
                      <td>{{orcamentoCadastroForm.get('valorBruto').value | currency}}</td>
                      <!-- <td>{{orcamentoCadastroForm.get('valorImpostosRetidos').value | currency}}</td>
                              <td>{{orcamentoCadastroForm.get('valorAposRetencoes').value | currency}}</td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Financeiro">
            <br>
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="temServico">
                <mat-card>
                  <mat-card-header>
                    <mat-card-title>Parcelas - Serviços</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div id="itens">
                      <div class="row">
                        <mat-form-field class="col-md-4 col-xs-12 form-group">
                          <mat-label>Número de Parcelas</mat-label>
                          <input type="number" matInput formControlName="numeroParcelasServico">
                        </mat-form-field>
                        <form [formGroup]="dataVencimentoParcela" >
                          <mat-form-field class="col-md-4 col-xs-12 form-group">
                            <mat-label>Vencimento 1ª Parcela</mat-label>
                            <input matInput formControlName="dataVencimentoParcela" [min]="currentDate"
                              [matDatepicker]="picker6">
                            <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                            <mat-datepicker #picker6></mat-datepicker>
                          </mat-form-field>
                        </form>
                        <div class="col-md-4">
                          <button mat-mini-fab color="accent" class="sagui-button" (click)="gerarParcelas(1)"
                            *ngIf="orcamentoCadastroForm.value.orcamentoId > 0" matTooltip="Atualizar">
                            <mat-icon>cached</mat-icon>
                          </button>
                          <button mat-mini-fab color="accent" class="sagui-button"
                            (click)="adicionarNovaParcelaVazia(1, 0)"
                            *ngIf="orcamentoCadastroForm.value.orcamentoId > 0" matTooltip="Adicionar">
                            <mat-icon>add</mat-icon>
                          </button>
                        </div>
                      </div>
                      <div id="list" class="row">
                        <div class="table-responsive col-md-12">
                          <table class="table table-striped" cellspacing="0" cellpadding="0">
                            <thead>
                              <tr>
                                <th>Data Vencimento</th>
                                <th>Valor Parcela</th>
                                <th>Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let orcamentoParcela of orcamentoParcelasServico;trackBy:trackByIndex">
                                <td>
                                  <mat-form-field appearance="legacy">
                                    <input matInput [matDatepicker]="picker3"
                                      [(ngModel)]="orcamentoParcela.dataVencimento"
                                      [ngModelOptions]="{standalone: true}" (click)="picker3.open()"
                                      (keydown)="(false)" />
                                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                    <mat-datepicker #picker3></mat-datepicker>
                                  </mat-form-field>
                                </td>
                                <td>
                                  <mat-form-field appearance="legacy" class="col-md-4 col-xs-12 form-group">
                                    <input currencyMask matInput [(ngModel)]="orcamentoParcela.valorParcela"
                                      [ngModelOptions]="{standalone: true}" />
                                  </mat-form-field>
                                </td>
                                <td class="actions">
                                  <a mat-mini-fab color="warn"
                                    (click)="DeleteOrcamentoParcela(orcamentoParcela.orcamentoParcelaId, 1)"
                                    matTooltip="Deletar">
                                    <mat-icon>delete</mat-icon></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="temProdutoAcabado">
                <mat-card>
                  <mat-card-header>
                    <mat-card-title>Parcelas - Produtos</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div id="itens">
                      <div class="row">
                        <mat-form-field class="col-md-3 col-xs-12 form-group">
                          <mat-label>Número de Parcelas</mat-label>
                          <input type="number" matInput formControlName="numeroParcelasProduto">
                        </mat-form-field>
                        <form [formGroup]="dataVencimentoParcela" >
                          <mat-form-field class="col-md-3 col-xs-12 form-group">
                            <mat-label>Vencimento 1ª Parcela</mat-label>
                            <input matInput formControlName="dataVencimentoParcela" [matDatepicker]="picker5" [min]="currentDate">
                            <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                            <mat-datepicker #picker5></mat-datepicker>
                          </mat-form-field>
                        </form>
                        <div class="col-md-6">
                          <button mat-mini-fab color="accent" class="sagui-button" (click)="gerarParcelas(0)"
                            *ngIf="orcamentoCadastroForm.value.orcamentoId > 0" matTooltip="Atualizar">
                            <mat-icon>cached</mat-icon>
                          </button>
                          <button mat-mini-fab color="accent" class="sagui-button"
                            (click)="adicionarNovaParcelaVazia(0, 0)"
                            *ngIf="orcamentoCadastroForm.value.orcamentoId > 0" matTooltip="Adicionar">
                            <mat-icon>add</mat-icon>
                          </button>
                        </div>
                      </div>
                      <div id="list" class="row">
                        <div class="table-responsive col-md-12">
                          <table class="table table-striped" cellspacing="0" cellpadding="0">
                            <thead>
                              <tr>
                                <th>Data Vencimento</th>
                                <th>Valor Parcela</th>
                                <th>Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let orcamentoParcela of orcamentoParcelasProduto;trackBy:trackByIndex">
                                <td>
                                  <mat-form-field>
                                    <input matInput [matDatepicker]="picker3"
                                      [(ngModel)]="orcamentoParcela.dataVencimento"
                                      [ngModelOptions]="{standalone: true}" (click)="picker3.open()"
                                      (keydown)="(false)" />
                                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                    <mat-datepicker #picker3></mat-datepicker>
                                  </mat-form-field>
                                </td>
                                <td>
                                  <div class="row">
                                    <mat-form-field class="col-md-12">
                                      <input currencyMask matInput [(ngModel)]="orcamentoParcela.valorParcela"
                                        [ngModelOptions]="{standalone: true}" />
                                    </mat-form-field>
                                  </div>
                                </td>
                                <td class="actions">
                                  <a mat-mini-fab color="warn"
                                    (click)="DeleteOrcamentoParcela(orcamentoParcela.orcamentoParcelaId, 0)"
                                    matTooltip="Deletar">
                                    <mat-icon>delete</mat-icon></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Fiscal" *ngIf="orcamentoCadastroForm.value.orcamentoId > 0">
            <br />
            <div class="row">
              <mat-form-field class="col-md-3 col-xs-12 form-group">
                <mat-label>Modalidade Nota Fiscal</mat-label>
                <mat-select formControlName="modNFe">
                  <mat-option [value]="0">0 - Não se Aplica</mat-option>
                  <mat-option [value]="55">55 - NF-e</mat-option>
                  <mat-option [value]="65">65 - NFC-e</mat-option>
                  <mat-option [value]="99">99 - NFS-e</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <br/>
            <div class="row">
              <div class="col-md-2 col-xs-12 form-group">
                <label>Total <br> {{orcamentoCadastroForm.value.valorTotal | currency}}</label>
              </div>
              <div class="col-md-2 col-xs-12 form-group">
                <label>Desconto <br> {{orcamentoCadastroForm.value.valorDesconto |
                  currency}}</label>
              </div>
              <div class="col-md-2 col-xs-12 form-group">
                <label>Base de Cálculo <br> {{orcamentoCadastroForm.value.valorBruto |
                  currency}}</label>
              </div>
              <div class="col-md-2 col-xs-12 form-group">
                <label>Após Retenções<br>
                  {{orcamentoCadastroForm.value.valorAposRetencoes | currency}}</label>
              </div>
              <div class="col-md-3 col-xs-12 form-group">
                <label>Valor Líquido (Bruto - Impostos) <br>
                  {{orcamentoCadastroForm.value.valorLiquido | currency}}</label>
              </div>
            </div>
            <div id="list" class="row">
              <div class="table-responsive col-md-12">
                <table class="table table-striped" cellspacing="0" cellpadding="0">
                  <thead>
                    <tr>
                      <th>Imposto</th>
                      <th>Valor</th>
                      <th>%</th>
                      <th>Retido na Fonte</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="orcamentoCadastroForm.value?.modNFe == 99">
                      <td>ISS</td>
                      <td>{{orcamento?.valorIss | currency}}</td>
                      <td>{{(orcamento?.valorIss * 100 / orcamento?.valorBruto).toFixed(2)}} %</td>
                      <td>
                        <mat-checkbox formControlName="issRetido"></mat-checkbox>
                      </td>
                    </tr>
                    <tr *ngIf="orcamentoCadastroForm.value?.modNFe != 99">
                      <td>ICMS</td>
                      <td>{{orcamento?.valorIcms | currency}}</td>
                      <td>{{(orcamento?.valorIcms * 100 / orcamento?.valorBruto).toFixed(2)}} %</td>
                      <td></td>
                    </tr>
                    <tr *ngIf="orcamentoCadastroForm.value?.modNFe != 99">
                      <td>ICMS ST</td>
                      <td>{{orcamento?.valorIcmsSt | currency}}</td>
                      <td>{{(orcamento?.valorIcmsSt * 100 / orcamento?.valorBruto).toFixed(2)}} %</td>
                      <td></td>
                    </tr>
                    <tr *ngIf="orcamentoCadastroForm.value?.modNFe != 99">
                      <td>IPI</td>
                      <td>{{orcamento?.valorIpi | currency}}</td>
                      <td>{{(orcamento?.valorIpi * 100 / orcamento?.valorBruto).toFixed(2)}} %</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>PIS</td>
                      <td>{{orcamento?.valorPis | currency}}</td>
                      <td>{{(orcamento?.valorPis * 100 / orcamento?.valorBruto).toFixed(2)}} %</td>
                      <td>
                        <mat-checkbox formControlName="pisRetido"></mat-checkbox>
                      </td>
                    </tr>
                    <tr *ngIf="orcamentoCadastroForm.value?.modNFe != 99">
                      <td>PIS ST</td>
                      <td>{{orcamento?.valorPisSt | currency}}</td>
                      <td>{{(orcamento?.valorPisSt * 100 / orcamento?.valorBruto).toFixed(2)}} %</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>cofins</td>
                      <td>{{orcamento?.valorCofins | currency}}</td>
                      <td>{{(orcamento?.valorCofins * 100 / orcamento?.valorBruto).toFixed(2)}} %</td>
                      <td>
                        <mat-checkbox formControlName="cofinsRetido"></mat-checkbox>
                      </td>
                    </tr>
                    <tr *ngIf="orcamentoCadastroForm.value?.modNFe != 99">
                      <td>cofins ST</td>
                      <td>{{orcamento?.valorCofinsSt | currency}}</td>
                      <td>{{(orcamento?.valorCofinsSt * 100 / orcamento?.valorBruto).toFixed(2)}} %</td>
                      <td></td>
                    </tr>
                    <tr *ngIf="orcamentoCadastroForm.value?.modNFe == 99">
                      <td>CSLL</td>
                      <td>{{orcamento?.valorCsll | currency}}</td>
                      <td>{{(orcamento?.valorCsll * 100 / orcamento?.valorBruto).toFixed(2)}} %</td>
                      <td>
                        <mat-checkbox formControlName="csllRetido"></mat-checkbox>
                      </td>
                    </tr>
                    <tr *ngIf="orcamentoCadastroForm.value?.modNFe == 99">
                      <td>IRPJ</td>
                      <td>{{orcamento?.valorIr | currency}}</td>
                      <td>{{(orcamento?.valorIr * 100 / orcamento?.valorBruto).toFixed(2)}} %</td>
                      <td>
                        <mat-checkbox formControlName="irRetido"></mat-checkbox>
                      </td>
                    </tr>
                    <tr *ngIf="orcamentoCadastroForm.value?.modNFe == 99">
                      <td>INSS</td>
                      <td>{{orcamento?.valorInss | currency}}</td>
                      <td>{{(orcamento?.valorInss * 100 / orcamento?.valorBruto).toFixed(2)}} %</td>
                      <td>
                        <mat-checkbox formControlName="inssRetido"></mat-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </form>
    </div>
    <div class="row sagui-form-card" *ngIf="orcamento?.orcamentoId > 0">
      <div class="col-md-6 col-sm-6 col-xs-6">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Descontos em Reais: {{this.orcamentoCadastroForm.value.somaTotalDescontosEmReais | currency}}
              <button
              mat-mini-fab  color="accent" class="sagui-button sagui-mini-button report-card-icon"
              (click)="openDialogDescontoAdicional()">
              <mat-icon>add</mat-icon>
            </button></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <span class="sagui-card-content">
            Desconto itens R${{this.orcamentoCadastroForm.value.descontosItensEmReais}} +
            Desconto Adicionais R${{this.orcamentoCadastroForm.value.descontosAdicionaisEmReais}}
            </span>
          </mat-card-content>
        </mat-card>
        <mat-card>
          <mat-card-header>
            <mat-card-title>Total s/ descontos: {{this.orcamentoCadastroForm.value.valorTotal |
              currency}}</mat-card-title>
          </mat-card-header>
        </mat-card>


      </div>
      <div class="col-md-6 col-sm-6 col-xs-6">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Desconto em Porcentagem: {{this.orcamentoCadastroForm.value.somaTotalDescontosEmPorcentagem}}%
              <button mat-mini-fab  color="accent" class="sagui-button sagui-mini-button report-card-icon"
              (click)="openDialogDescontoAdicional()">
              <mat-icon>add</mat-icon>
            </button></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <span class="sagui-card-content">
            Desconto itens R${{this.orcamentoCadastroForm.value.descontosItensEmReais}} +
            Desconto Adicionais R${{this.orcamentoCadastroForm.value.descontosAdicionaisEmReais}}
            </span>
          </mat-card-content>
        </mat-card>
        <mat-card>
          <mat-card-header>
            <mat-card-title>Total com Descontos: {{this.orcamentoCadastroForm.value.valorBruto |
              currency}}</mat-card-title>
          </mat-card-header>
        </mat-card>


      </div>

    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Itens</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div id="itens">
              <div class="row">
                <div class="col-md-12">
                  <button mat-raised-button color="accent" matTooltip="Adicionar item" (click)="ModalItem(0)"
                    *ngIf="orcamentoCadastroForm.value.orcamentoId > 0" [disabled]="
                      orcamentoCadastroForm.value.orcamentoId == 0 ||
                      orcamentoCadastroForm.value.OrcamentoStatus == 99">
                    <mat-icon>add</mat-icon> Item
                  </button>
                </div>
              </div>
              <div id="list" class="row">
                <div class="table-responsive col-md-12">
                  <table class="table table-striped" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th class="actions">Ações</th>
                        <th>Tipo</th>
                        <th>Código</th>
                        <th>Descrição</th>
                        <th>NCM</th>
                        <th>Unid.</th>
                        <th>Valor Un.</th>
                        <th>Qtde.</th>
                        <th>Total</th>
                        <th>Desconto</th>
                        <th>Com Desconto</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let orcamentoitem of orcamentoItens">
                        <td class="actions">
                          <a mat-mini-fab (click)="ModalItem(orcamentoitem.orcamentoItemId)" matTooltip="Editar">
                            <mat-icon>edit</mat-icon></a>
                          <a mat-mini-fab (click)="
                              DeleteOrcamentoItem(orcamentoitem.orcamentoItemId)" color="warn" matTooltip="Deletar">
                            <mat-icon>delete</mat-icon></a>
                        </td>
                        <td>{{retornarTipoItem(orcamentoitem.produtoTipo)}}</td>
                        <td>{{orcamentoitem.produto.codProd}}</td>
                        <td>{{orcamentoitem.produto.descricao}}</td>
                        <td>{{orcamentoitem.produto.ncm}}</td>
                        <td>{{orcamentoitem.unidade}}</td>
                        <td>{{orcamentoitem.valorUnitario | currency}}</td>
                        <td>{{orcamentoitem.quantidade}}</td>
                        <td>{{orcamentoitem.valorTotal | currency}}</td>
                        <td>{{orcamentoitem.valorDesconto | currency}}</td>
                        <td>{{orcamentoitem.valorBruto | currency}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</mat-dialog-content>
