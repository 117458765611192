'use strict';
import { Component, HostListener, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DocumentoTemplateService } from '../shared/documento-template.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';

import { DocumentoTemplate } from '../shared/documento-template.model';
import { environment } from 'src/environments/environment';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';

export interface DialogData {
  documentoTemplateId: number;
}

@Component({
  selector: 'documento-template-cadastro',
  templateUrl: 'documento-template-cadastro.component.html',
})

export class DocumentoTemplateCadastroComponent {

  DocumentoTemplate: any = {};
  documentoTemplates: any = [];
  documentoTemplateCadastroForm: FormGroup;
  maximizado: boolean;
  pessoaFisica: boolean = false;
  isento: boolean = false;
  ufs: any = [];
  paises: any = [];
  municipios: any = [];
  cepPesquisado: any = {};
  saguiApp: string = '';
  lastSelection: Range | null = null;

  showTags: boolean = false;
  tags = [
  ];


  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DocumentoTemplateCadastroComponent>,
    private _formBuilder: FormBuilder,
    private htmlPrinterService: HtmlPrinterService,
    @Inject(MAT_DIALOG_DATA) public IDocumentoTemplate: DialogData,
    private documentoTemplateService: DocumentoTemplateService,
    private location: Location) {
  }

  ngOnInit() {

    this.saguiApp = environment.SaguiApp;

    this.maximizado = false;

    this.documentoTemplateCadastroForm = this._formBuilder.group(new DocumentoTemplate());

    if (this.IDocumentoTemplate.documentoTemplateId > 0) {

      this.buscarDocumentoTemplate(this.IDocumentoTemplate.documentoTemplateId);
    }

    this.onChanges();

    this.restaurarDialog();
  }

  onChanges(): void {
  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  buscarDocumentoTemplate(documentoTemplateId: number) {
    this.documentoTemplateService.getDocumentoTemplate(documentoTemplateId).subscribe(data => {
      this.InsereDadosDocumentoTemplate(data.body);
    });
  }

  InsereDadosDocumentoTemplate(data: any) {

    this.documentoTemplateCadastroForm.patchValue(data);
    this.atualizarTags();
  }

  salvar(documentoTemplateForm): void {

    if (documentoTemplateForm.value.documentoTemplateId == 0) {

      this.documentoTemplateService.postDocumentoTemplate(documentoTemplateForm.value).subscribe(data => {

        this._snackBar.open("Registro Salvo com Sucesso");

        this.buscarDocumentoTemplate(data.body.documentoTemplateId);

      });

    }
    else {
      this.documentoTemplateService.putDocumentoTemplate(documentoTemplateForm.value, documentoTemplateForm.value.documentoTemplateId).subscribe(data => {

        this._snackBar.open("Registro Salvo com Sucesso");

        this.buscarDocumentoTemplate(documentoTemplateForm.value.documentoTemplateId);

      });
    }

  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here..',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    sanitize: true,
    toolbarPosition: 'top',
  };

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent) {
    const editorElement = document.getElementById('editor1');
    const clickedInsideEditor = editorElement && editorElement.contains(event.target as Node);

    if (clickedInsideEditor) {
      this.updateSelection();
    } else {
      this.lastSelection = null;
    }
  }



  updateSelection() {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      this.lastSelection = selection.getRangeAt(0);
    }
  }
  openTagSelection() {
    const editorElement = document.getElementById('editor1').getElementsByClassName('angular-editor-textarea')[0] as HTMLElement;
    if (editorElement) {
      const selection = window.getSelection();
      if (selection) {
        const range = selection.getRangeAt(0);
        this.lastSelection = range.cloneRange();
        editorElement.focus();
        this.showTags = true;
      }
    }
  }

  closeTagSelection() {
    this.showTags = false;
  }

  insertTag(placeholder: string) {
    if (!this.lastSelection) return false;

    const editorElement = document.getElementById('editor1');
    if (editorElement && this.isSelectionInsideEditor(this.lastSelection, editorElement)) {
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(this.lastSelection);
        document.execCommand('insertText', false, placeholder);

        this.showTags = false;
      }
    }
    return false;
  }


  isSelectionInsideEditor(range: Range, editorElement: HTMLElement): boolean {
    let currentNode = range.commonAncestorContainer;
    while (currentNode) {
      if (currentNode === editorElement) {
        return true;
      }
      currentNode = currentNode.parentNode;
    }
    return false;
  }

  openImageInsertDialog() {
    const imageUrl = prompt('Por favor, insira o URL da imagem:');
    if (imageUrl) {
      this.insertImageAtCursor(imageUrl);
    }
  }

insertImageAtCursor(imgUrl: string) {
  const imgTag = `<img src="${imgUrl}" alt="Imagem">`;
  if (!this.lastSelection) {
    return;
  }
  const editorElement = document.getElementById('editor1');
  if (editorElement && this.isSelectionInsideEditor(this.lastSelection, editorElement)) {
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(this.lastSelection);
      document.execCommand('insertHTML', false, imgTag);
      this.showTags = false;
    }
  }
}



  atualizarTags(): void {
    this.showTags = false;
    let documentoTipo = this.documentoTemplateCadastroForm.value.documentoTipo;
    switch (documentoTipo) {
      case 4:  //Orçamento
        this.tags = [
          { name: 'Nome Fantasia da Empresa', placeholder: '{{Empresa.NomeFantasia}}' },
          { name: 'Logradouro da Empresa', placeholder: '{{Empresa.Logradouro}}' },
          { name: 'Número da Empresa', placeholder: '{{Empresa.Numero}}' },
          { name: 'Bairro da Empresa', placeholder: '{{Empresa.Bairro}}' },
          { name: 'Município da Empresa', placeholder: '{{Empresa.Municipio}}' },
          { name: 'UF da Empresa', placeholder: '{{Empresa.Uf}}' },
          { name: 'CEP da Empresa', placeholder: '{{Empresa.Cep}}' },
          { name: 'Telefone da Empresa', placeholder: '{{Empresa.Telefone}}' },
          { name: 'Email de Venda da Empresa', placeholder: '{{Empresa.EmailVenda}}' },
          { name: 'Inscrição Estadual da Empresa', placeholder: '{{Empresa.InscricaoEstadual}}' },
          { name: 'CNPJ da Empresa', placeholder: '{{Empresa.Cnpj}}' },
          { name: 'Logo da Empresa', placeholder: '{{Empresa.Logo}}' },
          { name: 'Razão Social do Cliente', placeholder: '{{Parceiro.RazaoSocial}}' },
          { name: 'CNPJ do Cliente', placeholder: '{{Parceiro.Cnpj}}' },
          { name: 'CPF do Cliente', placeholder: '{{Parceiro.Cpf}}' },
          { name: 'Logradouro do Cliente', placeholder: '{{Parceiro.Logradouro}}' },
          { name: 'Número do Cliente', placeholder: '{{Parceiro.Numero}}' },
          { name: 'Bairro do Cliente', placeholder: '{{Parceiro.Bairro}}' },
          { name: 'CEP do Cliente', placeholder: '{{Parceiro.Cep}}' },
          { name: 'Município do Cliente', placeholder: '{{Parceiro.Municipio}}' },
          { name: 'UF do Cliente', placeholder: '{{Parceiro.Uf}}' },
          { name: 'Telefone do Cliente', placeholder: '{{Parceiro.Telefone}}' },
          { name: 'Inscrição Estadual do Cliente', placeholder: '{{Parceiro.InscricaoEstadual}}' },
          { name: 'Email do Cliente', placeholder: '{{Parceiro.Email}}' },
          { name: 'Valor Total do Orçamento', placeholder: '{{orcamentotemp.ValorTotal}}' },
          { name: 'Valor de Desconto', placeholder: '{{orcamentotemp.ValorDesconto}}' },
          { name: 'Valor Bruto', placeholder: '{{orcamentotemp.ValorBruto}}' },
          { name: 'Nome do Vendedor', placeholder: '{{NomeVendedor}}' },
          { name: 'Cargo do Vendedor', placeholder: '{{CargoVendedor}}' },
          { name: 'Email do Vendedor', placeholder: '{{EmailVendedor}}' },
          { name: 'Dados Adicionais', placeholder: '{{orcamentotemp.DadosAdicionais}}' },
          { name: 'Data do Orçamento', placeholder: '{{orcamentotemp.DataOrcamento}}' },
          { name: 'Data de Validade', placeholder: '{{orcamentotemp.DataValidade}}' },
          { name: 'ID do Orçamento', placeholder: '{{orcamentotemp.OrcamentoId}}' },
          { name: 'Total de Descontos em Reais', placeholder: '{{orcamentotemp.SomaTotalDescontosEmReais}}' },
          { name: 'Total de Descontos em Porcentagem', placeholder: '{{orcamentotemp.SomaTotalDescontosEmPorcentagem}}' },
          { name: 'Texto Livre', placeholder: '{{orcamentotemp.TextoLivre}}' },
          { name: 'Observações', placeholder: '{{orcamentotemp.Observacoes}}' },
          { name: 'Itens Serv sem desc', placeholder: '{{orcamentoItensServicoSemDesconto}}'},
          { name: 'Itens Serv com desc', placeholder: '{{orcamentoItensServicoComDesconto}}'},
        ];
        break;
      case 8: //Fatura de Venda
        this.tags = [
          { name: 'Nome Fantasia da Empresa', placeholder: '{{Empresa.nomeFantasia}}' },
          { name: 'Logradouro da Empresa', placeholder: '{{Empresa.logradouro}}' },
          { name: 'Número da Empresa', placeholder: '{{Empresa.numero}}' },
          { name: 'Bairro da Empresa', placeholder: '{{Empresa.bairro}}' },
          { name: 'Município da Empresa', placeholder: '{{Empresa.Municipio.descricao}}' },
          { name: 'UF da Empresa', placeholder: '{{Empresa.uf.siglaUF}}' },
          { name: 'CEP da Empresa', placeholder: '{{Empresa.cep}}' },
          { name: 'Telefone da Empresa', placeholder: '{{Empresa.telefone}}' },
          { name: 'Email Fatura da Empresa', placeholder: '{{Empresa.EmailFatura}}' },
          { name: 'Inscrição Estadual da Empresa', placeholder: '{{Empresa.inscEstadual}}' },
          { name: 'CNPJ da Empresa', placeholder: '{{Empresa.cnpj}}' },
          { name: 'Logo da Empresa', placeholder: '{{Empresa.Logo}}' },
          { name: 'Razão Social do Parceiro', placeholder: '{{Parceiro.razaoSocial}}' },
          { name: 'CNPJ do Parceiro', placeholder: '{{Parceiro.cnpj}}' },
          { name: 'CPF do Parceiro', placeholder: '{{Parceiro.cpf}}' },
          { name: 'Logradouro do Parceiro', placeholder: '{{Parceiro.logradouro}}' },
          { name: 'Número do Parceiro', placeholder: '{{Parceiro.numero}}' },
          { name: 'Bairro do Parceiro', placeholder: '{{Parceiro.bairro}}' },
          { name: 'CEP do Parceiro', placeholder: '{{Parceiro.cep}}' },
          { name: 'Município do Parceiro', placeholder: '{{Parceiro.municipio.descricao}}' },
          { name: 'UF do Parceiro', placeholder: '{{Parceiro.municipio.Uf.siglaUF}}' },
          { name: 'Telefone do Parceiro', placeholder: '{{Parceiro.telefone}}' },
          { name: 'Inscrição Estadual do Parceiro', placeholder: '{{Parceiro.inscEstadual}}' },
          { name: 'Valor Bruto da Fatura', placeholder: '{{faturatemp.ValorBruto}}' },
          { name: 'Valor Total da Fatura', placeholder: '{{faturatemp.ValorTotal}}' },
          { name: 'Descrição da Fatura', placeholder: '{{faturatemp.Descricao}}' },
          { name: 'Data de Criação da Fatura', placeholder: '{{faturatemp.DataCriacao}}' },
          { name: 'ID da Fatura', placeholder: '{{faturatemp.FaturaId}}' },
          { name: 'Itens da Fatura', placeholder: '{{faturaItens}}' },
          { name: 'Parcelas da Fatura', placeholder: '{{faturaParcelas}}' }
];
        break;
      case 16: // Recibo do Contas a Receber
      case 17: // Recibo do Contas a Pagar
        this.tags = [
          { name: 'CNPJ da Empresa', placeholder: '{{Empresa.Cnpj}}' },
          { name: 'CPF da Empresa', placeholder: '{{Empresa.Cpf}}' },
          { name: 'Razão Social da Empresa', placeholder: '{{Empresa.RazaoSocial}}' },
          { name: 'Nome Fantasia da Empresa', placeholder: '{{Empresa.NomeFantasia}}' },
          { name: 'Logradouro da Empresa', placeholder: '{{Empresa.Logradouro}}' },
          { name: 'Número da Empresa', placeholder: '{{Empresa.Numero}}' },
          { name: 'Bairro da Empresa', placeholder: '{{Empresa.Bairro}}' },
          { name: 'Município da Empresa', placeholder: '{{Empresa.Municipio}}' },
          { name: 'UF da Empresa', placeholder: '{{Empresa.Uf}}' },
          { name: 'CEP da Empresa', placeholder: '{{Empresa.Cep}}' },
          { name: 'Telefone da Empresa', placeholder: '{{Empresa.Telefone}}' },
          { name: 'Email Financeiro da Empresa', placeholder: '{{Empresa.EmailFinanceiro}}' },
          { name: 'Inscrição Estadual da Empresa', placeholder: '{{Empresa.inscEstadual}}' },
          { name: 'Logo da Empresa', placeholder: '{{Empresa.LogoByte}}' },
          { name: 'Razão Social do Parceiro', placeholder: '{{Parceiro.RazaoSocial}}' },
          { name: 'Nome Fantasia do Parceiro', placeholder: '{{Parceiro.NomeFantasia}}' },
          { name: 'CNPJ do Parceiro', placeholder: '{{Parceiro.Cnpj}}' },
          { name: 'CPF do Parceiro', placeholder: '{{Parceiro.Cpf}}' },
          { name: 'Logradouro do Parceiro', placeholder: '{{Parceiro.Logradouro}}' },
          { name: 'Número do Parceiro', placeholder: '{{Parceiro.Numero}}' },
          { name: 'Bairro do Parceiro', placeholder: '{{Parceiro.Bairro}}' },
          { name: 'Cep do Parceiro', placeholder: '{{Parceiro.Cep}}' },
          { name: 'Município do Parceiro', placeholder: '{{Parceiro.Municipio}}' },
          { name: 'UF do Parceiro', placeholder: '{{Parceiro.Uf}}' },
          { name: 'Telefone do Parceiro', placeholder: '{{Parceiro.Telefone}}' },
          { name: 'Inscrição Estadual do Parceiro', placeholder: '{{Parceiro.inscEstadual}}' },
          { name: 'Descrição da Conta Parcela', placeholder: '{{contaParcela.Descricao}}' },
          { name: 'Valor do Pagamento', placeholder: '{{contaParcela.ValorPagamento}}' },
          { name: 'ID da Conta Parcela', placeholder: '{{contaParcela.ContaParcelaId}}' },
          { name: 'Data de Pagamento', placeholder: '{{contaParcela.DataPagamento}}' },
        ];
        break;
      case 18: //Histórico Escolar
        this.tags = [
          { name: 'Logo da Empresa em Base64', placeholder: '{{empresa.LogoByte}}' },
          { name: 'Razão Social da Empresa', placeholder: '{{empresa.razaoSocial}}' },
          { name: 'Nome Fantasia da Empresa', placeholder: '{{empresa.nomeFantasia}}' },
          { name: 'CNPJ da Empresa', placeholder: '{{empresa.Cnpj}}' },
          { name: 'Logradouro da Empresa', placeholder: '{{empresa.logradouro}}' },
          { name: 'Número da Empresa', placeholder: '{{empresa.numero}}' },
          { name: 'CEP da Empresa', placeholder: '{{empresa.cep}}' },
          { name: 'Bairro da Empresa', placeholder: '{{empresa.bairro}}' },
          { name: 'Descrição do Município da Empresa', placeholder: '{{empresa.Municipio.descricao}}' },
          { name: 'Descrição da UF da Empresa', placeholder: '{{empresa.uf.descUF}}' },
          { name: 'Telefone da Empresa', placeholder: '{{empresa.telefone}}' },
          { name: 'Email da Empresa', placeholder: '{{empresa.email}}' },
          { name: 'Nome do Aluno', placeholder: '{{aluno.nome}}' },
          { name: 'RG do Aluno', placeholder: '{{aluno.rg}}' },
          { name: 'Registro do Aluno', placeholder: '{{aluno.RegistroAluno}}' },
          { name: 'Descrição do Município do Aluno', placeholder: '{{aluno.Municipio.descricao}}' },
          { name: 'Sigla da UF do Aluno', placeholder: '{{aluno.Municipio.Uf.siglaUF}}' },
          { name: 'Descrição do País do Aluno', placeholder: '{{aluno.Pais.descPais}}' },
          { name: 'Data de Nascimento do Aluno', placeholder: '{{aluno.dataNasc}}' },
          { name: 'Nota de Português', placeholder: '{{portugues[i].Nota}}' },
          { name: 'Nota de Inglês', placeholder: '{{ingles[i].Nota}}' },
          { name: 'Nota de Matemática', placeholder: '{{matematica[i].Nota}}' },
          { name: 'Nota de Ciências', placeholder: '{{ciencias[i].Nota}}' },
          { name: 'Nota de História', placeholder: '{{historia[i].Nota}}' },
          { name: 'Nota de Geografia', placeholder: '{{geografia[i].Nota}}' },
          { name: 'Nota de Arte', placeholder: '{{arte[i].Nota}}' },
          { name: 'Nota de Educação Física', placeholder: '{{educacaoFisica[i].Nota}}' },
          { name: 'Ano Concluinte', placeholder: '{{anoConcluinte}}' },
          { name: 'Dia Atual', placeholder: '{{diaAtual}}' },
          { name: 'Mês Atual', placeholder: '{{mesAtual}}' },
          { name: 'Ano Atual', placeholder: '{{anoAtual}}' },
        ];
        break;
      case 21: //Ficha de Funcionário
        this.tags = [
          { name: 'Foto do Funcionário', placeholder: '{{Funcionario.Foto}}' },
          { name: 'Cor', placeholder: '{{Funcionario.CaracteristicaCor}}' },
          { name: 'Cabelo', placeholder: '{{Funcionario.CaracteristicaCabelo}}' },
          { name: 'Barba', placeholder: '{{Funcionario.CaracteristicaBarba}}' },
          { name: 'Bigode', placeholder: '{{Funcionario.CaracteristicaBigode}}' },
          { name: 'Olhos', placeholder: '{{Funcionario.CaracteristicaOlhos}}' },
          { name: 'Altura em Centímetros', placeholder: '{{Funcionario.CaracteristicaAlturaemCentimetros}}' },
          { name: 'Peso em Quilos', placeholder: '{{Funcionario.CaracteristicaPesoEmQuilos}}' },
          { name: 'Sinais', placeholder: '{{Funcionario.CaracteristicaSinais}}' },
          { name: 'Nome Fantasia da Empresa', placeholder: '{{Empresa.NomeFantasia}}' },
          { name: 'Logradouro da Empresa', placeholder: '{{Empresa.Logradouro}}' },
          { name: 'Número da Empresa', placeholder: '{{Empresa.Numero}}' },
          { name: 'Bairro da Empresa', placeholder: '{{Empresa.Bairro}}' },
          { name: 'Município da Empresa', placeholder: '{{Empresa.Municipio}}' },
          { name: 'UF da Empresa', placeholder: '{{Empresa.Uf}}' },
          { name: 'CEP da Empresa', placeholder: '{{Empresa.Cep}}' },
          { name: 'CNPJ da Empresa', placeholder: '{{Empresa.Cnpj}}' },
          { name: 'Nome do Funcionário', placeholder: '{{Funcionario.RazaoSocial}}' },
          { name: 'Código do Funcionário', placeholder: '{{Funcionario.Codigo}}' },
          { name: 'Registro do Funcionário', placeholder: '{{Funcionario.Registro}}' },
          { name: 'Matrícula do Funcionário', placeholder: '{{Funcionario.Matricula}}' },
          { name: 'Filiação Mãe', placeholder: '{{Funcionario.FiliacaoMae}}' },
          { name: 'Filiação Pai', placeholder: '{{Funcionario.FiliacaoPai}}' },
          { name: 'Nacionalidade', placeholder: '{{Funcionario.Nacionalidade}}' },
          { name: 'Grau de Instrução', placeholder: '{{Funcionario.GrauInstrucao}}' },
          { name: 'Dependentes para Salário Família', placeholder: '{{Funcionario.DependentesSalarioFamilia}}' },
          { name: 'Dependentes para IR', placeholder: '{{Funcionario.DependentesIrrf}}' },
        ];
        break;
      case 22: //Ficha de Veículo
        this.tags = [
          { name: 'Descrição do Veículo', placeholder: '{{Veiculo.Descricao}}' },
          { name: 'Placa do Veículo', placeholder: '{{Veiculo.Placa}}' },
          { name: 'Marca do Veículo', placeholder: '{{Veiculo.Marca}}' },
          { name: 'Modelo do Veículo', placeholder: '{{Veiculo.Modelo}}' },
          { name: 'Tipo de Carroceria do Veículo', placeholder: '{{Veiculo.VeiculoTipoCarroceria}}' },
          { name: 'Tipo de Rodado do Veículo', placeholder: '{{Veiculo.VeiculoTipoRodado}}' },
          { name: 'Combustível do Veículo', placeholder: '{{Veiculo.Combustivel}}' },
          { name: 'Categoria do Veículo', placeholder: '{{Veiculo.Categoria}}' },
          { name: 'Data de Aquisição do Veículo', placeholder: '{{Veiculo.DataAquisicao}}' },
          { name: 'Ano de Fabricação do Veículo', placeholder: '{{Veiculo.AnoFabricacao}}' },
          { name: 'Ano do Modelo do Veículo', placeholder: '{{Veiculo.AnoModelo}}' },
          { name: 'Capacidade de Passageiros', placeholder: '{{Veiculo.CapacidadePassageiros}}' },
          { name: 'Cor do Veículo', placeholder: '{{Veiculo.Cor}}' },
          { name: 'Chassi do Veículo', placeholder: '{{Veiculo.Chassi}}' },
          { name: 'Tara do Veículo', placeholder: '{{Veiculo.Tara}}' },
          { name: 'Capacidade em KG', placeholder: '{{Veiculo.CapKG}}' },
          { name: 'Capacidade em M3', placeholder: '{{Veiculo.CapM3}}' },
          { name: 'Potência do Veículo', placeholder: '{{Veiculo.Potencia}}' },
          { name: 'Cilindradas do Veículo', placeholder: '{{Veiculo.Cilindradas}}' },
          { name: 'Número do CRLV', placeholder: '{{Veiculo.CrlvNumero}}' },
          { name: 'Município do Veículo', placeholder: '{{Veiculo.Municipio}}' },
          { name: 'Número ANTT', placeholder: '{{Veiculo.AnttNumero}}' },
          { name: 'Data de Cadastro na ANTT', placeholder: '{{Veiculo.AnttDataCadastro}}' },
          { name: 'Data de Validade na ANTT', placeholder: '{{Veiculo.AnttDataValidade}}' },
          { name: 'Nome do Proprietário', placeholder: '{{Veiculo.ProprietarioNome}}' },
          { name: 'CPF/CNPJ do Proprietário', placeholder: '{{Veiculo.ProprietarioCpfCnpj}}' },
          { name: 'Fotos do Veículo', placeholder: '{{Fotos}}' },
        ];
        break;

      default:
        this.tags = [
        ];
    }
  }

}
