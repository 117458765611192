'use strict';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from "rxjs/operators";
import { ContratoService } from '../shared/contrato.service';
import { ContratoItemComponent } from '../contrato-item/contrato-item.component';
import {ContratoFaturaComponent} from '../contrato-fatura/contrato-fatura.component'
import { DocumentoCadastroComponent } from '../../documento/documento-cadastro/documento-cadastro.component';
import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';
import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';
import { Contrato } from '../shared/contrato.model';
import { ContratoSignatarioComponent } from '../contrato-signatario/contrato-signatario.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdicionaRelacaoContratoDialogComponent } from '../adiciona-relacao-contrato-dialog/adiciona-relacao-contrato-dialog.component';
import { DocumentosRelacionadosComponent } from 'src/app/documento/documentos-relacionados/documentos-relacionados.component';
import { MatTabGroup } from '@angular/material/tabs';
import { CalculaParcelasComponent } from 'src/app/Components/parcelas/calcula-parcelas/calcula-parcelas.component';
import { DescontoDialogComponent, DialogData as DescontoDialogData } from '../../core/dialogs/desconto-dialog/desconto-dialog/desconto-dialog.component'
import { of } from 'rxjs';
import { EditorHtmlTemplateComponent } from 'src/app/Components/editor-html-template/editor-html-template/editor-html-template.component';
import { UtilsService } from 'src/app/core/utils/utils.service';


export interface DialogData {
  contratoId: number;
}

@Component({
  selector: 'contrato-cadastro',
  templateUrl: 'contrato-cadastro.component.html',
})

export class ContratoCadastroComponent {
  @ViewChild(EditorHtmlTemplateComponent) editorHtmlTemplate: EditorHtmlTemplateComponent;


  contrato: any = {};
  empresa: any = {};
  cliente: any = {};
  empresas: any = [];
  metodosAssinaturaPermitidos: any = [];
  contratoTemplates: any = [];
  contratos: any = [];
  parceiros: any[];
  contratoCadastroForm: FormGroup;
  maximizado: boolean;
  lastSelection: Range | null = null;
  dataVencimentoParcela: FormGroup;
  valorFoiAlterado: boolean = false;
  previousValueForm:any;



  filteredProdutos: any[];
  isDataLoaded = false;
  contratoTags:any;

  showTags: boolean = false;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  

  private errorElement: HTMLElement | null = null;
  mensagemErro: any;
   // Propriedade para controlar a aba ativa
   activeTabIndex: number = 0;



  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ContratoCadastroComponent>,
    private _formBuilder: FormBuilder,
    private htmlPrinterService: HtmlPrinterService,
    @Inject(MAT_DIALOG_DATA) public IContrato: DialogData,
    private utilsService: UtilsService,
    private contratoService: ContratoService, private renderer: Renderer2, private el: ElementRef,private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {

    
    this.maximizado = false;

    

    this.contratoCadastroForm = this._formBuilder.group(new Contrato());

    this.contratoCadastroForm.addControl('parceiroAutoComplete', new FormControl(''));
    this.contratoCadastroForm.get('parceiroAutoComplete').setValidators([Validators.required]);
    this.contratoCadastroForm.get('status').setValidators([Validators.required]);
    this.contratoCadastroForm.get('contratoEmpresas').setValidators([Validators.required, this.utilsService.minArrayLengthValidator(1)]);
    this.contratoCadastroForm.get('contratoItens').setValidators([Validators.required, this.utilsService.minArrayLengthValidator(1)]);
    this.contratoCadastroForm.get('contratoParcelas').setValidators([Validators.required, this.utilsService.minArrayLengthValidator(1)]);
    this.contratoCadastroForm.get('parceiroId').setValidators([Validators.required]);
    this.contratoCadastroForm.get('contratoMetodoAssinatura').setValidators([Validators.required]);
    this.contratoCadastroForm.get('dataInicio').setValidators([Validators.required]);
    this.contratoCadastroForm.get('dataFim').setValidators([Validators.required]);

    this.contratoCadastroForm.get('status').updateValueAndValidity();
    this.contratoCadastroForm.get('contratoEmpresas').updateValueAndValidity();
    this.contratoCadastroForm.get('contratoItens').updateValueAndValidity();
    this.contratoCadastroForm.get('contratoParcelas').updateValueAndValidity();
    this.contratoCadastroForm.get('parceiroId').updateValueAndValidity();
    this.contratoCadastroForm.get('contratoMetodoAssinatura').updateValueAndValidity();
    this.contratoCadastroForm.get('dataInicio').updateValueAndValidity();
    this.contratoCadastroForm.get('dataFim').updateValueAndValidity();
    
    if(this.contratoCadastroForm.get('contratoMetodoAssinatura').value !== 2){

      this.contratoCadastroForm.get('contratoSignatarios').setValidators([Validators.required, this.utilsService.minArrayLengthValidator(1)]);
      this.contratoCadastroForm.get('contratoHtmlTexto').setValidators([Validators.required]);

      this.contratoCadastroForm.get('contratoSignatarios').updateValueAndValidity();
      this.contratoCadastroForm.get('contratoHtmlTexto').updateValueAndValidity();

    }
  
    if (this.IContrato.contratoId > 0) {

      this.buscarContrato(this.IContrato.contratoId);

    } else {
      this.contrato = {};
      this.contrato.contratoId = 0;
      this.isDataLoaded = true;
    }


    this.buscarEmpresas();

    this.getContratoTemplates();

    this.getMetodosAssinatura();

    this.onChanges();

    this.dialogRef.disableClose = true;

     // Subscrição ao evento backdropClick
    this.dialogRef.backdropClick().subscribe(() => {
      this.verificarAlteracoesContrato();
    });

    //antes de fechar essa dialog, verificar se houve alterações no contratoCadastroForm e se houve, perguntar se deseja salvar. Se a resposta for sim, mas o contrato possuir erros, mantes a dialog aberta

    
  }

  

  goToTab(index: number) {
    if (this.tabGroup) {
      this.tabGroup.selectedIndex = index;
    }}

  ngAfterViewInit(){


    this.errorElement = this.el.nativeElement.querySelector('.mat-toolbar');

    
  }

     // Método para definir a aba ativa
     setActiveTabIndex(event: any) {
      this.activeTabIndex = event.index;
    if (this.activeTabIndex === 4) {
      // Força a detecção de mudanças
      this.cdr.detectChanges();
     
    }
    }


  determinaValidadores(event:any){


    if(event.value !== 2){

      this.contratoCadastroForm.get('contratoSignatarios').setValidators([Validators.required, this.utilsService.minArrayLengthValidator(1)]);
      this.contratoCadastroForm.get('contratoHtmlTexto').setValidators([Validators.required]);

      this.contratoCadastroForm.get('contratoSignatarios').updateValueAndValidity();
      this.contratoCadastroForm.get('contratoHtmlTexto').updateValueAndValidity();

    }else{

      this.contratoCadastroForm.get('contratoSignatarios').clearValidators();
      this.contratoCadastroForm.get('contratoHtmlTexto').clearValidators();

      this.contratoCadastroForm.get('contratoSignatarios').updateValueAndValidity();
      this.contratoCadastroForm.get('contratoHtmlTexto').updateValueAndValidity();
    }



  }

  onItensChange(itens: any[]) {
    //inserir o array de itens no contratoCadastroForm em contratoItens
    this.contratoCadastroForm.get('contratoItens').setValue(itens);
  }

  onValorTotalBrutoChange(valorTotalBruto: number) {
    this.contratoCadastroForm.get('valorTotalBruto').setValue(valorTotalBruto);
  }

  onDescontosAplicadosAosItensChange(descontosAplicadosAosItens: number) {
    this.contratoCadastroForm.get('descontosAplicadosAosItens').setValue(descontosAplicadosAosItens);
    this.contratoCadastroForm.get('somaDeTodosOsDescontos').setValue(descontosAplicadosAosItens + this.contratoCadastroForm.get('descontosAplicadosGlobalmente').value);
  }

  onValorTotalLiquidoChange(valorTotalLiquidoItens: number) {
   const valorTotalLiquido = valorTotalLiquidoItens - this.contratoCadastroForm.get('descontosAplicadosGlobalmente').value;
   this.contratoCadastroForm.get('valorTotalLiquido').setValue(valorTotalLiquido);
  }

  onParcelasFinaisChange(parcelas: any[]) {

    const parcelasFinais = parcelas?.map((parcela: any) => {
          return {
            contratoParcelaId: parcela.parcelaId,
        dataVencimento: parcela.dataVencimentoParcela,
        valorLiquido: parcela.valorLiquido,
        tipoPagamento: parcela.tipoPagamento


    }});

    this.contratoCadastroForm.get('contratoParcelas').setValue(parcelasFinais);
  }

  onHtmlFinalChange(html: any) {
    this.contratoCadastroForm.get('contratoHtmlTexto').setValue(html);
    this.contrato.contratoHtmlTexto = html;
  }

  ondocumentosRelacionadosChange(documentosRelacionados: any[]) {

    this.contratoCadastroForm.get('documentos').setValue(documentosRelacionados);
  }

  onSignatariosChange(signatarios: any[]) {
      
      this.contratoCadastroForm.get('contratoSignatarios').setValue(signatarios);
    }

  onChanges(): void {

    this.contratoCadastroForm
      .get('parceiroAutoComplete').valueChanges
      .pipe(
        startWith(''),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => {
          if (value?.length >= 3) {
            // Retorna o resultado da API se o comprimento for 3 ou mais caracteres
            return this.contratoService.getClientePorNome({id: value, empresasIds: (this.contratoCadastroForm.get('contratoEmpresas').value).map((empresa: any) => empresa.empresaId)}).pipe(
              map(response => response.body || [])
            );
          } else {
            // Retorna um array vazio se o comprimento for menor que 3 caracteres
            return of([]);
          }
        })
      ).subscribe(data => {
        // Atualiza a lista de clientes filtrados
        this.parceiros = data;

      });




  }

  displayFn(cliente): string {
    return cliente ? cliente.nomeFantasia : '';
  }

  

  buscarEmpresas() {

    this.contratoService.getEmpresa().subscribe(data => {

      this.empresas = data.body;

    });
  }

  listaEmpresa() {

    if (this.contratoCadastroForm.value && this.contratoCadastroForm.value.contratoEmpresas) {
      let selectedEmpresas = this.empresas.filter(empresa =>
        this.contratoCadastroForm.value.contratoEmpresas.some(pe => pe.empresaId === empresa.empresaId)
      );

      if(selectedEmpresas.length == 0){
        selectedEmpresas.push(this.empresas.find(empresa => empresa.empresaId === this.contratoCadastroForm.value.empresaId));
      }
      this.contratoCadastroForm.get('contratoEmpresas').setValue(selectedEmpresas);

    }
  }


  getMetodosAssinatura() {

    this.contratoService.getMetodosAssinatura().subscribe(data => {

      this.metodosAssinaturaPermitidos = data.body;

    });
  }

  getContratoTemplates() {

    this.contratoService.getContratoTemplates().subscribe(data => {

      this.contratoTemplates = data.body;


      if(this.contratoCadastroForm.value.contratoId == 0){
        let contratoTemplate = this.contratoTemplates.find(x => x.contratoTemplatePadrao == true);
        this.contratoCadastroForm.get('contratoHtmlTexto').setValue(contratoTemplate.html);
        this.contratoCadastroForm.get('contratoTemplateId').setValue(contratoTemplate.contratoTemplateId);
      }

    });
  }

  verificarAlteracoesContrato() {
  if (!this.isFormEqual(this.previousValueForm, this.contratoCadastroForm)) {
      const dialogRef2 = this.dialog.open(ConfirmDialogComponent, {
        data: {
          titulo: "🔎 Confirmação de Alterações",
          mensagem: "Você realizou alterações, porém não salvou. Deseja salvar as alterações realizadas nesse contrato?"
      
        }
      }); 

      dialogRef2.afterClosed().subscribe(result => {
        if (result) {
          if(!this.erro()){
          this.salvar(this.contratoCadastroForm);
          //fechar dialog
          this.dialogRef.close();
          
          }else{
            this._snackBar.open('😖 Operação não permitida! \n\n Esse contrato possui erros que precisam ser corrigidos antes de salvar.', '✖', {
              duration: 15000, panelClass: ['mat-toolbar', 'warn']
            });
          
          }
        }else{
          this.dialogRef.close();
        }

      })
    }else{
      this.dialogRef.close();

    }
  }

  

  isFormEqual(initialState: any, currentState: any): boolean {

    for (const key of Object.keys(initialState.value)) {

      if(!(JSON.stringify(initialState.value[key]) === JSON.stringify(currentState.value[key]))){
        return false;
      }

    }

    return true;
  }

  fecharDialog() {
    this.verificarAlteracoesContrato();
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;



    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('70%', '100vh');
    }
    this.maximizado = false;
  }
  adicionaRelacaoContratoDialog() {
    const dialogRef = this.dialog.open(AdicionaRelacaoContratoDialogComponent, {
      data: {
        contratoId: this.contrato.contratoId,
      },
      height: '60%',
      width: '60%'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  buscarContrato(contratoId: number) {

    this.contratoService.getContrato(contratoId).subscribe(data => {

      this.InsereDadosContrato(data.body);
    })

  }

    openDescontoDialog(): void {
      const dialogRef = this.dialog.open(DescontoDialogComponent, {
        width: '400px',
        data: { valorTotal: this.contratoCadastroForm.get('valorTotalBruto').value - this.contratoCadastroForm.get('descontosAplicadosAosItens').value,
              descontosAtual: this.contratoCadastroForm.get('descontosAplicadosGlobalmente').value 
  
        }
      });

    dialogRef.afterClosed().subscribe(result => {

        if (result) {
          const { descontoEmPorcentagem, descontoEmReais, valorTotalNovo } = result;
          this.contratoCadastroForm.get('descontosAplicadosGlobalmente').setValue(descontoEmReais);
          this.contratoCadastroForm.get('valorTotalLiquido').setValue(valorTotalNovo);
          this.contratoCadastroForm.get('somaDeTodosOsDescontos').setValue(descontoEmReais + this.contratoCadastroForm.get('descontosAplicadosAosItens').value);
        }
   
    });
  }


  InsereDadosContrato(data: any) {

    this.contratoCadastroForm.patchValue(data);
      
    //chamar this.contratoService.getClientePorNome passando data.nomeParceiro e uma lista de todas os objetos empresas contidas em contratoCadastroForm(contratoEmpresas). Ele vai retornar uma lista de clientes que tem o nome igual ao valor passado, inserir no this.contratoCadastroForm.get('parceiroAutoComplete') o cliente que retornar da lista que tenha o mesmo clienteId de data.parceiroId
    this.contratoService.getClientePorNome({id: data.nomeParceiro, empresasIds: (this.contratoCadastroForm.get('contratoEmpresas').value).map((empresa: any) => empresa.empresaId)}).subscribe(data => {
      this.contratoCadastroForm.get('parceiroAutoComplete').setValue(data.body[0]);
    });
    

    this.contrato = data;
    this.empresa = data.empresa;

    this.contratos = [];
    this.contratos.push(this.contrato);

    if (this.contrato.status == 99) {
      this.contratoCadastroForm.disable();
    }

    

    setTimeout(() => {
      this.listaEmpresa();
      this.previousValueForm = this._formBuilder.group(new Contrato());

      this.previousValueForm.patchValue(this.contratoCadastroForm.value);
      this.isDataLoaded = true;
    }, 500);

  

  


  }

  DeterminaParceiro(parceiro: any) {
    this.contratoCadastroForm.get('parceiroId').setValue(parceiro.clienteId);
    this.contratoCadastroForm.get('nomeParceiro').setValue(parceiro.razaoSocial);
  }



  CancelarContrato(cancelarId) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja realmente Cancelar essa contrato?"
      }
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.contratoService.deleteContrato(cancelarId).subscribe(data => {

          this._snackBar.open("Registro Cancelado com Sucesso");

          this.buscarContrato(cancelarId);

        });
      }
    });
  };

  salvar(contratoForm): void {

    var _contrato = this.contrato;

    _contrato.cliente = null;
    _contrato.empresa = null;

    let empresasIds = contratoForm.value?.contratoEmpresas?.map(empresa => empresa.empresaId);

    let contratoEmpresas = empresasIds?.map(empresaId => {
      return {
        ContratoEmpresaId: this.contrato.contratoEmpresas?.find(empresa => empresa.empresaId == empresaId)?.contratoEmpresaId,
        ContratoId: this.contrato.contratoId,
        EmpresaId: empresaId,
        SaguiClienteId: contratoForm.value?.saguiClienteId
      }
    });

    contratoForm.contratoEmpresas = contratoEmpresas;

    if (_contrato.contratoId == 0) {

      this.contratoService.postContrato(contratoForm.value).subscribe(data => {

        this._snackBar.open("Registro Salvo com Sucesso");

        this.buscarContrato(data.body.contratoId);


      });

    }
    else {
      this.contratoService.putContrato(contratoForm.value, contratoForm.value.contratoId).subscribe(data => {


          this._snackBar.open('📝 Contrato Salvo com Sucesso! ', '✖', {
            duration: 7000, panelClass: ['mat-toolbar', 'succes']
          });
              this.InsereDadosContrato(data.body);
        });

    
    }

  };

  imprimir(): void {

    this.contratoService.putContrato(this.contratoCadastroForm.value, this.contratoCadastroForm.value.contratoId).subscribe(data => {

      this._snackBar.open("Registro Salvo com Sucesso");
      this._snackBar.open('📝 Contrato Salvo com Sucesso! ', '✖', {
        duration: 105000, panelClass: ['mat-toolbar', 'succes']
      });
        
      //Vai para a aba 4 - Templates para poder carregar o componente do tiny
      this.goToTab(4);

      setTimeout(() => {
        
        //trazer o editor do tinyMCE que está dentro do editorHtmlTemplate para pode chamar a função mcePrint
        this.editorHtmlTemplate.triggerPrint();
      }, 500);
      
        
      
    });

  
  }

  GerarVenda(Contrato: any): void {

    if (this.contrato.contratoItens.length > 0) {

      this.contratoService.postGeraVenda(Contrato.value).subscribe(data => {

        const dialogRef = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Sucesso",
            mensagem: "Venda Gerada"
          }
        });

        this.buscarContrato(this.contrato.contratoId);

      });
    }
    else {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Informação",
          mensagem: "Para gerar um pedido de venda, você deve adicionar itens ao seu contrato"
        }
      });

    }
  }


  AssinarOnline(Contrato: any): void {

    this.contratoService.postAssinarOnline(Contrato.value).subscribe(data => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Sucesso",
          mensagem: "Contrato enviado para Assinatura Online"
        }
      });

      this.buscarContrato(this.contrato.contratoId);

    });
  }


  PreencherTabela(cliente: any) {

    if (cliente != undefined) {
      this.contrato.clienteId = cliente.clienteId;
    }
  }

  ModalItem(contratoItemId): void {

    const dialogRef = this.dialog.open(ContratoItemComponent, {
      data: {
        contratoItemId: contratoItemId,
        contrato: this.contratoCadastroForm.value,
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscarContrato(this.contrato.contratoId);
    });

  }
  newSelection(event: any) {
    const selectedValue = event.value;
    let contratoTemplate = this.contratoTemplates.find(x => x.contratoTemplateId == selectedValue);
    this.contratoCadastroForm.get('contratoHtmlTexto').setValue(contratoTemplate.html);
    this.contratoCadastroForm.get('contratoTemplateId').setValue(selectedValue);
    this.contrato.contratoHtmlTexto = contratoTemplate.html;
  }

  

 faturarContrato(contratoId){

  if(this.contrato.contratoParcelas){

  const dialogRef = this.dialog.open(ContratoFaturaComponent, {
    data: {
      contrato: this.contrato,
      //contrato: this.contratoCadastroForm.value,
    },
    maxHeight: '100vh',
    maxWidth: '100vw'
  });

  

  dialogRef.afterClosed().subscribe(result => {
   if(result){
    this.dialogRef.close();
   }
  });

  }else{

   
    this._snackBar.open('😖 Operação não permitida! \n\n Esse contrato não possui parcelas para serem faturadas! \nAdicione a forma de pagamento antes de faturar', '✖', {
      duration: 15000, panelClass: ['mat-toolbar', 'warn']
    });

    this.mudarParaAba(3);

  }
 }

 trackByIndex(index: number, obj: any): any {
  return index;
}

  criaContratoAPartirDeUmContratoExistente(contratoId) {
    let mensagem = "Deseja realmente gerar um novo contrato a partir deste? ";

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: mensagem
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        this.contratoService.postCriaContratoAPartirDeUmExistente(contratoId).subscribe(data => {

          let contratoId: number = Number(data);
          this.visualizarContrato(contratoId);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  }

  visualizarContrato(contratoId: number) {
    const dialogRef = this.dialog.open(ContratoCadastroComponent, {
      data: {
        contratoId: contratoId,
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

  }

 

  TextoAcao(tipoAcao: number): string {

    return this.contratoService.textoAcao(tipoAcao);

  }

  Download(contratoForm) {

    this.contratoService.PostContratoDownload(contratoForm.value).subscribe(data => {

      window.location.assign(data.body.url);

    });
  }

  mudarParaAba(index: number) {
    this.tabGroup.selectedIndex = index;
  }

 
  getProdutoPorNomeEEmpresasService(params: any) {
    return this.contratoService.getProdutoPorNomeEEmpresas(params);
  }

  getTemplateTagsService(params: any) {
    return this.contratoService.PostContratoTemplatesTags(params);
  }

  postTrocarTagsHtmlService(params: any) {
    return this.contratoService.postTrocarTagsContratoHtml(params);
  }

  // Função que extrai os objetos 'empresa' de cada 'contratoEmpresas'
  geContratotEmpresas(): any[] {
    const contratoEmpresasArray = this.contratoCadastroForm.get('contratoEmpresas').value;
    return contratoEmpresasArray.map(ce => ce.empresa);
  }

  postDocumentoByIdEEmpresas(params: any) {
    return this.contratoService.postDocumentoByIdEEmpresas(params);
  }

    erro():boolean{
    //se o valorTotalLiquido do contratoCadastroForm for diferente da soma do valor de todas as parcelas de contratoCadastroForm.contratoParcelas retorna true
      if(this.isDataLoaded && parseFloat((this.contratoCadastroForm.get('valorTotalLiquido').value).toFixed(2)) != parseFloat((this.contratoCadastroForm.get('contratoParcelas').value?.reduce((a, b) => a + b.valorLiquido, 0 )).toFixed(2))){
        this.renderer.addClass(this.errorElement, 'error');
        this.mensagemErro= 'ERRO! O valor das parcelas geradas não está batendo com o valor total do contrato!';
        return true;
      }else{

        if(!this.isDataLoaded){ 
          return false;
        }
      }

    if(this.errorElement){
    this.renderer.removeClass(this.errorElement, 'error');
    }
    return false;

    }

  

}
