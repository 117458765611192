export const environment = {

  production: false,
  webApiSaguiControlUrl: 'https://saguicontrolapi-homologacao.azurewebsites.net',
  webApiSaguiEduUrl: 'https://saguieduapi-homologacao.azurewebsites.net',
  webApiSaguiLogUrl: 'https://saguilogapi-homologacao.azurewebsites.net',
  webApiSaguikeyvault: 'https://geradordechavesecertficados.azurewebsites.net/',
  apiD4Sign: 'demo.d4sign.com.br',
  SaguiApp: 'SaguiControl',
  saguiControlClientId: '6816e5fc-b4be-42b6-8ff5-a486fc6e4bf6',
  saguiControlTenantUrl: 'https://saguitech.com.br/saguicontrolapi',
  emailSuporte: 'suporte@saguitech.com.br',
  scope: 'https://saguitech.com.br/saguicontrolapi/user_impersonation',
};
