<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>Histórico</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar(clienteHistoricoForm)"
        *ngIf="clienteHistoricoForm.value.clienteHistoricoId == 0">
        <mat-icon>save</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="md-dialog-content">
        <br />
        <form [formGroup]="clienteHistoricoForm">
            <div class="card">
                <br />
                <div class="row" *ngIf="clienteHistoricoForm.value.clienteHistoricoId > 0">
                    <mat-form-field class="form-group col-md-6">
                        <mat-label>Data</mat-label>
                        <input matInput [matDatepicker]="picker1" formControlName="dataCriacao"
                            name="DataCriacao" (disabled)="true">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="col-md-6 col-xs-12 form-group">
                        <mat-label>Usuário</mat-label>
                        <input matInput formControlName="usuario" (disabled)="true">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-12 col-xs-12 form-group">
                        <mat-label>Título</mat-label>
                        <input type="text" matInput formControlName="titulo">
                    </mat-form-field>
                </div>
                <div class="row">
                    <angular-editor formControlName="descricao"></angular-editor>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>