import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class DocumentoService {

    getVisualizaDocumento(parametros: any): Observable<HttpResponse<any>> {
        
        const params = {
            id: parametros.idOrigem,
            tipoOrigem: parametros.tipoOrigem,
        }

        return this.http.get(environment.webApiSaguiControlUrl + '/api/documento/visualizapororigemtipoorigem', { observe: 'response', params })
    }


    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
