<ng-container>
  <div class="md-dialog-content">
      <div class="row">
          <div class="table-responsive col-md-12">
              <table class="table table-striped" cellspacing="0" cellpadding="0">
                  <thead>
                      <tr>
                          <th>Ações</th>
                          <th>Tipo Destino</th>
                          <th>Id Documento Destino</th>
                          <th>Observações</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let documentoRelacionado of documentos">
                          <td class="actions">
                              <a mat-mini-fab (click)="navegar(documentoRelacionado)" matTooltip="Editar">
                                  <mat-icon>edit</mat-icon>
                              </a>
                          </td>
                          <td>{{PreencheTipoDocumento(documentoRelacionado.tipoDocumentoDestino)}}</td>
                          <td>{{documentoRelacionado.idDocumentoDestino}}</td>
                          <td>{{documentoRelacionado.descricao}}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>
</ng-container>

