
export class OrcamentoItem {
    orcamentoItemId: number = 0;
    orcamentoId: number = 0;
    produtoId: number = 0;
    descricao: string = '';
    codProd: string = '';
    
    unidade: string = '';
    informacoesAdicionais: string = '';
    produtoTipo: number = 0;
    ncm: string = '';
    saguiClienteId: number = 0;
    grupoContaId: number = 0;
    
    valorUnitario: number = 0;
    quantidade: number = 0;

    valorTotal: number = 0;
    valorDesconto: number = 0;
    valorBruto: number = 0;

    valorIcms: number = 0;
    valorIpi: number = 0;

    valorFrete: number = 0;
    valorSeguro: number = 0;

    valorOutrasDespesas: number = 0;
}