'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { NfeService } from '../shared/nfe.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';
import { NfeEvento } from '../shared/nfe-evento.model';
import { NfeEventoService } from '../shared/nfe-evento.service';
import { Nfe } from '../shared/nfe.model';

export interface DialogData {
  nfeId: number;
  nfeEventoId: number;
}

@Component({
  selector: 'nfe-carta-correcao',
  templateUrl: 'nfe-carta-correcao.component.html',
})

export class NfeCartaCorrecaoComponent {

  nfeCartaCorrecaoForm: FormGroup;
  maximizado: boolean;
  nfeEventos: any = [];
  nfe: Nfe = new Nfe();
  empresa: any = {};
  cliente: any = {};
  permiteSalvar: boolean = true;
  permiteImprimir: boolean = false;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NfeCartaCorrecaoComponent>,
    private _formBuilder: FormBuilder,
    private htmlPrinterService: HtmlPrinterService,
    @Inject(MAT_DIALOG_DATA) public INfeEvento: DialogData,
    private nfeEventoService: NfeEventoService) {
  }

  ngOnInit() {

    this.maximizado = false;

    this.nfeCartaCorrecaoForm = this._formBuilder.group(new NfeEvento());

    this.nfeCartaCorrecaoForm.get('nfeId').setValue(this.INfeEvento.nfeId);

    if (this.INfeEvento.nfeEventoId > 0) {

      this.buscaNfeEvento(this.INfeEvento.nfeEventoId);

    }

    this.onChanges();

    this.restaurarDialog();
  }


  buscaNfeEvento(nfeEventoId: number) {

    this.nfeEventoService.getNfeEvento(nfeEventoId).subscribe(data => {

      this.permiteSalvar = false;

      if (data.body.evento.tpEvento == '110110') {
        this.permiteImprimir = true;
      }

      this.nfeCartaCorrecaoForm.patchValue(data.body.evento);

      this.nfe = data.body.nfe;
      this.empresa = data.body.empresa;
      this.cliente = data.body.cliente;

      this.nfeEventos = [];
      this.nfeEventos.push(data.body.evento);

    }, (error: HttpErrorResponse) => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          erro: error
        }
      });
    })
  }

  onChanges(): void {

  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  salvar(): void {

    this.nfeEventoService.postCartaCorreca(this.nfeCartaCorrecaoForm.value).subscribe(data => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Sucesso",
          mensagem: "Carta de Correção associada com a NF-e"
        }
      });

      this.fecharDialog();

    });
  }

  imprimirEvento() {

    this.htmlPrinterService.printDiv('printVenda', 'Carta de Correção');

  }

}
