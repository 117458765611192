'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, switchMap } from "rxjs/operators";

import { CompraService } from '../shared/compra.service';
import { CompraItemComponent } from '../compra-item/compra-item.component';

import { FaturaCadastroComponent } from '../../fatura/fatura-cadastro/fatura-cadastro.component';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';

import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';

import { Compra } from '../shared/compra.model';
import { Transportadora } from '../../transportadora/shared/transportadora.model';
import { NfeCadastroComponent } from '../../fiscal/nfe-cadastro/nfe-cadastro.component';
import { NfeInboundImportarComponent } from '../../fiscal/nfe-inbound-importar/nfe-inbound-importar.component';
import { AnexoComponent } from 'src/app/anexo/anexo/anexo.component';
import { LogComponent } from 'src/app/log/log/log.component';

export interface DialogData {
    compraId: number;
}

@Component({
    selector: 'compra-cadastro',
    templateUrl: 'compra-cadastro.component.html',
})

export class CompraCadastroComponent {

    compra: any = {};
    empresa: any = {};
    transportadora: Transportadora;
    fornecedor: any = {};
    localEntrega: any = {};
    localRetirada: any = {};
    empresas: any = [];
    compras: any = [];
    aprovadores: any = [];
    transportadoras: Transportadora[];
    fornecedores: any[];
    locaisRetirada: any[];
    locaisEntrega: any[];
    compraCadastroForm: FormGroup;
    maximizado: boolean;
    exibirMovimentarEstoque: boolean = false;
    exibirFaturar: boolean = false;
    permiteEdicao: boolean = false;
    exibirNfeInbound: boolean = false;

    constructor(
        public dialog: MatDialog,
    private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<CompraCadastroComponent>,
        private _formBuilder: FormBuilder,
        private htmlPrinterService: HtmlPrinterService,
        @Inject(MAT_DIALOG_DATA) public ICompra: DialogData,
        private compraService: CompraService) {
    }

    ngOnInit() {

        this.maximizado = false;

        this.compraCadastroForm = this._formBuilder.group(new Compra());

        this.compraCadastroForm.get('compraStatus').disable();

        if (this.ICompra.compraId > 0) {

            this.buscarCompra(this.ICompra.compraId);

        } else {
            this.compra = {};
            this.compra.dataCompra = new Date();
            this.compra.dataPrevista = new Date();
            this.compraCadastroForm.get('compraStatus').setValue(0);
            this.permiteEdicao = true;
        }

        this.buscarEmpresas();

        this.buscarFuncionarios();

        this.buscarTransportadoras();

        this.compraCadastroForm.addControl('fornecedorAutoComplete', new FormControl(''));

        this.compraCadastroForm.addControl('localRetiradaAutoComplete', new FormControl(''));

        this.compraCadastroForm.addControl('localEntregaAutoComplete', new FormControl(''));

        this.compraCadastroForm
            .get('fornecedorAutoComplete')
            .valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => value?.length >= 3 ?
                    this.compraService.getFornecedorPorNome(value) : []
                )
            )
            .subscribe(data =>
                this.fornecedores = data.body
            );

        this.compraCadastroForm
            .get('localRetiradaAutoComplete')
            .valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => value?.length >= 3 ?
                    this.compraService.getFornecedorPorNome(value) : []
                )
            )
            .subscribe(data =>
                this.locaisRetirada = data.body
            );

        this.compraCadastroForm
            .get('localEntregaAutoComplete')
            .valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => value?.length >= 3 ?
                    this.compraService.getClientePorNome(value) : []
                )
            )
            .subscribe(data =>
                this.locaisEntrega = data.body
            );

        this.restaurarDialog();
    }

    buscarEmpresas() {

        this.compraService.getEmpresa().subscribe(data => {

            this.empresas = data.body;

        });
    }

    buscarFuncionarios() {

        this.compraService.getAprovadores().subscribe(data => {

            this.aprovadores = data.body;

        });
    }

    desvincularNFe(compraId: number): void {
        this.compraService.getDesvincularNFe(compraId).subscribe(data => {

            this.buscarCompra(compraId);

        });
    }

    buscarTransportadoras() {

        this.compraService.getTransportadoras().subscribe(data => {

            this.transportadoras = data.body;

        });
    }

    fecharDialog() {
        this.dialogRef.close();
    }

    maximizarDialog() {
        this.dialogRef.updateSize('100%', '100%');
        this.maximizado = true;
    }

    restaurarDialog() {
        let innerWidth = window.innerWidth;

        if (innerWidth < 1024) {
            this.dialogRef.updateSize('90%', '100vh');
        }
        else {
            this.dialogRef.updateSize('60%', '100vh');
        }
        this.maximizado = false;
    }


    buscarCompra(compraId: number) {

        this.compraService.getCompra(compraId).subscribe(data => {

            this.InsereDadosCompra(data.body);

        })
    }


    InsereDadosCompra(data: any) {

        this.compraCadastroForm.patchValue(data);

        this.compra = data;
        this.compra.indPag = data.indPag.toString();
        this.compra.compraStatus = data.compraStatus.toString();
        this.fornecedor = this.compra.fornecedor;
        this.empresa = data.empresa;
        this.transportadora = data.transportadora;

        if (this.compra.compraId > 0 && this.compra.compraStatus < 5 && this.compra.compraStatus > 2) {
            this.exibirMovimentarEstoque = true;
        }

        if (this.compra.compraId > 0 && this.compra.compraStatus < 5 && this.compra.faturaId == 0 && this.compra.compraStatus > 2) {

            this.exibirFaturar = true;
        }

        if (this.compra.compraId > 0 && this.compra.compraStatus < 5 && this.compra.compraStatus > 2 && this.compra.nfeId == 0) {

            this.exibirNfeInbound = true;
        }

        if (this.compra.compraStatus > 0) {
            this.compraCadastroForm.disable();
            this.permiteEdicao = false;
        } else {
            this.permiteEdicao = true;
            this.compraCadastroForm.enable();
        }


        this.compraCadastroForm.get('fornecedorAutoComplete').setValue(this.fornecedor.nomeFantasia);

        const promiselocalEntrega = new Promise<void>((resolve, reject) => {

            if (this.compra.idlocalEntrega) {
                this.compraService.getClientePorId(this.compra.idlocalEntrega).subscribe(data => {

                    this.localEntrega = data.body;
                    this.compraCadastroForm.get('localEntregaAutoComplete').setValue(this.localEntrega.nomeFantasia);

                    resolve();

                }, (error: HttpErrorResponse) => {

                    resolve();
                    const dialogRef = this.dialog.open(InfoDialogComponent, {
                        data: {
                            titulo: "Erro",
                            erro: error
                        }
                    });
                });
            }
            else {
                resolve();
            }
        });

        const promiselocalRetirada = new Promise<void>((resolve, reject) => {

            if (this.compra.idlocalRetirada) {
                this.compraService.getFornecedorPorId(this.compra.idlocalRetirada).subscribe(data => {

                    this.localRetirada = data.body;
                    this.compraCadastroForm.get('localRetiradaAutoComplete').setValue(this.localRetirada.nomeFantasia);
                    resolve();

                }, (error: HttpErrorResponse) => {
                    resolve();
                    const dialogRef = this.dialog.open(InfoDialogComponent, {
                        data: {
                            titulo: "Erro",
                            erro: error
                        }
                    });
                });
            }
            else {
                resolve();
            }
        });

        Promise.all([promiselocalEntrega, promiselocalRetirada]).then(values => {

            this.compra.totalCompra = 0;
            this.compra.totalDescontos = 0;
            this.compra.totalGeral = 0;

            var arrayLength = this.compra.compraItens.length;
            for (var i = 0; i < arrayLength; i++) {

                this.compra.totalCompra = this.compra.totalCompra + this.compra.compraItens[i].vProd;
                this.compra.totalDescontos = this.compra.totalDescontos + this.compra.compraItens[i].vDesc;
            }

            this.compra.totalGeral = this.compra.totalCompra - this.compra.totalDescontos;

            let modalidadeFrete = "";

            switch (this.compra.modFrete) {
                case 0:
                    modalidadeFrete = "Contratação do frete por conta do remetente(CIF)";
                    break;
                case 1:
                    modalidadeFrete = "Contratação do frete por conta do destinatário(FOB)";
                    break;
                case 2:
                    modalidadeFrete = "Transporte próprio por conta do remetente";
                    break;
                case 3:
                    modalidadeFrete = "Transporte próprio por conta do destinatário";
                    break;
                case 9:
                    modalidadeFrete = "Sem ocorrência de transporte";
                    break;
            }

            this.compra.modalidadeFrete = modalidadeFrete;

            this.compras = [];

            this.compras.push(this.compra);

            if (this.compra.compraStatus == 5) {
                this.compraCadastroForm.disable();
            }
        });

    }

    DeterminafornecedorId(id: number) {
        this.compraCadastroForm.get('fornecedorId').setValue(id);
    }

    DeterminalocalEntregaId(id: number) {
        this.compraCadastroForm.get('idlocalEntrega').setValue(id);
    }

    DeterminalocalRetiradaId(id: number) {
        this.compraCadastroForm.get('idlocalRetirada').setValue(id);
    }

    CancelarCompra(cancelarId: number) {

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                titulo: "Confirmação",
                mensagem: "Deseja realmente Cancelar essa compra?"
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                this.compraService.deleteCompra(cancelarId).subscribe(data => {

                    this._snackBar.open("Registro Cancelado com Sucesso");

                    this.buscarCompra(cancelarId);

                });
            }
        });
    };

    salvar(compraForm): void {

        if (compraForm.value.compraId == 0) {

            this.compraService.postCompra(compraForm.value).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.buscarCompra(data.body.compraId);


            });

        }
        else {
            this.compraService.putCompra(compraForm.value, compraForm.value.compraId).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.buscarCompra(compraForm.value.compraId);


            });
        }

    };

    MovimentarEstoque (compra: Compra) {

        var estoques = [];

        const parametros: any = {};

        parametros.idOrigem = compra.compraId;
        parametros.tipoOrigem = 0;
        parametros.tipoDestino = 3;

        this.compraService.getVisualizaDocumento(parametros).subscribe(data => {

            estoques = data.body;

            if (estoques.length > 0) {

                const dialogRefEstoque = this.dialog.open(ConfirmDialogComponent, {
                    data: {
                        titulo: "Movimentar Estoque",
                        mensagem: "Já existe uma entrada de estoque para essa compra. Deseja criar outra?"
                    }
                });

                dialogRefEstoque.afterClosed().subscribe(result => {
                    if (result) {
                        this.EnviarMovimentoEstoque(compra);
                    }
                });
            }
            else {

                const dialogRefEstoque = this.dialog.open(ConfirmDialogComponent, {
                    data: {
                        titulo: "Movimentar Estoque",
                        mensagem: "Deseja criar uma nova entrada de estoque para essa compra?"
                    }
                });

                dialogRefEstoque.afterClosed().subscribe(result => {
                    if (result) {

                        this.EnviarMovimentoEstoque(compra);
                    }
                });
            }
        });
    };

    EnviarMovimentoEstoque (compra: Compra) {

        this.compraService.postMovimentarEstoque(compra).subscribe(data => {

            const dialogRef = this.dialog.open(InfoDialogComponent, {
                data: {
                    titulo: "Sucesso",
                    mensagem: "Entradas de Estoque Realizadas"
                }
            });

        });
    };

    imprimir(): void {

        let titulo = "Pedido de Compra";

        this.htmlPrinterService.printDiv('printCompra', titulo);
    }

    Fatura(compra: Compra) {

        var faturas = [];

        const parametros: any = {};

        parametros.idOrigem = compra.compraId;
        parametros.tipoOrigem = 0;
        parametros.tipoDestino = 0;

        this.compraService.getVisualizaDocumento(parametros).subscribe(data => {

            faturas = data.body;

            if (faturas.length > 0) {

                const dialogRefEstoque = this.dialog.open(ConfirmDialogComponent, {
                    data: {
                        titulo: "Gerar Fatura",
                        mensagem: "Já existe uma fatura para esta compra. Deseja criar outra?"
                    }
                });

                dialogRefEstoque.afterClosed().subscribe(result => {
                    if (result) {
                        this.GeraFatura(compra);
                    }
                });
            }
            else {

                const dialogRefEstoque = this.dialog.open(ConfirmDialogComponent, {
                    data: {
                        titulo: "Gerar Fatura",
                        mensagem: "Deseja faturar essa compra?"
                    }
                });

                dialogRefEstoque.afterClosed().subscribe(result => {
                    if (result) {

                        this.GeraFatura(compra);
                    }
                });
            }
        });
    }

    GeraFatura(compra: Compra): void {

        this.compraService.postGeraFatura(compra).subscribe(data => {

            const dialogRef = this.dialog.open(InfoDialogComponent, {
                data: {
                    titulo: "Sucesso",
                    mensagem: "Compra Faturada"
                }
            });

            this.buscarCompra(this.compra.compraId);

            this.VisualizaFatura(data.body.faturaId);

        });
    }

    PreencherTabela(fornecedor: any) {

        if (fornecedor != undefined) {
            this.compra.fornecedorId = fornecedor.fornecedorId;
        }
    }

    PreencherTabelalocalRetirada(localRetirada: any) {

        if (localRetirada != undefined) {
            this.compra.idlocalRetirada = localRetirada.fornecedorId;
        }
    }

    PreencherTabelalocalEntrega(localEntrega) {

        if (localEntrega != undefined) {
            this.compra.idlocalEntrega = localEntrega.clienteId;
        }
    }

    ModalItem(compraItemId: number): void {


        const dialogRef = this.dialog.open(CompraItemComponent, {
            data: {
                compraItemId: compraItemId,
                compra: this.compra
            },
            maxHeight: '100vh'
        });

        dialogRef.afterClosed().subscribe(result => {
            this.buscarCompra(this.compra.compraId);
        });

    }

    DeleteCompraItem(compraItemId: number) {

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                titulo: "Confirmação",
                mensagem: "Deseja realmente excluir esse item?"
            }
        });


        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                this.compraService.deleteCompraItem(compraItemId).subscribe(data => {

                    this.buscarCompra(this.compra.compraId);

                });
            }
        });
    };

    VisualizaNFe() {

        const dialogRef = this.dialog.open(NfeCadastroComponent, {
            data: {
                nfeId: this.compra.nfeId,
            },
            maxHeight: '100vh'
        });

    }

    VisualizaFatura(faturaId: number) {

        const dialogRef = this.dialog.open(FaturaCadastroComponent, {
            data: {
                faturaId: faturaId,
                faturaTipo: 1
            },
            maxHeight: '100vh'
        });
    }

    solicitarAprovacao() {

        if (this.compra.aprovadorId == 0) {
            const dialogRefEstoque = this.dialog.open(InfoDialogComponent, {
                data: {
                    titulo: "Erro",
                    mensagem: "Você precisa selecionar um aprovador da Compra e Salvar"
                }
            });

            return;
        }

        const dialogRefEstoque = this.dialog.open(ConfirmDialogComponent, {
            data: {
                titulo: "Enviar para aprovação",
                mensagem: "Deseja enviar para aprovação? Após aprovada, você não poderá mais editar a Compra"
            }
        });

        dialogRefEstoque.afterClosed().subscribe(result => {
            if (result) {

                this.compraService.postSolicitarAprovacao(this.compraCadastroForm.value).subscribe(data => {

                    const dialogRefEstoque = this.dialog.open(InfoDialogComponent, {
                        data: {
                            titulo: "Sucesso",
                            mensagem: "Foi enviado um e-mail para o aprovador desta Compra"
                        }
                    });

                    this.buscarCompra(this.compra.compraId);

                });
            }
        });
    }

    aprovar() {

        const dialogRefEstoque = this.dialog.open(ConfirmDialogComponent, {
            data: {
                titulo: "Aprovar",
                mensagem: "Deseja aprovar essa compra?"
            }
        });

        dialogRefEstoque.afterClosed().subscribe(result => {
            if (result) {

                this.compraService.postAprovar(this.compra).subscribe(data => {

                    const dialogRefEstoque = this.dialog.open(InfoDialogComponent, {
                        data: {
                            titulo: "Sucesso",
                            mensagem: "Compra Aprovada"
                        }
                    });

                    this.buscarCompra(this.compra.compraId);

                });

            }
        });
    }

    confirmarCompra() {

        this.compraService.postConfirmarCompra(this.compra).subscribe(data => {

            const dialogRefEstoque = this.dialog.open(InfoDialogComponent, {
                data: {
                    titulo: "Sucesso",
                    mensagem: "Compra Confirmada"
                }
            });

            this.buscarCompra(this.compra.compraId);

        });
    }

    definirRecebido(){

        const dialogRefEstoque = this.dialog.open(ConfirmDialogComponent, {
            data: {
                titulo: "Definir como Recebido",
                mensagem: "Deseja definir como recebido? Essa opção deve ser usada quando a compra não possuir uma NF-e. Se possuir uma NF-e recomendamos utilizar a opção Receber NF-e"
            }
        });

        dialogRefEstoque.afterClosed().subscribe(result => {
            if (result) {

                this.compraService.postDefinirComoRecebido(this.compra).subscribe(data => {

                    const dialogRefEstoque = this.dialog.open(InfoDialogComponent, {
                        data: {
                            titulo: "Sucesso",
                            mensagem: "Compra definida como Recebida"
                        }
                    });

                    this.buscarCompra(this.compra.compraId);

                });
            }
        });
    }

    definirComoemCriacao(){

        const dialogRefEstoque = this.dialog.open(ConfirmDialogComponent, {
            data: {
                titulo: "Definir como Em Criação",
                mensagem: "Deseja desbloquear para edições? Essa opção retornará o Ordem de Compra para Em Criação e será necessária uma nova aprovação."
            }
        });

        dialogRefEstoque.afterClosed().subscribe(result => {
            if (result) {

                this.compraService.postDefinirComoemCriacao(this.compra).subscribe(data => {

                    const dialogRefEstoque = this.dialog.open(InfoDialogComponent, {
                        data: {
                            titulo: "Sucesso",
                            mensagem: "Agora a Compra permitirá edições"
                        }
                    });

                    this.buscarCompra(this.compra.compraId);

                });
            }
        });
    }

    abrirLogs(){

        const dialogRef = this.dialog.open(LogComponent, {
          data: {
            entidadeId: this.compra.compraId,
            logTipo: 2
          },
          maxHeight: '100vh',
          maxWidth: '100vw'
        });
    
      }

    receberNfe() {

        const dialogRef = this.dialog.open(NfeInboundImportarComponent, {
            maxHeight: '100vh',
            maxWidth: '100vw',
            data: { 
                compraId: this.compra.compraId,
                vendaId: 0
            },
        });

        dialogRef.afterClosed().subscribe(result => {

            this.buscarCompra(this.compra.compraId);

        });

    }

    abreModalAnexo() {

        const dialogRef = this.dialog.open(AnexoComponent, {
          data: {
            documentoIdReferenciado: this.compra.compraId,
            documentoTipo: 0,
            saguiClienteId: this.compra.saguiClienteId
          },
          maxHeight: '100vh',
          maxWidth: '100vw'
        });
      }

    //VisualizaFluxoDocumentos(Compra: any) {

    //    documentoComponent.locals = {
    //        tipoOrigem: 0,
    //        idDocumento: Compra.compraId,
    //    };

    //    $mdDialog.show(documentoComponent).then(function () {

    //        this.cancel();

    //    });
    //}
}
