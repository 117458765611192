import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class LogService {

    getLogs(itemId: number, tipoLog: number): Observable<HttpResponse<any>>{
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/log/' + itemId + '?logTipo=' + tipoLog, { observe: 'response' })
    }

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
