<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>Eventos da NF-e</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button mat-raised-button (click)="modalCartaCorrecao(0)" matTooltip="Adicionar Carta Correção">
    <mat-icon>add</mat-icon> Carta Correção</button>
</div>
<mat-dialog-content>
  <div class="md-dialog-content">
    <br />
    <div id="list" class="row">
      <div class="table-responsive col-md-12">
        <table class="table table-striped" cellspacing="0" cellpadding="0">
          <thead>
            <tr>
              <th>Ações</th>
              <th>Evento</th>
              <th>Descrição</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let nfeEvento of nfeEventos">
              <td>
                <a mat-mini-fab *ngIf="nfeEvento.tpEvento == '110110'"
                  (click)="modalCartaCorrecao(nfeEvento.nfeEventoId)" matTooltip="Editar Carta Correção">
                  <mat-icon>edit</mat-icon>
                </a>
              </td>
              <td>{{nfeEvento.tpEvento == '110111' ? 'Cancelamento' : 'Carta de Correção'}}</td>
              <td>{{nfeEvento.tpEvento == '110111' ? nfeEvento.xJust : nfeEvento.xCorrecao }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</mat-dialog-content>
