import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class CepService {

    getDadosEndereco(cepTemp: string): Observable<HttpResponse<any>> {

        let cep = cepTemp.replace(/\D/g, '');

        if (cep != "") {

            let validacep = /^[0-9]{8}$/;

            if (validacep.test(cep)) {

                return this.http.get('https://viacep.com.br/ws/' + cep + '/json', { observe: 'response' })
            }
        }
    }


    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
