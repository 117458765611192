'use strict'
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VendaService } from '../shared/venda.service';
import { CfopService } from '../../cfop/shared/cfop.service';

import { debounceTime, switchMap } from "rxjs/operators";

import { VendaItem } from '../shared/venda-item.model';
import { Produto } from '../../produto/shared/produto.model';
import { Cfop } from '../../cfop/shared/cfop.model';
import { Estoque } from '../../estoque/shared/estoque.model';
import { Venda } from '../shared/venda.model';


export interface DialogData {
    vendaItemId: number;
    venda: any;
}

@Component({
    selector: 'venda-item',
    templateUrl: 'venda-item.component.html',
})

export class VendaItemComponent {

    venda: Venda;
    listaServicos: any = [];
    vendaItemForm: FormGroup;
    maximizado: boolean;
    vendaItemId: number;
    vendaitem: any = {};
    cfops: Cfop[];
    produtos: Produto[];
    estoques: Estoque[];

    showIcmsTrib: boolean = false;
    showIcmsSTRet: boolean = false;
    showIcmsST: boolean = false;
    showPercentualReducaoBC: boolean = false;
    showPercentualCreditoSN: boolean = false;
    showValorCreditoIcmsSN: boolean = false;

    showIpiTributado: boolean = false;
    showIpiAliquota: boolean = false;
    showIpiPauta: boolean = false;

    showPisAliquota: boolean = false;
    showPisPauta: boolean = false;
    showValorPis: boolean = false;
    showPisST: boolean = false;
    showTipoCalculoPis: boolean = false;
    showTipoCalculoPisST: boolean = false;
    showPisSTPauta: boolean = false;
    showPisSTAliquota: boolean = false;
    showValorPisST: boolean = false;

    showCofinsAliquota: boolean = false;
    showCofinsPauta: boolean = false;
    showValorCofins: boolean = false;
    showValorCofinsST: boolean = false;
    showTipoCalculoCofins: boolean = false;
    showTipoCalculoCofinsST: boolean = false;
    showCofinsST: boolean = false;
    showCofinsSTAliquota: boolean = false;
    showCofinsSTPauta: boolean = false;

    constructor(
        public dialog: MatDialog,
    private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<VendaItemComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public IVendaItem: DialogData,
        private vendaService: VendaService,
        private cfopService: CfopService) {
    }

    ngOnInit() {

        this.maximizado = false;

        this.venda = this.IVendaItem.venda;

        this.vendaItemId = this.IVendaItem.vendaItemId;

        this.vendaItemForm = this._formBuilder.group(new VendaItem());

        this.vendaItemForm.addControl('produtoAutoComplete', new FormControl(''));

        this.vendaItemForm.get('vendaId').setValue(this.venda.vendaId);

        this.vendaItemForm.get('orig').setValue(0);

        if (this.venda.vendaTipo == 0 || this.venda.vendaTipo == 2) {

            this.buscaCfop();
        }

        if (this.venda.vendaTipo == 1) {

            this.getListaServico();
            this.vendaItemForm.get('produtoTipo').setValue(1);
        }

        this.onChanges();

        this.buscarVendaItem();

        this.maximizarDialog();
    }

    onChanges(): void {

        this.vendaItemForm
            .get('produtoAutoComplete')
            .valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => value?.length >= 3 ?
                    this.vendaService.getProdutoPorNome({
                        id: value,
                        tipoProduto: this.vendaItemForm.get('produtoTipo').value
                    }) : []
                )
            )
            .subscribe(data =>
                this.produtos = data.body
            );

        this.vendaItemForm
            .get('valorDeducoes')
            .valueChanges
            .subscribe(data => this.calculavalorLiquido());

        this.vendaItemForm
            .get('outrasRetencoes')
            .valueChanges
            .subscribe(data => this.calculavalorLiquido());

        this.vendaItemForm
            .get('descontoIncondicionado')
            .valueChanges
            .subscribe(data => this.calculavalorLiquido());

        this.vendaItemForm
            .get('descontoCondicionado')
            .valueChanges
            .subscribe(data => this.calculavalorLiquido());

        this.vendaItemForm
            .get('valorPis')
            .valueChanges
            .subscribe(data => this.calculavalorLiquido());

        this.vendaItemForm
            .get('valorCofins')
            .valueChanges
            .subscribe(data => this.calculavalorLiquido());

        this.vendaItemForm
            .get('valorIr')
            .valueChanges
            .subscribe(data => this.calculavalorLiquido());

        this.vendaItemForm
            .get('valorInss')
            .valueChanges
            .subscribe(data => this.calculavalorLiquido());

        this.vendaItemForm
            .get('valorCsll')
            .valueChanges
            .subscribe(data => this.calculavalorLiquido());

        this.vendaItemForm
            .get('icmsDifal')
            .valueChanges
            .subscribe(data => this.trataicmsDifal(data));

        this.vendaItemForm
            .get('cstIcms')
            .valueChanges
            .subscribe(data => this.defineIcmsCst(data));

        this.vendaItemForm
            .get('cstIpi')
            .valueChanges
            .subscribe(data => this.defineIpiCst(data));

        this.vendaItemForm
            .get('tipoCalculoIPI')
            .valueChanges
            .subscribe(data => this.defineIpiTipoCalculo(data));

        this.vendaItemForm
            .get('cstPis')
            .valueChanges
            .subscribe(data => this.definePisCst(data));

        this.vendaItemForm
            .get('tipoCalculoPIS')
            .valueChanges
            .subscribe(data => this.definePisTipoCalculo(data));

        this.vendaItemForm
            .get('tipoCalculoPISST')
            .valueChanges
            .subscribe(data => this.definePisStTipoCalculo(data));

        this.vendaItemForm
            .get('cstCofins')
            .valueChanges
            .subscribe(data => this.definecofinsCst(data));

        this.vendaItemForm
            .get('tipoCalculocofins')
            .valueChanges
            .subscribe(data => this.definecofinsTipoCalculo(data));

        this.vendaItemForm
            .get('tipoCalculocofinsST')
            .valueChanges
            .subscribe(data => this.definecofinsStTipoCalculo(data));

    }

    calculavalorLiquido(): void {

        let valorLiquidoNfse = this.vendaItemForm.get('valorServicos').value
            - this.vendaItemForm.get('valorPis').value
            - this.vendaItemForm.get('valorCofins').value
            - this.vendaItemForm.get('valorInss').value
            - this.vendaItemForm.get('valorIr').value
            - this.vendaItemForm.get('valorCsll').value
            - this.vendaItemForm.get('outrasRetencoes').value
            - this.vendaItemForm.get('valorIssRetido').value
            - this.vendaItemForm.get('descontoIncondicionado').value
            - this.vendaItemForm.get('descontoCondicionado').value;

        let valorLiquidoNfseTemp = valorLiquidoNfse.toFixed(2);

        this.vendaItemForm.get('valorLiquidoNfse').setValue(valorLiquidoNfseTemp);
    }

    calculaImpostos(): void {

        let valorTotal = this.vendaItemForm.get('valorTotal').value;
        this.vendaItemForm.get('valorBruto').setValue(valorTotal);
        this.vendaItemForm.get('valorServicos').setValue(valorTotal);

        this.vendaService.postCalculaImposto(this.vendaItemForm.value).subscribe(data => {

            if (this.venda.vendaTipo == 1) {
                this.vendaItemForm.get('aliquotaIssqn').setValue(data.body.aliquotaIssqn);
                this.vendaItemForm.get('valorPis').setValue(data.body.valorPis);
                this.vendaItemForm.get('valorCofins').setValue(data.body.valorCofins);
                this.vendaItemForm.get('valorIr').setValue(data.body.valorIr);
                this.vendaItemForm.get('valorInss').setValue(data.body.valorInss);
                this.vendaItemForm.get('valorCsll').setValue(data.body.valorCsll);
                this.vendaItemForm.get('baseCalculo').setValue(data.body.baseCalculo);
                this.vendaItemForm.get('valorLiquidoNfse').setValue(data.body.valorLiquidoNfse);
                this.vendaItemForm.get('valorIss').setValue(data.body.valorIss);
                this.vendaItemForm.get('valorIssRetido').setValue(data.body.valorIssRetido);
                this.vendaItemForm.get('issRetido').setValue(data.body.issRetido);
            }
            else {
                this.vendaItemForm.get('cofinsSTpCofins').setValue(data.body.cofinsSTpCofins);
                this.vendaItemForm.get('cofinsSTqUnid').setValue(data.body.cofinsSTqUnid);
                this.vendaItemForm.get('cofinsSTvBC').setValue(data.body.cofinsSTvBC);
                this.vendaItemForm.get('cofinsSTvCofins').setValue(data.body.cofinsSTvCofins);
                this.vendaItemForm.get('cofinsSTvUnid').setValue(data.body.cofinsSTvUnid);
                this.vendaItemForm.get('cofinspCofins').setValue(data.body.cofinspCofins);
                this.vendaItemForm.get('cofinsqUnid').setValue(data.body.cofinsqUnid);
                this.vendaItemForm.get('cofinsvBC').setValue(data.body.cofinsvBC);
                this.vendaItemForm.get('cofinsvCofins').setValue(data.body.cofinsvCofins);
                this.vendaItemForm.get('cofinsvUnid').setValue(data.body.cofinsvUnid);
                this.vendaItemForm.get('icmsSTmodBCST').setValue(data.body.icmsSTmodBCST);
                this.vendaItemForm.get('icmsSTpIcmsST').setValue(data.body.icmsSTpIcmsST);
                this.vendaItemForm.get('icmsSTpMVAST').setValue(data.body.icmsSTpMVAST);
                this.vendaItemForm.get('icmsSTpRedBCST').setValue(data.body.icmsSTpRedBCST);
                this.vendaItemForm.get('icmsSTvBCST').setValue(data.body.icmsSTvBCST);
                this.vendaItemForm.get('icmsSTvIcmsST').setValue(data.body.icmsSTvIcmsST);
                this.vendaItemForm.get('icmspCredSN').setValue(data.body.icmspCredSN);
                this.vendaItemForm.get('icmspIcms').setValue(data.body.icmspIcms);
                //this.vendaItemForm.get('icmspIcmsInter').setValue(data.body.icmspIcmsInter);
                this.vendaItemForm.get('icmspRedBC').setValue(data.body.icmspRedBC);
                this.vendaItemForm.get('icmsvBC').setValue(data.body.icmsvBC);
                this.vendaItemForm.get('icmsvCredIcmsSN').setValue(data.body.icmsvCredIcmsSN);
                this.vendaItemForm.get('icmsvIcms').setValue(data.body.icmsvIcms);
                this.vendaItemForm.get('ipiCNPJProd').setValue(data.body.ipiCNPJProd);
                this.vendaItemForm.get('ipicEnq').setValue(data.body.ipicEnq);
                this.vendaItemForm.get('ipiclEnq').setValue(data.body.ipiclEnq);
                //this.vendaItemForm.get('ipicodSelo').setValue(data.body.ipicodSelo);
                this.vendaItemForm.get('ipipIpi').setValue(data.body.ipipIpi);
                this.vendaItemForm.get('ipiqUnid').setValue(data.body.ipiqUnid);
                this.vendaItemForm.get('ipivBC').setValue(data.body.ipivBC);
                this.vendaItemForm.get('ipivIpi').setValue(data.body.ipivIpi);
                this.vendaItemForm.get('ipivUnid').setValue(data.body.ipivUnid);
                this.vendaItemForm.get('icmsDifal').setValue(data.body.icmsDifal);
                this.vendaItemForm.get('pisSTpPis').setValue(data.body.pisSTpPis);
                //this.vendaItemForm.get('pisSTqUnid').setValue(data.body.pisSTqUnid);
                this.vendaItemForm.get('pisSTvBC').setValue(data.body.pisSTvBC);
                this.vendaItemForm.get('pisSTvPis').setValue(data.body.pisSTvPis);
                //this.vendaItemForm.get('pisSTvUnid').setValue(data.body.pisSTvUnid);
                this.vendaItemForm.get('pispPis').setValue(data.body.pispPis);
                this.vendaItemForm.get('pisqUnid').setValue(data.body.pisqUnid);
                this.vendaItemForm.get('pisvBC').setValue(data.body.pisvBC);
                this.vendaItemForm.get('pisvPis').setValue(data.body.pisvPis);
                this.vendaItemForm.get('pisvUnid').setValue(data.body.pisvUnid);
                this.vendaItemForm.get('cstCofins').setValue(data.body.cstCofins);
                this.vendaItemForm.get('cstIcms').setValue(data.body.cstIcms);
                this.vendaItemForm.get('cstIpi').setValue(data.body.cstIpi);
                this.vendaItemForm.get('cstPis').setValue(data.body.cstPis);
                this.vendaItemForm.get('infAdProd').setValue(data.body.infAdProd);
            }

        });
    }

    buscarVendaItem() {
        
        if (this.vendaItemId > 0) {

            this.vendaService.getVendaItem(this.vendaItemId).subscribe(data => {
                this.vendaItemForm.patchValue(data.body);
                this.vendaItemForm.get('produtoAutoComplete').setValue(data.body.descricao);
                this.SearchEstoquesByprodutoId(data.body.produtoId);

            });
        } else {
            this.vendaItemForm.get('indTot').setValue(1);
            this.vendaItemForm.get('ipicEnq').setValue(999);
            this.vendaItemForm.get('cstIcms').setValue(201);
            this.vendaItemForm.get('cstIpi').setValue(99);
            this.vendaItemForm.get('cstPis').setValue(99);
            this.vendaItemForm.get('cstCofins').setValue(99);
            this.vendaItemForm.get('icmSorig').setValue(0);
        }
    }

    getListaServico() {

        this.vendaService.getListaServicos().subscribe(data => {

            this.listaServicos = data.body;

        });
    }

    buscaCfop() {
        const parametros: any = {};

        parametros.empresaId = this.venda.empresaId;

        if (this.venda.vendaTipo == 0) {
            parametros.direcao = 1;
        }
        else {
            parametros.direcao = 2;
        }

        this.cfopService.getCfops(parametros).subscribe(data => {
            this.cfops = data.body;

            if (this.cfops.length > 0 && this.vendaItemForm.get('cfop').value == 0)
                this.vendaItemForm.get('cfop').setValue(this.cfops[0].codCfop);

        });
    }

    fecharDialog() {
        this.dialogRef.close();
    }

    salvar() {

        if (this.vendaItemForm.value.vendaItemId == 0) {

            this.vendaItemForm.get('vendaId').setValue(this.venda.vendaId);

            this.vendaService.postIncluiVendaItem(this.vendaItemForm.value).subscribe(data => {

                this.fecharDialog();

            });
        }
        else {

            this.vendaService.putAtualizaVendaItem(this.vendaItemForm.value, this.vendaItemForm.value.vendaItemId).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.fecharDialog();

            });
        }
    };

    determinaprodutoId(produto: Produto) {

        this.vendaItemForm.get('produtoId').setValue(produto.produtoId);
        this.vendaItemForm.get('codProd').setValue(produto.codProd);
        this.vendaItemForm.get('descricao').setValue(produto.descricao);
        this.vendaItemForm.get('ncm').setValue(produto.ncm);
        this.vendaItemForm.get('ean').setValue(produto.ean);
        this.vendaItemForm.get('eanTrib').setValue(produto.eanTrib);
        this.vendaItemForm.get('unTributaria').setValue(produto.unTributaria);
        this.vendaItemForm.get('vlrTributario').setValue(produto.vlrTributario);
        this.vendaItemForm.get('unComercial').setValue(produto.unComercial);
        this.vendaItemForm.get('vlrComercial').setValue(produto.vlrComercial);
        this.vendaItemForm.get('extipi').setValue(produto.extipi);
        this.vendaItemForm.get('infAdProd').setValue(produto.infAdProd);
        this.vendaItemForm.get('orig').setValue(produto.orig);
        this.vendaItemForm.get('cest').setValue(produto.cest);
        this.vendaItemForm.get('pesoBruto').setValue(produto.pesoBruto);
        this.vendaItemForm.get('pesoLiquido').setValue(produto.pesoLiquido);
        this.vendaItemForm.get('grupoContaId').setValue(produto.grupoContaId);

        this.SearchEstoquesByprodutoId(produto.produtoId);

        if (produto.servicoListaId > 0) {

            this.vendaService.getListaServico(produto.servicoListaId).subscribe(result => {

                this.vendaItemForm.get('listaServico').setValue(result.body.codigo);

            });
        }
    }

    SearchEstoquesByprodutoId(produtoId: number) {
        this.vendaService.getEstoqueByProduto(produtoId).subscribe(data => {
            this.estoques = data.body;

            if (this.estoques.length > 0 && this.vendaItemForm.get('estoqueId').value == 0)
                this.vendaItemForm.get('estoqueId').setValue(this.estoques[0].estoqueId);
        });
    }

    onChangeValorComercial() {
        
        let vlrComercial = this.vendaItemForm.get('vlrComercial').value;
        let qtdComercial = this.vendaItemForm.get('qtdComercial').value;
        let valorTotal = Math.round((qtdComercial * vlrComercial) * 100) / 100;
        this.vendaItemForm.get('valorTotal').setValue(valorTotal.toFixed(2));
        this.vendaItemForm.get('qtdTributaria').setValue(qtdComercial);
        this.vendaItemForm.get('vlrTributario').setValue(vlrComercial);

        if (this.venda.vendaTipo != 1) {
            this.calculaImpostos();
        }

    }

    TrataValorICMS() {

        let icmsvIcms = (this.vendaItemForm.get('icmsvBC').value * this.vendaItemForm.get('icmspIcms').value) / 100;

        this.vendaItemForm.get('icmsvIcms').setValue(icmsvIcms);
    }

    TrataValorICMSST() {

        let icmsSTvIcmsST = (this.vendaItemForm.get('icmsSTvBCST').value * this.vendaItemForm.get('icmsSTpIcmsST').value) / 100;

        this.vendaItemForm.get('icmsSTvIcmsST').setValue(icmsSTvIcmsST);
    }

    trataicmsDifal(utilizaicmsDifal: boolean) {

        if (utilizaicmsDifal == true) {

            let valorBaseCalculo = this.vendaItemForm.get('valorTotal').value +
                this.vendaItemForm.get('vFrete').value +
                this.vendaItemForm.get('vSeg').value +
                this.vendaItemForm.get('vOutro').value +
                this.vendaItemForm.get('ipivIpi').value -
                this.vendaItemForm.get('valorDesconto').value;

            let diferencialAliquota = (this.vendaItemForm.get('icmsSTpIcmsST').value - this.vendaItemForm.get('icmspIcms').value) / 100;

            this.vendaItemForm.get('icmsvBC').setValue(valorBaseCalculo);
            this.vendaItemForm.get('icmsSTvBCST').setValue(valorBaseCalculo);
            this.vendaItemForm.get('icmsSTvIcmsST').setValue(valorBaseCalculo * diferencialAliquota);
        };

    };

    defineIcmsCst(cstIcms: string) {

        this.showPercentualCreditoSN = false;
        this.showValorCreditoIcmsSN = false;
        this.showIcmsST = false;
        this.showIcmsSTRet = false;
        this.showIcmsTrib = false;
        this.showPercentualReducaoBC = false;

        switch (cstIcms) {
            case '00':
                this.ExibeICMS();
                break;
            case '10':
                this.ExibeICMS();
                this.ExibeICMSST();
                break;
            case '20':
                this.ExibeICMS();
                this.showPercentualReducaoBC = true;
                break;
            case '30':
                this.ExibeICMSST();
                break;
            case '40':
            case '41':
            case '50':
                break;
            case '51':
                this.ExibeICMS();
                this.showPercentualReducaoBC = true;
                break;
            case '60':
                this.showIcmsSTRet = true;
                break;
            case '70':
                this.ExibeICMS();
                this.ExibeICMSST();
                this.showPercentualReducaoBC = true;
                break;
            case '90':
                this.ExibeICMS();
                this.ExibeICMSST();
                this.showPercentualReducaoBC = true;
                break;
            case 'ICMSPart':
                //Partilha de ICMS entre UF
                break;
            case 'ICMSST':
                //ICMS ST devido para a UF de destino, nas operações interestaduais de produtos que tiveram retenção antecipada de ICMS por ST na UF do remetente. Repasse via Substituto Tributário.
                break;
            case '101':
                //Tributada pelo Simples Nacional com permissão de crédito
                this.showPercentualCreditoSN = true;
                this.showValorCreditoIcmsSN = true;
                this.showIcmsST = false;
                break;
            case '102':
                //Tributada pelo Simples Nacional sem permissão de crédito.
                this.showPercentualCreditoSN = false;
                this.showValorCreditoIcmsSN = false;
                this.showIcmsST = false;
                break;
            case '103':
                //Isenção do ICMS no Simples Nacional para faixa de receita bruta.
                this.showPercentualCreditoSN = false;
                this.showValorCreditoIcmsSN = false;
                this.showIcmsST = false;
                break;
            case '201':
                //Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por Substituição Tributária.
                this.showPercentualCreditoSN = true;
                this.showValorCreditoIcmsSN = true;
                this.showIcmsST = true;
                break;
            case '202':
                //Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por Substituição Tributária.
                this.showPercentualCreditoSN = false;
                this.showValorCreditoIcmsSN = false;
                this.showIcmsST = true;
                break;
            case '203':
                //Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por Substituição Tributária.
                this.showPercentualCreditoSN = true;
                this.showValorCreditoIcmsSN = true;
                this.showIcmsST = true;
                break;
            case '300':
                //Imune.
                this.showPercentualCreditoSN = false;
                this.showValorCreditoIcmsSN = false;
                this.showIcmsST = false;
                break;
            case '400':
                //Não tributada pelo Simples Nacional.
                this.showPercentualCreditoSN = false;
                this.showValorCreditoIcmsSN = false;
                this.showIcmsST = false;
                break;
            case '500':
                //ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação.
                this.showPercentualCreditoSN = false;
                this.showValorCreditoIcmsSN = false;
                this.showIcmsST = false;
                break;
            case '900':
                //Outros
                this.showPercentualCreditoSN = true;
                this.showValorCreditoIcmsSN = true;
                this.ExibeICMS();
                this.ExibeICMSST();
                this.showPercentualReducaoBC = true;
                break;
        };
    };

    ExibeICMS() {
        this.showIcmsTrib = true;

        if (this.vendaItemForm.get('icmsvBC').value == 0) {
            this.vendaItemForm.get('icmsvBC').setValue(this.vendaItemForm.get('valorTotal').value);
        }
    }

    ExibeICMSST() {
        this.showIcmsST = true;

        if (this.vendaItemForm.get('icmsSTvBCST').value == 0) {
            this.vendaItemForm.get('icmsSTvBCST').setValue(this.vendaItemForm.get('valorTotal').value);
        }
    }


    defineIpiCst(cstIpi) {

        switch (cstIpi) {
            case '49':
            case '00':
            case '50':
            case '99':
                this.showIpiTributado = true;
                this.defineIpiTipoCalculo(0);
                break;
            case '01':
            case '02':
            case '03':
            case '04':
            case '05':
            case '51':
            case '52':
            case '53':
            case '54':
            case '55':
                this.showIpiTributado = false;
                break;
        };
    };

    defineIpiTipoCalculo(tipoCaculo: number) {

        switch (tipoCaculo) {
            case 0:
                this.showIpiAliquota = true;
                this.showIpiPauta = false;
                break;
            case 1:
                this.showIpiAliquota = false;
                this.showIpiPauta = true;
                break;
        };
    };

    definePisCst(cstPis: string) {

        switch (cstPis) {
            case '01':
            case '02':
                this.showTipoCalculoPis = false;
                this.showTipoCalculoPisST = false;
                this.showPisAliquota = true;
                this.showPisPauta = false;
                this.showPisST = false;
                this.showValorPis = true;
                this.showValorPisST = false;
                this.definePisTipoCalculo(0);
                break;
            case '03':
                this.showTipoCalculoPis = false;
                this.showTipoCalculoPisST = false;
                this.showPisAliquota = false;
                this.showPisPauta = true;
                this.showPisST = false;
                this.showValorPis = true;
                this.showValorPisST = false;
                this.definePisTipoCalculo(1);
                break;
            case '04':
            case '06':
            case '07':
            case '08':
            case '09':
                this.showTipoCalculoPis = false;
                this.showTipoCalculoPisST = false;
                this.showPisAliquota = false;
                this.showPisPauta = false;
                this.showPisST = false;
                this.showValorPis = false;
                this.showValorPisST = false;
                break;
            case '05':
                this.showTipoCalculoPis = false;
                this.showTipoCalculoPisST = true;
                this.showPisST = true;
                this.showPisAliquota = false;
                this.showPisPauta = false;
                this.showValorPis = false;
                this.showValorPisST = true;
                break;
            case '49':
            case '50':
            case '51':
            case '52':
            case '53':
            case '54':
            case '55':
            case '56':
            case '60':
            case '61':
            case '62':
            case '63':
            case '64':
            case '65':
            case '66':
            case '67':
            case '70':
            case '71':
            case '72':
            case '73':
            case '74':
            case '75':
            case '98':
            case '99':
                this.showTipoCalculoPis = true;
                this.showTipoCalculoPisST = false;
                this.showPisST = false;
                this.showPisAliquota = false;
                this.showPisPauta = false;
                this.showValorPis = true;
                this.showValorPisST = false;
                this.definePisTipoCalculo(0);
                break;
        };

    }

    definePisTipoCalculo(pisTipoCalculo: number) {

        switch (pisTipoCalculo) {
            case 0:
                this.showPisAliquota = true;
                this.showPisPauta = false;
                break;
            case 1:
                this.showPisAliquota = false;
                this.showPisPauta = true;
                break;
        };

    }

    definePisStTipoCalculo(pisStTipoCalculo: number) {

        switch (pisStTipoCalculo) {
            case 0:
                this.showPisSTAliquota = true;
                this.showPisSTPauta = false;
                break;
            case 1:
                this.showPisSTAliquota = false;
                this.showPisSTPauta = true;
                break;
        };
    }

    definecofinsCst(cstCofins: string) {

        switch (cstCofins) {
            case '01':
            case '02':
                this.showTipoCalculoCofins = false;
                this.showTipoCalculoCofinsST = false;
                this.showCofinsAliquota = true;
                this.showCofinsPauta = false;
                this.showCofinsST = false;
                this.showValorCofins = true;
                this.showValorCofinsST = false;
                break;
            case '03':
                this.showTipoCalculoCofins = false;
                this.showTipoCalculoCofinsST = false;
                this.showCofinsAliquota = false;
                this.showCofinsPauta = true;
                this.showCofinsST = false;
                this.showValorCofins = true;
                this.showValorCofinsST = false;
                break;
            case '04':
            case '06':
            case '07':
            case '08':
            case '09':
                this.showTipoCalculoCofins = false;
                this.showTipoCalculoCofinsST = false;
                this.showCofinsAliquota = false;
                this.showCofinsPauta = false;
                this.showCofinsST = false;
                this.showValorCofins = true;
                this.showValorCofinsST = false;
                break;
            case '05':
                this.showTipoCalculoCofins = false;
                this.showTipoCalculoCofinsST = true;
                this.showCofinsST = true;
                this.showCofinsAliquota = false;
                this.showCofinsPauta = false;
                this.showValorCofins = false;
                this.showValorCofinsST = true;
                break;
            case '49':
            case '50':
            case '51':
            case '52':
            case '53':
            case '54':
            case '55':
            case '56':
            case '60':
            case '61':
            case '62':
            case '63':
            case '64':
            case '65':
            case '66':
            case '67':
            case '70':
            case '71':
            case '72':
            case '73':
            case '74':
            case '75':
            case '98':
            case '99':
                this.showTipoCalculoCofins = true;
                this.showTipoCalculoCofinsST = false;
                this.showCofinsST = false;
                this.showCofinsAliquota = true;
                this.showCofinsPauta = false;
                this.showValorCofins = true;
                this.showValorCofinsST = false;
                break;
        };
    };

    definecofinsTipoCalculo(cofinsTipoCalculo: number) {


        switch (cofinsTipoCalculo) {
            case 0:
                this.showCofinsAliquota = true;
                this.showCofinsPauta = false;
                break;
            case 1:
                this.showCofinsAliquota = false;
                this.showCofinsPauta = true;
                break;
        };

    }

    definecofinsStTipoCalculo(cofinsStTipoCalculo: number) {

        switch (cofinsStTipoCalculo) {
            case 0:
                this.showCofinsSTAliquota = true;
                this.showCofinsSTPauta = false;
                break;
            case 1:
                this.showCofinsSTAliquota = false;
                this.showCofinsSTPauta = true;
                break;
        };
    }


    maximizarDialog() {
        this.dialogRef.updateSize('100%', '100%');
        this.maximizado = true;
    }

    restaurarDialog() {
        this.dialogRef.updateSize('80vh', '100vh');
        this.maximizado = false;
    }

}
