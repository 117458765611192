
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { NfeService } from '../shared/nfe.service';

export interface DialogData {
  nfeId: number;
}

@Component({
  selector: 'nfe-pedido-compra',
  templateUrl: 'nfe-pedido-compra.component.html',
})

export class NfePedidoCompraComponent {

  nfePedidoCompraForm: FormGroup;
  maximizado: boolean;
  filtro: any = {};
  buscouCompras: boolean = false;
  compras: any = [];
  dataDisable: boolean;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NfePedidoCompraComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private nfeService: NfeService) {
  }

  ngOnInit() {

    this.dataDisable = true;

    this.filtro.dataAtual = new Date();

    this.filtro.dataFinal = new Date();

    var dataInicial = new Date().setDate(this.filtro.dataFinal.getDate() - 30);

    this.nfePedidoCompraForm = this._formBuilder.group({
      vencimento: [''],
      dataInicial: new Date(dataInicial),
      dataFinal: new Date(),
      compraStatus: 3,
      
    });

    this.nfePedidoCompraForm
      .get('vencimento')
      .valueChanges
      .subscribe(
        (data) => {

          if (data == 'hoje') {
            this.dataDisable = true;
            this.nfePedidoCompraForm.get('dataInicial').setValue(new Date());
            this.nfePedidoCompraForm.get('dataFinal').setValue(new Date());
          }
          if (data == 'essemes') {
            this.dataDisable = true;
            var date = new Date();
            this.nfePedidoCompraForm.get('dataInicial').setValue(new Date(date.getFullYear(), date.getMonth(), 1));
            this.nfePedidoCompraForm.get('dataFinal').setValue(new Date(date.getFullYear(), date.getMonth() + 1, 0));
          }
          if (data == 'escolher') {
            this.dataDisable = false;
            var date = new Date();
            this.nfePedidoCompraForm.get('dataInicial').setValue(new Date(date.getFullYear(), date.getMonth(), 1));
            this.nfePedidoCompraForm.get('dataFinal').setValue(new Date(date.getFullYear(), date.getMonth() + 1, 0));
          }

        });

    this.nfePedidoCompraForm.get('vencimento').setValue('essemes');

    this.onChanges();

    this.maximizarDialog();
  }

  onChanges(): void {

  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    this.dialogRef.updateSize('80vh', '100vh');
    this.maximizado = false;
  }

  buscaCompras() {

    this.nfeService.postVisualizaComprasPorFiltro(this.nfePedidoCompraForm.value).subscribe(data => {

      this.compras = data.body;

      this.buscouCompras = true;

    });
  };

  vincularCompras() {

    let nfePedidoCompra: any = {};

    nfePedidoCompra.nfeId = this.dialogData.nfeId;
    nfePedidoCompra.IDsDocumento = ""; 

    for (let i = 0; i < this.compras.length; i++) {
      if (this.compras[i].selected) {
        nfePedidoCompra.IDsDocumento = nfePedidoCompra.IDsDocumento + this.compras[i].compraId + ",";
      }
    }

    this.nfeService.postNfeVincularPedidoCompra(nfePedidoCompra).subscribe(data => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Sucesso",
          mensagem: "Pedidos de Compras Vinculados com NF-e Inbound"
        }
      });

      this.fecharDialog();

    });

  }

  statusCompra(status: number) {

    switch (status) {
        case 0:
            return "Em Criação";
        case 1:
            return "Aguardando Aprovação";
        case 2:
            return "Aprovado";
        case 3:
            return "Aguardando Mercadoria";
        case 4:
            return "Recebida";
    }
}

}
