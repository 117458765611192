import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class ContaContabilService {

  ContaContabilFiltro(item: any): Observable<HttpResponse<any>> {

    let filtro: any = {};
        filtro.descricao = item;  

    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/contacontabil/filtros/' +item, { observe: 'response' })
  }

  postVisualizaContaContabilPorFiltro(item: any): Observable<HttpResponse<any>> {

    let filtro: any = {};
        filtro.descricao = item.descricao;
        filtro.empresaIds = item.empresaIds;
    
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/contacontabil/visualizaporfiltro/', filtro, { observe: 'response' })
  }

  postVisualizaPlanoContabil(item: any, skipLoader = 'false'): Observable<HttpResponse<any>> {

    const headers = new HttpHeaders().set('X-Skip-Loader', skipLoader); 
    
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/contacontabil/visualizaporfiltro/', item, {headers, observe: 'response' } )
  }

  getContaContabil(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/contacontabil/visualiza/' + itemId, { observe: 'response' })
  }

  postContaContabil(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/contacontabil/inclui/', item, { observe: 'response' })
  };

  putContaContabil(item: any, itemId: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/contacontabil/atualiza/' + itemId, item, { observe: 'response' })
  };

  deleteContaContabil(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/contacontabil/exclui/' + itemId, { observe: 'response' })
  }

  getEmpresa(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/contaContabil/empresas/', { observe: 'response' })
  }

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
