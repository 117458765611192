<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>Template de Impressão</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" matTooltip="Salvar"
    (click)="salvar(documentoTemplateCadastroForm)"><mat-icon>save</mat-icon></button>
</div>
<mat-dialog-content>
  <br />
  <form [formGroup]="documentoTemplateCadastroForm">
    <div class="row">
      <mat-form-field class="col-md-4 col-xs-12 form-group">
        <mat-label>Tipo de Documento</mat-label>
        <mat-select formControlName="documentoTipo" (blur)="atualizarTags()">
          <mat-option [value]="4">Orçamento</mat-option>
          <mat-option [value]="8">Fatura de Venda</mat-option>
          <mat-option [value]="16">Recibo do Contas a Receber</mat-option>
          <mat-option [value]="17">Recibo do Contas a Pagar</mat-option>
          <mat-option *ngIf="saguiApp == 'SaguiEdu'" [value]="18">Histórico Escolar</mat-option>
          <mat-option [value]="21">Ficha de Funcionário</mat-option>
          <mat-option [value]="22">Ficha de Veículo</mat-option>
          <mat-option [value]=" 24">Ordem de Serviço</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-tab-group>
      <mat-tab label="Layout">
        <br>
        <div class="row" style="position: relative;">
          <angular-editor id="editor1" formControlName="html" [config]="editorConfig">
            <ng-template #customButtons let-executeCommandFn="executeCommandFn">
              <ae-toolbar-set>
                <ae-button iconClass="fa fa-tags" title="Tags Jurídico" (buttonClick)="openTagSelection()"></ae-button>
                <ae-button iconClass="fa fa-image" title="Inserir Imagem"
                  (buttonClick)="openImageInsertDialog()"></ae-button>
              </ae-toolbar-set>
            </ng-template>
          </angular-editor>
          <div class="tags-panel" *ngIf="showTags"
          style="position: absolute; top: 0; right: 0; width: 200px; height: 100%; background-color: white; border-left: 1px solid #ccc; overflow-y: auto;">
          <button mat-icon-button (click)="closeTagSelection()" style="position: absolute; top: 5px; right: 5px;">
            <mat-icon>close</mat-icon>
          </button>
          <ul style="list-style: none; margin: 0; padding: 40px 10px 10px 10px;">
            <li *ngFor="let tag of tags" (click)="insertTag(tag.placeholder)"
              style="margin-bottom: 10px; cursor: pointer; color: black;">{{ tag.name }}</li>
          </ul>
        </div>
        </div>
      </mat-tab>
      <mat-tab label="HTML">
        <br>
        <div class="row">
          <div class="col-md-12 col-xs-12">
            <textarea matInput rows="100" placeholder="Editar HTML" formControlName="html"></textarea>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</mat-dialog-content>
