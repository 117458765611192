import { NfeItem } from "./nfe-item.model";

export class Nfe {

  nfeId: number = 0;

  inbound: boolean = false;

  fornecedorId: number = 0;

  faturaId: number = 0;

  avisosInbound: string = '';

  chaveAcesso: string = '';

  statusProcessamento: number = 0;

  cNF: number = 0;

  cfop: number = 0;

  natOp: string = '';

  indPag: number = 0;

  mod: number = 0;

  serie: number = 0;

  nNF: number = 0;

  dhEmi: Date = new Date();

  dhSaiEnt: Date = new Date();

  tpNF: number = 1;

  idDest: number = 0;

  cMunFG: number = 0;

  tpImp: number = 0;

  tpEmis: number = 0;

  cDV: number = 0;

  tpAmb: number = 0;

  finNFe: number = 1;

  indFinal: number = 0;

  indPres: number = 0;

  procEmi: number = 0;

  verProc: string = '';

  dhCont: Date = new Date();

  xJust: string = '';

  nProt: string = '';

  modFrete: number = 0;

  transportadoraId: number = 0;

  qVol: number = 0;

  pesoL: number = 0;

  pesoB: number = 0;

  esp: number = 0;

  especie: string = '';

  idlocalEntrega: number = 0;

  idlocalRetirada: number = 0;

  empresaId: number = 0;

  razaoSocialCliente: string = '';

  refNFe: string = '';

  clienteId: number = 0;

  saguiClienteId: number = 0;

  possuiPedidoCompra: boolean = false;

  ufSaidaPais: string = '';

  xLocExporta: string = '';

  xLocDespacho: string = '';

  infCpl: string = '';

  infAdFisco: string = '';

  nfeItens: NfeItem[] = [];

  possuiReferencia: boolean = false;
}
