import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AppMaterialModule } from './app-material/app-material.module';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './login/login/login.component';
import { HomeComponent } from './home/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'code',
    component: HomeComponent,
  },
  {
    path: 'state',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'venda',
    loadChildren: () => import('./venda/venda.module').then(m => m.VendaModule)
  },
  {
    path: 'remessa',
    loadChildren: () => import('./remessa/remessa.module').then(m => m.RemessaModule)
  },
  {
    path: 'cliente',
    loadChildren: () => import('./cliente/cliente.module').then(m => m.ClienteModule)
  },
  {
    path: 'faturavenda',
    loadChildren: () => import('./fatura/fatura.module').then(m => m.FaturaModule)
  },
  {
    path: 'faturacompra',
    loadChildren: () => import('./fatura/fatura.module').then(m => m.FaturaModule)
  },
  {
    path: 'fornecedor',
    loadChildren: () => import('./fornecedor/fornecedor.module').then(m => m.FornecedorModule)
  },
  {
    path: 'centro',
    loadChildren: () => import('./centro/centro.module').then(m => m.CentroModule)
  },
  {
    path: 'produto',
    loadChildren: () => import('./produto/produto.module').then(m => m.ProdutoModule)
  },
  {
    path: 'produtos-e-servicos',
    loadChildren: () => import('./produtoeservico/servico.module').then(m => m.ServicoModule)
  },
  {
    path: 'assinaturas',
    loadChildren: () => import('./assinatura/assinatura.module').then(m => m.AssinaturaModule)
  },
  {
    path: 'produtousoconsumo',
    loadChildren: () => import('./produto/produto.module').then(m => m.ProdutoModule)
  },
  {
    path: 'ativoimobilizado',
    loadChildren: () => import('./produto/produto.module').then(m => m.ProdutoModule)
  },
  {
    path: 'embalagem',
    loadChildren: () => import('./produto/produto.module').then(m => m.ProdutoModule)
  },
  {
    path: 'email',
    loadChildren: () => import('./email/email.module').then(m => m.EmailModule)
  },
  {
    path: 'compra',
    loadChildren: () => import('./compra/compra.module').then(m => m.CompraModule)
  },
  {
    path: 'veiculo',
    loadChildren: () => import('./veiculo/veiculo.module').then(m => m.VeiculoModule)
  },
  {
    path: 'empresa',
    loadChildren: () => import('./empresa/empresa.module').then(m => m.EmpresaModule)
  },
  {
    path: 'rh',
    loadChildren: () => import('./rh/rh.module').then(m => m.RhModule)
  },
  {
    path: 'financeiro',
    loadChildren: () => import('./financeiro/financeiro.module').then(m => m.FinanceiroModule)
  },
  {
    path: 'juridico',
    loadChildren: () => import('./juridico/juridico.module').then(m => m.JuridicoModule)
  },
  {
    path: 'contacorrente',
    loadChildren: () => import('./conta-corrente/conta-corrente.module').then(m => m.ContaCorrenteModule)
  },
  {
    path: 'estoque',
    loadChildren: () => import('./estoque/estoque.module').then(m => m.EstoqueModule)
  },
  {
    path: 'seguranca',
    loadChildren: () => import('./seguranca/seguranca.module').then(m => m.SegurancaModule)
  },
  {
    path: 'fiscal',
    loadChildren: () => import('./fiscal/fiscal.module').then(m => m.FiscalModule)
  },
  {
    path: 'prospect',
    loadChildren: () => import('./prospect/prospect.module').then(m => m.ProspectModule)
  },
  {
    path: 'contabil',
    loadChildren: () => import('./contabil/contabil.module').then(m => m.ContabilModule)
  },
  {
    path: 'transportadora',
    loadChildren: () => import('./transportadora/transportadora.module').then(m => m.TransportadoraModule)
  },
  {
    path: 'documentotemplate',
    loadChildren: () => import('./documento/documento.module').then(m => m.DocumentoModule)
  },
  {
    path: 'configuracao',
    loadChildren: () => import('./configuracao/configuracao.module').then(m => m.ConfiguracaoModule)
  },
  {
    path: 'crm',
    loadChildren: () => import('./crm/crm.module').then(m => m.CrmModule)
  },
  {
    path: 'pedagogico',
    loadChildren: () => import('./pedagogico/pedagogico.module').then(m => m.PedagogicoModule)
  },
  {
    path: 'diario',
    loadChildren: () => import('./diario/diario.module').then(m => m.DiarioModule)
  },
  {
    path: 'transporte',
    loadChildren: () => import('./transporte/transporte.module').then(m => m.TransporteModule)
  },
  {
    path: 'auditoria',
    loadChildren: () => import('./auditoria/auditoria.module').then(m => m.AuditoriaModule)
  },
  {
    path: '**',
    redirectTo: '',
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { 
      useHash: true, 
      relativeLinkResolution: 'legacy' }),
    AppMaterialModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
