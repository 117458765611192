<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>Adicionar Parcelas Recorrentes</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()"><mat-icon>save</mat-icon></button>
</div>
<mat-dialog-content>
  <div class="md-dialog-content">
    <br />
    <div class="row">
      <form [formGroup]="contaParcelaRecorrenteForm">
        <div class="col-md-12">
          <div class="card">
            <mat-card>
              <mat-card-header>
                <mat-card-title>Parcelas Recorrentes</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="row">
                  <mat-form-field class="col-md-3 col-xs-12 form-group">
                    <mat-label>Valor Parcela</mat-label>
                    <input currencyMask matInput formControlName="valorParcela">
                  </mat-form-field>
                  <mat-form-field class="col-md-9 col-xs-12 form-group">
                    <mat-label>Descrição</mat-label>
                    <input type="text" matInput formControlName="descricao">
                  </mat-form-field>
                  <mat-form-field class="col-md-4 col-xs-12 form-group">
                    <mat-label>Quantidade de Parcelas</mat-label>
                    <input type="number" matInput formControlName="quantidade">
                  </mat-form-field>
                  <mat-form-field class="col-md-4 col-xs-12 form-group">
                    <mat-label>Data do Primeiro Pagamento</mat-label>
                    <input matInput [matDatepicker]="picker1" formControlName="dataPrimeiroVencimento">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
