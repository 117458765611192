<h1 mat-dialog-title>{{data.titulo}}</h1>
<div mat-dialog-content>
    <p style="white-space: pre-line; display: block">
      Para adicionar um arquivo como anexo, clique em 'Importar arquivo' e, em seguida, clique em 'Enviar'.<br><br>
      Se não desejar adicionar nenhum arquivo, simplesmente clique em 'Enviar'.
      </p><br>
</div>
<div>
  <button type="button" mat-raised-button (click)="fileInput.click()">Escolha o Arquivo</button>
  <span *ngIf="fileName" >  "{{fileName}}"</span>
  <input class="hidden" (change)="onFileSelected($event)" #fileInput type="file" id="file">
</div>
<div mat-dialog-actions class="md-toolbar-tools">
    <div class="posicionar-direita">
      <button color="primary" mat-raised-button (click)="EnviarEmail()">Enviar</button>
  </div>
</div>
