
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { NfeService } from '../shared/nfe.service';

export interface DialogData {
  nfeId: number;
}

@Component({
  selector: 'nfe-pedido-venda',
  templateUrl: 'nfe-pedido-venda.component.html',
})

export class NfePedidoVendaComponent {

  nfePedidoVendaForm: FormGroup;
  maximizado: boolean;
  filtro: any = {};
  buscouVendas: boolean = false;
  vendas: any = [];
  dataDisable: boolean;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NfePedidoVendaComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private nfeService: NfeService) {
  }

  ngOnInit() {

    this.dataDisable = true;

    this.filtro.dataAtual = new Date();

    this.filtro.dataFinal = new Date();

    var dataInicial = new Date().setDate(this.filtro.dataFinal.getDate() - 30);

    this.nfePedidoVendaForm = this._formBuilder.group({
      vencimento: [''],
      dataInicial: new Date(dataInicial),
      dataFinal: new Date(),
      devolucao: true
    });

    this.nfePedidoVendaForm
      .get('vencimento')
      .valueChanges
      .subscribe(
        (data) => {

          if (data == 'hoje') {
            this.dataDisable = true;
            this.nfePedidoVendaForm.get('dataInicial').setValue(new Date());
            this.nfePedidoVendaForm.get('dataFinal').setValue(new Date());
          }
          if (data == 'essemes') {
            this.dataDisable = true;
            var date = new Date();
            this.nfePedidoVendaForm.get('dataInicial').setValue(new Date(date.getFullYear(), date.getMonth(), 1));
            this.nfePedidoVendaForm.get('dataFinal').setValue(new Date(date.getFullYear(), date.getMonth() + 1, 0));
          }
          if (data == 'escolher') {
            this.dataDisable = false;
            var date = new Date();
            this.nfePedidoVendaForm.get('dataInicial').setValue(new Date(date.getFullYear(), date.getMonth(), 1));
            this.nfePedidoVendaForm.get('dataFinal').setValue(new Date(date.getFullYear(), date.getMonth() + 1, 0));
          }

        });

    this.nfePedidoVendaForm.get('vencimento').setValue('essemes');

    this.onChanges();

    this.maximizarDialog();
  }

  onChanges(): void {

  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    this.dialogRef.updateSize('80vh', '100vh');
    this.maximizado = false;
  }

  buscaVendas() {

    this.nfeService.postVisualizaVendasPorFiltro(this.nfePedidoVendaForm.value).subscribe(data => {

      this.vendas = data.body;

      this.buscouVendas = true;

    });
  };

  vincularVendas() {

    let nfePedidoVenda: any = {};

    nfePedidoVenda.nfeId = this.dialogData.nfeId;
    nfePedidoVenda.IDsDocumento = ""; 

    for (let i = 0; i < this.vendas.length; i++) {
      if (this.vendas[i].selected) {
        nfePedidoVenda.IDsDocumento = nfePedidoVenda.IDsDocumento + this.vendas[i].vendaId + ",";
      }
    }

    this.nfeService.postNfeVincularPedidoVenda(nfePedidoVenda).subscribe(data => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Sucesso",
          mensagem: "Pedidos de Vendas Vinculados com NF-e Inbound"
        }
      });

      this.fecharDialog();

    });

  }

  statusVenda(status: number) {

    switch (status) {

      case 0:
          return 'Em Criação';
      case 1:
          return 'Aguardando Compra';
      case 2:
          return 'Aguardando Produção';
      case 3:
          return "Concluída";
      case 4:
          return "Com Devolução";
      case 99:
          return "Cancelada";
      default:
          return 'Em criação';
  }
}

}
