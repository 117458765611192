import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class HomeService {

  getEmpresa(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/empresa/visualizatodas', { observe: 'response' })
  }

  getVerificarAcessoModulo(moduloId: number): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/usuario/verificaacessoaomodulo/' + moduloId, { observe: 'response' })
  }

  postVendasPorPeriodo(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/vendasporperiodo/', item, { observe: 'response' })
  }

  postComprasPorPeriodo(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/compra/comprasporperiodo/', item, { observe: 'response' })
  }

  postContaParcelaPorPeriodo(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/contaparcela/visualizaparcelasporfiltro/', item, { observe: 'response' })
  }

  postContaPorPeriodo(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/conta/contasporperiodo/', item, { observe: 'response' })
  }

  postCaixaMovimentoPorFiltro(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/caixamovimento/visualizasaldoporfiltro/', item, { observe: 'response' })
  } 

  getEstoqueMinimo(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/estoque/visualizaminimo', { observe: 'response' })
  }

  getCaixas(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/caixa/visualizaporfiltro', item, { observe: 'response' })
  }

  importarContasContabeis(dados: any[]): Observable<HttpResponse<any>> {
    const url = environment.webApiSaguiControlUrl + '/api/ContaContabil/importar';
    return this.http.post(url, dados, { observe: 'response' });
  }

  importarCategorias(dados: any[]): Observable<HttpResponse<any>> {
    const url = environment.webApiSaguiControlUrl + '/api/GrupoConta/importar';
    return this.http.post(url, dados, { observe: 'response' });
  }


  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
