'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CaixaMovimentoService } from '../shared/caixa-movimento.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

import { CaixaMovimento } from '../shared/caixa-movimento.model';
import { grupoContaService } from 'src/app/financeiro/shared/grupo-conta.service';

export interface DialogData {
  caixaMovimentoId: number;
  caixaId: number;
}

@Component({
  selector: 'caixa-movimento',
  templateUrl: 'caixa-movimento.component.html',
})

export class CaixaMovimentoComponent {

  caixaMovimentoForm: FormGroup;
  maximizado: boolean;
  caixaMovimento: any = {};
  grupoContas: any = [];

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CaixaMovimentoComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public ICaixaMovimento: DialogData,
    private caixaMovimentoService: CaixaMovimentoService,
    private grupoContaService: grupoContaService,) {
  }

  ngOnInit() {

    this.maximizado = false;

    this.caixaMovimentoForm = this._formBuilder.group(new CaixaMovimento());

    this.caixaMovimentoForm.get('caixaId').setValue(this.ICaixaMovimento.caixaId);

    if (this.ICaixaMovimento.caixaMovimentoId > 0) {

      this.buscaCaixaMovimento(this.ICaixaMovimento.caixaMovimentoId);

    }

    this.buscarGrupoContas();

    this.onChanges();

    this.restaurarDialog();
  }

  onChanges(): void {

  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {

    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  buscarGrupoContas() {

    this.grupoContaService.getgrupoContasPagarReceber().subscribe(data => {

      this.grupoContas = data.body;

    });
  }

  buscaCaixaMovimento(caixaMovimentoId: number) {

    this.caixaMovimentoService.getCaixaMovimento(caixaMovimentoId).subscribe(data => {

      this.caixaMovimentoForm.patchValue(data.body.caixaMovimento);

    }, (error: HttpErrorResponse) => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          erro: error
        }
      });
    })
  }

  salvar(): void {

    if (this.ICaixaMovimento.caixaMovimentoId == 0) {

      this.caixaMovimentoService.postCaixaMovimento(this.caixaMovimentoForm.value).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

        this.fecharDialog();

      });

    }
    else {
      this.caixaMovimentoService.putCaixaMovimento(this.caixaMovimentoForm.value, this.caixaMovimentoForm.value.caixaMovimentoId,).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

        this.fecharDialog();

      });
    }
  }
}
