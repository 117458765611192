export class Anexo {

    anexoId: number = 0;

    documentoTipo: number = 0;

    dataCriacao: Date = new Date();

    descricao: string = '';

    link: string = '';

    //Chave do Tipo de Documento Referênciado
    documentoIdReferenciado: number = 0;

    nomeArquivo: string = '';

    corpoArquivo: any;

    saguiClienteId: number = 0;
}