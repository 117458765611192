import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class ClienteFaturamentoService {

    get(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/clientefaturamento/visualiza/' + itemId, { observe: 'response' })
    }

    post(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/clientefaturamento/inclui/', item, { observe: 'response' })
    };

    put(item: any, itemId: any): Observable<HttpResponse<any>> {
        return this.http.put(
            environment.webApiSaguiControlUrl + '/api/clientefaturamento/atualiza/' + itemId, item, { observe: 'response' })
    };

    delete(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
            environment.webApiSaguiControlUrl + '/api/clientefaturamento/exclui/' + itemId, { observe: 'response' })
    }

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
