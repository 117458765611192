import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class RemessaService {

    postVisualizaRemessasPorFiltro(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/remessa/visualizatodas/', item, { observe: 'response' })
    }

    getEmpresa(): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/remessa/visualizatodasempresas', { observe: 'response' })
    }

    getTransportadoras(): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/remessa/transportadoras', { observe: 'response' })
    }

    getRemessa(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/remessa/visualiza/' + itemId, { observe: 'response' })
    }

    getClientePorId(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/remessa/visualizacliente/' + itemId, { observe: 'response' })
    }

    getClientePorNome(nome: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/remessa/visualizaclientepornome/' + nome, { observe: 'response' })
    }

    getFornecedorPorId(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/remessa/visualizafornecedor/' + itemId, { observe: 'response' })
    }

    getFornecedorPorNome(nome: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/remessa/visualizafornecedorpornome/' + nome, { observe: 'response' })
    }

    postRemessa(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/remessa/inclui/', item, { observe: 'response' })
    };

    putRemessa(item: any, itemId: any): Observable<HttpResponse<any>> {
        return this.http.put(
            environment.webApiSaguiControlUrl + '/api/remessa/atualiza/' + itemId, item, { observe: 'response' })
    };

    deleteRemessa(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
            environment.webApiSaguiControlUrl + '/api/remessa/exclui/' + itemId, { observe: 'response' })
    }

    getRemessaItem(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/remessaitem/visualiza/' + itemId, { observe: 'response' })
    }

    postIncluiRemessaItem(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/remessaitem/inclui/', item, { observe: 'response' })
    };

    putAtualizaRemessaItem(item: any, itemId: any): Observable<HttpResponse<any>> {
        return this.http.put(
            environment.webApiSaguiControlUrl + '/api/remessaitem/atualiza/' + itemId, item, { observe: 'response' })
    };

    deleteRemessaItem(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
            environment.webApiSaguiControlUrl + '/api/remessaitem/exclui/' + itemId, { observe: 'response' })
    }

    getVisualizaDocumento(parametros: any): Observable<HttpResponse<any>> {

        const params = {
            idOrigem: parametros.idOrigem,
            tipoOrigem: parametros.tipoOrigem,
            tipoDestino: parametros.tipoDestino
        }

        return this.http.get(environment.webApiSaguiControlUrl + '/api/remessa/visualizadocumentopororigemdestino', { observe: 'response', params })
    }

    postProcessaPicking(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/remessa/processapicking', item, { observe: 'response' })
    };

    postProcessaPacking(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/remessa/processapacking', item, { observe: 'response' })
    };

    postProcessaEntregue(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/remessa/processaentregue', item, { observe: 'response' })
    };

    getProdutoPorNome(parametros: any): Observable<HttpResponse<any>> {

        const params = {
            id: parametros.id,
            tipoProduto: parametros.tipoProduto,
        }

        return this.http.get(environment.webApiSaguiControlUrl + '/api/remessa/autocompleteproduto', { observe: 'response', params })
    }

    getEstoqueByProduto(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/remessaitem/visualizaestoqueporproduto/' + itemId, { observe: 'response' })
    }


    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
