<div class="mat">
  <mat-table [dataSource]="dataSource" matColumnResize class="custom-table">
    <ng-container *ngIf="enableSelection" matColumnDef="select">
      <mat-header-cell *matHeaderCellDef class="checkbox-header">
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="checkbox-cell">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? toggleRow(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container *ngFor="let column of columns" [matColumnDef]="column.def">
      <mat-header-cell *matHeaderCellDef [ngClass]="{'acoesHeader': column.def === 'acaoTable'}"> {{column.header}} </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="{'acoes': column.def === 'acaoTable'}"> 
        <ng-container *ngIf="column.def === 'valor_cash'; else defaultContent">
          {{element[column.def] | currency}}
        </ng-container>
        <ng-container *ngIf="column.def === 'acaoTable'">
          <button mat-icon-button matTooltip="Ações" [matMenuTriggerFor]="menu" *ngIf="actions">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <ng-container *ngFor="let action of actions">
              <button mat-menu-item (click)="action.method(element.id)">{{ action.name }}</button>
            </ng-container>
          </mat-menu>
          <button class="duplicar" mat-mini-fab matTooltip="Duplicar" (click)="duplicar(element)" *ngIf="onDuplicar">
            <mat-icon>copy</mat-icon>
          </button>
          <button mat-mini-fab matTooltip="Editar" (click)="editar(element)" class="editar" *ngIf="onEditar">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-mini-fab class="mat-warn warn" matTooltip="Excluir" (click)="excluir(element)" *ngIf="onExcluir">
            <mat-icon>delete</mat-icon>
          </button>
        </ng-container>
        <ng-template #defaultContent>
          <span>{{element[column.def]}}</span>
        </ng-template>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="text-uppercase"></mat-row>
  </mat-table>

  <mat-paginator *ngIf="enablePagination" [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons></mat-paginator>
</div>
