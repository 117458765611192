import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from '../app-material/app-material.module';
import { ChartsModule } from 'ng2-charts';


import { HomeComponent } from './home/home.component';

import { HomeService } from './shared/home.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AppMaterialModule,
        ChartsModule
    ],
    declarations: [
        HomeComponent
    ],
    providers: [
        HomeService
    ]
})

export class HomeModule { }
