<mat-toolbar>
  <div class="md-toolbar-tools">
      <h1 mat-dialog-title>Adicionar relação com Item</h1>
      <span flex></span>
      <div class="posicionar-direita">
          <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
      <mat-icon>fullscreen_exit</mat-icon>
    </button>
          <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
      <mat-icon>fullscreen</mat-icon>
    </button>
          <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
      <mat-icon>close</mat-icon>
    </button>
      </div>
  </div>
</mat-toolbar>
<mat-dialog-content>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()">
        <mat-icon>save</mat-icon>
    </button>
  </div>
  <br>

  <form [formGroup]="adicionaContratoDocumentoDtoForm">
    <div class="row">
      <mat-form-field class="col-md-8 col-xs-12 form-group">
          <mat-label>Identificação (ID)</mat-label>
          <input type="text" matInput formControlName="itemDestinoId">
      </mat-form-field>
      <mat-form-field class="col-md-4 col-xs-12 form-group">
          <mat-label>Tipo Relação</mat-label>
          <mat-select formControlName="tipoDocumentoDestino">
            <mat-option value="1">Venda</mat-option>
            <mat-option value="4">Orçamento</mat-option>
            <mat-option value="6">Contrato Anterior</mat-option>
          </mat-select>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
