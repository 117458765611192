import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class ContaCorrenteVendaService {

  postVisualizarPorFiltro(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/contacorrentevenda/visualizatodasporfiltro', item, { observe: 'response' })
  }

  getPorId(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/contacorrentevenda/visualiza/' + itemId, { observe: 'response' })
  }

  getPorcontaCorrenteId(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/contacorrentevenda/visualizaporcontacorrente/' + itemId, { observe: 'response' })
  }

  post(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/contacorrentevenda/inclui/', item, { observe: 'response' })
  };

  put(item: any, itemId: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/contacorrentevenda/atualiza/' + itemId, item, { observe: 'response' })
  };

  delete(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/contacorrentevenda/exclui/' + itemId, { observe: 'response' })
  }
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
