import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppMaterialModule } from '../app-material/app-material.module';
import { AnexoService } from './shared/anexo.service';
import { AnexoComponent } from './anexo/anexo.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AppMaterialModule,
    ],
    declarations: [
        AnexoComponent,
    ],
    providers: [
        AnexoService
    ]
})

export class AnexoModule { }
