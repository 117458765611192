export class ContaParcela {

  contaParcelaId: number = 0;

  tipoConta: number = 0;

  contaParcelaTipo: number = 0;

  contaContabilDebitoId: number = 0;

  contaContabilCreditoId: number = 0;

  descricao: string = '';

  valorParcela: number = 0;

  dataCriacao: Date = new Date();

  dataVencimento: Date = new Date();

  valorAteVenc: number = 0;

  tipoMulta: number = 0; //0 - %, 1 - R$

  valorMultaPosVenc: number = 0;

  valorMultaCalculada: number = 0;

  tipoJuros: number = 0; //0 - %, 1 - R$

  valorMoraDia: number = 0;

  valorMoraCalculada: number = 0;

  statusPagamento: number = 0; //0- A pagar, 1- Pago, 2- Cancelado, 3- Estornado, 4 - Retido

  dataPagamento: Date = new Date();

  valorPagamento: number = 0;

  observacaoPagamento: string = '';

  valorQueFicaraAtivo: number = 0;

  tipoPagamento: number = 0;

  contaCorrenteId: number = 0;

  caixaMovimentoId: number = 0;

  boletoId: number = 0;

  nossoNumero: number = 0;

  valorBruto: number = 0;

  valorLiquido: number = 0;

  saldo: number = 0;

  nfseId: number = 0;

  nfeId: number = 0;

  nfseNumero: number = 0;

  nfeNumero: number = 0;

  adiantamentoContaId: number = 0;

  operacaoContabilId: number = 0;

  saguiClienteId: number = 0;

  contaId: number = 0;

  selected: boolean = false;

  caixaId: number = 0;
  
  numeroParcelas: number = 1;
  
  numeroDoComprovanteDeVenda: string = '';
  
  codigoAutenticacao : string = '';
  
  contaCorrenteVendaId : number = 0
  
  contaCorrenteTerminalCartaoId: number = 0;

  contaCorrenteTerminalId: number = 0;
}
