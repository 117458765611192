'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { NfeService } from '../shared/nfe.service';

import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';
import { InfoDialogComponent } from 'src/app/core/dialogs/info-dialog/info-dialog.component';
import { NfeDanfe } from '../shared/nfe-danfe.model';

export interface DialogData {
  nfeId: number;
}

@Component({
  selector: 'nfe-danfe',
  templateUrl: 'nfe-danfe.component.html',
  styleUrls:['nfe-danfe.component.css']
})

export class NfeDanfeComponent {

  maximizado: boolean;
  vSeg: number = 0;
  vBC: number = 0;
  vICMS: number = 0;
  vBCST: number = 0;
  vST: number = 0;
  vProd: number = 0;
  vFrete: number = 0;
  vDesc: number = 0;
  vOutro: number = 0;
  vIPI: number = 0;
  vNF: number = 0;
  vII: number = 0;
  vICMSDeson: number = 0;
  vServ: number = 0;
  nfe: any = {}
  empresa: any = {};
  cliente: any = {};
  transportadora: any = {};
  chaveAcessoQuatro: string = '';
  empresaMunicipio: string = '';
  empresaUf: string = '';
  clienteMunicipio: string = '';
  clienteUf: string = '';
  transportadoraMunicipio: string = '';
  transportadoraUf: string = '';

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NfeDanfeComponent>,
    private htmlPrinterService: HtmlPrinterService,
    @Inject(MAT_DIALOG_DATA) public INfe: DialogData,
    private nfeService: NfeService) {
  }

  ngOnInit() {

    this.maximizado = true;

    this.buscaNfe(this.INfe.nfeId);

    this.maximizarDialog();
  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }


  buscaNfe(nfeId: number) {

    this.nfeService.getNfe(nfeId).subscribe(data => {

      this.InsereDadosNfe(data.body);

    }, (error: HttpErrorResponse) => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          erro: error
        }
      });
    })
  }


  InsereDadosNfe(data: any) {

    this.nfe = data.nfe;
    this.empresa = data.empresa;
    this.empresaMunicipio = data.empresa.municipio?.descricao;
    this.empresaUf = data.empresa.municipio?.uf.siglaUF;
    this.cliente = data.cliente;
    this.clienteMunicipio = data.cliente.municipio?.descricao;
    this.clienteUf = data.cliente.municipio?.uf.siglaUF;
    this.transportadora = data.transportadora;
    if (this.transportadora.transportadoraId > 0) {
      this.transportadoraMunicipio = data.transportadora.municipio?.descricao;
      this.transportadoraUf = data.transportadora.municipio?.uf.siglaUF;
    }
    this.chaveAcessoQuatro = this.chunk(this.nfe.chaveAcesso, 4);

    this.nfe.nfeItens.forEach(item => {

      this.vSeg += item.vSeg;
      this.vProd += item.vProd;
      this.vFrete += item.vFrete;
      this.vDesc += item.vDesc;
      this.vOutro += item.vOutro;

      item.nfeItemImpostos.forEach(imposto => {

        if (imposto.impostoId == 1) {
          if (item.cstIcms == "00" || item.cstIcms == "10" || item.cstIcms == "20" || item.cstIcms == "51" ||
            item.cstIcms == "70" || item.cstIcms == "90" || item.cstIcms == "900") {
            this.vBC += imposto.vBC;
            this.vICMS += imposto.vlrImposto;
          }
        }

        if (imposto.impostoId == 2) {
          if (
            item.cstIcms == "10" ||
            item.cstIcms == "30" ||
            item.cstIcms == "60" ||
            item.cstIcms == "70" ||
            item.cstIcms == "90" ||
            item.cstIcms == "201" ||
            item.cstIcms == "202" ||
            item.cstIcms == "900")
            {
              this.vBCST += imposto.vBC;
              this.vST += imposto.vlrImposto;
            }
        }

        if (imposto.impostoId == 3) {
          this.vIPI += imposto.vlrImposto;
        }
      });
    });

    this.vNF = this.vNF - this.vDesc - this.vICMSDeson + this.vST + this.vFrete + this.vSeg + this.vOutro + this.vII +
      this.vIPI + this.vServ + this.vProd;
  }

  verificarObsHom(tpAmb: number) {
    let mensagem = "";

    if (tpAmb == 2) {
      mensagem = "SEM VALOR FISCAL";
    }

    return mensagem;

  };

  validaicmsvBC(item) {

    let vBC = 0;

    if (
      item.cstIcms == "00" || 
      item.cstIcms == "10" || 
      item.cstIcms == "20" || 
      item.cstIcms == "51" || 
      item.cstIcms == "70" || 
      item.cstIcms == "90" || 
      item.cstIcms == "900") {

      item.nfeItemImpostos.forEach(imposto => {
        if (imposto.impostoId == 1) {
          vBC = imposto.vBC;
        }
      });
    }

    return vBC;
  };

  validaOrigemMercadoria(item) {

    let origemMercadoria = 0;

    item.nfeItemImpostos.forEach(imposto => {
      if (imposto.impostoId == 1) {
        origemMercadoria = imposto.orig;
      }
    });

    return origemMercadoria;
  };

  validaicmsvIcms(item) {

    let vICMS = 0;

    if (item.cstIcms == "00" || item.cstIcms == "10" || item.cstIcms == "20" || item.cstIcms == "51" || item.cstIcms == "70" || item.cstIcms == "90" || item.cstIcms == "900") {

      item.nfeItemImpostos.forEach(imposto => {
        if (imposto.impostoId == 1) {
          vICMS = imposto.vlrImposto;
        }
      });
    }

    return vICMS;
  };

  validaicmspIcms(item) {

    let pICMS = 0;

    if (item.cstIcms == "00" || item.cstIcms == "10" || item.cstIcms == "20" || item.cstIcms == "51" || item.cstIcms == "70" || item.cstIcms == "90" || item.cstIcms == "900") {

      item.nfeItemImpostos.forEach(imposto => {
        if (imposto.impostoId == 1) {
          pICMS = imposto.vlrAliquota;
        }
      });
    }

    return pICMS;
  };

  validaipivIpi(impostos) {

    let vIPI = "";

    impostos.forEach(imposto => {
      if (imposto.impostoId == 3) {
        vIPI = imposto.vlrImposto;
      }
    });

    return vIPI;
  };

  validaipipIpi(impostos) {

    let pIPI = "";

    impostos.forEach(imposto => {
      if (imposto.impostoId == 3) {
        pIPI = imposto.vlrAliquota;
      }
    });

    return pIPI;
  };


  verificarFreteConta(modFrete) {

    let frete = "";

    switch (modFrete) {
      case 0:
        frete = '0 - EMITENTE';
        break;
      case 1:
        frete = '1 – DEST/REM';
        break;
      case 2:
        frete = '2 – TERCEIROS';
        break;
      case 9:
        frete = '9 – SEM FRETE';
        break;
    }

    return frete;
  };

  chunk(str: string, n: number) {
    let ret = "";
    let i = 0;
    let len = 0;

    for (i = 0, len = str.length; i < len; i += n) {
      ret = ret + " " + (str.substr(i, n))
    }

    return ret;
  }

  imprime(): void {

    let titulo = "DANFE";

    this.htmlPrinterService.printDiv('printDanfe', titulo);
  }

  enviarPorEmail(){

    let printContents = document.getElementById('printDanfe').innerHTML;

    let texto = '<html><head><meta charset="utf-8">';
    texto += '<link rel="stylesheet" href="css/danfe/danfe.css" />';
    texto += '<link rel="stylesheet" href="css/bootstrap.min.css" />';
    texto += '</head><body>' + printContents + '</body></html>';

    let nfeDanfe: NfeDanfe = {
      html: texto,
      nfeId: this.nfe.nfeId
    };

    this.nfeService.postEnviarPorEmail(nfeDanfe).subscribe(data => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Sucesso",
          mensagem: "DANFE e XML enviados com Sucesso"
        }
      });

    });

  }

}
