export class DadoBancario {
    dadoBancarioId: number = 0;
    dadoBancarioTipoOrigem: number = 0;
    dadoBancarioTipo: number = 0;
    idOrigem: number = 0;
    bancoId: number = 0;
    agenciaNumero: string = '';
    contaCorrenteNumero: string = '';
    favorecidoNome: string = '';
    favorecidoPessoaFisica: boolean = false;
    favorecidoCpf: string = '';
    favorecidoCnpj: string = '';
    saguiClienteId: number = 0;
}