export class Nfse {

  nfseId: number = 0;

  //1 - Produção, 2 - Homologação
  tpAmb: number = 0;

  statusProcessamento: string = '';

  numeroRps: number = 0;

  serieRps: string = '';

  tipoRps: number = 0;// 1-Rps 2-Nota fiscal conjugada (Mista) 3-Cupom

  dtEmissao: Date = new Date();

  naturezaOperacao: number = 0;//1-Tributacao no municipio 2-Tributacao fora do municipio 3-Isencao 4-Imune 5-Exigibilidade suspensa por decisao judicial 6-Exigibilidade suspensa por procedimento administrativo.

  regimeEspecialTributacao: number = 0;// 1-Microempresa municipal 2-Estimativa 3-Sociedade de profissionais 4-Cooperativa 5-Microeempresario individual(MEI) 6-Microeempresario e empresa de pequeno porte(ME EPP).

  optanteSimplesNacional: number = 0;// 1-Sim 2-Nao

  incentivadorCultural: number = 0;

  status: number = 0;//1-Normal 2-Cancelado

  valorServicos: number = 0;

  valorDeducoes: number = 0;

  issRetido: number = 0; // 1-Sim 2-Nao

  baseCalculo: number = 0;

  valorPis: number = 0;

  valorCofins: number = 0;

  valorInss: number = 0;

  valorIr: number = 0;

  valorCsll: number = 0;

  valorIss: number = 0;

  outrasRetencoes: number = 0;

  aliquotaIssqn: number = 0;

  aliquotaPis: number = 0;

  aliquotaCofins: number = 0;

  aliquotaInss: number = 0;

  aliquotaIr: number = 0;

  aliquotaCsll: number = 0;

  valorLiquidoNfse: number = 0;

  valorIssRetido: number = 0;

  descontoCondicionado: number = 0;

  descontoIncondicionado: number = 0;

  listaServico: string = '';

  codigoTributacao: string = '';

  discriminacao: string = '';

  codArt: string = '';

  codObra: string = '';

  //Código do Serviço no município - São Paulo
  cServico: string = '';

  codigoMunicipioIbge: number = 0;

  localPrestacaoMunicipioId : number = 0;
  
  localPrestacaoUfId: number = 0;

  dataEmissaoNfse: Date = new Date();

  numeroNfse: number = 0;

  codigoVerificacao: string = '';

  cnae: string = '';

  empresaId: number = 0;

  clienteId: number = 0;

  saguiClienteId: number = 0;
  LinkImpressao: string = '';
}
