import { Component, OnInit } from '@angular/core';
import { HomeService } from '../shared/home.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { Total } from '../shared/total.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.css']
})
export class HomeComponent implements OnInit {

  exibeVenda = false;
  exibeCompra = false;
  exibeEstoque = false;
  exibeFinanceiro = false;
  filtro: any = {};
  filtroMesAnterior: any = {};
  total: Total = new Total();
  totalMesAnterior: Total = new Total();
  homeForm: FormGroup;
  porcentagemVendas: number = 0;
  porcentagemVendasFaturamento: number = 0;
  porcentagemCompras: number = 0;
  porcentagemComprasFaturamento: number = 0;
  parcelasPagar: any = [];
  parcelasReceber: any = [];
  empresas: any = [];
  estoques: any = [];
  caixas: any = [];
  caixaMovimentos: any = [];
  filtroReceber: any = {};
  filtroPagar: any = {};

  faturamentoMesChartData: ChartDataSets[] = [];
  faturamentoMesChartLabels: Label[] = ['Recebido', 'Pago', 'Líquido'];
  faturamentoMesChartOptions: ChartOptions = {
    responsive: true
  };
  faturamentoMesChartLegend: boolean = false;
  faturamentoMesChartType: ChartType = 'horizontalBar';

  despesasReceitasChartData: ChartDataSets[] = [];
  despesasReceitasChartLabels: Label[] = ['Despesas', 'Receitas'];
  despesasReceitasChartOptions: ChartOptions = {
    responsive: true
  };
  despesasReceitasChartLegend: boolean = true;
  despesasReceitasChartType: ChartType = 'pie';

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private homeService: HomeService,
    private _formBuilder: FormBuilder,
    private router: Router) { }

  ngOnInit(): void {

    let urlApp = window.location.hostname;

      
      if (urlApp.indexOf('saguiedu') > 0) {
        this.router.navigate(['/pedagogico/matriculometro']);
      }



    this.homeForm = this._formBuilder.group({
      empresaId: 0,
    });

    this.filtro.empresaId = 0;

    this.onChanges();

    this.carregarEmpresas();
  }

  onChanges(): void {

    this.homeForm
      .get('empresaId')
      .valueChanges
      .subscribe(data => {
        if (data > 0) {

          this.filtro.empresaId = data;
          this.filtroMesAnterior.empresaId = data;

          this.carregarPermissoes()
        };
      });
  };

  carregarEmpresas() {

    this.homeService.getEmpresa().subscribe(data => {

      this.empresas = data.body;

      this.homeForm.get('empresaId').setValue(this.empresas[0].empresaId);

    });

  }

  carregarVendas() {

    this.homeService.getVerificarAcessoModulo(1).subscribe(data => {

      this.exibeVenda = true;

      this.homeService.postVendasPorPeriodo(this.filtro).subscribe(data => {

        let vendas = data.body;

        vendas.forEach(venda => {
          this.total.vendas = this.total.vendas + venda.total;
          this.total.vendasFaturamento = this.total.vendasFaturamento + venda.faturamento;
        });

        this.homeService.postVendasPorPeriodo(this.filtroMesAnterior).subscribe(data => {

          let vendasMesAnterior = data.body;

          vendasMesAnterior.forEach(vendaMesAnterior => {
            this.totalMesAnterior.vendas = this.totalMesAnterior.vendas + vendaMesAnterior.total;
            this.totalMesAnterior.vendasFaturamento = this.totalMesAnterior.vendasFaturamento + vendaMesAnterior.faturamento;
          });

          if (this.totalMesAnterior.vendas > 0) {
            this.porcentagemVendas = ((this.total.vendas * 100) / this.totalMesAnterior.vendas) - 100;
          } else {
            this.porcentagemVendas = 0;
          }
          if (this.totalMesAnterior.vendasFaturamento > 0) {
            this.porcentagemVendasFaturamento = ((this.total.vendasFaturamento * 100) / this.totalMesAnterior.vendasFaturamento) - 100;
          } else {
            this.porcentagemVendasFaturamento = 0;
          }

        });
      })
    });
  }

  carregarCompras() {

    this.homeService.getVerificarAcessoModulo(2).subscribe(data => {

      this.exibeCompra = true;

      this.homeService.postComprasPorPeriodo(this.filtro).subscribe(data => {

        let compras = data.body;

        compras.forEach(compra => {
          this.total.compras = this.total.compras + compra.total;
          this.total.comprasFaturamento = this.total.comprasFaturamento + compra.faturamento;
        });

        this.homeService.postComprasPorPeriodo(this.filtroMesAnterior).subscribe(data => {

          let comprasMesAnterior = data.body;

          comprasMesAnterior.forEach(compraMesAnterior => {
            this.totalMesAnterior.compras = this.totalMesAnterior.compras + compraMesAnterior.total;
            this.totalMesAnterior.comprasFaturamento = this.totalMesAnterior.comprasFaturamento + compraMesAnterior.faturamento;
          });

          if (this.totalMesAnterior.compras > 0) {
            this.porcentagemCompras = ((this.total.compras * 100) / this.totalMesAnterior.compras) - 100;
          } else {
            this.porcentagemCompras = 0;
          }
          if (this.totalMesAnterior.comprasFaturamento > 0) {
            this.porcentagemComprasFaturamento = ((this.total.comprasFaturamento * 100) / this.totalMesAnterior.comprasFaturamento) - 100;
          } else {
            this.porcentagemComprasFaturamento = 0;
          }

        });
      });

    });
  }

  carregarEstoques() {

    this.homeService.getVerificarAcessoModulo(3).subscribe(data => {

      this.exibeEstoque = true;

      this.homeService.getEstoqueMinimo().subscribe(data => {

        this.estoques = data.body;

      });

    });
  }

  carregarPermissoes() {

    let date = new Date();
    this.filtro.dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);
    this.filtro.dataFinal = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.filtroMesAnterior.dataInicial = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    this.filtroMesAnterior.dataFinal = new Date(date.getFullYear(), date.getMonth(), 0);

    this.total.vendas = 0;
    this.total.vendasFaturamento = 0;
    this.total.compras = 0;
    this.total.comprasFaturamento = 0;
    this.total.receberPrevisto = 0;
    this.total.recebido = 0;
    this.total.aReceber = 0;
    this.total.pagarPrevisto = 0;
    this.total.pago = 0;
    this.total.margemRealizada = 0;
    this.total.margemPrevista = 0;
    this.totalMesAnterior.vendas = 0;
    this.totalMesAnterior.vendasFaturamento = 0;
    this.totalMesAnterior.compras = 0;
    this.totalMesAnterior.comprasFaturamento = 0;

    this.carregarVendas();

    this.carregarCompras();

    this.carregarEstoques();

    this.carregarFinanceiro();

  }

  buscarContaParcelasReceber(filtro) {

    filtro.tpConta = 0;

    this.homeService.postContaParcelaPorPeriodo(filtro).subscribe(data => {

      this.parcelasReceber = data.body;

    });
  }

  buscarContaParcelasPagar(filtro) {

    filtro.tpConta = 1;

    this.homeService.postContaParcelaPorPeriodo(filtro).subscribe(data => {

      this.parcelasPagar = data.body;

    });
  }

  carregarFinanceiro() {

    this.homeService.getVerificarAcessoModulo(4).subscribe(data => {

      this.exibeFinanceiro = true;

      //Contas a Receber
      this.filtroReceber.aReceber = true;
      this.filtroReceber.dataInicial = new Date();
      this.filtroReceber.dataFinal = new Date();
      this.filtroReceber.empresaId = this.homeForm.value.empresaId;
      this.buscarContaParcelasReceber(this.filtroReceber);

      //Contas a Pagar
      this.filtroPagar.aReceber = true;
      this.filtroPagar.dataInicial = new Date();
      this.filtroPagar.dataFinal = new Date();
      this.filtroPagar.empresaId = this.homeForm.value.empresaId;
      this.buscarContaParcelasPagar(this.filtroPagar);

      //Caixas
      this.homeService.getCaixas(this.filtro).subscribe(data => {
        this.caixas = data.body;
      });

      this.homeService.postContaPorPeriodo(this.filtro).subscribe(data => {

        let parcelasAtual = data.body;

        parcelasAtual.forEach(parcela => {

          this.total.aReceber += parcela.aReceber;
          this.total.receberPrevisto += this.total.receberPrevisto + parcela.receberPrevisto;

          this.total.pagarPrevisto += parcela.pagarPrevisto;

        });

        if (this.total.receberPrevisto == 0) {
          this.total.margemPrevista = (this.total.receberPrevisto - this.total.pagarPrevisto) * 100;

        } else {

          this.total.margemPrevista = ((this.total.receberPrevisto - this.total.pagarPrevisto) * 100) / this.total.receberPrevisto;
        }

        this.homeService.postCaixaMovimentoPorFiltro(this.filtro).subscribe(data => {

          this.caixaMovimentos = data.body;

          this.caixaMovimentos.forEach((parcela: { recebido: number; pago: number; }) => {

            this.total.recebido = this.total.recebido + parcela.recebido;
            this.total.pago = this.total.pago + parcela.pago;

          });

          if (this.total.recebido == 0) {
            this.total.margemRealizada = (this.total.recebido - this.total.pago) * 100;

          } else {

            this.total.margemRealizada = ((this.total.recebido - this.total.pago) * 100) / this.total.recebido;
          }

          this.faturamentoMesChartData = [{
            backgroundColor: ['rgb(0, 204, 170)', 'rgb(221, 51, 51)', '#edb32e'],
            borderColor: 'rgba(255, 99, 132,0)',
            data: [this.total.recebido, this.total.pago, this.total.recebido - this.total.pago]
          }];

          let receita: number;
          let despesa: number;
          if (this.total.recebido != 0) {
            receita = 100 - (this.total.pago * 100 / this.total.recebido);
            receita = receita >= 0 ? receita : 0;
            despesa = (this.total.pago * 100 / this.total.recebido);
          } else {

            receita = 0;
            despesa = 100;
          }

          let despesaDados: number = +despesa.toFixed(2);
          let receitaDados: number = +receita.toFixed(2);

          this.despesasReceitasChartData = [{
            label: 'Faturamento Total',
            backgroundColor: ['rgb(221, 51, 51)', 'rgb(0, 204, 170)'],
            borderColor: 'rgba(255, 99, 132,0)',
            data: [despesaDados, receitaDados]
            //data: [despesa.toFixed(2), receita.toFixed(2)]
          }];
        });
      });
    });
  }
}

