'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DadoBancarioService } from '../shared/dado-bancario.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

import { DadoBancario } from '../shared/dado-bancario.model';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { DadoBancarioChavePix } from '../shared/dado-bancario-chave-pix.model';
import { ConfirmDialogComponent } from 'src/app/core/dialogs/confirm-dialog/confirm-dialog.component';

export interface DialogData {
  dadoBancarioId: number;
  idOrigem: number;
  dadoBancarioTipoOrigem: number;
  saguiClienteId: number;
}

@Component({
  selector: 'dado-bancario-cadastro',
  templateUrl: 'dado-bancario-cadastro.component.html',
})

export class DadoBancarioCadastroComponent {

  dadoBancarioCadastroForm: FormGroup;
  dadoBancarioChavePixForm: FormGroup;
  maximizado: boolean;
  bancos: any = [];
  dadoBancarioChavesPix: any = [];

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DadoBancarioCadastroComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public IDadoBancario: DialogData,
    private dadoBancarioService: DadoBancarioService,
    private utilsService: UtilsService) {
  }

  ngOnInit() {

    this.maximizado = false;

    this.dadoBancarioCadastroForm = this._formBuilder.group(new DadoBancario());
    this.dadoBancarioChavePixForm = this._formBuilder.group(new DadoBancarioChavePix());

    if (this.IDadoBancario.dadoBancarioId > 0) {

      this.buscarDadoBancario(this.IDadoBancario.dadoBancarioId);

    } else {

      this.dadoBancarioCadastroForm.get('idOrigem').setValue(this.IDadoBancario.idOrigem);
      this.dadoBancarioCadastroForm.get('dadoBancarioTipoOrigem').setValue(this.IDadoBancario.dadoBancarioTipoOrigem);
      this.dadoBancarioCadastroForm.get('saguiClienteId').setValue(this.IDadoBancario.saguiClienteId);
    }

    this.buscarBancos();

    this.onChanges();

    this.restaurarDialog();
  }

  onChanges(): void {
  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  buscarBancos() {

    this.dadoBancarioService.getBancos().subscribe(data => {

      this.bancos = data.body;

    });
  }

  buscarDadoBancario(dadoBancarioId: number) {

    this.dadoBancarioService.getDadoBancario(dadoBancarioId).subscribe(data => {

      this.inserirDadosDadoBancario(data.body);

    });
  }

  inserirDadosDadoBancario(data: any) {

    this.dadoBancarioCadastroForm.patchValue(data);

    this.buscarDadoBancarioChavePix();

  }

  salvar(): void {

    this.tratarCampos();

    let dadoBancarioForm = this.dadoBancarioCadastroForm;

    if (dadoBancarioForm.value.dadoBancarioId == 0) {

      this.dadoBancarioService.postDadoBancario(dadoBancarioForm.value).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

        this.buscarDadoBancario(data.body.dadoBancarioId);

      });

    }
    else {
      this.dadoBancarioService.putDadoBancario(dadoBancarioForm.value, dadoBancarioForm.value.dadoBancarioId).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

        this.buscarDadoBancario(dadoBancarioForm.value.dadoBancarioId);

      });
    }
  };

  tratarCampos() {

    let cnpj = this.utilsService.removeCaracteresEspeciais(this.dadoBancarioCadastroForm.get('favorecidoCnpj').value);
    cnpj = this.utilsService.removerEspacosComecoFim(cnpj);
    this.dadoBancarioCadastroForm.get('favorecidoCnpj').setValue(cnpj);

    let cpf = this.utilsService.removeCaracteresEspeciais(this.dadoBancarioCadastroForm.get('favorecidoCpf').value);
    cpf = this.utilsService.removerEspacosComecoFim(cpf);
    this.dadoBancarioCadastroForm.get('favorecidoCpf').setValue(cpf);

  }

  incluirChavePix() {

    let dadoBancarioChavePix: DadoBancarioChavePix = this.dadoBancarioChavePixForm.value;

    dadoBancarioChavePix.dadoBancarioId = this.dadoBancarioCadastroForm.get('dadoBancarioId').value;

    this.dadoBancarioService.postDadoBancarioChavePix(dadoBancarioChavePix).subscribe(data => {

      this.buscarDadoBancarioChavePix();

    });
  }

  buscarDadoBancarioChavePix() {

    this.dadoBancarioService.getDadoBancarioChavePix(this.dadoBancarioCadastroForm.get('dadoBancarioId').value).subscribe(data => {

      this.dadoBancarioChavesPix = data.body;

    });
  }

  retornarTipoChavePix(chavePixTipo: number) {

    switch (chavePixTipo) {
      case 0:
        return 'Cpf';
      case 1:
        return 'Cnpj';
      case 2:
        return 'Celular';
      case 3:
        return 'E-mail';
      case 4:
        return 'Chave Aleatória';
    }
  }

  confirmarExclusaoChavePix(dadoBancarioChavePixId: number) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja realmente excluir esse contato?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.dadoBancarioService.deleteDadoBancarioChavePix(dadoBancarioChavePixId).subscribe(data => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Sucesso",
              mensagem: "Registro excluído com Sucesso"
            }
          });

          this.buscarDadoBancarioChavePix();

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  }
}
