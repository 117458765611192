'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CfopService } from '../../cfop/shared/cfop.service';
import { Cfop } from '../../cfop/shared/cfop.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

export interface DialogData {
  vendaTipo: number,
  empresaId: number,
}

@Component({
  selector: 'venda-devolucao',
  templateUrl: 'venda-devolucao.component.html',
})

export class VendaDevolucaoComponent {

  cfops: Cfop[];
  cfop: any = {};

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<VendaDevolucaoComponent>,
    @Inject(MAT_DIALOG_DATA) public IVenda: DialogData,
    private cfopService: CfopService) { }

  ngOnInit() {
    this.buscaCfop();
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  salvar(cfop): void {
    this.dialogRef.close(cfop);
  }

  buscaCfop() {
    const parametros: any = {};

    parametros.empresaId = this.IVenda.empresaId;
    parametros.direcao = 2;

    this.cfopService.getCfops(parametros).subscribe(data => {
      this.cfops = data.body;

    });
  }

}
