<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>Carta de Correção</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()" *ngIf="permiteSalvar">
        <mat-icon>save</mat-icon>
    </button>
    <button mat-raised-button (click)="imprimirEvento()" matTooltip="Imprimir">
        <mat-icon>print</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="md-dialog-content">
        <br />
        <div class="row">
            <form [formGroup]="nfeCartaCorrecaoForm">
                <div class="col-md-12">
                    <br>
                    <div class="row">
                        <mat-form-field class="form-group col-md-12">
                            <mat-label>Texto da Carta de Correção</mat-label>
                            <textarea matInput rows="5" formControlName="xCorrecao"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
    </div>
</mat-dialog-content>
<div style="display:flex; justify-content: center; background-color: #c3c3c3;display: none">
    <div class="danfe" id="printVenda">
        <div class="page" *ngFor="let nfeEvento of nfeEventos">
            <div class="bloco">
                <table id="dados-nfe" class="tabelaDanfe">
                    <thead>
                        <tr>
                            <td rowspan="4" class="campo" style="text-align:center">
                                <div class="conteudoPedido negrito">
                                    <h3>Carta de Correção </h3>
                                    <h5>
                                        ESTE RELATÓRIO NÃO TEM VALOR FISCAL E É UMA SIMPLES
                                    </h5>
                                    <h5>
                                        REPRESENTAÇÃO DA CARTA DE CORREÇÃO ELETRÔNICA INDICADA ABAIXO
                                    </h5>
                                </div>
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="bloco">
                <div class="conteudoPedido negrito fonte-7-pontos">NOTA FISCAL ELETRÔNICA</div>
                <table>
                    <tr>
                        <td colspan="3" class="campo razao-social">
                            <div class="conteudoPedido fonte-5-pontos">SÉRIE</div>
                            <div class="conteudoPedido">
                                {{nfeEvento.nfe.serie}}
                            </div>
                        </td>
                        <td class="campo cnpj-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">NÚMERO DA NF-E</div>
                            <div class="conteudoPedido">
                                {{nfeEvento.nfe.nNF}}
                            </div>
                        </td>
                        <td class="campo razao-social">
                            <div class="conteudoPedido fonte-5-pontos">CHAVE DE ACESSO</div>
                            <div class="conteudoPedido">
                                {{nfeEvento.nfe.chaveAcesso}}
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="bloco">
                <div class="conteudoPedido negrito fonte-7-pontos">CARTA DE CORREÇÃO</div>
                <table>
                    <tr>
                        <td colspan="3" class="campo razao-social">
                            <div class="conteudoPedido fonte-5-pontos">EVENTO</div>
                            <div class="conteudoPedido">
                                {{nfeEvento.tpEvento}}
                            </div>
                        </td>
                        <td class="campo cnpj-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">PROTOCOLO</div>
                            <div class="conteudoPedido">
                                {{nfeEvento.nProt}}
                            </div>
                        </td>
                        <td class="campo cnpj-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">SEQUÊNCIA</div>
                            <div class="conteudoPedido">
                                {{nfeEvento.nSeqEvento}}
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="bloco">
                <div class="conteudoPedido negrito fonte-7-pontos">EMITENTE</div>
                <table>
                    <tr>
                        <td colspan="3" class="campo razao-social">
                            <div class="conteudoPedido fonte-5-pontos">NOME / RAZÃO SOCIAL</div>
                            <div class="conteudoPedido">
                                {{empresa.razaoSocial}}
                            </div>
                        </td>
                        <td class="campo cnpj-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">CNPJ / CPF</div>
                            <div class="conteudoPedido">
                                {{empresa.cnpj}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="campo endereco-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">ENDEREÇO</div>
                            <div class="conteudoPedido">
                                {{empresa.logradouro}} &nbsp;
                                {{empresa.numero}}
                            </div>
                        </td>
                        <td class="campo bairro-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">BAIRRO / DISTRITO</div>
                            <div class="conteudoPedido">
                                {{empresa.bairro}}
                            </div>
                        </td>
                        <td class="campo cep-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">CEP</div>
                            <div class="conteudoPedido">
                                {{empresa.cep}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="campo municipio-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">MUNICÍPIO</div>
                            <div class="conteudoPedido">
                                {{empresa.municipio?.descricao}}
                            </div>
                        </td>
                        <td class="campo fone-fax-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">TELEFONE</div>
                            <div class="conteudoPedido">
                                {{empresa.telefone}}
                            </div>
                        </td>
                        <td class="campo uf-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">UF</div>
                            <div class="conteudoPedido">
                                {{empresa.municipio?.uf.siglaUF}}
                            </div>
                        </td>
                        <td class="campo inscricao-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">INSCRIÇÃO ESTADUAL</div>
                            <div class="conteudoPedido">
                                {{empresa.inscEstadual}}
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="bloco">
                <div class="conteudoPedido negrito fonte-7-pontos">DESTINATÁRIO/REMETENTE</div>
                <table>
                    <tr>
                        <td colspan="3" class="campo razao-social">
                            <div class="conteudoPedido fonte-5-pontos">NOME / RAZÃO SOCIAL</div>
                            <div class="conteudoPedido">
                                {{cliente.razaoSocial}}
                            </div>
                        </td>
                        <td class="campo cnpj-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">CNPJ / CPF</div>
                            <div class="conteudoPedido">
                                {{cliente.cnpj != undefined ? cliente.cnpj : cliente.cpf}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="campo endereco-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">ENDEREÇO</div>
                            <div class="conteudoPedido">
                                {{cliente.logradouro}} &nbsp;
                                {{cliente.numero}}
                            </div>
                        </td>
                        <td class="campo bairro-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">BAIRRO / DISTRITO</div>
                            <div class="conteudoPedido">
                                {{cliente.bairro}}
                            </div>
                        </td>
                        <td class="campo cep-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">CEP</div>
                            <div class="conteudoPedido">
                                {{cliente.cep}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="campo municipio-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">MUNICÍPIO</div>
                            <div class="conteudoPedido">
                                {{cliente.municipio?.descricao}}
                            </div>
                        </td>
                        <td class="campo fone-fax-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">TELEFONE</div>
                            <div class="conteudoPedido">
                                {{cliente.telefone}}
                            </div>
                        </td>
                        <td class="campo uf-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">UF</div>
                            <div class="conteudoPedido">
                                {{cliente.municipio?.uf.siglaUF}}
                            </div>
                        </td>
                        <td class="campo inscricao-destinatario">
                            <div class="conteudoPedido fonte-5-pontos">INSCRIÇÃO ESTADUAL</div>
                            <div class="conteudoPedido">
                                {{cliente.inscEstadual}}
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="bloco">
                <table style="width:100%">
                    <tr>
                        <td class="campo">
                            <div class="conteudoPedido negrito">CORREÇÃO</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="campo dados-adicionais">
                            <div class="conteudoPedido">
                                {{nfeEvento.xCorrecao}}
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="bloco">
                <table style="width:100%">
                    <tr>
                        <td class="campo">
                            <div class="conteudoPedido negrito">CONDIÇÕES DE USO</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="campo dados-adicionais">
                            <div class="conteudoPedido">
                                <h5>
                                    A Carta de Correção é disciplinada pelo § 1º-A do
                                    art. 7º do Convênio S/N, de 15 de dezembro de 1970
                                    e pode ser utilizada para regularização de erro
                                    ocorrido na emissão de documento fiscal, desde que
                                    o erro não esteja relacionado com: I - as variáveis
                                    que determinam o valor do imposto tais como: base
                                    de cálculo, alíquota, diferença de preço, quantidade,
                                    valor da operação ou da prestação; II - a correção de
                                    dados cadastrais que implique mudança do
                                    remetente ou do destinatário; III - a data de emissão
                                    ou de saída.
                                </h5>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
