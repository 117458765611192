
<mat-accordion>
  <mat-expansion-panel  [expanded]="panelOpenState" (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title *ngIf="!panelOpenState">
        ➕ Clique para adicionar novos itens
      </mat-panel-title>
      <mat-panel-title *ngIf="panelOpenState">
        Adicione novos itens 👇
       </mat-panel-title>
      <mat-panel-description>

      </mat-panel-description>

    </mat-expansion-panel-header>
  
             
<form [formGroup]="itemForm">
  <div class="row">
    <mat-form-field class="form-group col-md-2">
      <mat-label>Quantidade</mat-label>
      <input type="number" matInput formControlName="quantidade" #qtdComercial appRequiredField>
    </mat-form-field>
    <mat-form-field class="col-md-5 col-xs-12 form-group">
      <mat-label>Serviço/Produto que será adicionado</mat-label>
      <input type="text" matInput formControlName="item" [matAutocomplete]="item" appRequiredField>
      <mat-autocomplete #item="matAutocomplete" [displayWith]="displayItemFn" (optionSelected)="onItemSelected($event.option.value)">
        <mat-option *ngFor="let produto of filteredProdutos" [value]="produto">
          {{produto.codProd}} - {{produto.descricao}}
        </mat-option>
        <mat-option *ngIf="(!(filteredProdutos?.length>0)&&(itemForm?.get('item').value?.length >= 3))" class="nao-encontrado">
          ❌ Produto/Serviço não localizado nas filiais selecionadas.
        </mat-option>
      </mat-autocomplete>
      <mat-hint class="custom-hint">🔎 Digite 3 letras que existam sequencialmente no Nome ou no Código do Serviço/Produto desejado.</mat-hint>
    </mat-form-field>

    <div *ngIf="itemForm.get('item').value?.produtoId>0">
      <mat-form-field class="form-group col-md-4">
        <mat-label>Valor Unitário</mat-label>
        <span matPrefix>R$ &nbsp;</span>
        <input type="number" step="0.01" matInput formControlName="valorUnitario" appRequiredField>
      </mat-form-field>
    </div>
</div>
<div class="row">
  <div *ngIf="itemForm.get('item').value?.produtoId>0">
      <mat-form-field class="form-group col-md-3">
        <mat-label>Desconto no Valor Unitário</mat-label>
        <span matPrefix>R$ &nbsp;</span>
        <input type="number" step="0.01" matInput formControlName="descontoUnitario" appRequiredField>
      </mat-form-field>
      <mat-form-field class="form-group col-md-3">
        <mat-label>Valor Total Bruto</mat-label>
        <span matPrefix>R$ &nbsp;</span>
        <input type="number" matInput step="0.01" formControlName="valorTotalBruto" appRequiredField>
      </mat-form-field>

      <mat-form-field class="form-group col-md-4">
        <mat-label>Valor Total Líquido</mat-label>
        <span matPrefix>R$ &nbsp;</span>
        <input type="number"  matInput step="0.01" formControlName="valorTotalLiquido"appRequiredField>
      </mat-form-field>
    </div>
    <div class="col-md-2">
      <button mat-mini-fab matTooltip="Adicionar Item" (click)="addItem()" [disabled]="!(itemForm.get('item').value?.produtoId>0)">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</form>
  </mat-expansion-panel>
</mat-accordion>
<hr>

  <div *ngIf="itensTabela.data?.length > 0">
    <div class="row">
      <h3>Itens adicionados:</h3>
      <app-table fixed
        [dataSource]="itensTabela"   
        [columns]="columns"
        [enablePagination]="false"
        [onExcluir]="onExcluir.bind(this)"
        [itemIdProperty]="'produtoId'"  
        class="servico-table">

      </app-table>
    </div>
  </div>
  
