export class ItemParcela {

    parcelaId: number = 0;

    dataVencimento:Date = new Date();

    valorLiquido: number=0;

    tipoPagamento:  TipoPagamento = 3 ;
     
    saguiClienteId: number = 0;
}

export enum TipoPagamento {

    Dinheiro = 0,
    Debito = 1,
    Credito = 2,
    Boleto = 3,
    Transferencia = 4,
    Cheque = 5,
    ValeRefeicao = 6,
    Banco = 7,
    PrestacaoConta = 8,
    Devolucao = 9,
    TransferenciaEntreCaixas = 10,
    Pix = 11
}
