'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { EstoqueMovimentoService } from '../shared/estoque-movimento.service';
import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { EstoqueMovimento } from '../shared/estoque-movimento.model';

export interface DialogData {
  estoqueId: number;
  estoqueMovimentoId: number;
}

@Component({
  selector: 'estoque-movimento',
  templateUrl: 'estoque-movimento.component.html',
})

export class EstoqueMovimentoComponent {

  estoqueMovimentoForm: FormGroup;
  maximizado: boolean;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EstoqueMovimentoComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public IEstoqueMovimento: DialogData,
    private estoqueMovimentoService: EstoqueMovimentoService) {
  }

  ngOnInit() {

    this.maximizado = false;

    this.estoqueMovimentoForm = this._formBuilder.group(new EstoqueMovimento());

    if (this.IEstoqueMovimento.estoqueMovimentoId > 0) {

      this.buscarEstoqueMovimento(this.IEstoqueMovimento.estoqueMovimentoId);

    } else {

      this.estoqueMovimentoForm.get('estoqueId').setValue(this.IEstoqueMovimento.estoqueId);

    }    

    this.restaurarDialog();
  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {

    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }


  buscarEstoqueMovimento(estoqueMovimentoId: number) {

    this.estoqueMovimentoService.getEstoqueMovimento(estoqueMovimentoId).subscribe(data => {

      this.estoqueMovimentoForm.patchValue(data.body);

    }, (error: HttpErrorResponse) => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          erro: error
        }
      });
    })
  }

  salvar(): void {

    if (this.estoqueMovimentoForm.value.estoqueMovimentoId == 0) {

      this.estoqueMovimentoService.postEstoqueMovimento(this.estoqueMovimentoForm.value).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

        this.fecharDialog();

      });

    }
    else {
      this.estoqueMovimentoService.putEstoqueMovimento(this.estoqueMovimentoForm.value, this.estoqueMovimentoForm.value.estoqueMovimentoId).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

        this.fecharDialog();

      });
    }
  };
}
