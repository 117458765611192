import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class AnexoService {

    postAnexo(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/anexo/inclui/', item, { observe: 'response' })
    };

    getAnexoBaixar(itemId: any): Observable<Blob> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/anexo/baixar/' + itemId,  { responseType: 'blob' })
    }

    postVisualizaAnexoPorFiltro(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/anexo/visualizaporfiltro/', item, { observe: 'response' })
    }

    delete(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
          environment.webApiSaguiControlUrl + '/api/anexo/exclui/' + itemId, { observe: 'response' })
      }

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
