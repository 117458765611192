<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>Logs</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<mat-dialog-content>
    <div class="md-dialog-content">
        <br>
        <div class="row">
            <div class="table-responsive col-md-12">
                <table class="table table-striped" cellspacing="0" cellpadding="0">
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Usuário</th>
                            <th>Detalhe</th>
                            <th>Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let log of logs">
                            <td>{{log.data | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                            <td>{{log.usuario}}</td>
                            <td>
                                <button mat-mini-fab (click)="modalStatusDetalhe(log.textoLog)" matTooltip="Informações">
                                    <mat-icon>info</mat-icon>
                                </button>
                            </td>
                            <td>{{retornaTipoLog(log.logTipo)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</mat-dialog-content>