<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>Embalagem {{embalagemCadastroForm.value.embalagemId}}</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
        </div>
    </div>
</mat-toolbar>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar(embalagemCadastroForm)">
        <mat-icon>save</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="md-dialog-content">
        <br />
        <div class="row">
            <form [formGroup]="embalagemCadastroForm">
                <div class="col-md-12">
                    <div class="card">
                        <div class="row">
                            <mat-form-field class="col-md-6 col-xs-12 form-group">
                                <mat-label>Emitente</mat-label>
                                <mat-select formControlName="empresaId">
                                    <mat-option *ngFor="let empresa of empresas" [value]="empresa.empresaId">
                                        {{empresa.nomeFantasia}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field>
                                    <mat-label>Data da Embalagem</mat-label>
                                    <input matInput [matDatepicker]="picker1" formControlName="dataEmbalagem">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Itens</h2>
                        <ul class="nav navbar-right panel_toolbox"></ul>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content">
                        <div id="itens">
                            <div class="row">
                                <div class="col-md-12">
                                    <!--<button mat-raised-button color="primary" (click)="ModalItem(0)" [disabled]="embalagem.embalagemId == 0 ? false : true"><mat-icon>add</mat-icon> Item</button>-->
                                </div>
                            </div>
                            <div id="list" class="row">
                                <div class="table-responsive col-md-12">
                                    <table class="table table-striped" cellspacing="0" cellpadding="0">
                                        <thead>
                                            <tr>
                                                <th>Cod.</th>
                                                <th>Produto</th>
                                                <th>Quantidade</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let embalagemitem of embalagemItens">
                                                <td>{{embalagemitem.codProd}}</td>
                                                <td>{{embalagemitem.descricao}}</td>
                                                <td>{{embalagemitem.quantidade}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
