export class ContratoSignatario {

    contratoSignatarioId: number = 0;

    email: string = '';

    acao: number = 0;

    nome: string = '';

    dataNascimento: Date = new Date();

    cpf: string = '';

    rg: string = '';

    assinado: boolean = false;

    estrangeiro: boolean = false;

    certificadoIcpBr: boolean = false;

    assinarPresencialmente: boolean = false;

    necessarioDocumentoComFoto: boolean = false;

    necessarioDocumentoComSelfie: boolean = false;

    signatariod4SignId: string = '';

    contratoId: number = 0;
}


