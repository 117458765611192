<div class="row" >
<mat-accordion>
  <mat-expansion-panel  [expanded]="panelOpenState" (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
       Adicionar novos signatários
      </mat-panel-title>
      <mat-panel-description>
       
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="row">
      
    <form [formGroup]="signatarioForm">
      <div class="row">
        <div class="form-group col-md-12">
          <mat-slide-toggle formControlName="estrangeiro">Signatário Estrangeiro</mat-slide-toggle>
     </div>
        <mat-form-field class="form-group col-md-5">
          <mat-label>Nome Completo</mat-label><input matInput formControlName="nome">
      </mat-form-field>
      <mat-form-field class="form-group col-md-3">
        <mat-label>Cpf</mat-label>
        <input matInput formControlName="cpf">
        <mat-error *ngIf="signatarioForm.get('cpf').hasError('cpfInvalido')">
          CPF inválido</mat-error>
        
    </mat-form-field>
    <mat-form-field class="form-group col-md-3">
        <mat-label>Rg</mat-label><input matInput  formControlName="rg">
    </mat-form-field>
   
    <mat-form-field class="form-group col-md-4">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email">
      <mat-error *ngIf="signatarioForm.get('email').hasError('emailInvalido')">
        Email inválido</mat-error>
  </mat-form-field>
        <mat-form-field class="col-md-3 col-xs-12 form-group">
            <mat-label>Assinar como:</mat-label>
            <mat-select formControlName="acao" >
              <mat-option *ngFor="let option of optionsAcao" [value]="option.value">{{ option.viewValue }}</mat-option>
  
            </mat-select>
        </mat-form-field>
       
  
        
        <div class="col-md-3">
          
             
        <mat-slide-toggle formControlName="assinado" *ngIf="metodoAssinatura"> Assinou o Contrato</mat-slide-toggle>
        
        </div>
        <div class="col-md-2">
          <button mat-mini-fab matTooltip="Adicionar Signatário" (click)="addSignatario()" [disabled]="signatarioForm.invalid">
            <mat-icon>add</mat-icon>
          </button>
          </div>
  
  
    </div>
  </form>
  
  </div>
  </mat-expansion-panel>
</mat-accordion>
</div>
<hr>

    <div class="row"  *ngIf="signatariosContrato.data.length>0">
        <app-table fixed
        [dataSource]="signatariosContrato"   
        [columns]="columnsSignatario"
        [enablePagination]="false"
        [onExcluir]="onExcluirSignatario.bind(this)"
        [itemIdProperty] ="'nome'"  class="servico-table"> 
    </app-table>
    </div>