export class EstoqueMovimento {

  estoqueMovimentoId: number = 0;

  //0 - entrada do estoque, 1 - saída
  direcao: number = 0;

  descricao: string = '';

  quantidade: number = 0;

  valor: number = 0;

  data: Date = new Date();

  numLote: number = 0;

  estoqueId: number = 0;

  //Referência para Notas Fiscais
  documento: string = '';

  saldo: number = 0;

  estoqueLoteId: number = 0;

  saguiClienteId: number = 0;
}
