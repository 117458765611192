export class CompraItem {
    compraItemId: number = 0;
    nItem: number = 0;;
    cfop: number = 0;
    produtoId: number = 0;
    produtoTipo: number = 0;
    vProd: number = 0;
    vFrete:number = 0; 
    vSeg:number = 0; 
    vDesc:number = 0; 
    vOutro:number = 0; 
    indTot:number = 0; 
    codProd:string = ''; 
    descricao:string = ''; 
    ncm:string = ''; 
    ean:string = ''; 
    eanTrib:string = ''; 
    unTributaria:string = ''; 
    qtdTributaria:number = 0; 
    vlrTributario:number = 0; 
    unComercial:string = ''; 
    qtdComercial:number = 0; 
    vlrComercial:number = 0; 
    extipi:number = 0; 
    infAdProd:string = ''; 
    compraId:number = 0; 
    estoqueId:number = 0; 
    numLote:number = 0; 
    estoqueLoteId: number = 0;
    saguiClienteId: number = 0;
}