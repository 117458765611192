import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class VendaService {

  postVisualizaVendasPorFiltro(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/visualizatodas/', item, { observe: 'response' })
  }

  postVisualizaVendasPorPeriodo(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/vendasporperiodo/', item, { observe: 'response' })
  }

  postVisualizaVendasPorProdutoPeriodo(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/vendasporprodutoperiodo/', item, { observe: 'response' })
  }

  postVisualizaVendasPorClientePeriodo(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/vendasporclienteperiodo/', item, { observe: 'response' })
  }

  postFaturarPorFiltro(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/faturarporfiltro/', item, { observe: 'response' })
  }

  getEmpresa(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/venda/visualizatodasempresas', { observe: 'response' })
  }

  getVendedores(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/venda/visualizatodosvendedores', { observe: 'response' })
  }

  getTransportadoras(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/venda/transportadoras', { observe: 'response' })
  }

  getVenda(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/venda/visualiza/' + itemId, { observe: 'response' })
  }

  getClientePorId(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/venda/visualizacliente/' + itemId, { observe: 'response' })
  }

  getClientePorNome(nome: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/venda/visualizaclientepornome/' + nome, { observe: 'response' })
  }

  getFornecedorPorId(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/venda/visualizafornecedor/' + itemId, { observe: 'response' })
  }

  getFornecedorPorNome(nome: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/venda/visualizafornecedorpornome/' + nome, { observe: 'response' })
  }

  getCodigoBarras(codBarras: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/venda/visualizacodigobarrasdoproduto/' + codBarras, { observe: 'response' })
  }

  postVenda(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/inclui/', item, { observe: 'response' })
  };

  postPdv(item: any): Observable<HttpResponse<any>>{
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/incluipdv/', item, { observe: 'response' })
  };

  putVenda(item: any, itemId: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/venda/atualiza/' + itemId, item, { observe: 'response' })
  };

  deleteVenda(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/venda/exclui/' + itemId, { observe: 'response' })
  }

  getVendaItem(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/vendaitem/visualiza/' + itemId, { observe: 'response' })
  }

  postIncluiVendaItem(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/vendaitem/inclui/', item, { observe: 'response' })
  };

  putAtualizaVendaItem(item: any, itemId: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/vendaitem/atualiza/' + itemId, item, { observe: 'response' })
  };

  deleteVendaItem(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/vendaitem/exclui/' + itemId, { observe: 'response' })
  }

  deleteVendaParcela(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/vendaparcela/exclui/' + itemId, { observe: 'response' })
  }

  getVisualizaDocumento(parametros: any): Observable<HttpResponse<any>> {

    const params = {
      idOrigem: parametros.idOrigem,
      tipoOrigem: parametros.tipoOrigem,
      tipoDestino: parametros.tipoDestino
    }

    return this.http.get(environment.webApiSaguiControlUrl + '/api/venda/visualizadocumentopororigemdestino', { observe: 'response', params })
  }

  postGeraFatura(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/gerarfatura', item, { observe: 'response' })
  };

  postGerarContrato(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/gerarcontrato', item, { observe: 'response' })
  };

  postGeraNFe(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/gerarnfe', item, { observe: 'response' })
  };

  postGeraNFeDevolucao(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/gerarnfedevolucao', item, { observe: 'response' })
  };

  postGeraRemessa(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/gerarremessa', item, { observe: 'response' })
  };

  postMovimentaEstoque(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/movimentarestoques', item, { observe: 'response' })
  };

  postGeraDevolucao(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/gerardevolucao', item, { observe: 'response' })
  };

  postGeraDevolucaoEstoque(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/venda/gerardevolucaoestoque', item, { observe: 'response' })
  };

  getProdutoPorNome(parametros: any): Observable<HttpResponse<any>> {

    const params = {
      id: parametros.id,
      tipoProduto: parametros.tipoProduto,
    }

    return this.http.get(environment.webApiSaguiControlUrl + '/api/venda/autocompleteproduto', { observe: 'response', params })
  }

  getEstoqueByProduto(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/vendaitem/visualizaestoqueporproduto/' + itemId, { observe: 'response' })
  }

  postCalculaImposto(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/vendaitem/calculaimposto', item, { observe: 'response' })
  };

  getListaServicos(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/servicolista/visualizatodas', { observe: 'response' })
  }

  getListaServicoCadastrados(empresaId): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/servicolista/visualizatodascadastradas/' + empresaId, { observe: 'response' })
  }

  getListaServico(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/servicolista/visualizaporid/' + itemId, { observe: 'response' })
  }

  getContaCorrenteTerminais(): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/venda/visualizatodosterminais/', { observe: 'response' })
  }

  postGerarParcelas(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/vendaparcela/gerarparcelas', item, { observe: 'response' })
  };

  putDescontosAdicionais(DescontoDto: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/venda/descontos-adicionais/', DescontoDto, { observe: 'response' })
  }

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
