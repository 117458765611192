<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>Remessa {{remessa.remessaId}}</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()"
        [disabled]="remessa.remessaStatus == 5 ">
        <mat-icon>save</mat-icon>
    </button>
    <button mat-raised-button (click)="imprimir()" *ngIf="remessa.remessaId > 0 " matTooltip="Imprimir">
        <mat-icon>print</mat-icon>
    </button>
    <button mat-raised-button mat-raised-button [matMenuTriggerFor]="opcoes" matTooltip="Opções">
        <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #opcoes>
        <button *ngIf="remessa.remessaId > 0 && remessa.remessaStatus == 0" (click)="processaPicking()"
            mat-menu-item>Processa Picking
        </button>
        <button *ngIf="remessa.remessaStatus == 1" (click)="processaPacking()" mat-menu-item>
            Processa Packing
        </button>
        <button *ngIf="remessa.remessaStatus == 2" (click)="processaEntrega()" mat-menu-item>
            Definir como Entregue
        </button>
    </mat-menu>
</div>
<mat-dialog-content>
    <div class="md-dialog-content">
        <br />
        <div class="row">
            <form [formGroup]="remessaCadastroForm">
                <div class="col-md-12">
                    <div class="card">
                        <mat-tab-group>
                            <mat-tab label="Geral">
                                <br />
                                <div class="row">
                                    <mat-form-field class="col-md-6 col-xs-12 form-group">
                                        <mat-label>Emitente</mat-label>
                                        <mat-select formControlName="empresaId">
                                            <mat-option *ngFor="let empresa of empresas" [value]="empresa.empresaId">
                                                {{empresa.nomeFantasia}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="col-md-12 col-xs-12 form-group">
                                        <mat-label>Cliente</mat-label>
                                        <input type="text" matInput [matAutocomplete]="auto"
                                            formControlName="clienteAutoComplete">
                                        <mat-autocomplete #auto="matAutocomplete">
                                            <mat-option *ngFor="let parceiro of clientes"
                                                [value]="parceiro.nomeFantasia" (click)="determinaCliente(parceiro)">
                                                CNPJ: {{parceiro.cnpj}} - {{parceiro.nomeFantasia}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-form-field>
                                            <mat-label>Data da Remessa</mat-label>
                                            <input matInput [matDatepicker]="picker1" formControlName="dataCriacao">
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker #picker1></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field>
                                            <mat-label>Data da Venda</mat-label>
                                            <input matInput [matDatepicker]="picker2" formControlName="dataVenda"
                                                [disabled]="true">
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-form-field>
                                            <mat-label>Previsão Carregamento</mat-label>
                                            <input matInput [matDatepicker]="picker3"
                                                formControlName="dataPrevisaoCarregamento">
                                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field>
                                            <mat-label>Previsão Entrega</mat-label>
                                            <input matInput [matDatepicker]="picker4"
                                                formControlName="dataPrevisaoEntrega">
                                            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                            <mat-datepicker #picker4></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <mat-form-field class="col-md-6 col-xs-12 form-group">
                                        <mat-label>Status</mat-label>
                                        <mat-select formControlName="remessaStatus" [disabled]="true">
                                            <mat-option [value]="0">Aguardando Picking</mat-option>
                                            <mat-option [value]="1">Aguardando Packing</mat-option>
                                            <mat-option [value]="2">Aguardando Carregamento</mat-option>
                                            <mat-option [value]="3">Em Trânsito</mat-option>
                                            <mat-option [value]="4">Concluída</mat-option>
                                            <mat-option [value]="99">Cancelada</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="form-group col-md-6 col-xs-12">
                                        <mat-label>Peso Líquido (KG)</mat-label>
                                        <input type="number" matInput formControlName="pesoLiquido">
                                    </mat-form-field>
                                    <mat-form-field class="form-group col-md-6 col-xs-12">
                                        <mat-label>Peso Bruto (KG)</mat-label>
                                        <input type="number" matInput formControlName="pesoBruto">
                                    </mat-form-field>
                                </div>
                                <mat-form-field class="form-group col-md-6">
                                    <mat-label>Quantidade de Volumes</mat-label>
                                    <input type="number" matInput formControlName="quantidadevolumesTransportados">
                                </mat-form-field>
                                <mat-form-field class="form-group col-md-6">
                                    <mat-label>Espécie dos Volumes</mat-label>
                                    <input type="text" matInput formControlName="especieVolumesTransportados">
                                </mat-form-field>
                            </mat-tab>
                            <mat-tab label="Entrega">
                                <br />
                                <div class="row">
                                    <mat-form-field class="col-md-6 col-xs-12 form-group">
                                        <mat-label>Local de Entrega (Cliente)</mat-label>
                                        <input type="text" matInput [matAutocomplete]="localEntrega"
                                            formControlName="localEntregaAutoComplete">
                                        <mat-autocomplete #localEntrega="matAutocomplete">
                                            <mat-option *ngFor="let localEntrega of locaisEntrega"
                                                [value]="localEntrega.nomeFantasia"
                                                (click)="DeterminalocalEntregaId(localEntrega.clienteId)">
                                                CNPJ: {{localEntrega.cnpj}} - {{localEntrega.nomeFantasia}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                            </mat-tab>
                            <mat-tab label="Picking e Packing">
                                <br />
                                <div class="row">
                                    <fieldset class="col-md-4">
                                        <legend>Picking</legend>
                                        <label>Usuário: {{remessaCadastroForm.value.usuarioPicking}}</label>
                                        <label>Data: {{remessaCadastroForm.value.dataPicking | date: 'dd/MM/yyyy
                                            HH:mm'}}</label>
                                    </fieldset>
                                    <fieldset class="col-md-4">
                                        <legend>Packing</legend>
                                        <label>Usuário: {{remessaCadastroForm.value.usuarioPacking}}</label>
                                        <label>Data: {{remessaCadastroForm.value.dataPacking | date: 'dd/MM/yyyy
                                            HH:mm'}}</label>
                                    </fieldset>
                                </div>
                                <div class="row" *ngIf="remessa.remessaId > 0">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="x_panel">
                                            <div class="x_title">
                                                <h2>Embalagens</h2>
                                                <ul class="nav navbar-right panel_toolbox"></ul>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div class="x_content">
                                                <br />
                                                <div id="itens">
                                                    <div id="list" class="row">
                                                        <div class="table-responsive col-md-12">
                                                            <table class="table table-striped" cellspacing="0"
                                                                cellpadding="0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Id</th>
                                                                        <th>Cod.</th>
                                                                        <th>Embalagem</th>
                                                                        <th>Peso</th>
                                                                        <th>Cod.</th>
                                                                        <th>Produto</th>
                                                                        <th>Quantidade</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody *ngFor="let embalagem of remessa.embalagens">
                                                                    <tr>
                                                                        <td>{{embalagem.embalagemId}}</td>
                                                                        <td>{{embalagem.produto.codProd}}</td>
                                                                        <td>{{embalagem.produto.descricao}}</td>
                                                                        <td>{{embalagem.peso}}</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr
                                                                        *ngFor="let embalagemitem of embalagem.embalagemItens">
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td>{{embalagemitem.codProd}}</td>
                                                                        <td>{{embalagemitem.descricao}}</td>
                                                                        <td>{{embalagemitem.quantidade}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab label="Inf. Adicionais">
                                <br />
                                <div class="row">
                                    <mat-form-field class="form-group col-md-6">
                                        <mat-label>Dados Adicionais</mat-label>
                                        <textarea matInput rows="5" formControlName="dadosAdicionais"></textarea>
                                    </mat-form-field>
                                </div>
                            </mat-tab>
                            <mat-tab label="Tranportes">
                                <br />
                                <div class="row">
                                    <mat-form-field class="col-md-6 col-xs-12 form-group">
                                        <mat-label>Transportadora</mat-label>
                                        <mat-select formControlName="transportadoraId">
                                            <mat-option *ngFor="let transportadora of transportadoras"
                                                [value]="transportadora.transportadoraId">
                                                {{transportadora.nomeFantasia}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="col-md-6 col-xs-12 form-group">
                                        <mat-label>Tipo de Entrega</mat-label>
                                        <mat-select formControlName="tipoEntrega" name="tipoEntrega">
                                            <mat-option [value]="1">Transportadora</mat-option>
                                            <mat-option [value]="2">Veículo Próprio</mat-option>
                                            <mat-option [value]="3">Cliente Retira</mat-option>
                                            <mat-option [value]="4">Correios</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="col-md-6 col-xs-12 form-group">
                                        <mat-label>Tipo de Frente</mat-label>
                                        <mat-select formControlName="modFrete" name="modFrete">
                                            <mat-option [value]="0">Contratação do frete por conta do remetente
                                                (CIF)</mat-option>
                                            <mat-option [value]="1">Contratação do frete por conta do destinatário
                                                (FOB)</mat-option>
                                            <mat-option [value]="2">Transporte próprio por conta do
                                                remetente</mat-option>
                                            <mat-option [value]="3">Transporte próprio por conta do
                                                destinatário</mat-option>
                                            <mat-option [value]="9">Sem ocorrência de transporte</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </form>
        </div>
        <div class="row" *ngIf="remessa.remessaId > 0">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Itens</h2>
                        <ul class="nav navbar-right panel_toolbox"></ul>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content">
                        <br />
                        <div id="itens">
                            <div id="list" class="row">
                                <div class="table-responsive col-md-12">
                                    <table class="table table-striped" cellspacing="0" cellpadding="0">
                                        <thead>
                                            <tr>
                                                <th>Código</th>
                                                <th>Descrição</th>
                                                <th>NCM</th>
                                                <th>Lote</th>
                                                <th>Unid.</th>
                                                <th>Qtde.</th>
                                                <th>Embalagem</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let remessaitem of remessa.remessaItens">
                                                <td>{{remessaitem.codProd}}</td>
                                                <td>{{remessaitem.descricao}}</td>
                                                <td>{{remessaitem.ncm}}</td>
                                                <td>{{remessaitem.numeroLote}}</td>
                                                <td>{{remessaitem.unidade}}</td>
                                                <td>{{remessaitem.quantidade}}</td>
                                                <td>{{remessaitem.embalagemProdutoDescricao}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="display:flex; justify-content: center; background-color: #c3c3c3; display: none">
            <div class="danfe" id="printRemessa">
                <div class="page" *ngFor="let remessatemp of remessas">
                    <div class="bloco">
                        <table id="dados-nfe" class="tabelaDanfe">
                            <thead>
                                <tr>
                                    <td class="campo">
                                        <img [src]="'data:image/JPEG;base64,'+ empresa.logoByte" id="logoCliente">
                                    </td>
                                    <td rowspan="4" class="campo quadro-identificacao-emitente">
                                        <div class="conteudoPedido negrito">
                                            <h3>Ordem de Remessa</h3>
                                            <h5>{{empresa.nomeFantasia}}</h5>
                                        </div>
                                        <div class="conteudoPedido">
                                            {{empresa.logradouro}}, {{empresa.numero}} , {{empresa.bairro}} <br />
                                            {{empresa.municipio?.descricao}} - {{empresa.municipio?.uf.siglaUF}}, CEP:
                                            {{empresa.cep}} <br />
                                            Tel: {{empresa.telefone}} <br />
                                            e-mail: {{empresa.emailRemessa}} <br />
                                            IE: {{empresa.inscEstadual}} - CNPJ: {{empresa.cnpj}}
                                        </div>
                                    </td>
                                    <td class="campo data-emissao">
                                        <div class="conteudoPedido negrito">EMISSÃO</div>
                                        <div class="conteudoPedido">
                                            <h5>{{remessatemp.dataCriacao | date:'dd/MM/yyyy'}}</h5>
                                        </div>
                                        <div class="conteudoPedido negrito">REMESSA</div>
                                        <div class="conteudoPedido negrito">
                                            <h5>{{remessatemp.remessaId}}</h5>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="bloco">
                        <div class="conteudoPedido negrito fonte-7-pontos">DESTINATÁRIO/REMETENTE</div>
                        <table>
                            <tr>
                                <td colspan="3" class="campo razao-social">
                                    <div class="conteudoPedido fonte-5-pontos">NOME / RAZÃO SOCIAL</div>
                                    <div class="conteudoPedido">
                                        {{cliente.razaoSocial}}
                                    </div>
                                </td>
                                <td class="campo cnpj-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">CNPJ / CPF</div>
                                    <div class="conteudoPedido">
                                        {{cliente.cnpj != undefined ? cliente.cnpj : cliente.cpf}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="campo endereco-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">ENDEREÇO</div>
                                    <div class="conteudoPedido">
                                        {{cliente.logradouro}} &nbsp;
                                        {{cliente.numero}}
                                    </div>
                                </td>
                                <td class="campo bairro-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">BAIRRO / DISTRITO</div>
                                    <div class="conteudoPedido">
                                        {{cliente.bairro}}
                                    </div>
                                </td>
                                <td class="campo cep-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">CEP</div>
                                    <div class="conteudoPedido">
                                        {{cliente.cep}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="campo municipio-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">MUNICÍPIO</div>
                                    <div class="conteudoPedido">
                                        {{cliente.municipio?.descricao}}
                                    </div>
                                </td>
                                <td class="campo fone-fax-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">TELEFONE</div>
                                    <div class="conteudoPedido">
                                        {{cliente.telefone}}
                                    </div>
                                </td>
                                <td class="campo uf-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">UF</div>
                                    <div class="conteudoPedido">
                                        {{cliente.municipio?.uf.siglaUF}}
                                    </div>
                                </td>
                                <td class="campo inscricao-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">INSCRIÇÃO ESTADUAL</div>
                                    <div class="conteudoPedido">
                                        {{cliente.inscEstadual}}
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="bloco">
                        <div class="conteudoPedido negrito fonte-7-pontos">TRANSPORTE</div>
                        <table>
                            <tr>
                                <td colspan="3" class="campo razao-social">
                                    <div class="conteudoPedido fonte-5-pontos">NOME / RAZÃO SOCIAL</div>
                                    <div class="conteudoPedido" *ngIf="transportadora != undefined">
                                        {{transportadora.razaoSocial}}
                                    </div>
                                </td>
                                <td class="campo endereco-destinatario">
                                    <div class="conteudoPedido fonte-5-pontos">CNPJ / CPF</div>
                                    <div class="conteudoPedido" *ngIf="transportadora != undefined">
                                        {{transportadora.cnpj}}
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <table class="sem-borda-superior">
                            <tr>
                                <td class="campo municipio-destinatario">
                                    <div class="conteudo fonte-5-pontos">MODALIDADE DO FRETE</div>
                                    <div class="conteudo">
                                        {{remessatemp.modalidadeFrete}}
                                    </div>
                                </td>
                                <td class="campo quantidade-transportador">
                                    <div class="conteudo fonte-5-pontos">QUANTIDADE</div>
                                    <div class="conteudo">
                                        {{remessatemp.volumesTransportados}}
                                    </div>
                                </td>
                                <td colspan="2" class="campo especie-transportador">
                                    <div class="conteudo fonte-5-pontos">ESPÉCIE</div>
                                    <div class="conteudo">
                                        {{remessatemp.especieVolumesTransportados}}
                                    </div>
                                </td>
                                <td class="campo peso-bruto-transportador">
                                    <div class="conteudo fonte-5-pontos">PESO BRUTO</div>
                                    <div class="conteudo">
                                        {{remessatemp.pesoBrutoTransportado}}
                                    </div>
                                </td>
                                <td class="campo peso-liquido-transportador">
                                    <div class="conteudo fonte-5-pontos">PESO LÍQUIDO</div>
                                    <div class="conteudo">
                                        {{remessatemp.pesoLiquidoTransportado}}
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="bloco">
                        <div class="conteudoPedido negrito fonte-7-pontos">DADOS DOS PRODUTOS / SERVIÇOS</div>
                        <table id="dados-produtos">
                            <thead>
                                <tr>
                                    <th class="campo">
                                        <div class="conteudoPedido fonte-5-pontos">CÓDIGO</div>
                                    </th>
                                    <th class="campo">
                                        <div class="conteudoPedido fonte-5-pontos">DESCRIÇÃO DOS PRODUTOS / SERVIÇOS
                                        </div>
                                    </th>
                                    <th class="campo">
                                        <div class="conteudoPedido fonte-5-pontos">NCM</div>
                                    </th>
                                    <th class="campo">
                                        <div class="conteudoPedido fonte-5-pontos">LOTE</div>
                                    </th>
                                    <th class="campo">
                                        <div class="conteudoPedido fonte-5-pontos">UNID</div>
                                    </th>
                                    <th class="campo">
                                        <div class="conteudoPedido fonte-5-pontos">QUANT</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of remessatemp.remessaItens">
                                    <!--<td class="campo sem-borda-superior-inferior">-->
                                    <td class="campo ">
                                        <div class="conteudoPedido fonte-7-pontos">
                                            {{item.codProd}}
                                        </div>
                                    </td>
                                    <!--<td class="campo sem-borda-superior-inferior">-->
                                    <td class="campo ">
                                        <div class="conteudoPedido fonte-7-pontos">
                                            {{item.descricao}}
                                        </div>
                                    </td>
                                    <!--<td class="campo sem-borda-superior-inferior">-->
                                    <td class="campo ">
                                        <div class="conteudoPedido fonte-7-pontos">
                                            {{item.ncm}}
                                        </div>
                                    </td>
                                    <td class="campo ">
                                        <div class="conteudoPedido fonte-7-pontos">
                                            {{item.numeroLote}}
                                        </div>
                                    </td>
                                    <!--<td class="campo sem-borda-superior-inferior">-->
                                    <td class="campo ">
                                        <div class="conteudoPedido fonte-7-pontos">
                                            {{item.unidade}}
                                        </div>
                                    </td>
                                    <!--<td class="campo sem-borda-superior-inferior">-->
                                    <td class="campo ">
                                        <div class="conteudoPedido fonte-7-pontos">
                                            {{item.quantidade}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="bloco rodape">
                        <!--ng-if="page.isLastPage"-->
                        <table id="dados_adicionais">
                            <tr>
                                <td class="campo">
                                    <div class="conteudoPedido negrito">DADOS ADICIONAIS</div>
                                </td>
                                <td class="campo">
                                    <div class="conteudoPedido negrito">ENTREGUE POR</div>
                                </td>
                                <td class="campo">
                                    <div class="conteudoPedido negrito">RETIRADO POR</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="campo dados-adicionais">
                                    <div class="conteudoPedido">
                                        {{remessatemp.dadosAdicionais}}
                                    </div>
                                </td>
                                <td class="campo reservado-fisco">

                                </td>
                                <td class="campo reservado-fisco">

                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
