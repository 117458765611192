import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    mensagem: string;
    titulo: string;
}

@Component({
    selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['confirm-dialog.component.css'],
})
export class ConfirmDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    ngOnInit(){

        let innerWidth = window.innerWidth;

        if (innerWidth < 1024) {
            this.dialogRef.updateSize('80%');
        }
        else {
            this.dialogRef.updateSize('30%');
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onYesClick(): void{
        this.dialogRef.close(true);
    }
}
