export class ClienteFaturamento {

    clienteFaturamentoId: number = 0;

    diaFatura: number = 0;

    diaVencimento: number = 0;

    clienteId: number = 0;

    dataUltimaAtualizacao: Date = new Date();

    grupoContaId: number = 0;

    caixaId: number = 0;

    saguiClienteId: number = 0;
    
}