<h3 mat-dialog-title>{{data.titulo}}</h3>
<div mat-dialog-content>
    <p>{{data.mensagem}}</p>
</div>
<div mat-dialog-actions class="md-toolbar-tools">
    <div class="row">
        <div class="col-md-6 no">  
        <button mat-raised-button color="warn" (click)="onNoClick()">Não</button>
        </div>
        <div class="col-md-6 yes">  
        <button mat-raised-button color="primary" (click)="onYesClick()">Sim</button>
        </div>
    </div>
</div> 