'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, switchMap } from "rxjs/operators";

import { NfseStatusService } from '../shared/nfse-status.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';

export interface DialogData {
  nfseId: number;
}

@Component({
  selector: 'nfse-status',
  templateUrl: 'nfse-status.component.html',
})

export class NfseStatusComponent {

  maximizado: boolean;
  nfsesStatus: any[];

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NfseStatusComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public INfseStatus: DialogData,
    private nfseStatusService: NfseStatusService) {
  }

  ngOnInit() {

    this.maximizado = false;

    if (this.INfseStatus.nfseId > 0) {

      this.nfseStatusService.getNfseStatus(this.INfseStatus.nfseId).subscribe(data => {

        this.nfsesStatus = data.body;

      })

    }

    this.restaurarDialog();
  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  modalStatusDetalhe(mensagem: string){

    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        titulo: "Detalhe",
        mensagem: mensagem
      }

    });

  }
}
