<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>Estoque Movimento</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()"><mat-icon>save</mat-icon></button>
</div>
<mat-dialog-content>
  <div class="md-dialog-content">
    <br />
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="estoqueMovimentoForm">
          <div class="row">
            <mat-form-field class="col-md-4 col-xs-12 form-group">
              <mat-label>Direção</mat-label>
              <mat-select formControlName="direcao">
                <mat-option [value]="0">Entrada</mat-option>
                <mat-option [value]="1">Saída</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="form-group col-md-6">
              <mat-label>Descrição</mat-label>
              <input type="text" matInput formControlName="descricao">
            </mat-form-field>
            <mat-form-field class="form-group col-md-6">
              <mat-label>Quantidade</mat-label>
              <input type="number" matInput formControlName="quantidade">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="form-group col-md-6">
              <mat-label>Documento</mat-label>
              <input type="text" matInput formControlName="documento">
              <mat-hint>Exemplo: Número da NF-e que criou a movimentação</mat-hint>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </div>
</mat-dialog-content>