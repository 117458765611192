export class Log {

    logId: number = 0;

    usuario: string = '';

    textoLog: string = '';

    logTipo: number = 0;

    data: Date = new Date();

    entidadeId: number = 0;

    saguiClienteId: number = 0;
}