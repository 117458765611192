<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>NF-e {{nfe.nfeId}} {{atualizaStatus(nfeCadastroForm.value.statusProcessamento)}}</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" *ngIf="permiteSalvar" matTooltip="Salvar" (click)="salvar()">
    <mat-icon>save</mat-icon>
  </button>
  <button class="sagui-button" mat-raised-button *ngIf="processar" (click)="processa()" matTooltip="Enviar"><mat-icon>send</mat-icon>
    Sefaz</button>
  <button class="sagui-button" mat-raised-button *ngIf="permiteImprimir" (click)="imprime()" matTooltip="Imprimir"><mat-icon>print</mat-icon>
    DANFE</button>
  <button class="sagui-button" mat-raised-button [matMenuTriggerFor]="opcoes" matTooltip="Opções"><mat-icon>more_horiz</mat-icon></button>
  <mat-menu #opcoes>
    <button mat-menu-item *ngIf="nfeCadastroForm.value.nfeId > 0" (click)="modalNfeStatus()">
      <mat-icon>info</mat-icon> Status</button>
    <button mat-menu-item *ngIf="permiteExportarXml" (click)="exportaXml()">Exporta XML</button>
    <button mat-menu-item *ngIf="cancelar" (click)="cancelarNfe()"><mat-icon>delete</mat-icon> Cancelar</button>
    <button mat-menu-item *ngIf="permiteExibirEventos" (click)="exibeEventos()"><span class="fa fa-align-center"></span>
      Eventos</button>
  </mat-menu>
</div>
<mat-dialog-content>
  <div class="md-dialog-content">
    <br />
    <div class="row">
      <form [formGroup]="nfeCadastroForm">
        <div class="col-md-12">
          <mat-tab-group>
            <mat-tab label="Geral">
              <br />
              <div class="row">

                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Emitente</mat-label>
                  <mat-select formControlName="empresaId">
                    <mat-option *ngFor="let empresa of empresas" [value]="empresa.empresaId">
                      {{empresa.nomeFantasia}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-6 col-xs-12 form-group" *ngIf="nfeInbound">
                  <mat-label>Fornecedor</mat-label>
                  <input type="text" matInput formControlName="fornecedorRazaoSocial">
                </mat-form-field>
                <mat-form-field class="col-md-6 col-xs-12 form-group" *ngIf="!nfeInbound">
                  <mat-label>Destinatário</mat-label>
                  <input type="text" matInput [matAutocomplete]="auto" formControlName="clienteAutoComplete">
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let parceiro of clientes" [value]="parceiro.nomeFantasia"
                      (click)="determinaCliente(parceiro)">
                      CNPJ: {{parceiro.cnpj}} - {{parceiro.nomeFantasia}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="col-md-6 col-xs-12 form-group" *ngIf="!nfeInbound">
                  <mat-label>CFOP</mat-label>
                  <mat-select formControlName="cfop" (selectionChange)="definenaturezaOperacao($event.value)">
                    <mat-option *ngFor="let cfop of cfops" [value]="cfop.codCfop">
                      {{cfop.codCfop + ' - ' + cfop.descricao}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Natureza da Operação</mat-label>
                  <input type="text" matInput formControlName="natOp">
                </mat-form-field>
              </div>

            </mat-tab>
            <mat-tab label="Entrega">
              <br />
              <div class="row">
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Local de Entrega</mat-label>
                  <input type="text" matInput [matAutocomplete]="localEntrega"
                    formControlName="localEntregaAutoComplete">
                  <mat-autocomplete #localEntrega="matAutocomplete">
                    <mat-option *ngFor="let localEntrega of locaisEntrega" [value]="localEntrega.nomeFantasia"
                      (click)="DeterminalocalEntregaId(localEntrega.clienteId)">
                      CNPJ: {{localEntrega.cnpj}} - {{localEntrega.nomeFantasia}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-hint>Se diferente do destinatário</mat-hint>
                </mat-form-field>
              </div>
            </mat-tab>
            <mat-tab label="Observações">
              <br />
              <div class="row">
                <mat-form-field class="form-group col-md-12">
                  <mat-label>Informações Adicionais de Interesse do Contribuinte</mat-label>
                  <textarea matInput rows="5" formControlName="infCpl"></textarea>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="form-group col-md-12">
                  <mat-label>Informações Adicionais de Interesse do Fisco</mat-label>
                  <textarea matInput rows="5" formControlName="infAdFisco"></textarea>
                </mat-form-field>
              </div>
            </mat-tab>
            <mat-tab label="Tranportes">
              <br />
              <div class="row">
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Transportadora</mat-label>
                  <mat-select formControlName="transportadoraId">
                    <mat-option *ngFor="let transportadora of transportadoras"
                      [value]="transportadora.transportadoraId">
                      {{transportadora.nomeFantasia}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Modalidade do Frete</mat-label>
                  <mat-select formControlName="modFrete">
                    <mat-option [value]="0">Contratação do frete por conta do remetente (CIF)</mat-option>
                    <mat-option [value]="1">Contratação do frete por conta do destinatário (FOB)</mat-option>
                    <mat-option [value]="2">Transporte próprio por conta do remetente</mat-option>
                    <mat-option [value]="3">Transporte próprio por conta do destinatário</mat-option>
                    <mat-option [value]="9">Sem ocorrência de transporte</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Quantidade de Volumes Transportados</mat-label>
                  <input type="number" matInput formControlName="qVol">
                </mat-form-field>
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Espécie dos Volumes Transportados</mat-label>
                  <input type="text" matInput formControlName="especie">
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="form-group col-md-6 col-xs-12">
                  <mat-label>Peso Líquido (KG)</mat-label>
                  <input type="number" matInput formControlName="pesoL">
                </mat-form-field>
                <mat-form-field class="form-group col-md-6 col-xs-12">
                  <mat-label>Peso Bruto (KG)</mat-label>
                  <input type="number" matInput formControlName="pesoB">
                </mat-form-field>
              </div>
            </mat-tab>
            <mat-tab label="NF-e">
              <br />
              <div class="row">
                <div class="col-md-6 col-xs-12 form-group">
                  <mat-label>Chave de Acesso: {{nfeCadastroForm.value.chaveAcesso}}</mat-label>
                </div>
              </div>
              <div class="row">
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Forma de Emissão</mat-label>
                  <mat-select formControlName="tpEmis">
                    <mat-option [value]="1">1 - Emissão normal</mat-option>
                    <mat-option [value]="2">2 - Contingência FS-IA</mat-option>
                    <mat-option [value]="3">3 - Contingência SCAN</mat-option>
                    <mat-option [value]="4">4 - Contingência DPEC</mat-option>
                    <mat-option [value]="5">5 - Contingência FS-DA</mat-option>
                    <mat-option [value]="6">6 - Contingência SVC-AN</mat-option>
                    <mat-option [value]="7">7 - Contingência SVC-RS</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Finalidade de Emissão</mat-label>
                  <mat-select formControlName="finNFe">
                    <mat-option [value]="1">1 - NF-e normal</mat-option>
                    <mat-option [value]="2">2 - NF-e complementar</mat-option>
                    <mat-option [value]="3">3 - NF-e de ajuste</mat-option>
                    <mat-option [value]="4">4 - Devolução de mercadoria</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Forma de Emissão</mat-label>
                  <mat-select formControlName="indPres">
                    <mat-option [value]="0">Não se aplica</mat-option>
                    <mat-option [value]="1">Operação presencial</mat-option>
                    <mat-option [value]="2">Operação não presencial, pela Internet</mat-option>
                    <mat-option [value]="3">Operação não presencial, Teleatendimento</mat-option>
                    <mat-option [value]="4">NFC-e em operação com entrega a domicílio</mat-option>
                    <mat-option [value]="9">Operação não presencial, outros</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Consumidor</mat-label>
                  <mat-select formControlName="indFinal">
                    <mat-option [value]="0">Normal</mat-option>
                    <mat-option [value]="1">Consumidor final</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Modalidade Nota Fiscal</mat-label>
                  <mat-select formControlName="mod">
                    <mat-option [value]="55">55 - NF-e</mat-option>
                    <mat-option [value]="65">65 - NFC-e</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="row">
                <br />
                <mat-form-field class="col-md-3 col-xs-12 form-group">

                  <mat-label>Cod. Município Fato Gerador</mat-label>
                  <input type="text" matInput formControlName="cMunFG">
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="col-md-3 col-xs-12 form-group">
                  <mat-label>Número da NF-e</mat-label>
                  <input type="text" matInput formControlName="nNF" [readonly]="true">
                </mat-form-field>
                <mat-form-field class="col-md-3 col-xs-12 form-group">
                  <mat-label>Série da NF-e</mat-label>
                  <input type="text" matInput formControlName="serie" [readonly]="true">
                </mat-form-field>
                <mat-form-field class="form-group col-md-3">
                  <mat-label>Data da Emissão</mat-label>
                  <input matInput [matDatepicker]="picker1" formControlName="dhEmi">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="form-group col-md-3">
                  <mat-label>Data de Saída ou Entrada</mat-label>
                  <input matInput [matDatepicker]="picker2" formControlName="dhSaiEnt">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Tipo de Documento</mat-label>
                  <mat-select formControlName="tpNF">
                    <mat-option [value]="0">Entrada</mat-option>
                    <mat-option [value]="1">Saída</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Indicador de Pagamento</mat-label>
                  <mat-select formControlName="indPag">
                    <mat-option [value]="0">Pagamento à vista</mat-option>
                    <mat-option [value]="1">Pagamento a prazo</mat-option>
                    <mat-option [value]="2">Outros</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="row">
                <div class="col-md-6 col-xs-12 form-group">
                  <mat-checkbox formControlName="possuiReferencia">Possui Nota Fiscal Referenciada?</mat-checkbox>
                </div>

                <mat-form-field class="form-group col-md-6 col-xs-12" *ngIf="nfeCadastroForm.value.possuiReferencia">
                  <mat-label>Chave de Acesso NF-e Referenciada</mat-label>
                  <input matInput formControlName="refNFe">
                </mat-form-field>
              </div>
              <div class="row" *ngIf="nfeInbound">
                <mat-form-field class="form-group col-md-12">
                  <mat-label>Inbound - Avisos</mat-label>
                  <textarea matInput rows="5" formControlName="avisosInbound"></textarea>
                </mat-form-field>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </form>
    </div>
    <div class="row" *ngIf="nfeCadastroForm.value.nfeId > 0">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Itens da NF-e</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div id="itens">
              <div class="row">
                <form [formGroup]="nfeItemForm">
                  <div class="row">
                    <mat-form-field class="col-md-2 col-xs-12 form-group">
                      <mat-label>Tipo de Produto</mat-label>
                      <mat-select formControlName="produtoTipo">
                        <mat-option [value]="0">Produto Acabado</mat-option>
                        <mat-option [value]="2">Uso e Consumo</mat-option>
                        <mat-option [value]="3">Ativo Imobilizado</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-6 col-xs-12 form-group">
                      <mat-label>Digite o Nome do Produto</mat-label>
                      <input type="text" matInput [matAutocomplete]="auto" formControlName="produtoAutoComplete">
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let produto of produtos" [value]="produto.descricao"
                          (click)="determinaprodutoId(produto)">
                          {{produto.descricao}} - {{produto.vlrComercial | currency}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="col-md-4 col-xs-12 form-group">
                      <mat-label>CFOP</mat-label>
                      <mat-select formControlName="cfop">
                        <mat-option *ngFor="let cfop of cfops" [value]="cfop.codCfop">
                          {{cfop.codCfop + ' - ' + cfop.descricao}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="form-group col-md-4 col-xs-12">
                      <mat-label>Quantidade Comercial"</mat-label><input type="number" matInput
                        formControlName="qtdComercial" (blur)="onChangeValorComercial()">
                    </mat-form-field>
                    <mat-form-field class="form-group col-md-4 col-xs-12">
                      <mat-label>Valor Comercial</mat-label>
                      <input currencyMask matInput formControlName="vlrComercial" (blur)="onChangeValorComercial()">
                    </mat-form-field>
                    <mat-form-field class="form-group col-md-4 col-xs-12">
                      <mat-label>Valor Total</mat-label>
                      <input currencyMask matInput formControlName="vProd">
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <button mat-raised-button color="primary" (click)="calculaImposto()" matTooltip="Salvar"><mat-icon>save</mat-icon>
                        Salvar Item</button>
                    </div>
                    <div class="col-md-4">
                      <mat-hint>Essa é uma versão resumida do formulário. Para abrir o formulário completo com mais
                        opções clique no botão abaixo</mat-hint>
                      <br>
                      <button mat-stroked-button (click)="modalNfeItem(0,true)">Formulário Completo</button>
                    </div>
                  </div>
                </form>

              </div>
              <br>
              <div id="list" class="row">
                <div class="table-responsive col-md-12">
                  <table class="table table-striped" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th class="actions">Ações</th>
                        <th>Código</th>
                        <th>Descrição</th>
                        <th>NCM</th>
                        <th>CFOP</th>
                        <th>Unid.</th>
                        <th>Qtde.</th>
                        <th>Valor Un.</th>
                        <th>Valor Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let nfeitem of nfeItens">
                        <td class="actions">
                          <a mat-mini-fab (click)="modalNfeItem(nfeitem.nfeItemId, false)" matTooltip="Editar NF-e Item"
                            *ngIf="permiteSalvar && !nfeInbound">
                            <mat-icon>edit</mat-icon>
                          </a>
                          <a mat-mini-fab color="warn" (click)="deleteNfeItem(nfeitem.nfeItemId)"matTooltip="Deletar"
                            *ngIf="permiteSalvar && !nfeInbound">
                            <mat-icon>delete</mat-icon>
                          </a>
                        </td>
                        <td>{{nfeitem.codProd}}</td>
                        <td>{{nfeitem.descricao}}</td>
                        <td>{{nfeitem.ncm}}</td>
                        <td>{{nfeitem.cfop}}</td>
                        <td>{{nfeitem.unComercial}}</td>
                        <td>{{nfeitem.qtdComercial}}</td>
                        <td>{{nfeitem.vlrComercial | currency}}</td>
                        <td>{{nfeitem.vProd | currency}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</mat-dialog-content>
