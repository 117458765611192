import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class ContaParcelaService {

  postVisualizaParcelasPorFiltro(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/contaparcela/visualizaparcelasporfiltro/', item, { observe: 'response' })
  }

  postReciboPorEmail(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/contaparcela/enviareciboporemail', item, { observe: 'response' })
  };

  postGeraNfse(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/contaparcela/geranfse', item, { observe: 'response' })
  };

  postCancelarContaParcelas(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/contaparcela/exclui', item, { observe: 'response' })
  };

  getContaParcela(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/contaparcela/visualiza/' + itemId, { observe: 'response' })
  }

  postContaParcela(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/contaparcela/inclui', item, { observe: 'response' })
  };

  postContaParcelaRecorrente(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/contaparcela/incluirrecorrencia', item, { observe: 'response' })
  };

  postImprimirRecibo(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/contaparcela/imprimirrecibo', item, { observe: 'response' })
  };

  putContaParcela(itemId: any, item: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/contaparcela/atualiza/' + itemId, item, { observe: 'response' })
  };

  putContaParcelaCorrigir(itemId: any, item: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/contaparcela/corrigir/' + itemId, item, { observe: 'response' })
  };

  postContaParcelaPorPeriodo(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/contaparcela/visualizaparcelasporfiltro/', item, { observe: 'response' })
  }

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
