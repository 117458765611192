<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>Método de Pagamento</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()">
        <mat-icon>save</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="md-dialog-content">
        <br />
        <form [formGroup]="clienteMetodoPagamentoForm">
            <div class="row">
                <mat-form-field class="form-group col-md-4 col-xs-12">
                    <mat-label>Nome no Cartão</mat-label>
                    <input matInput formControlName="nomeNoCartao">
                </mat-form-field>
                <mat-form-field class="form-group col-md-4 col-xs-12">
                    <mat-label>Número do Cartão</mat-label>
                    <input matInput formControlName="numeroCartao">
                </mat-form-field>
                <mat-form-field class="form-group col-md-4 col-xs-12">
                    <mat-label>CVV (Código de Segurança)</mat-label>
                    <input matInput formControlName="codigoSeguranca">
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                    <mat-label>Cartão</mat-label>
                    <mat-select formControlName="cartaoMarca">
                        <mat-option [value]="'Mastercard'">Mastercard</mat-option>
                        <mat-option [value]="'Visa'">Visa</mat-option>
                        <mat-option [value]="'Amex'">Amex</mat-option>
                        <mat-option [value]="'Elo'">Elo</mat-option>
                        <mat-option [value]="'Hipercard'">Hipercard</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="form-group col-md-4 col-xs-12">
                    <mat-label>Vencimento Mês</mat-label>
                    <input matInput formControlName="expiraMes">
                </mat-form-field>
                <mat-form-field class="form-group col-md-4 col-xs-12">
                    <mat-label>Vencimento Ano</mat-label>
                    <input matInput formControlName="expiraAno">
                </mat-form-field>
            </div>
        </form>
    </div>
</mat-dialog-content>
