'use strict'
import { Component, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContratoService } from '../shared/contrato.service';
import { CfopService } from '../../cfop/shared/cfop.service';

import { debounceTime, switchMap } from "rxjs/operators";

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

import { ContratoItem } from '../shared/contrato-item.model';
import { Produto } from '../../produto/shared/produto.model';
import { Cfop } from '../../cfop/shared/cfop.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    contratoItemId: number;
    contrato: any;
}

@Component({
    selector: 'contrato-item',
    templateUrl: 'contrato-item.component.html',
})

export class ContratoItemComponent {

    contrato: any = {};
    contratoItemForm: FormGroup;
    maximizado: boolean;
    contratoItemId: number;
    contratoitem: any = {};
    cfops: Cfop[];
    produtos: Produto[] = [];

    constructor(
        public dialog: MatDialog,
    private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<ContratoItemComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
        private contratoService: ContratoService) {
    }

    ngOnInit() {

        this.maximizado = false;

        this.contrato = this.dialogData.contrato;

        this.contratoItemId = this.dialogData.contratoItemId;

        this.contratoItemForm = this._formBuilder.group(new ContratoItem());

        this.contratoItemForm.addControl('produtoAutoComplete', new FormControl(''));

        this.contratoItemForm.get('contratoId').setValue(this.contrato.contratoId);

        this.onChanges();

        this.buscarContratoItem();

        this.restaurarDialog();
    }

    onChanges(): void {

        let produtoTipo = 0;

        this.contratoItemForm
            .get('produtoAutoComplete')
            .valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => value?.length >= 3 ?
                    this.contratoService.getProdutoPorNome({
                        id: value,
                        tipoProduto: this.contratoItemForm.get('produtoTipo').value
                    }) : []
                )
            )
            .subscribe(data =>
                this.produtos = data.body
            );
    }

    buscarContratoItem() {
        if (this.contratoItemId > 0) {

            this.contratoService.getContratoItem(this.contratoItemId).subscribe(data => {

                this.contratoItemForm.patchValue(data.body);

                this.contratoItemForm.get('produtoAutoComplete').setValue(data.body.descricao);

            });
        }
    }

    fecharDialog() {
        this.dialogRef.close();
    }

    salvar(ContratoItemForm) {

        if (this.contratoItemId == 0) {

            ContratoItemForm.contratoId = this.contrato.contratoId;

            this.contratoService.postIncluiContratoItem(ContratoItemForm.value).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.fecharDialog();

            });
        }
        else {

            this.contratoService.putAtualizaContratoItem(ContratoItemForm.value, ContratoItemForm.value.contratoItemId).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.fecharDialog();

            });
        }
    };


    DeterminaprodutoId(produto: Produto) {

        this.contratoItemForm.get('produtoId').setValue(produto.produtoId);
        this.contratoItemForm.get('codProd').setValue(produto.codProd);
        this.contratoItemForm.get('descricao').setValue(produto.descricao);
        this.contratoItemForm.get('unidade').setValue(produto.unTributaria);
        this.contratoItemForm.get('valorUnitario').setValue(produto.vlrTributario);
        this.contratoItemForm.get('informacoesAdicionais').setValue(produto.infAdProd);
    }

    OnChangeqtdComercial(qtdComercial: any) {

        let vlrComercial = this.contratoItemForm.get('valorUnitario').value;
        let valorTotal = Math.round((qtdComercial * vlrComercial) * 100) / 100;
        this.contratoItemForm.get('valorTotal').setValue(valorTotal.toFixed(2));
        this.contratoItemForm.get('quantidade').setValue(qtdComercial);
    }

    OnChangevlrComercial(vlrComercial: any) {

        let qtdComercial = this.contratoItemForm.get('quantidade').value;
        let valorTotal = Math.round((qtdComercial * vlrComercial) * 100) / 100;
        this.contratoItemForm.get('valorTotal').setValue(valorTotal.toFixed(2));
        this.contratoItemForm.get('valorUnitario').setValue(vlrComercial);
    }

    maximizarDialog() {
        this.dialogRef.updateSize('100%', '100%');
        this.maximizado = true;
    }

    restaurarDialog() {
        this.dialogRef.updateSize('80vh', '100vh');
        this.maximizado = false;
    }
}
