import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppMaterialModule } from '../app-material/app-material.module';

import { DocumentoCadastroComponent } from './documento-cadastro/documento-cadastro.component';

import { DocumentoService } from './shared/documento.service';
import { DocumentoTemplateRoutingModule } from './documento-routing.module';
import { DocumentoTemplateComponent } from './documento-template/documento-template.component';
import { DocumentoTemplateCadastroComponent } from './documento-template-cadastro/documento-template-cadastro.component';
import { DocumentoTemplateService } from './shared/documento-template.service';
import { DocumentosRelacionadosComponent } from './documentos-relacionados/documentos-relacionados.component';
import { VendaService } from '../venda/shared/venda.service';
import { OrcamentoService } from '../venda/shared/orcamento.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AppMaterialModule,
        DocumentoTemplateRoutingModule,
    ],
    exports: [
      DocumentosRelacionadosComponent,
    ],
    declarations: [
        DocumentoCadastroComponent,
        DocumentoTemplateComponent,
        DocumentoTemplateCadastroComponent,
        DocumentosRelacionadosComponent
    ],
    providers: [
        DocumentoService,
        DocumentoTemplateService,
        VendaService,
        OrcamentoService

    ]
})

export class DocumentoModule { }
