import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class EmbalagemService {

    postVisualizaEmbalagemsPorFiltro(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
          environment.webApiSaguiControlUrl + '/api/embalagem/visualizatodas/', item, { observe: 'response' })
    }

    getEmbalagem(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
          environment.webApiSaguiControlUrl + '/api/embalagem/visualiza/' + itemId, { observe: 'response' })
    }

    postEmbalagem(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
          environment.webApiSaguiControlUrl + '/api/embalagem/inclui/', item, { observe: 'response' })
    };

    putEmbalagem(item: any, itemId: any): Observable<HttpResponse<any>> {
        return this.http.put(
          environment.webApiSaguiControlUrl + '/api/embalagem/atualiza/' + itemId, item, { observe: 'response' })
    };

    deleteEmbalagem(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
          environment.webApiSaguiControlUrl + '/api/embalagem/exclui/' + itemId, { observe: 'response' })
    }

    getEmpresa(): Observable<HttpResponse<any>> {
        return this.http.get(
          environment.webApiSaguiControlUrl + '/api/embalagem/visualizatodasempresas', { observe: 'response' })
    }

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
