<mat-toolbar>
  <div class="md-toolbar-tools" >
    <h1 mat-dialog-title>Contrato {{contrato.contratoId}} {{contrato.status == 99 ? 'Cancelado' : ''}}</h1>
    <p *ngIf="erro()" style="display: flex; align-items: center;">
      <mat-icon aria-hidden="false" style="font-size: 24px; vertical-align: middle; margin-right: 8px;">error</mat-icon>
      {{this.mensagemErro}}
    </p>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar(contratoCadastroForm)"
    [disabled]="contrato.status == 99 || erro() || contratoCadastroForm.invalid"><mat-icon>save</mat-icon></button>
  <button mat-raised-button (click)="imprimir()" *ngIf="contrato.contratoId > 0"
    matTooltip="Imprimir" [disabled]="erro() || contratoCadastroForm.invalid"><mat-icon>print</mat-icon></button>
  <button mat-raised-button [matMenuTriggerFor]="opcoes" matTooltip="Opções" [disabled]="erro() || contratoCadastroForm.invalid" ><mat-icon>more_horiz</mat-icon></button>
  <mat-menu #opcoes>
    <button *ngIf="contrato.contratoMetodoAssinatura != 2 && contrato.contratoId > 0 && contrato.status < 2"
      (click)="AssinarOnline(contratoCadastroForm); $event.stopPropagation()" mat-menu-item>
      <span class="fa fa-paper-plane"></span>Assinaturas
    </button>
    <button *ngIf="contrato.contratoId > 0 && contrato.status != 99"
      (click)="criaContratoAPartirDeUmContratoExistente(contrato.contratoId); $event.stopPropagation()" mat-menu-item>
      Realizar renovação de contrato
    </button>
    <button *ngIf="contrato.contratoId > 0 && contrato.status <= 2"
      (click)="faturarContrato(contrato.contratoId); $event.stopPropagation()" mat-menu-item>
      Faturar
    </button>
    <button [disabled]="contrato.contratoId > 0 && contrato.status < 2 ? false : true"
      (click)="CancelarContrato(contrato.contratoId); $event.stopPropagation()" mat-menu-item matTooltip="Cancelar Contrato">Cancelar
    </button>
  </mat-menu>

</div>
<mat-dialog-content>

    <br />
    <div class="row">
      <form [formGroup]="contratoCadastroForm">


            <mat-tab-group #tabGroup (selectedTabChange)="setActiveTabIndex($event)" >
              <mat-tab label="Informações Gerais">
                <br />
                <div class="row">
                  <mat-form-field class="col-md-12 col-xs-12 form-group">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status" name="Status">
                      <mat-option [value]="0">Em Criação</mat-option>
                      <mat-option [value]="1">Pendente de Assinatura</mat-option>
                      <mat-option [value]="2">Assinado</mat-option>
                      <mat-option [value]="3">Concluído</mat-option>
                      <mat-option [value]="99">Cancelado</mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>
                <div class="row">
                  <mat-form-field class="col-md-6 col-xs-12 form-group">
                    <mat-label>Contratada(s)</mat-label>
                    <mat-select appRequiredField formControlName="contratoEmpresas" multiple required>
                      <mat-option *ngFor="let empresa of empresas" [value]="empresa">
                        {{empresa.nomeFantasia}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="row">
                    <mat-form-field class="col-md-6 col-xs-12 form-group" >
                      <mat-label>Contratante</mat-label>
                      <input appRequiredField type="text" matInput [matAutocomplete]="auto" formControlName="parceiroAutoComplete">
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="DeterminaParceiro($event.option.value)">
                        <mat-option *ngFor="let parceiro of parceiros" [value]="parceiro">
                          CNPJ/CPF: {{parceiro.cnpj != null ? parceiro.cnpj : parceiro.cpf}} - {{parceiro.nomeFantasia !=
                          null ? parceiro.nomeFantasia : parceiro.razaoSocial}}
                        </mat-option>
                      </mat-autocomplete>

                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                    <mat-form-field class="col-md-6">
                      <mat-label>Data da Início da Vigência</mat-label>
                      <input matInput [matDatepicker]="picker1" formControlName="dataInicio" (keydown)="false"
                        (click)="picker1.open()" appRequiredField>
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>


                    <mat-form-field class="col-md-6">
                      <mat-label>Data da Fim da Vigência</mat-label>
                      <input matInput [matDatepicker]="picker2" formControlName="dataFim" (keydown)="false"
                        (click)="picker2.open()" appRequiredField>
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>

                </div>

                <div class="row">
                  <mat-form-field class="form-group col-md-12">
                    <mat-label>Dados Adicionais</mat-label>
                    <textarea matInput rows="5" formControlName="dadosAdicionais" appRequiredField></textarea>

                  </mat-form-field>
                </div>
              </mat-tab>
              <mat-tab label="Produtos/Serviços do Contrato">
                <br />
                <div class="row">
                  <div class="col-md-12 col-sm-12 col-xs-12">


                       <br>
                       <app-itens [getProdutoPorNomeEEmpresasService]="getProdutoPorNomeEEmpresasService.bind(this)" [empresas]="contrato.contratoEmpresas" [itens]="contrato.contratoItens"
                       [panelOpenState]="false"
                       (novosItens)="onItensChange($event)"
                       (valorTotalBruto)="onValorTotalBrutoChange($event)"
                       (descontosAplicadosAosItens)="onDescontosAplicadosAosItensChange($event)"
                       (valorTotalLiquido)="onValorTotalLiquidoChange($event)"></app-itens>



                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Formas de Pagamento">
                <br />

                <div class="row sagui-form-card">

                  <div class="col-md-12 col-sm-12 col-xs-6">
                    <h3>Resumo Financeiro</h3>
                    <div class="col-md-4 col-sm-12 col-xs-12">

                    <mat-card class="darkblue-whiteblue-background report-card">
                      <mat-card-content>
                          <span class="report-card-title">{{this.contratoCadastroForm.get('valorTotalBruto').value |
                            currency}}</span>
                          <i class=" report-card-icon"></i>
                          <br />
                          <p>Valor Bruto</p>
                          <span class="count_bottom">Valor sem descontos aplicados</span>
                      </mat-card-content>
                  </mat-card>
                  </div>
                  <div class="col-md-4 col-sm-6 col-xs-6">
                    <mat-card class="darkblue-whiteblue-background report-card">
                      <mat-card-content>
                          <span class="report-card-title">{{this.contratoCadastroForm.get('somaDeTodosOsDescontos').value  | currency}}</span>
                          <div class=" report-card-icon"> <button
                            mat-mini-fab  color="accent" class="sagui-button sagui-mini-button report-card-icon" (click)="openDescontoDialog()">
                            <mat-icon>add</mat-icon>
                          </button></div>
                          <br />
                          <p>Descontos Totais</p>
                          <span class="count_bottom">Desconto dos Itens {{this.contratoCadastroForm.get('descontosAplicadosAosItens').value | currency:'BRL' }} +
                            Desconto Globais {{this.contratoCadastroForm.get('descontosAplicadosGlobalmente').value | currency:'BRL' }}
                       </span>

                      </mat-card-content>
                  </mat-card>

                  </div>
                    <div class="col-md-4 col-sm-12 col-xs-12">
                      <mat-card class="whitegreen-darkgreen-background report-card">
                        <mat-card-content>
                            <span class="report-card-title">{{this.contratoCadastroForm.get('valorTotalLiquido').value  |
                              currency}}</span>
                            <i class=" report-card-icon"></i>
                            <br />
                            <p>Valor Líquido</p>
                          <span class="count_bottom">Valor com todos os descontos aplicados</span>
                        </mat-card-content>
                    </mat-card>

                    </div>

                </div>


                </div>
                <hr>
                <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <app-calcula-parcelas fixed
                    [valorTotal]="contratoCadastroForm.get('valorTotalLiquido').value"
                    [parcelasIniciais]="contrato.contratoParcelas ? contrato.contratoParcelas : []"
                    [parcelaIdNome] = "'contratoParcelaId'"
                    [panelOpenState] = "false"
                    (parcelasFinaisChange)="onParcelasFinaisChange($event)">
                    </app-calcula-parcelas>
                </div>
                </div>
            </mat-tab>
              <mat-tab label="Assinaturas">
                <br />
                <h3>Como o contrato será assinado?</h3>

                  <div class="row">
                    <mat-form-field class="col-md-12 col-xs-12 form-group">
                      <mat-label>Método de Assinatura</mat-label>
                      <mat-select formControlName="contratoMetodoAssinatura" (selectionChange)="determinaValidadores($event)">
                        <mat-option *ngFor="let metodoAssinaturaPermitido of metodosAssinaturaPermitidos"
                          [value]="metodoAssinaturaPermitido.metodoAssinaturaPermitidoId">
                          {{metodoAssinaturaPermitido.descricao}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <app-cadastro-signatario [panelOpenState]="false" [metodoAssinatura]="contratoCadastroForm.get('contratoMetodoAssinatura').value" [contratoSignatariosAnterior]="contratoCadastroForm.get('contratoSignatarios').value" (contratoSignatariosNovos)="onSignatariosChange($event)"></app-cadastro-signatario>

                <br>

              </mat-tab>


              <mat-tab label="Template">
                <br />
                <div class="row">
                  <mat-form-field class="col-md-12 col-xs-12 form-group">
                    <mat-label>Template</mat-label>
                    <mat-select formControlName="contratoTemplateId" (selectionChange)="newSelection($event)">
                      <mat-option *ngFor="let contratoTemplate of contratoTemplates" [value]="contratoTemplate.contratoTemplateId">
                        {{ contratoTemplate.descricao }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="row" style="position: relative;">
                  <ng-container *ngIf="activeTabIndex === 4">
                 <app-editor-html-template [htmlAntigo]="contrato.contratoHtmlTexto" [objeto]="contrato" [empresas]="contratoCadastroForm.get('contratoEmpresas').value" [getTemplateTagsService]="getTemplateTagsService.bind(this)" [postTrocarTagsHtmlService]="postTrocarTagsHtmlService.bind(this)"  (novoHtml)="onHtmlFinalChange($event)"></app-editor-html-template>
                </ng-container>
                </div>
              </mat-tab>

              <mat-tab label="Documentos Relacionados">

                <div class="row">
                  <app-documentos-relacionados-component [id]="contrato.contratoId" [panelOpenState]="false" [empresas]="contratoCadastroForm.get('contratoEmpresas')?.value" [postDocumentoByIdEEmpresas]="postDocumentoByIdEEmpresas.bind(this)" [clienteId]="contratoCadastroForm.get('parceiroId')?.value" (documentosRelacionadosNovos)="ondocumentosRelacionadosChange($event)"></app-documentos-relacionados-component>
                </div>

            </mat-tab>
        </mat-tab-group>

      </form>
    </div>
      <div style="display:flex; justify-content: center; background-color: #c3c3c3; display: none" id="printContrato"
      [innerHTML]="contratoCadastroForm.value.contratoHtmlTexto">
    </div>

</mat-dialog-content>
