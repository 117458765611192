export class Total {
  vendas: number = 0;
  vendasFaturamento: number = 0;
  compras: number = 0;
  comprasFaturamento: number = 0;
  receberPrevisto: number = 0;
  recebido: number = 0;
  aReceber: number = 0;
  pagarPrevisto: number = 0;
  pago: number = 0;
  margemRealizada: number = 0;
  margemPrevista: number = 0;
}
