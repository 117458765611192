'use strict'
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FaturaService } from '../shared/fatura.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

import { FaturaItem } from '../shared/fatura-item.model';
import { Produto } from '../../produto/shared/produto.model';
import { Estoque } from '../../estoque/shared/estoque.model';


export interface DialogData {
    faturaItemId: number;
    fatura: any;
}

@Component({
    selector: 'fatura-item',
    templateUrl: 'fatura-item.component.html',
})

export class FaturaItemComponent {

    fatura: any = {};
    listaServicos: any = [];
    faturaItemForm: FormGroup;
    maximizado: boolean;
    faturaItemId: number;
    faturaitem: any = {};
    produtos: Produto[];
    estoques: Estoque[];

    constructor(
        public dialog: MatDialog,
    private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<FaturaItemComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public IFaturaItem: DialogData,
        private faturaService: FaturaService) {
    }

    ngOnInit() {

        this.maximizado = false;

        this.fatura = this.IFaturaItem.fatura;

        this.faturaItemId = this.IFaturaItem.faturaItemId;

        this.faturaItemForm = this._formBuilder.group(new FaturaItem());

        //this.faturaItemForm.addControl('produtoAutoComplete', new FormControl(''));

        this.faturaItemForm.get('faturaId').setValue(this.fatura.faturaId);

        this.faturaItemForm.get('unComercial').setValue('UN');

        this.faturaItemForm.get('qtdComercial').setValue(1);

        //this.faturaItemForm.get('orig').setValue(0);

        this.onChanges();

        //this.buscaCfop();

        //this.getListaServico();

        this.buscarFaturaItem();

        this.restaurarDialog();
    }

    onChanges(): void {

        this.faturaItemForm
            .get('valorBruto')
            .valueChanges
            .subscribe(data => this.calcularValorBruto(data, 0));
            
        this.faturaItemForm
            .get('valorDesconto')
            .valueChanges
            .subscribe(data => this.calcularValorBruto(0, data));
    }

    calcularValorBruto(valorBruto: number, valorDesconto: number) {

        if (valorDesconto == 0)
            valorDesconto = this.faturaItemForm.get('valorDesconto').value;

        if (valorBruto == 0)
            valorBruto = this.faturaItemForm.get('valorBruto').value;
            
        let valorTotal = valorBruto - valorDesconto;

        this.faturaItemForm.get('valorTotal').setValue(valorTotal);

    }

    calculavalorLiquido(): void {

        let valorLiquidoNfse = this.faturaItemForm.get('valorServicos').value
            - this.faturaItemForm.get('valorPis').value
            - this.faturaItemForm.get('valorCofins').value
            - this.faturaItemForm.get('valorInss').value
            - this.faturaItemForm.get('valorIr').value
            - this.faturaItemForm.get('valorCsll').value
            - this.faturaItemForm.get('outrasRetencoes').value
            - this.faturaItemForm.get('valorIssRetido').value
            - this.faturaItemForm.get('descontoIncondicionado').value
            - this.faturaItemForm.get('descontoCondicionado').value;

        let valorLiquidoNfseTemp = valorLiquidoNfse.toFixed(2);

        this.faturaItemForm.get('valorLiquidoNfse').setValue(valorLiquidoNfseTemp);
    }

    calculaImpostos(): void {

        if (this.fatura.faturaTipo == 1) {
            let valorBruto = this.faturaItemForm.get('valorBruto').value;
            this.faturaItemForm.get('valorBruto').setValue(valorBruto);

            this.faturaService.postCalculaImposto(this.faturaItemForm.value).subscribe(data => {

                this.faturaItemForm.get('aliquotaIssqn').setValue(data.body.aliquotaIssqn);
                this.faturaItemForm.get('valorPis').setValue(data.body.valorPis);
                this.faturaItemForm.get('valorCofins').setValue(data.body.valorCofins);
                this.faturaItemForm.get('valorIr').setValue(data.body.valorIr);
                this.faturaItemForm.get('valorInss').setValue(data.body.valorInss);
                this.faturaItemForm.get('valorCsll').setValue(data.body.valorCsll);
                this.faturaItemForm.get('baseCalculo').setValue(data.body.baseCalculo);
                this.faturaItemForm.get('valorLiquidoNfse').setValue(data.body.valorLiquidoNfse);
                this.faturaItemForm.get('valorIss').setValue(data.body.valorIss);
                this.faturaItemForm.get('valorIssRetido').setValue(data.body.valorIssRetido);
                this.faturaItemForm.get('issRetido').setValue(data.body.issRetido);

            });
        }
    }

    buscarFaturaItem() {

        if (this.faturaItemId > 0) {

            this.faturaService.getFaturaItem(this.faturaItemId).subscribe(data => {
                this.faturaItemForm.patchValue(data.body);
                this.faturaItemForm.get('produtoAutoComplete').setValue(data.body.descricao);

            });
        }
    }

    getListaServico() {

        this.faturaService.getListaServicos().subscribe(data => {

            this.listaServicos = data.body;

        });
    }

    fecharDialog() {
        this.dialogRef.close();
    }

    salvar() {

        if (this.faturaItemId == 0) {

            this.faturaItemForm.value.faturaId = this.fatura.faturaId;

            this.faturaService.postIncluiFaturaItem(this.faturaItemForm.value).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.fecharDialog();

            });
        }
        else {

            this.faturaService.putAtualizaFaturaItem(this.faturaItemForm.value, this.faturaItemForm.value.faturaItemId).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.fecharDialog();

            });
        }
    };

    onChangeValorComercial() {

        let vlrComercial = this.faturaItemForm.get('vlrComercial').value;
        let qtdComercial = this.faturaItemForm.get('qtdComercial').value;
        let valorBruto = Math.round((qtdComercial * vlrComercial) * 100) / 100;
        this.faturaItemForm.get('valorBruto').setValue(valorBruto.toFixed(2));
        this.faturaItemForm.get('qtdTributaria').setValue(qtdComercial);
        this.faturaItemForm.get('qtdComercial').setValue(qtdComercial);
        this.faturaItemForm.get('vlrTributario').setValue(vlrComercial);
        this.faturaItemForm.get('vlrComercial').setValue(vlrComercial);
    }

    maximizarDialog() {
        this.dialogRef.updateSize('100%', '100%');
        this.maximizado = true;
    }

    restaurarDialog() {
        this.dialogRef.updateSize('80vh', '100vh');
        this.maximizado = false;
    }
}
