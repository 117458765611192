<app-loader></app-loader>
<div class="example-container">
  <mat-sidenav-container class="example-sidenav-container">
    <mat-sidenav #sidenav mode="over" opened="false" class="header-sidebar">
      <br />
      <mat-nav-list *ngFor="let modulo of modulosIndex">
        <mat-list-item (click)="AtivarModulo(modulo)" class="parent fonte-branca">
          <span class="material-symbols-rounded">
            {{modulo.icone}}
          </span>
          <span class="full-width" *ngIf="isExpanded || isShowing"> {{modulo.nome}}</span>
          <span class="menu-button fa fa-chevron-down" [ngClass]="{'rotated' : moduloAtivo == modulo.nome}"></span>
        </mat-list-item>
        <div class="submenu" [ngClass]="{'expanded' : moduloAtivo == modulo.nome}" *ngIf="moduloAtivo == modulo.nome">
          <div *ngFor="let funcionalidade of modulo.funcionalidades">
            <a class="fonte-branca" mat-list-item [routerLink]="[funcionalidade.route]" (click)="sidenav.toggle();EscondeMenu()">{{funcionalidade.nome}}</a>
          </div>
        </div>
      </mat-nav-list>
      <mat-nav-list>
        <mat-list-item (click)="LogOut()" class="parent fonte-branca">
          <i class="fa fa-sign-out"></i>
          <span class="full-width" *ngIf="isExpanded || isShowing"> Sair</span>
        </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="right_col container-fluid" role="main">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <div class="top_nav" *ngIf="autenticado">
    <div class="nav_menu" style="background-image: url({{appImagemFundo}});">
      <nav class="nav navbar-nav">
        <a mat-button (click)="sidenav.toggle(); EscondeMenu()"><i class="fa fa-bars"></i></a>
        <a [routerLink]="'home'"><img  class="logo-saguicontrol" [src]="appLogo" /></a>
        <img *ngIf="logoSaguiCliente" class="logoCliente" src="data:image/jpg;base64,{{logoSaguiCliente}}" />
        <app-notification></app-notification>
      </nav>
    </div>
  </div>

</div>
