import { Component, OnInit, ViewChild } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { HeaderService } from './header/shared/header.service';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InfoDialogComponent } from './core/dialogs/info-dialog/info-dialog.component';
import { EventMessage, EventType, AuthenticationResult } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { NotificationComponent } from './notificacao/notification.component';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css'],
})

export class AppComponent implements OnInit  {
  @ViewChild(NotificationComponent) notification: NotificationComponent;

  title = 'saguicontol-web';
  menuClass: string = "nav-sm";
  modulosIndex: any = [];
  autenticado: boolean = false;
  moduloAtivo: string = "";
  logoSaguiCliente: string = "";
  fotoUsuario: string = "";
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  mobile: boolean = false;
  appLogo = '';
  appImagemFundo = '';

  notifications = [];

    constructor(

    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private headerService: HeaderService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar) {
  }

  ngOnInit() {

    let urlApp = window.location.hostname;

    if (urlApp.indexOf('saguilog') > 0) {
      this.appLogo = './assets/images/saguilog-logo-login.png';
      this.appImagemFundo = './assets/images/saguilog-polygon.jpg';
    }
    else {
      
      if (urlApp.indexOf('saguiedu') > 0) {
        this.appLogo = './assets/images/saguiedu-logo-login.png';
        this.appImagemFundo = './assets/images/saguiedu-polygon.jpg';
      }

      else {
        this.appLogo = './assets/images/saguicontrol-logo-login.png';
        this.appImagemFundo = './assets/images/saguicontrol-polygon.jpg';
      }
    }

    if (window.screen.width < 650) {
      this.mobile = true;
    }

    this.CheckAccount();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {

        const payload = result.payload as AuthenticationResult;

        if (payload?.account) {
          this.msalService.instance.setActiveAccount(payload.account);
          this.CheckAccount();
        }
      });
  }

  CheckAccount() {

    this.autenticado = this.msalService.instance.getAllAccounts().length > 0;

    if (this.autenticado) {

      this.CarregaMenu();

    }else{
      
    }

    /**
     * 
     * Configuração da tag do chat
     * 
     */

    const accounts = this.msalService.instance.getAllAccounts();
    accounts.forEach(account => {
      const user = {
          name: account.name,
          id: account.homeAccountId,
          email: account.username,
          createdAt: new Date().getTime()
      };
      // @ts-ignore
      window.fcWidget.setExternalId(user.id);
      // @ts-ignore
      window.fcWidget.user.setFirstName(user.name);
      // @ts-ignore
      window.fcWidget.user.setEmail(user.email);
      // @ts-ignore
      window.fcWidget.user.setProperties({
        cf_plan: "Pro",                 
        cf_status: "Active"             
      });
    });

    /**
     * 
     * Fim da Configuração da tag do chat
     * 
     */
  }

  onChanges(): void {

  }

  LogOut() {
    this.msalService.logout();
  };

  EscondeMenu() {

    if (this.menuClass == "nav-md") {
      this.menuClass = "nav-sm";
    } else {
      this.menuClass = "nav-md";
    }

  }

  AtivarModulo(modulo: any) {

    if (this.moduloAtivo != modulo.nome) {
      this.moduloAtivo = modulo.nome;
    } else {
      this.moduloAtivo = "";
    }
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async CarregaMenu() {

    this.headerService.getUsuarioModuloFuncionalidades().subscribe(data => {

      this.modulosIndex = data.body;

    });

    this.headerService.getLogo().subscribe(data => {

      this.logoSaguiCliente = data.body;

    });

  }

}


