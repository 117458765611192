'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';

import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';

import { ContaCorrenteVenda } from '../shared/conta-corrente-venda.model';
import { ContaCorrenteVendaService } from '../shared/conta-corrente-venda.service';
import { ContaCorrenteVendaParcelaComponent } from '../conta-corrente-venda-parcela/conta-corrente-venda-parcela.component';
import { ContaCorrenteVendaParcelaService } from '../shared/conta-corrente-venda-parcela.service';

export interface DialogData {
  contaCorrenteVendaId: number;
}

@Component({
  selector: 'conta-corrente-venda',
  templateUrl: 'conta-corrente-venda.component.html',
})

export class ContaCorrenteVendaComponent {

  contaCorrenteVendaForm: FormGroup;
  maximizado: boolean;
  contaCorrenteVendaParcelas: any = [];
  cartao: any = {};

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ContaCorrenteVendaComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public IContaCorrenteVenda: DialogData,
    private contaCorrenteVendaService: ContaCorrenteVendaService,
    private contaCorrenteVendaParcelaservice: ContaCorrenteVendaParcelaService) {
  }

  ngOnInit() {

    this.maximizado = false;

    this.contaCorrenteVendaForm = this._formBuilder.group(new ContaCorrenteVenda());

    if (this.IContaCorrenteVenda.contaCorrenteVendaId > 0) {

      this.buscarContaCorrenteVenda(this.IContaCorrenteVenda.contaCorrenteVendaId);

    }

    this.onChanges();

    this.restaurarDialog();
  }

  onChanges(): void {

  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {

    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  buscarContaCorrenteVenda(contaCorrenteVendaId: number) {

    this.contaCorrenteVendaService.getPorId(contaCorrenteVendaId).subscribe(data => {

      this.contaCorrenteVendaForm.patchValue(data.body);

      this.contaCorrenteVendaParcelas = data.body.contaCorrenteVendaParcelas;

      this.cartao = data.body.contaCorrenteTerminalCartao.cartao;

      this.contaCorrenteVendaParcelas.forEach(parcela => {
        parcela.numeroParcela = parcela.sequencia + "/" + data.body.numeroParcelas;
      });

    }, (error: HttpErrorResponse) => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          erro: error
        }
      });
    })
  }

  salvar(): void {

    if (this.contaCorrenteVendaForm.value.contaCorrenteVendaId == 0) {

      this.contaCorrenteVendaService.post(this.contaCorrenteVendaForm.value).subscribe(data => {

        this.buscarContaCorrenteVenda(data.body.contaCorrenteVendaId);

      });

    }
    else {
      this.contaCorrenteVendaService.put(this.contaCorrenteVendaForm.value, this.contaCorrenteVendaForm.value.contaCorrenteVendaId).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

        this.buscarContaCorrenteVenda(this.contaCorrenteVendaForm.value.contaCorrenteVendaId);

      });
    }
  };

  contaCorrenteVendaParcelaModal(contaCorrenteVendaParcelaId: number) {

    const dialogRef = this.dialog.open(ContaCorrenteVendaParcelaComponent, {
      data: {
        contaCorrenteVendaParcelaId: contaCorrenteVendaParcelaId,
        contaCorrenteVendaId: this.IContaCorrenteVenda.contaCorrenteVendaId
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(resultado => {

      this.buscarContaCorrenteVenda(this.IContaCorrenteVenda.contaCorrenteVendaId);

    });
  }

  excluirContaCorrenteVendaParcela(cancelarId) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja realmente excluir essa parcela?"
      }
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.contaCorrenteVendaParcelaservice.delete(cancelarId).subscribe(data => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Sucesso",
              mensagem: "Registro Cancelado com Sucesso"
            }
          });

          this.buscarContaCorrenteVenda(this.IContaCorrenteVenda.contaCorrenteVendaId);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  };
}
