<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>Relacionar Compra com NF-e Inbound</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<mat-dialog-content>
    <br />
    <form [formGroup]="nfePedidoCompraForm">
        <div class="row">
            <div class="form-group col-md-2">
                <label for="exibir">Gerada entre:</label>
            </div>
            <div class="form-group col-md-10">
                <mat-radio-group formControlName="vencimento">
                    <mat-radio-button value="hoje">Hoje</mat-radio-button>
                    <mat-radio-button value="essemes">Esse mês</mat-radio-button>
                    <mat-radio-button value="escolher">Escolher o período</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="row">
            <mat-form-field class="form-group col-md-3 col-xs-12">
                <mat-label>Data Inicial</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="dataInicial"
                    name="DataInicial" [disabled]="dataDisable">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="form-group col-md-3 col-xs-12">
                <mat-label>Data Final</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="dataFinal"
                    name="DataFinal" [disabled]="dataDisable">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>
        <br />
        <div class="row">
            <div class="col-md-6">
                <button mat-raised-button color="accent" (click)="buscaCompras()" matTooltip="Pesquisar Compras">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
            <div class="col-md-6">
                <button mat-raised-button color="accent" (click)="vincularCompras()" *ngIf="buscouCompras" matTooltip=" Vincular Compras">
                    Vincular Compras
                </button>
            </div>
        </div>
    </form>
    <br />
    <hr>
    <div id="list" class="row">
        <div class="table-responsive col-md-12">
            <table class="table table-striped" cellspacing="0" cellpadding="0">
                <thead>
                    <tr>
                        <th>Vincular?</th>
                        <th>ID</th>
                        <th>Status</th>
                        <th>Fatura</th>
                        <th>NF-e</th>
                        <th>Data da Compra</th>
                        <th>Data Prevista</th>
                        <th>Fornecedor</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let compra of compras">
                        <td class="actions">
                            <input type="checkbox" [(ngModel)]="compra.selected">
                        </td>
                        <td>{{compra.compraId}}</td>
                        <td>{{statusCompra(compra.compraStatus)}}</td>
                        <td>{{compra.faturaId > 0 ? compra.faturaId : 'Não Faturada' }}</td>
                        <td>{{compra.nfeId > 0 ? compra.nfeId : 'Não Recebida' }}</td>
                        <td>{{compra.dataCompra | date:'dd/MM/yyyy'}}</td>
                        <td>{{compra.dataPrevista | date:'dd/MM/yyyy'}}</td>
                        <td>{{compra.razaoSocial}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</mat-dialog-content>
