<section id="main-content">
  <div class="x_panel">
    <div class="x_content">
      <div class="row">
        <form [formGroup]="homeForm">
          <mat-form-field class="col-md-6 col-xs-12 form-group">
            <mat-label>Empresa</mat-label>
            <mat-select formControlName="empresaId">
              <mat-option *ngFor="let empresa of empresas" [value]="empresa.empresaId">
                {{empresa.nomeFantasia}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <div class="row" *ngIf="exibeFinanceiro">
        <h3>Mês Atual</h3>
        <hr />
        <div class="col-md-6 col-sm-12 col-xs-12">
          <canvas baseChart [datasets]="faturamentoMesChartData" [labels]="faturamentoMesChartLabels"
            [options]="faturamentoMesChartOptions" [legend]="faturamentoMesChartLegend"
            [chartType]="faturamentoMesChartType"></canvas>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <mat-card
            class="report-card {{total.receberPrevisto == 0 ? 'darkgreenblue-whitegreenblue-background report-card' : total.recebido/total.receberPrevisto >= 0.75 ? 'whitegreen-darkgreen-background report-card' : total.recebido/total.receberPrevisto >= 0.5 ? 'orange-yellow-background report-card'  : 'red-orange-background report-card'}}">
            <mat-card-content>
              <span class="report-card-title">{{total.recebido | currency}}</span>
              <i class="fa fa-arrow-circle-down fa-3x report-card-icon"></i>
              <p>Total Recebido</p>
              <span class="count_bottom">Previsão do mês: {{total.receberPrevisto | currency}}</span>
            </mat-card-content>
          </mat-card>
          <mat-card
            class="report-card {{total.recebido >= total.pago ? 'whitegreen-darkgreen-background report-card' : 'red-orange-background report-card'}}">
            <mat-card-content>
              <span class="report-card-title">{{total.pago | currency}} </span>
              <i class="fa fa-arrow-circle-up fa-3x report-card-icon"></i>
              <p>Total Pago</p>
              <span class="count_bottom">Previsão do mês: {{total.pagarPrevisto | currency}}</span>
            </mat-card-content>
          </mat-card>
          <mat-card
            class="report-card {{total.margemRealizada >= 10 ? 'whitegreen-darkgreen-background report-card' : total.margemRealizada >= 1 ? 'orange-yellow-background report-card'  : 'red-orange-background report-card'}}">
            <mat-card-content>
              <span class="report-card-title">{{total.margemRealizada | number: '1.2-2'}} %</span>
              <i class="fa fa-money fa-3x report-card-icon"></i>
              <p>Margem de Lucro Líquida</p>
              <span class="count_bottom">Previsão do mês: {{total.margemPrevista | number: '1.2-2'}} %</span>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <canvas baseChart [datasets]="despesasReceitasChartData" [labels]="despesasReceitasChartLabels"
            [options]="despesasReceitasChartOptions" [legend]="despesasReceitasChartLegend"
            [chartType]="despesasReceitasChartType"></canvas>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12" *ngIf="exibeFinanceiro">
      <div class="x_panel">
        <div class="x_content">
          <div id="list" class="row">
            <div class="col-md-12">
              <a href="#/financeiro/contapagar">
                <h3>Contas a Pagar<small> nesse mês</small></h3>
              </a>
              <hr />
              <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                  <mat-card
                    class="{{total.pagarPrevisto/total.receberPrevisto <= 0.75 ? 'whitegreen-darkgreen-background report-card' : total.pagarPrevisto/total.receberPrevisto <= 0.99 ? 'orange-yellow-background report-card'  : 'red-orange-background report-card'}}">
                    <mat-card-content>
                      <span class="report-card-title">{{total.pagarPrevisto | currency}}</span>
                      <i class="fa fa-arrow-circle-down fa-3x report-card-icon"></i>
                      <p>Total a Pagar Previsto</p>
                    </mat-card-content>
                  </mat-card>
                  <mat-card
                    class="report-card {{total.pago<=total.pagarPrevisto ? 'whitegreen-darkgreen-background report-card' : 'red-orange-background report-card'}}">
                    <mat-card-content>
                      <span class="report-card-title">{{total.pago | currency}}</span>
                      <i class="fa fa-arrow-circle-down fa-3x report-card-icon"></i>
                      <p>Total Pago</p>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                  <h4>Contas a Pagar Hoje</h4>
                  <hr />
                  <table class="table table-striped" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th>Descrição</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let parcelaPagar of parcelasPagar">
                        <td>{{parcelaPagar.descricao}}</td>
                        <td>{{parcelaPagar.valorParcela | currency}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12" *ngIf="exibeFinanceiro">
      <div class="x_panel">
        <div class="x_content">
          <div id="list" class="row">
            <div class="col-md-12">
              <a href="#/financeiro/contareceber">
                <h3>Contas a Receber<small> nesse mês</small></h3>
              </a>
              <hr />
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <mat-card class="report-card whitegreen-darkgreen-background">
                    <mat-card-content>
                      <span class="report-card-title">{{total.receberPrevisto | currency}}</span>
                      <i class="fa fa-arrow-circle-down fa-3x report-card-icon"></i>
                      <p>Total a Receber Previsto</p>
                    </mat-card-content>
                  </mat-card>
                  <mat-card
                    class="report-card {{total.receberPrevisto == 0 ? 'darkgreenblue-whitegreenblue-background report-card' : total.recebido/total.receberPrevisto >= 0.75 ? 'whitegreen-darkgreen-background report-card' : total.recebido/total.receberPrevisto >= 0.5 ? 'orange-yellow-background report-card'  : 'red-orange-background report-card'}}">
                    <mat-card-content>
                      <span class="report-card-title">{{total.recebido | currency}}</span>
                      <i class="fa fa-arrow-circle-down fa-3x report-card-icon"></i>
                      <p>Total Recebido</p>
                    </mat-card-content>
                  </mat-card>
                  <p></p>
                  <mat-card
                    class="report-card {{total.receberPrevisto == 0 ? 'darkgreenblue-whitegreenblue-background report-card' : total.recebido/total.receberPrevisto >= 0.75 ? 'whitegreen-darkgreen-background report-card' : total.recebido/total.receberPrevisto >= 0.5 ? 'orange-yellow-background report-card'  : 'red-orange-background report-card'}}">
                    <mat-card-content>
                      <span class="report-card-title">{{total.aReceber | currency}}</span>
                      <i class="fa fa-search-plus fa-3x report-card-icon"></i>
                      <p>A Receber ({{((total.aReceber * 100) / (total.recebido + total.aReceber)).toFixed(2)}}% em
                        aberto)</p>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-md-6">
                  <h4>Contas a Receber Hoje</h4>
                  <hr />
                  <table class="table table-striped" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th>Descrição</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let parcelaReceber of parcelasReceber">
                        <td>{{parcelaReceber.descricao}}</td>
                        <td>{{parcelaReceber.valorParcela | currency}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12" *ngIf="exibeVenda">
      <div class="x_panel">
        <div class="x_content">
          <div id="list" class="row">
            <div class="col-md-12">
              <a href="#/venda/pedidovenda">
                <h3>Vendas<small> nesse mês</small></h3>
              </a>
              <hr />
              <div class="row">
                <div class="col-md-5 col-sm-6 col-xs-12">
                  <mat-card class="report-card yellow-green-background">
                    <mat-card-content>
                      <span class="report-card-title">{{total.vendas}}</span>
                      <i class="fa fa-hand-holding-usd fa-3x report-card-icon"></i>
                      <p>Total de Vendas</p>
                      <span class="count_bottom"><i class="{{porcentagemVendas > 0 ? 'green' : 'red'}}">
                          <i class="{{porcentagemVendas > 0 ? 'fa fa-sort-asc': 'fa fa-sort-desc'}}"></i>
                          {{porcentagemVendas | number: '1.2-2'}}%</i> Comparado com o mês anterior</span>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-md-7 col-sm-6 col-xs-12">
                  <mat-card class="report-card yellow-green-background">
                    <mat-card-content>
                      <span class="report-card-title">{{total.vendasFaturamento | currency}}</span>
                      <i class="fa fa-money fa-3x report-card-icon"></i>
                      <p>Faturamento</p>
                      <span class="count_bottom"><i class="{{porcentagemVendasFaturamento > 0 ? 'green' : 'red'}}">
                          <i class="{{porcentagemVendasFaturamento > 0 ? 'fa fa-sort-asc': 'fa fa-sort-desc'}}"></i>
                          {{porcentagemVendasFaturamento | number: '1.2-2'}}%
                        </i> Comparado com o mês anterior</span>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12" *ngIf="exibeCompra">
      <div class="x_panel">
        <div class="x_content">
          <div id="list" class="row">
            <div class="col-md-12">
              <a href="#/compra/visualizatodas">
                <h3>Compras<small> nesse mês</small></h3>
              </a>
              <hr />
              <div class="row">
                <div class="col-md-5 col-sm-6 col-xs-12">
                  <mat-card class="report-card darkpurple-purple-background">
                    <mat-card-content>
                      <span class="report-card-title">{{total.compras}}</span>
                      <i class="fa fa-cart-plus fa-3x report-card-icon"></i>
                      <p>Total de Compras</p>
                      <span class="count_bottom">
                        <i class="{{porcentagemCompras > 0 ? 'green' : 'red'}}">
                          <i class="{{porcentagemCompras > 0 ? 'fa fa-sort-asc': 'fa fa-sort-desc'}}"></i>
                          {{porcentagemCompras | number: '1.2-2'}}%
                        </i> Comparado com o mês anterior</span>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-md-7 col-sm-6 col-xs-12">
                  <mat-card class="report-card darkpurple-purple-background">
                    <mat-card-content>
                      <span class="report-card-title">{{total.comprasFaturamento | currency}}</span>
                      <i class="fa fa-cart-plus fa-3x report-card-icon"></i>
                      <p>Faturamento</p>
                      <span class="count_bottom">
                        <i class="{{porcentagemComprasFaturamento > 0 ? 'green' : 'red'}}">
                          <i class="{{porcentagemComprasFaturamento > 0 ? 'fa fa-sort-asc': 'fa fa-sort-desc'}}"></i>
                          {{porcentagemComprasFaturamento | number: '1.2-2'}}%
                        </i> Comparado com o mês anterior
                      </span>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12" *ngIf="exibeFinanceiro">
      <div class="x_panel">
        <div class="x_content">
          <div id="list" class="row">
            <div class="table-responsive col-md-12">
              <a href="#/financeiro/fluxocaixa">
                <h3>Fluxo de Caixa Atual</h3>
              </a>
              <hr />
              <table class="table table-striped" cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <th>Caixa</th>
                    <th>Saldo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let caixa of caixas">
                    <td>{{caixa.nome}}</td>
                    <td>{{caixa.saldo | currency}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12" *ngIf="exibeEstoque">
      <div class="x_panel">
        <div class="x_content">
          <div id="list" class="row">
            <div class="table-responsive col-md-12 col-xs-12">
              <a href="#/estoque/visualizatodas">
                <h3>Estoques próximos ao mínimo</h3>
              </a>
              <hr />
              <table class="table table-striped" cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <th>Estoque</th>
                    <th>Mínimo</th>
                    <th>Quantidade Atual</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let estoque of estoques">
                    <td>{{estoque.produto.descricao}}</td>
                    <td>{{estoque.minimo}}</td>
                    <td>{{estoque.quantidade}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>