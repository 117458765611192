import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoaderInterceptor } from './core/interceptors/loader-interceptor.module';
import { MsalInterceptor } from '@azure/msal-angular';
import { ErrorHandler } from '@angular/core';
import { GlobalErrorHandlerService } from './core/interceptors/global-error-handler.service';

export const interceptorProviders =
  [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler, 
      useClass: GlobalErrorHandlerService
    }
  ];
