'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Location } from '@angular/common'; 
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { EmbalagemService } from '../shared/embalagem.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';

import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';

import { Embalagem } from '../shared/embalagem.model';

export interface DialogData {
    embalagemId: number;
}

@Component({
    selector: 'embalagem-cadastro',
    templateUrl: 'embalagem-cadastro.component.html',
})

export class EmbalagemCadastroComponent {

    empresas: any = [];
    embalagems: any = [];
    embalagemItens: any = [];
    embalagemCadastroForm: FormGroup;
    maximizado: boolean;

    constructor(
        public dialog: MatDialog,
    private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<EmbalagemCadastroComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public IEmbalagem: DialogData,
        private embalagemService: EmbalagemService) {
    }

    ngOnInit() {

        this.maximizado = false;

        this.embalagemCadastroForm = this._formBuilder.group(new Embalagem());

        if (this.IEmbalagem.embalagemId > 0) {

            this.buscarEmbalagem(this.IEmbalagem.embalagemId);

        }

        this.onChanges();

        this.restaurarDialog();
    }

    onChanges(): void {

    }


    fecharDialog() {
        this.dialogRef.close();
    }

    maximizarDialog() {
        this.dialogRef.updateSize('100%', '100%');
        this.maximizado = true;
    }

    restaurarDialog() {
        let innerWidth = window.innerWidth;

        if (innerWidth < 1024) {
            this.dialogRef.updateSize('90%', '100vh');
        }
        else {
            this.dialogRef.updateSize('60%', '100vh');
        }
        this.maximizado = false;
    }


    buscarEmbalagem(embalagemId: number) {

        this.embalagemService.getEmbalagem(embalagemId).subscribe(data => {

            this.InsereDadosEmbalagem(data.body);

        }, (error: HttpErrorResponse) => {

                const dialogRef = this.dialog.open(InfoDialogComponent, {
                    data: {
                        titulo: "Erro",
                        erro: error
                    }
            });
        })
    }


    InsereDadosEmbalagem(data: any) {

        this.embalagemCadastroForm.patchValue(data);

        this.embalagemItens = data.embalagemItens;
    }

    cancelarEmbalagem(cancelarId) {

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                titulo: "Confirmação",
                mensagem: "Deseja realmente Cancelar essa embalagem?"
            }
        });


        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                this.embalagemService.deleteEmbalagem(cancelarId).subscribe(data => {

                    this._snackBar.open("Registro Cancelado com Sucesso");

                    this.buscarEmbalagem(cancelarId);

                });
            }
        });
    };

    salvar(embalagemForm): void {


        if (embalagemForm.value.embalagemId == undefined) {

            this.embalagemService.postEmbalagem(embalagemForm.value).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.buscarEmbalagem(data.body.embalagemId);


            }, (error: HttpErrorResponse) => {

                    const dialogRef = this.dialog.open(InfoDialogComponent, {
                        data: {
                            titulo: "Erro",
                            erro: error
                        }
                });
            });

        }
        else {
            this.embalagemService.putEmbalagem(embalagemForm.value, embalagemForm.value.embalagemId).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.buscarEmbalagem(embalagemForm.value.embalagemId);


            }, (error: HttpErrorResponse) => {

                    const dialogRef = this.dialog.open(InfoDialogComponent, {
                        data: {
                            titulo: "Erro",
                            erro: error
                        }
                });
            });
        }

    };
}
