<mat-toolbar>
    <div class="md-toolbar-tools">
      <h1 mat-dialog-title>Faturar Contrato {{contrato.contratoId}}</h1>
      <span flex></span>
      <div class="posicionar-direita">
        <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
          <mat-icon>fullscreen_exit</mat-icon>
        </button>
        <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
          <mat-icon>fullscreen</mat-icon>
        </button>
        <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>
  <div mat-dialog-actions>
  
  </div>
  <mat-dialog-content>
    <div class="md-dialog-content">
        <form [formGroup]="configuracoesFatura">
            <mat-vertical-stepper [linear]="true" #stepper formArrayName="formArray" (selectionChange)="onStepChange($event)">

                <mat-step formGroupName="0" [stepControl]="formArray.get([0])">
                    
                    <ng-template matStepLabel>Em qual empresa você deseja Faturar?</ng-template>
                <hr>
                <div class="row">
                    <mat-form-field class="col-md-6 col-xs-12 form-group">
                      <mat-label>Empresa</mat-label>
                      <mat-select formControlName="empresaId">
                        <mat-option *ngFor="let empresa of empresas" [value]="empresa.empresaId">
                          {{empresa.nomeFantasia}} - CNPJ {{empresa.cnpj}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                <hr>
                    <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-6"><button matStepperNext mat-raised-button color="primary">Próximo</button></div>
                      
                      
                    </div>

                </mat-step>
                <mat-step formGroupName="1" [stepControl]="formArray.get([1])" >
                    
                    <ng-template matStepLabel>Quais itens desse Contrato serão Faturados?</ng-template>
                <hr>
                <div class="row">
                    <div class="col-md-12">
                        <app-table fixed
                        [dataSource]="dataSource" 
                        [columns]="columns"
                        [enableSelection]="true"
                        [enablePagination]="false"
                        (selectionChange)="onSelectionChange($event)"> 
                    </app-table>
                    </div>
                  </div>
                <hr>
                    <div class="row">
                        <div class="col-md-6"><button matStepperPrevious mat-raised-button>Anterior</button></div>
                        <div class="col-md-6"><button matStepperNext mat-raised-button color="primary" [disabled]="!isItemSelected()" >Próximo</button></div>
                      
                      
                    </div>

                </mat-step>
                <mat-step formGroupName="2" [stepControl]="formArray.get([2])">
                    <ng-template matStepLabel>Quais Parcelas você deseja Faturar?</ng-template>
                    <hr>
                    <div class="row form-group">
                       
                        <mat-radio-group aria-label="Select an option" formControlName="quaisParcelasFaturar" required>
                          <div class="col-md-6"> <mat-radio-button value="todas">Faturar todas as parcelas
                                desse Contrato</mat-radio-button></div>
                                <div class="col-md-6"><mat-radio-button value="algumas">Faturar apenas parcelas com vencimento
                                em um determinado período</mat-radio-button></div>
                        </mat-radio-group>
                        

                    </div>
                    <div class="row" *ngIf="configuracoesFatura.get('formArray').get([2]).get('quaisParcelasFaturar').value =='algumas'">
                      <div class="col-md-6"></div>
                      <div class="col-md-3">
                          <mat-form-field>
                            <mat-label>Data da Início</mat-label>
                            <input matInput [matDatepicker]="picker1" formControlName="dataInicio"
                              (click)="picker1.open()">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                          </mat-form-field>
                        </div>
                        <div class="col-md-3">
                          <mat-form-field>
                            <mat-label>Data da Fim</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="dataFim"
                              (click)="picker2.open()">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                    <hr>
                    <div class="row">
                      <div class="col-md-6"><button matStepperPrevious mat-raised-button>Anterior</button></div>
                        <div class="col-md-6"><button matStepperNext mat-raised-button color="primary" [disabled]="configuracoesFatura.get('formArray').get([2]).invalid">Próximo</button></div>
                    </div>
                  
                </mat-step>
                <mat-step formGroupName="3" [stepControl]="formArray.get([3])">
                    
                    <ng-template matStepLabel>Como você deseja Faturar?</ng-template>
                <hr>
                <div class="row form-group">
                    <mat-radio-group aria-label="Selecione uma opção" formControlName="comoFaturar" required>
                      <div class="col-md-6">
                        <mat-radio-button value="separado">
                          Criar <b>várias faturas</b>, uma para cada parcela deste contrato.
                          <mat-icon matTooltip="Esta opção cria uma fatura separada para cada parcela do contrato. Exemplo: Se o contrato tem 3 parcelas, serão geradas 3 faturas distintas, uma para cada parcela.">info</mat-icon>
                            
                          </mat-radio-button>
                          
                        </div>
                            <div class="col-md-6">
                              <mat-radio-button value="junto"> 
                              Criar <b>uma única fatura</b> que inclua todas as parcelas deste contrato.
                              <mat-icon matTooltip="Esta opção gera uma única fatura que agrega todas as parcelas do contrato. Exemplo: Se o contrato tem 3 parcelas, será gerada uma única fatura com 3 parcelas dentro dela. Ou seja, um espelho do contrato">info</mat-icon>
                            </mat-radio-button>
                            </div>
                            </mat-radio-group>

                </div>
                <hr>
                    <div class="row">
                      <div class="col-md-6"><button matStepperPrevious mat-raised-button>Anterior</button></div>
                        <div class="col-md-6"><button matStepperNext mat-raised-button color="primary" >Próximo</button></div>
                      
                      
                    </div>

                </mat-step>
                <mat-step formGroupName="4" [stepControl]="formArray.get([4])">
                 <ng-template matStepLabel>Informações Financeiras</ng-template>
                  <hr>
                  <div class="row form-group">
                    <div class="row"><div class="col-md-12"> <p>Insira as informações necessárias para faturamento</p></div></div>
                    <br>
                   <div class="row">
                    <mat-form-field class="col-md-4 col-xs-12 form-group">
                      <mat-label>Centro de Custo</mat-label>
                      <mat-select formControlName="centroCustoId">
                        <mat-option *ngFor="let centroCusto of centroCustos" [value]="centroCusto.centroCustoId">
                          {{centroCusto.descricao}}
                        </mat-option>
                        <mat-option *ngIf="centroCustos?.length == 0" disabled>
                          ❌ Não existem Centro de Custos cadastrados para a empresa de faturamento selecionada. <a (click)="centroCusto()">Clique aqui</a> para visualizar todos os centros de custos disponíveis.  
                         </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-4 col-xs-12 form-group">
                      <mat-label>Categoria</mat-label>
                      <mat-select formControlName="grupoContaId">
                        <mat-option *ngFor="let grupoConta of grupoContas" [value]="grupoConta.grupoContaId">
                          {{grupoConta.descricao}}
                        </mat-option>
                        <mat-option *ngIf="grupoContas?.length == 0" disabled>
                          ❌ Não existem Categorias cadastradas para a empresa de faturamento selecionada. <a (click)="categoriasFinanceiras()">Clique aqui</a> para visualizar todos as categorias disponíveis.  
                         </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-4 col-xs-12 form-group">
                      <mat-label>Caixa (Fluxo de Caixa)</mat-label>
                      <mat-select formControlName="caixaId">
                        <mat-option *ngFor="let caixa of caixas" [value]="caixa.caixaId">
                          {{caixa.nome}}
                        </mat-option>
                        <mat-option *ngIf="caixas?.length == 0" disabled>
                          ❌ Não existem Caixas cadastrados para a empresa de faturamento selecionada. <a (click)="caixaFinanceiro()">Clique aqui</a> para visualizar todos os templates disponíveis.  
                         </mat-option>
                      </mat-select>
                    </mat-form-field>
                   </div>
                  </div>
                  <hr>
                  <div class="row">
                      <div class="col-md-6"><button matStepperPrevious mat-raised-button>Anterior</button></div>
                      <div class="col-md-6"><button matStepperNext mat-raised-button color="primary">Próximo</button></div>
                    
                    
                  </div>

              </mat-step>
              <mat-step *ngIf="integracaoSOC" formGroupName="5" [stepControl]="formArray.get([5])">
                 
                <ng-template matStepLabel>Integração com o SOC</ng-template>
                <hr>
                <div class="row form-group">
                <mat-checkbox formControlName="substituirSoc">Substituir essa fatura pelas faturas que vierem do SOC sempre que a Integração com o SOC Títulos rodar.
                  <mat-icon matTooltip="Sempre que integração com o SOC Títulos rodar, essa fatura será inativada e substituída pela fatura que vier do SOC">info</mat-icon>
                    
                </mat-checkbox>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-6"><button matStepperPrevious mat-raised-button>Anterior</button></div>
                    <div class="col-md-6"><button matStepperNext mat-raised-button color="primary">Próximo</button></div>
                  
                  
                </div>

            </mat-step>
          
              <mat-step>
                <ng-template matStepLabel>Confirmar</ng-template>
                O sistema criará as Faturas abaixo, com suas respectivas Parcelas. Deseja confirmar essa operação?
                <div class="row">
                  <mat-list class="faturas">
                    <!-- Itera sobre cada fatura com um índice sequencial -->
                    <div *ngFor="let fatura of faturas; let i = index">
                      <!-- Cabeçalho para cada fatura -->
                      <div mat-subheader>
                        Fatura #{{ i + 1 }} - Valor Total Líquido: {{ fatura.valorLiquido | currency }}
                      </div>
                  
                      <!-- Itera sobre cada parcela da fatura atual -->
                      <mat-list>
                        <mat-list-item *ngFor="let parcela of fatura.faturaParcelas; let y = index">
                          <mat-icon mat-list-icon>local_atm</mat-icon>
                          <div mat-line>Parcela #{{ y + 1 }}</div>
                          <div mat-line>Data de Vencimento: {{ parcela.dataVencimento | date }}</div>
                          <div mat-line>Valor da Parcela: {{ parcela.valorParcela | currency }}</div>
                          <div mat-line>Valor Bruto: {{ parcela.valorBruto | currency }}</div>
                        </mat-list-item>
                      </mat-list>
                  
                      <mat-divider></mat-divider>
                    </div>
                  </mat-list>
                </div>
                <hr>
                <div class="row">
                  <div class="col-md-6"><button matStepperPrevious mat-raised-button (click)="stepper.reset()">Voltar</button></div>
                  <div class="col-md-6"><button matStepperNext mat-raised-button color="primary" [disabled]="configuracoesFatura.invalid" (click)="salvarFaturas()">Criar Fatura(s)</button></div>
                </div>
              </mat-step>
 
       
              </mat-vertical-stepper>

        </form>
     
    </div>
      

  </mat-dialog-content>
  