'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, switchMap } from "rxjs/operators";

import { OrcamentoService } from '../shared/orcamento.service';

import { OrcamentoItemComponent } from '../orcamento-item/orcamento-item.component';

import { DocumentoCadastroComponent } from '../../documento/documento-cadastro/documento-cadastro.component';
import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';

import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';

import { Orcamento } from '../shared/orcamento.model';
import { OrcamentoParcela } from '../shared/orcamento-parcela.model';
import { ClienteCadastroComponent } from 'src/app/cliente/cliente-cadastro/cliente-cadastro.component';
import { forEach } from 'jszip';
import { DescontoAdicionalComponent } from '../desconto-adicional/desconto-adicional.component';
import { OrcamentoParcelaDataVencimento } from '../shared/OrcamentoParcelaDataVencimento.model';

export interface DialogData {
  orcamentoId: number;
}

@Component({
  selector: 'orcamento-cadastro',
  templateUrl: 'orcamento-cadastro.component.html',
})

export class OrcamentoCadastroComponent {

  empresas: any = [];
  vendedores: any = [];
  orcamentos: any = [];
  parceiros: any[];
  orcamentoCadastroForm: FormGroup;
  maximizado: boolean;
  tipoOrcamento: number = 0;
  orcamentoParcelasServico: OrcamentoParcela[];
  orcamentoParcelasProduto: OrcamentoParcela[];
  orcamentoItens: any = [];
  temProdutoAcabado = false;
  temServico = false;
  temUsoConsumo = false;
  temAtivoImobilizado = false;
  temMateriaPrima = false;
  temEmbalagem = false;
  orcamento: any;
  dataVencimentoParcela: FormGroup;
  currentDate = new Date();
  parceirosEncontrados: boolean = true;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<OrcamentoCadastroComponent>,
    private _formBuilder: FormBuilder,
    private htmlPrinterService: HtmlPrinterService,
    @Inject(MAT_DIALOG_DATA) public IOrcamento: DialogData,
    private orcamentoService: OrcamentoService,
  ) {
  }

  ngOnInit() {

    const brTime = this.currentDate.toLocaleString("en-US", { timeZone: "America/Sao_Paulo" });
    this.currentDate = new Date(brTime);

    this.maximizado = false;

    this.dataVencimentoParcela = new FormGroup({
      dataVencimentoParcela: new FormControl(new Date())
    });

    this.orcamentoCadastroForm = this._formBuilder.group({
      orcamentoId: [0],
      tipoOrcamento: [0],
      dataCriacao: [new Date()],
      status: [0],
      dataOrcamento: [new Date()],
      dataValidade: [new Date()],
      comunicacaoInterna: [''],
      empresaId: [0],
      parceiroId: [0],
      nomeParceiro: [''],
      valorTotalProduto: [0],
      valorTotalServico: [0],
      valorDesconto: [0],
      valorBruto: [0],
      valorLiquido: [0],
      valorAposRetencoes: [0],
      vendedorId: [0],
      saguiClienteId: [0],
      orcamentoItens: [[]],
      orcamentoParcelas: [[]],
      numeroParcelasProduto: [1],
      diaVencimentoProduto: [1],
      numeroParcelasServico: [1],
      diaVencimentoServico: [1],
      valorTotal: [0],
      valorImpostosRetidos: [0],
      valorTotalProdutos: [0],
      valorDescontoProdutos: [0],
      valorBrutoProdutos: [0],
      valorImpostosRetidosProdutos: [0],
      valorAposRetencoesProdutos: [0],
      valorTotalServicos: [0],
      valorDescontoServicos: [0],
      valorBrutoServicos: [0],
      valorImpostosRetidosServicos: [0],
      valorAposRetencoesServicos: [0],
      observacoes: [''],
      descontosItensEmReais: [0.00],
      descontosAdicionaisEmReais: [0.00],
      somaTotalDescontosEmReais: [0.00],
      descontosItensEmPorcentagem: [0.00],
      descontosAdicionaisEmPorcentagem: [0.00],
      somaTotalDescontosEmPorcentagem: [0.00],
      natureza: [0],
      valorIcms: [0], // added
      valorIcmsSt: [0], // added
      valorIpi: [0], // added
      valorPis: [0], // added
      valorPisSt: [0], // added
      valorCofins: [0], // added
      valorCofinsSt: [0], // added
      valorInss: [0], // added
      valorIr: [0], // added
      valorCsll: [0], // added
      valorIss: [0], // added
      issRetido: [false], // added
      pisRetido: [false], // added
      cofinsRetido: [false], // added
      csllRetido: [false], // added
      irRetido: [false], // added
      inssRetido: [false], // added
      modNFe: [0], // added
      // ... outros campos do seu modelo
    });

    this.orcamentoCadastroForm.addControl('parceiroAutoComplete', new FormControl(''));

    if (this.IOrcamento.orcamentoId > 0) {
      this.buscarOrcamento(this.IOrcamento.orcamentoId);
    }

    this.getEmpresas();

    this.getFuncionarios();

    this.onChanges();

    this.maximizarDialog();
  }

  onChanges(): void {
    this.orcamentoCadastroForm
      .get('parceiroAutoComplete')
      .valueChanges
      .pipe(
        debounceTime(450),
        switchMap(value => value?.length >= 3 ?
          this.orcamentoService.getClientePorNome(value) : []
        )
      )
      .subscribe(data =>
        this.putDataInParceiros(data)
      );



  }

  putDataInParceiros(data) {

    this.parceiros = data.body;
    this.parceirosEncontrados = true;
    if (this?.parceiros?.length == 0) {
      this.parceirosEncontrados = false;
    }
  }


  determinaParceiro(parceiro: any) {

    if (parceiro.clienteId > 0) {
      this.orcamentoCadastroForm.get('parceiroId').setValue(parceiro.clienteId);
      this.determinaCliente(parceiro);
    } else {
      this.orcamentoCadastroForm.get('parceiroId').setValue(parceiro.fornecedorId);
    }

  }

  getEmpresas() {

    this.orcamentoService.getEmpresa().subscribe(data => {

      this.empresas = data.body;

    });
  }

  getFuncionarios() {

    this.orcamentoService.getVendedores().subscribe(data => {

      this.vendedores = data.body;

    });
  }

  fecharDialog() {
    this.dialogRef.close();
  }

  openDialogDescontoAdicional() {

    const dialogRef = this.dialog.open(DescontoAdicionalComponent, {
      data: {
        orcamentoId: this.orcamentoCadastroForm.value.orcamentoId,
        vendaId: 0,
        orcamento: this.orcamento
      },
      height: '55vh',
      width: '45vw'
    });

    dialogRef.afterClosed().subscribe(() => {
      this.ngOnInit();
    });

  }
  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  buscarOrcamento(orcamentoId: number) {

    this.orcamentoService.getOrcamento(orcamentoId).subscribe(data => {

      this.InsereDadosOrcamento(data.body);

    });
  }

  InsereDadosOrcamento(data: any) {
    this.orcamento = data;
    this.orcamentoCadastroForm.patchValue(data);
    if (this.orcamento.modNfe !== undefined) {
      this.orcamentoCadastroForm.patchValue({ modNFe: this.orcamento.modNfe });
  }

    this.orcamentoItens = data.orcamentoItens;
    if (data.orcamentoParcelas.length > 0) {
      this.dataVencimentoParcela.get('dataVencimentoParcela').setValue(new Date(data.orcamentoParcelas[0].dataVencimento));
    } else {
      this.dataVencimentoParcela.value.dataVencimentoParcela = new Date();
    }

    this.orcamentoParcelasProduto = [];
    this.orcamentoParcelasServico = [];

    let parcelas = data.orcamentoParcelas;
    let arrayLength = parcelas.length;

    for (var i = 0; i < arrayLength; i++) {

      let parcela: OrcamentoParcela = new OrcamentoParcela();

      parcela.dataVencimento = new Date(parcelas[i].dataVencimento);
      parcela.orcamentoParcelaId = parcelas[i].orcamentoParcelaId;
      parcela.valorParcela = parcelas[i].valorParcela;
      parcela.orcamentoId = parcelas[i].orcamentoId;
      parcela.produtoTipo = parcelas[i].produtoTipo;

      if (parcelas[i].produtoTipo == 0) {
        this.orcamentoParcelasProduto.push(parcela);
      }

      if (parcelas[i].produtoTipo == 1) {
        this.orcamentoParcelasServico.push(parcela);
      }
    }

    let parceiro: any = {};

    if (data.tipoOrcamento == 0) {

      this.orcamentoService.getClientePorId(data.parceiroId).subscribe(dataCliente => {
        parceiro = dataCliente.body;
        this.orcamentoCadastroForm.get('parceiroAutoComplete').setValue(parceiro.nomeFantasia);

      });

    }
    else {

      this.orcamentoService.getFornecedorPorId(data.parceiroId).subscribe(dataFornecedor => {
        parceiro = dataFornecedor.body;
        this.orcamentoCadastroForm.get('parceiroAutoComplete').setValue(parceiro.nomeFantasia);

      });

    }
  }

  cancelarOrcamento(cancelarId) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja realmente Cancelar essa orcamento?"
      }
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.orcamentoService.deleteOrcamento(cancelarId).subscribe(data => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Sucesso",
              mensagem: "Registro Cancelado com Sucesso"
            }
          });

          this.buscarOrcamento(cancelarId);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  };

  determinaCliente(cliente: any) {
    this.orcamentoCadastroForm.value.clienteId = cliente.clienteId;
    this.orcamentoCadastroForm.value.issRetido = cliente.retidoIssqn;
    this.orcamentoCadastroForm.value.pisRetido = cliente.retidoPis;
    this.orcamentoCadastroForm.value.cofinsRetido = cliente.retidoCofins;
    this.orcamentoCadastroForm.value.csllRetido = cliente.retidoCsll;
    this.orcamentoCadastroForm.value.irRetido = cliente.retidoIrrf;
    this.orcamentoCadastroForm.value.inssRetido = cliente.retidoInss;
  }

  salvar(orcamentoForm): void {
    if (orcamentoForm.value.orcamentoId == 0) {
         orcamentoForm.value.modNFe = 99;

      this.orcamentoService.postOrcamento(orcamentoForm.value).subscribe(data => {

        this.orcamentoCadastroForm.get('orcamentoId').setValue(data.body.orcamentoId);

        this.ModalItem(0);

        this.buscarOrcamento(data.body.orcamentoId);

      });

    }
    else {

      let orcamentoTemp = orcamentoForm.value;

      orcamentoTemp.orcamentoParcelas = [];

      this.orcamentoParcelasProduto.forEach(item => {
        orcamentoTemp.orcamentoParcelas.push(item);
      })

      this.orcamentoParcelasServico.forEach(item => {
        orcamentoTemp.orcamentoParcelas.push(item);
      });

      this.orcamentoService.putOrcamento(orcamentoTemp, orcamentoTemp.orcamentoId).subscribe(data => {

        this._snackBar.open("Registro Salvo com Sucesso");

        this.buscarOrcamento(orcamentoForm.value.orcamentoId);


      });
    }

  };

  salvarParcelas(parcelas: any) {
    this.orcamentoParcelasProduto.map(item => {
      item.orcamentoParcelaId = 0;
      return item;
    });

    this.orcamentoParcelasServico.map(item => {
      item.orcamentoParcelaId = 0;
      return item;
    });

    this.orcamentoService.postOrcamentoParcela(parcelas).subscribe(data => {
      data.body.orcamentoParcelas.map(item => {
        if (item.produtoTipo == 0) {
          this.orcamentoParcelasProduto.push(item);
        }

        if (item.produtoTipo == 1) {
          this.orcamentoParcelasServico.push(item);
        }
      });
    });
  }

  imprimir(): void {

    let valorTotal: number = 0;

    for (let i = 0; i < this.orcamentoItens.length; i++) {
      valorTotal += this.orcamentoItens[i].valorBruto;
    }

    this.orcamentoCadastroForm.value.valorBruto = valorTotal;
    this.orcamentoService.postImprimir(this.orcamentoCadastroForm.value).subscribe(data => {

      let titulo = "Orçamento";

      this.htmlPrinterService.printHtmlString(data.body.htmlString, titulo);

    });

  }

  ModalItem(orcamentoItemId): void {

    const dialogRef = this.dialog.open(OrcamentoItemComponent, {
      data: {
        orcamentoItemId: orcamentoItemId,
        Orcamento: this.orcamentoCadastroForm.value,
      },
      maxHeight: '100vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscarOrcamento(this.orcamentoCadastroForm.get('orcamentoId').value);
    });

  }

  geraVenda() {

    this.orcamentoService.postGerarVenda(this.orcamentoCadastroForm.value).subscribe(data => {

      this.buscarOrcamento(this.orcamentoCadastroForm.get('orcamentoId').value);
      this.visualizaFluxoDocumentos();

    });
  };

  definirStatus(status: number) {

    this.orcamentoCadastroForm.get('status').setValue(status);

    this.orcamentoService.putAtualizaStatus(this.orcamentoCadastroForm.value, this.orcamentoCadastroForm.value.orcamentoId).subscribe(data => {

      this.buscarOrcamento(this.orcamentoCadastroForm.get('orcamentoId').value);

    });
  };

  visualizaFluxoDocumentos() {

    const dialogRef = this.dialog.open(DocumentoCadastroComponent, {
      data: {
        tipoOrigem: 4,
        idDocumento: this.orcamentoCadastroForm.get('orcamentoId').value,
      },
      maxHeight: '100vh'
    });
  }

  DeleteOrcamentoItem(orcamentoItemId) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja realmente excluir esse item?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        this.orcamentoService.deleteOrcamentoItem(orcamentoItemId).subscribe(data => {

          this.buscarOrcamento(this.orcamentoCadastroForm.get('orcamentoId').value);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  };

  definirComoenviadoAoCliente() {

    this.orcamentoService.postDefinirComoenviadoAoCliente(this.orcamentoCadastroForm.value).subscribe(data => {

      const dialogRefEstoque = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Sucesso",
          mensagem: "Orçamento Confirmado"
        }
      });

      this.buscarOrcamento(this.orcamentoCadastroForm.value.orcamentoId);

    });

  }

  retornarTipoItem(produtoTipo: number) {
    switch (produtoTipo) {
      case 0:
        this.temProdutoAcabado = true;
        return 'Produto Acabado';
      case 1:
        this.temServico = true;
        return 'Serviço';
      case 2:
        this.temUsoConsumo = false;
        return 'Uso e Consumo';
      case 3:
        this.temAtivoImobilizado = false;
        return 'Ativo Imobilizado';
      case 4:
        this.temMateriaPrima = false;
        return 'Matéria Prima';
      case 5:
        this.temEmbalagem = false;
        return 'Embalagem';
    }
  }

  gerarParcelas(tipoProduto: number) {

    const orcamentoParcelaDataVencimento = new OrcamentoParcelaDataVencimento();

    orcamentoParcelaDataVencimento.orcamento = this.orcamentoCadastroForm.value;
    orcamentoParcelaDataVencimento.dataVencimento = this.dataVencimentoParcela.value.dataVencimentoParcela;

    if ((tipoProduto == 1 && (
      this.orcamentoCadastroForm.value.numeroParcelasServico == 0 ||
      this.orcamentoCadastroForm.value.diaVencimentoServico == 0)) ||
      (tipoProduto == 0 && (
        this.orcamentoCadastroForm.value.numeroParcelasProduto == 0 ||
        this.orcamentoCadastroForm.value.diaVencimentoProduto == 0))) {
      this.mostrarDialogoDeErro("Erro", "O número de parcelas e o dia devem ser maiores que zero");
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Gerar Novas Parcelas",
        mensagem: "Esse procedimento irá excluir as parcelas atuais e criar novas. Deseja continuar?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (tipoProduto == 0) {
          this.gerarParcelasProduto(orcamentoParcelaDataVencimento);
        } else if (tipoProduto == 1) {
          this.gerarParcelasServico(orcamentoParcelaDataVencimento);
        }
      }
    });

  }

  gerarParcelasProduto(orcamentoParcelaDataVencimento) {
    this.orcamentoService.postGerarorcamentoParcelasProduto(orcamentoParcelaDataVencimento).subscribe(
      () => {
        this.mostrarDialogoDeSucesso("Sucesso", "Parcelas geradas com sucesso");
        this.buscarOrcamento(this.orcamentoCadastroForm.get('orcamentoId').value);
      },
      (error: HttpErrorResponse) => {
        this.mostrarDialogoDeErro("Erro", "Ocorreu um erro ao gerar as parcelas de produtos: " + error.message);
      }
    );
  }



  gerarParcelasServico(orcamentoParcelaDataVencimento) {
    this.orcamentoService.postGerarorcamentoParcelasServico(orcamentoParcelaDataVencimento).subscribe(
      () => {
        this.mostrarDialogoDeSucesso("Sucesso", "Parcelas geradas com sucesso");
        this.buscarOrcamento(this.orcamentoCadastroForm.get('orcamentoId').value);
      },
      (error: HttpErrorResponse) => {
        this.mostrarDialogoDeErro("Erro", "Ocorreu um erro ao gerar as parcelas: " + error.message);
      }
    );
  }

  mostrarDialogoDeErro(titulo: string, mensagem: string, erro?: HttpErrorResponse) {
    let mensagemErro: string = 'Erro desconhecido';
    if (erro && erro.error) {
      mensagemErro = erro.error.message;
    }

    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        titulo: titulo,
        mensagem: mensagem,
        erro: mensagemErro
      }
    });
  }

  mostrarDialogoDeSucesso(titulo: string, mensagem: string) {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        titulo: titulo,
        mensagem: mensagem
      }
    });
  }

  adicionarNovaParcelaVazia(produtoTipo: number, valorParcela: number) {
    const parcela: OrcamentoParcela = new OrcamentoParcela();
    parcela.dataVencimento = new Date();
    parcela.valorParcela = valorParcela;
    parcela.orcamentoId = this.orcamentoCadastroForm.get('orcamentoId').value;
    parcela.produtoTipo = produtoTipo;

    switch (produtoTipo) {
      case 0:
        this.orcamentoParcelasProduto.push(parcela);
        break;
      case 1:
        this.orcamentoParcelasServico.push(parcela);
        break;
    }
  }

  DeleteOrcamentoParcela(orcamentoParcelaId, produtoTipo) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja realmente excluir essa parcela?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (orcamentoParcelaId.toString().indexOf("temp_") >= 0) {
          if (produtoTipo == 0) {
            this.removerParcelaDoProduto(orcamentoParcelaId);
          } else if (produtoTipo == 1) {
            this.removerParcelaDoServico(orcamentoParcelaId);
          }
        } else {
          this.orcamentoService.deleteOrcamentoParcela(orcamentoParcelaId).subscribe(
            () => {
              this.buscarOrcamento(this.orcamentoCadastroForm.get('orcamentoId').value);
            },
            (error: HttpErrorResponse) => {
              this.mostrarDialogoDeErro("Erro", "Ocorreu um erro ao excluir a parcela: " + error.message);
            }
          );
        }
      }
    });
  }

  removerParcelaDoProduto(orcamentoParcelaId) {
    const parcToRemove = this.orcamentoParcelasProduto.find(x => x.orcamentoParcelaId == orcamentoParcelaId);
    if (parcToRemove) {
      const index = this.orcamentoParcelasProduto.indexOf(parcToRemove);
      if (index > -1) {
        this.orcamentoParcelasProduto.splice(index, 1);
      }
    }
  }

  removerParcelaDoServico(orcamentoParcelaId) {
    const parcToRemove = this.orcamentoParcelasServico.find(x => x.orcamentoParcelaId == orcamentoParcelaId);
    if (parcToRemove) {
      const index = this.orcamentoParcelasServico.indexOf(parcToRemove);
      if (index > -1) {
        this.orcamentoParcelasServico.splice(index, 1);
      }
    }
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  clienteModal(clienteId: number, clienteNome: string) {

    const dialogRef = this.dialog.open(ClienteCadastroComponent, {
      data: {
        clienteId: clienteId,
        clienteNome: clienteNome
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.orcamentoCadastroForm.get('parceiroAutoComplete').setValue(result.nomeFantasia);
      this.orcamentoCadastroForm.get('parceiroId').setValue(result.clienteId);

      this.parceirosEncontrados = true;
    });
  };

  descricaoStatus(status: number): string {

    return this.orcamentoService.retornarStatus(status);
  }


}


