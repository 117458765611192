import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class ClienteService {

    postVisualizaClientesPorFiltro(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/cliente/visualizaclienteporfiltro/', item, { observe: 'response' })
    }

    getEmpresas(): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/cliente/visualizatodasempresas', { observe: 'response' })
    }
    getEmpresasAllDados(): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/cliente/empresas', { observe: 'response' })
    }

    getCliente(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/cliente/visualiza/' + itemId, { observe: 'response' })
    }

    getClienteHistoricos(itemId: number): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/ClienteHistorico/visualizaporcliente/' + itemId, { observe: 'response' })
    }

    getClienteServicos(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/clienteservico/visualizaporcliente/' + itemId, { observe: 'response' })
    }

    getClienteFaturamentos(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/clientefaturamento/visualizaporcliente/' + itemId, { observe: 'response' })
    }

    getClientePorId(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/cliente/visualizacliente/' + itemId, { observe: 'response' })
    }

    getClientePorNome(nome: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/cliente/visualizaclientepornome/' + nome, { observe: 'response' })
    }

    postCliente(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/cliente/inclui/', item, { observe: 'response' })
    };

    putCliente(item: any, itemId: any): Observable<HttpResponse<any>> {
      console.log(item, "Service");
        return this.http.put(
            environment.webApiSaguiControlUrl + '/api/cliente/atualiza/' + itemId, item, { observe: 'response' })
    };

    deleteCliente(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
            environment.webApiSaguiControlUrl + '/api/cliente/exclui/' + itemId, { observe: 'response' })
    }

    deleteClienteUsuario(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
            environment.webApiSaguiControlUrl + '/api/clienteusuario/exclui/' + itemId, { observe: 'response' })
    }

    getClienteUsuarioPorCliente(clienteId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/clienteusuario/visualizaporcliente/' + clienteId, { observe: 'response' })
    }

    getContatos(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/cliente/contatos/' + itemId, { observe: 'response' })
    }

    getDadosBancarios(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/cliente/dadosbancarios/' + itemId, { observe: 'response' })
    }
    consultaParceiro(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/cliente/consulta-parceiro', item, { observe: 'response' })
    }

    buscaSocCadastroUnidades(): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/cliente/soc-cadastro-unidades/0', { observe: 'response' })
    }

    verficaIntegracaoSeHaIntegrcaoSoc(): Observable<HttpResponse<any>> {
      return this.http.get(
        environment.webApiSaguiControlUrl + '/api/cliente/verifica-integracoes-soc', { observe: 'response' })
    }

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
