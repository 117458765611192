import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpClientModule } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class ContratoService {

    postVisualizaContratosPorFiltro(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/contrato/visualizatodas/', item, { observe: 'response' })
    }

    getEmpresa(): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/contrato/visualizatodasempresas', { observe: 'response' })
    }

    getTransportadoras(): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/contrato/transportadoras', { observe: 'response' })
    }

    getContrato(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/contrato/visualiza/' + itemId, { observe: 'response' })
    }

    getParceiroePorId(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/contrato/visualizacliente/' + itemId, { observe: 'response' })
    }

    getParceiroPorNome(parametros: any): Observable<HttpResponse<any>> {
        
        const params = {
            id: parametros.id,
           
        }


        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/contrato/visualizaclientepornome/', { observe: 'response', params })
    }

    getClientePorNome(parametros: any): Observable<HttpResponse<any>> {
        
        const params = {
            id: parametros.id,
            empresasIds: parametros.empresasIds
        }

        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/contrato/buscaclientepornomeeempresas/', params, { observe: 'response' })
    }

    getFornecedorPorId(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/contrato/visualizafornecedor/' + itemId, { observe: 'response' })
    }

    getFornecedorPorNome(nome: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/contrato/visualizafornecedorpornome/' + nome, { observe: 'response' })
    }

    postContrato(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/contrato/inclui/', item, { observe: 'response' })
    };

    putContrato(item: any, itemId: any): Observable<HttpResponse<any>> {
        return this.http.put(
            environment.webApiSaguiControlUrl + '/api/contrato/atualiza/' + itemId, item, { observe: 'response' })
    };

    deleteContrato(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
            environment.webApiSaguiControlUrl + '/api/contrato/exclui/' + itemId, { observe: 'response' })
    }

    getContratoItem(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/contratoitem/visualiza/' + itemId, { observe: 'response' })
    }

    postIncluiContratoItem(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/contratoitem/inclui/', item, { observe: 'response' })
    };

    putAtualizaContratoItem(item: any, itemId: any): Observable<HttpResponse<any>> {
        return this.http.put(
            environment.webApiSaguiControlUrl + '/api/contratoitem/atualiza/' + itemId, item, { observe: 'response' })
    };

    deleteContratoItem(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
            environment.webApiSaguiControlUrl + '/api/contratoitem/exclui/' + itemId, { observe: 'response' })
    }

    getSignatario(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/contratosignatario/visualiza/' + itemId, { observe: 'response' })
    }

    postIncluiSignatario(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/contratosignatario/inclui/', item, { observe: 'response' })
    };

    putAtualizaSignatario(item: any, itemId: any): Observable<HttpResponse<any>> {
        return this.http.put(
            environment.webApiSaguiControlUrl + '/api/contratosignatario/atualiza/' + itemId, item, { observe: 'response' })
    };

    deleteSignatario(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
            environment.webApiSaguiControlUrl + '/api/contratosignatario/exclui/' + itemId, { observe: 'response' })
    }

    getVisualizaDocumento(parametros: any): Observable<HttpResponse<any>> {

        const params = {
            idOrigem: parametros.idOrigem,
            tipoOrigem: parametros.tipoOrigem,
            tipoDestino: parametros.tipoDestino
        }

        return this.http.get(environment.webApiSaguiControlUrl + '/api/contrato/visualizadocumentopororigemdestino', { observe: 'response', params })
    }

    getContratoTemplates(): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/contratotemplate/visualizatodas', { observe: 'response' })
    }

    PostContratoTemplatesTags(empresas:any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/contratotemplate/retornatagscontratos', empresas, { observe: 'response' })
    }
    

    postContratoTemplatesPorEmpresa(empresas:any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/contratotemplate/visualizatodasporempresa', empresas, { observe: 'response' })
    }


    getContratoTemplate(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/contratotemplate/visualiza/' + itemId, { observe: 'response' })
    }

    postContratoTemplate(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/contratotemplate/inclui/', item, { observe: 'response' })
    };

    putContratoTemplate(item: any, itemId: any): Observable<HttpResponse<any>> {
        return this.http.put(
            environment.webApiSaguiControlUrl + '/api/contratotemplate/atualiza/' + itemId, item, { observe: 'response' })
    };

    deleteContratoTemplate(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
            environment.webApiSaguiControlUrl + '/api/contratotemplate/exclui/' + itemId, { observe: 'response' })
    }

    getContratoTemplateSignatario(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/contratotemplatesignatario/visualiza/' + itemId, { observe: 'response' })
    }

    getContratoTemplateSignatariosPorTemplateId(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/contratotemplatesignatario/visualizaportemplate/' + itemId, { observe: 'response' })
    }

    postTrocarTagsContratoHtml(item: any): Observable<HttpResponse<any>> {
        const headers = new HttpHeaders().set('X-Skip-Loader', 'true');
        return this.http.post(environment.webApiSaguiControlUrl + '/api/contratotemplate/processatemplate/', item, { headers, observe: 'response', responseType: 'text' });
    }
    

    postContratoTemplateSignatario(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/contratotemplatesignatario/inclui/', item, { observe: 'response' })
    };

    putContratoTemplateSignatario(item: any, itemId: any): Observable<HttpResponse<any>> {
        return this.http.put(
            environment.webApiSaguiControlUrl + '/api/contratotemplatesignatario/atualiza/' + itemId, item, { observe: 'response' })
    };

    deleteContratoTemplateSignatario(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
            environment.webApiSaguiControlUrl + '/api/contratotemplatesignatario/exclui/' + itemId, { observe: 'response' })
    }


    getMetodosAssinatura(): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/contrato/visualizametodosassinatura', { observe: 'response' })
    }

    getProdutoPorNome(parametros: any): Observable<HttpResponse<any>> {

        const params = {
            id: parametros.id,
            tipoProduto: parametros.tipoProduto,
        }

        return this.http.get(environment.webApiSaguiControlUrl + '/api/contrato/visualizaprodutopornome', { observe: 'response', params })
    }

    getProdutoPorNomeEEmpresas(parametros: any): Observable<HttpResponse<any>> {
        
        const params = {
            id: parametros.id,
            empresasIds: parametros.empresasIds
        }

        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/contrato/buscaprodutopornomeeempresas/', params, { observe: 'response' })
    }

    postDocumentoByIdEEmpresas(parametros: any): Observable<HttpResponse<any>> {

        const params = {
            empresasIds: parametros.empresasIds,
            idBuscado: parametros.idBuscado, 
            documentoTipo: parametros.tipoDocumentoDestino,
            clienteId: parametros.clienteId
        }

        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/contrato/buscadocumentoporideempresas/', { observe: 'response', params })}



    postGeraVenda(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/contrato/incluivenda/', item, { observe: 'response' })
    };


    postAssinarOnline(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/contrato/assinaronline/', item, { observe: 'response' })
    };

    PostContratoDownload(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/contrato/download/', item, { observe: 'response' })
    }

    textoAcao(tipoAcao: number): string {

        var textoStatus = "";

        switch (tipoAcao) {
            case 1:
                textoStatus = " Assinar";
                break;
            case 2:
                textoStatus = "Aprovar";
                break;
            case 3:
                textoStatus = "Reconhecer";
                break;
            case 4:
                textoStatus = "Assinar como parte";
                break;
            case 5:
                textoStatus = "Assinar como testemunha";
                break;
            case 6:
                textoStatus = "Assinar como interveniente";
                break;
            case 7:
                textoStatus = "Acusar recebimento";
                break;
            case 8:
                textoStatus = "Assinar como Emissor, Endossante e Avalista";
                break;
            default:
                textoStatus = "";
                break;
        }

        return textoStatus;
    }

    postCriaContratoAPartirDeUmExistente(contratoId: number): Observable<HttpResponse<number>> {
      return this.http
      .post( environment.webApiSaguiControlUrl + '/api/contrato/novo-contrato/' + contratoId,
      { observe: 'response' }) as Observable<HttpResponse<number>>;
    }

    adicionaRelacaoContrato(item: any): Observable<HttpResponse<any>> {
      return this.http.post(
          environment.webApiSaguiControlUrl + '/api/contrato/adiciona-relacao-documento-contrato/', item, { observe: 'response' })
    }

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
