<div class="notification-launcher">
  <mat-icon class="notification-icon" (click)="toggleNotifications()">notifications</mat-icon>
  <div class="notification-badge" *ngIf="unreadNotificationsCount > 0"><span>{{ unreadNotificationsCount }}</span></div>
  <div *ngIf="showNotifications" class="notification-dropdown">
    <div class="notification-header">
      <span>Notificações</span>
    </div>
    <div class="notification-body">
      <div *ngIf="notifications.length === 0">
        <div class="not-notification">
          <span>Nenhuma notificação</span>
        </div>
      </div>
      <div *ngFor="let notification of notifications">
        <div class="notification-content" [ngStyle]="{'background-color': !notification.lida ? '#f5f5f5' : '', 'border-radius': !notification.lida ? '20px' : '' }">
          <div class="row">
            <div class="col-md-2">
              <span 
                class="icone material-symbols-outlined" 
                [ngStyle]="{
                  'color': notification.tipo === 'sucesso' ? 'green' : (notification.tipo === 'erro' ? 'red' : (notification.tipo === 'notificacao' ? 'orange' : '')), 
                  'background-color': notification.tipo === 'sucesso' ? 'lightgreen' : (notification.tipo === 'erro' ? 'lightcoral' : (notification.tipo === 'notificacao' ? 'lightyellow' : ''))
                }">
                {{ notification.icon }}
              </span>
            </div>
            <div class="col-md-10">
              <h4 class="notification-title">{{ notification.title }}</h4>
              <div class="notification-remove" (click)="marcarComoLida(notification)" *ngIf="!notification.lida">
                Marcar como Lida
              </div>
              <span class="notification-date">{{ notification.date | date:'short' }}</span>
              <p class="notification-message">{{ notification.message }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="notification-footer">
      <a (click)="marcarTodasComoLida()">Marcar todas como Lida</a>
    </div>
  </div>
</div>