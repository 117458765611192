<mat-toolbar>
    <div class="md-toolbar-tools">
        <h1 mat-dialog-title>Item Compra</h1>
        <span flex></span>
        <div class="posicionar-direita">
            <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()">
        <mat-icon>save</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="md-dialog-content">
        <form [formGroup]="compraItemForm">
            <div class="row">
                <div class="col-md-12">
                    <br />
                    <div class="row">
                        <mat-form-field class="col-md-6 col-xs-12 form-group">
                            <mat-label>Tipo de Produto</mat-label>
                            <mat-select formControlName="produtoTipo" name="produtoTipo">
                                <mat-option [value]="0">Produto Acabado</mat-option>
                                <mat-option [value]="1">Serviço</mat-option>
                                <mat-option [value]="2">Uso e Consumo</mat-option>
                                <mat-option [value]="3">Ativo Imobilizado</mat-option>
                                <mat-option [value]="4">Matéria Prima</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="col-md-6 col-xs-12 form-group">
                            <mat-label>Produto/Serviço</mat-label>
                            <input type="text" matInput [matAutocomplete]="auto" formControlName="produtoAutoComplete">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let produto of produtos" [value]="produto.descricao"
                                    (click)="DeterminaprodutoId(produto)">
                                    {{produto.descricao}} - {{produto.valorCompra | currency}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="col-md-6 col-xs-12 form-group">
                            <mat-label>CFOP</mat-label>
                            <mat-select formControlName="cfop">
                                <mat-option *ngFor="let cfop of cfops" [value]="cfop.codCfop">
                                    {{cfop.codCfop}} - {{cfop.descricao}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="col-md-6 col-xs-12 form-group">
                            <mat-label>Estoque</mat-label>
                            <mat-select formControlName="estoqueId">
                                <mat-option *ngFor="let estoque of estoques" [value]="estoque.estoqueId">
                                    {{estoque.produto.descricao }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-6">
                            <input matInput placeholder="Número do Lote" formControlName="numLote">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="form-group col-md-4">
                            <mat-label>Quantidade Comercial</mat-label>
                            <input type="number" step="0.01" matInput formControlName="qtdComercial"
                                (blur)="OnChangeqtdComercial()">
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-4">
                            <mat-label>Valor Comercial</mat-label>
                            <input currencyMask matInput formControlName="vlrComercial" (blur)="OnChangevlrComercial()">
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-4">
                            <mat-label>Valor Total Bruto</mat-label>
                            <input currencyMask matInput formControlName="vProd">
                        </mat-form-field>
                    </div>
                    <h2>Dados do Produto</h2>
                    <hr />
                    <div class="row">
                        <mat-form-field class="form-group col-md-6">
                            <mat-label>Código do Produto</mat-label>
                            <input matInput formControlName="codProd">
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-6">
                            <mat-label>Descrição</mat-label>
                            <input matInput formControlName="descricao">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="form-group col-md-4">
                            <mat-label>NCM</mat-label>
                            <input matInput formControlName="ncm">
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-4">
                            <mat-label>EAN</mat-label>
                            <input matInput formControlName="ean">
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-4">
                            <mat-label>EAN Tributário</mat-label>
                            <input matInput formControlName="eanTrib">
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-4">
                            <mat-label>EX_TIPI</mat-label>
                            <input matInput formControlName="extipi">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="form-group col-md-3">
                            <mat-label>Unidade Comercial</mat-label>
                            <input matInput formControlName="unComercial">
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-3">
                            <mat-label>Unidade Tributária</mat-label>
                            <input matInput formControlName="unTributaria">
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-3">
                            <mat-label>Valor Tributário</mat-label>
                            <input currencyMask matInput formControlName="vlrTributario">
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-3">
                            <mat-label>Quantidade Tributaria</mat-label>
                            <input type="number" matInput formControlName="qtdTributaria">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="form-group col-md-12">
                            <mat-label>Informações Adicionais do Produto</mat-label>
                            <input matInput formControlName="infAdProd">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="form-group col-md-4">
                            <mat-label>Valor Total Bruto</mat-label>
                            <input currencyMask matInput formControlName="vProd">
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-4">
                            <mat-label>Valor Total do Frete</mat-label>
                            <input currencyMask matInput formControlName="vFrete">
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-4">
                            <mat-label>Valor Total do Seguro</mat-label>
                            <input currencyMask matInput formControlName="vSeg">
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-4">
                            <mat-label>Valor do Desconto</mat-label>
                            <input currencyMask matInput formControlName="vDesc">
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-4">
                            <mat-label>Outras despesas acessórias</mat-label>
                            <input currencyMask matInput formControlName="vOutro">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>