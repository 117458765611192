'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContaParcelaService } from '../shared/conta-parcela.service';
import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

export interface DialogData {
  contaId: number;
  descricaoConta: string;
}

@Component({
  selector: 'conta-parcela-recorrente',
  templateUrl: 'conta-parcela-recorrente.component.html',
})

export class ContaParcelaRecorrenteComponent {

  contaParcelaRecorrenteForm: FormGroup;
  maximizado: boolean;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ContaParcelaRecorrenteComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public IContaParcela: DialogData,
    private contaParcelaService: ContaParcelaService) {
  }

  ngOnInit() {

    this.maximizado = false;

    this.contaParcelaRecorrenteForm = this._formBuilder.group({
      valorParcela: 0,
      quantidade: 1,
      dataPrimeiroVencimento: new Date(),
      contaId: 0,
      descricao: ['']
    });

    this.contaParcelaRecorrenteForm.get('contaId').setValue(this.IContaParcela.contaId);
    this.contaParcelaRecorrenteForm.get('descricao').setValue(this.IContaParcela.descricaoConta);

    this.onChanges();

    this.restaurarDialog();
  }

  onChanges(): void {


  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {

    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  salvar(): void {

    this.contaParcelaService.postContaParcelaRecorrente(this.contaParcelaRecorrenteForm.value).subscribe(data => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Sucesso",
          mensagem: "Parcelas gerados com Sucesso"
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.fecharDialog();
      });

    });
  }
}
