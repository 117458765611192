import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { Produto } from 'src/app/produto/shared/produto.model';

@Component({
  selector: 'app-itens',
  templateUrl: './itens-cadastro.component.html',
})
export class ItensCadastroComponent implements OnInit {

  @Input() getProdutoPorNomeEEmpresasService: (params: any) => Observable<any>;
  @Input() empresas: any;
  @Input() itens: any[] = [];
  @Input() panelOpenState: boolean = true;

  @Output() valorTotalBruto = new EventEmitter<any>();
  @Output() descontosAplicadosAosItens = new EventEmitter<any>();
  @Output() valorTotalLiquido = new EventEmitter<any>();
  @Output() novosItens = new EventEmitter<any[]>();




  filteredProdutos: any[];

  columns = [
    { def: 'quantidade', header: 'Quantidade' },
    { def: 'codProd', header: 'Código' },
    { def: 'descricao', header: 'Nome' },
    { def: 'valorUnitario', header: 'Valor Unitário' },
    { def: 'descontoUnitario', header: 'Valor Desconto Unitário' },
    { def: 'valorTotalBruto', header: 'Valor Total Bruto' },
    { def: 'valorTotalLiquido', header: 'Valor Total Líquido' },
    { def: 'acaoTable', header: 'Ações' },


  ];
  itemForm: FormGroup;

  itensTabela = new MatTableDataSource<any>([]);

  constructor(private _formBuilder: FormBuilder, private currencyPipe: CurrencyPipe, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {

    this.itemForm = this._formBuilder.group({quantidade: [1, Validators.required],
      item:['', Validators.required],
      descricao: ['', Validators.required],
      codProd: [''],
      valorUnitario: [0, Validators.required],
      descontoUnitario: [0],
      valorTotalBruto: [0, Validators.required],
      valorTotalLiquido: [0, Validators.required],
      produtoId: [0, Validators.required],
      produtoTipo: [0, Validators.required],


    });

    this.onChanges();
    this.cdr.detectChanges();

  }



  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itens) {
      this.setFormatedItensIntoTable();
      this.calcularValoresGerais();}

  }




  onChanges(): void {
    this.itemForm.valueChanges.subscribe(val => {
      const valorUnitario= this.itemForm.get('valorUnitario').value;
      const quantidade = this.itemForm.get('quantidade').value;
      const descontoUnitario = this.itemForm.get('descontoUnitario').value;
      const valorTotalBruto = valorUnitario * quantidade;
      const valorTotalLiquido = (valorUnitario-descontoUnitario) * quantidade;
      this.itemForm.get('valorTotalBruto').setValue(valorTotalBruto, { emitEvent: false });
      this.itemForm.get('valorTotalLiquido').setValue(valorTotalLiquido, { emitEvent: false });

    });

    this.itemForm.get('item').valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => {
        if (value?.length >= 3) {
          // Retorna o resultado da API se o comprimento for 3 ou mais caracteres
          return this.getProdutoPorNomeEEmpresasService({
            id: value,
            empresasIds: this.empresas.map((empresa: any) => empresa.empresaId)}).pipe(
            map(response => response.body || [])
          );
        } else {
          // Retorna um array vazio se o comprimento for menor que 3 caracteres
          return of([]);
        }
      })
    ).subscribe(data => {
      // Atualiza a lista de clientes filtrados
      this.filteredProdutos = data;
    });


  }


  displayItemFn(item:any): string {
    return item ? item.descricao : '';
  }


  onItemSelected(item: Produto) {
    if(item){
      this.itemForm.patchValue({ valorUnitario: item.vlrComercial});
      this.itemForm.patchValue({ codProd: item.codProd});
      this.itemForm.patchValue({ produtoId: item.produtoId});
      this.itemForm.patchValue({ produtoTipo: item.produtoTipo});
      this.itemForm.patchValue({ descricao: item.descricao});
      this.itemForm.patchValue({ descontoUnitario: 0});

     }
  }


  addItem(): void {
    this.itens.push(this.itemForm.value);


      this.setFormatedItensIntoTable();
      this.calcularValoresGerais();
      this.panelOpenState =false
      this.emitChanges();
      this.itemForm.reset();


  }

  emitChanges() {
    this.novosItens.emit(this.itens);
  }

  setFormatedItensIntoTable(){
    // Set formatted item in itens.data
    this.itensTabela.data = this.itens?.map(item => {
      return {
        codProd: item.codProd,
        descricao: item.descricao,
        valorUnitario: this.currencyPipe.transform(item.valorUnitario, 'BRL', 'symbol'),
        produtoId: item.produtoId,
        produtoTipo: item.produtoTipo,
        quantidade: item.quantidade,
        valorTotalBruto: this.currencyPipe.transform(item.valorTotalBruto, 'BRL', 'symbol'),
        descontoUnitario: this.currencyPipe.transform(item.descontoUnitario, 'BRL', 'symbol'),
        valorTotalLiquido: this.currencyPipe.transform(item.valorTotalLiquido, 'BRL', 'symbol')
      };
    });

  }


  onExcluir(idToRemove: any): void {

    // Encontrar o índice do item a ser removido
    const index = this.itens.findIndex(item => item.produtoId === idToRemove);

    // Remover o item se encontrado
    if (index !== -1) {
      this.itens.splice(index,1);

    }

    // Atualizar os dados da tabela
    this.itensTabela.data = this.itens;
    this.calcularValoresGerais();
  }

  calcularValoresGerais() {

    const valorTotalBrutoTemp = this.itens?.reduce((sum, item) => sum + item.valorTotalBruto, 0);
    const descontosAplicadosAosItens = this.itens?.reduce((sum, item) => sum + item.descontoUnitario * item.quantidade, 0);
    const valorTotalLiquidoTemp = valorTotalBrutoTemp - descontosAplicadosAosItens;

    this.valorTotalBruto.emit(valorTotalBrutoTemp);
    this.descontosAplicadosAosItens.emit(descontosAplicadosAosItens);
    this.valorTotalLiquido.emit(valorTotalLiquidoTemp);
  }

}
