export class Embalagem {

    embalagemId: number = 0;

    embalagemStatus: number = 0;

    produtoId: number = 0;

    peso: number = 0;

    embalagemNumero: string = '';

    remessaId: number = 0;

    dataEmbalagem: Date = new Date(); 

    usuarioCriador: string = '';

    empresaId: number = 0;

    saguiClienteId: number = 0;
}