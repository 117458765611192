import { Injectable } from '@angular/core';

@Injectable()
export class ExportaCsvService {

  exporta(data: any, filename: string) {
  let link = document.createElement("a");
  link.setAttribute("target", "_blank");

  if (Blob !== undefined) {
    // Convertendo os dados em um arquivo Blob
    const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
  } else {
    // Caso Blob não seja suportado, criar um link de dados
    const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
    link.setAttribute("href", csvContent);
  }

  // Configurando o nome do arquivo para download
  link.setAttribute("download", filename);

  // Adicionando o link ao corpo do documento
  document.body.appendChild(link);

  // Simulando o clique no link para iniciar o download
  link.click();

  // Removendo o link do corpo do documento
  document.body.removeChild(link);
}


  constructor() {

  }
}
