<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>Fatura - {{fatura.faturaId}} - {{verificaFaturaStatus(fatura.faturaStatus)}}</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions class="text-right floating">
  <button mat-raised-button (click)="imprimir()" *ngIf="fatura.faturaId > 0" matTooltip="Imprimir">
    <mat-icon>print</mat-icon>
  </button>
  <button mat-raised-button [matMenuTriggerFor]="opcoes" matTooltip="Opções" *ngIf="fatura.faturaId > 0" >
    <mat-icon>more_horiz</mat-icon>
  </button>
  <mat-menu #opcoes>
    <button *ngIf="permiteFaturamento && fatura.nfseId == 0 && this.tpFatura == 0 && fatura.faturaTipoDocumento == 1" (click)="gerarNfseConta()"
      mat-menu-item>
      Gerar {{textoContaView}}, Boleto e NFS-e
    </button>
    <button *ngIf="permiteFaturamento && fatura.nfeId == 0 && this.tpFatura == 0 && fatura.faturaTipoDocumento == 0" (click)="gerarNfeConta()"
    mat-menu-item>
    Gerar {{textoContaView}}, Boleto e NF-e
    </button>
    <button *ngIf="permiteFaturamento" (click)="gerarConta()" mat-menu-item>
      Gerar {{textoContaView}} e Boleto
    </button>
    <button *ngIf="permiteFaturamento" (click)="gerarConta()" mat-menu-item>
      Gerar apenas {{textoContaView}}
    </button>
    <button *ngIf="fatura.nfseId == 0 && this.tpFatura == 0" (click)="addNfse(false)" mat-menu-item>
      Gerar Somente NFS-e
    </button>
    <button *ngIf="fatura.nfeId > 0" (click)="VisualizaNFe(fatura.nfeId)" mat-menu-item>
      <mat-icon>edit</mat-icon> NF-e</button>
    <button *ngIf="fatura.nfseId > 0" (click)="visualizarNfse(fatura.nfseId)" mat-menu-item>
      <mat-icon>edit</mat-icon> NFS-e
    </button>
    <button *ngIf="fatura.contaId > 0" (click)="abreModalConta(fatura.faturaTipo, fatura.contaId, fatura.faturaTipo)"
      mat-menu-item>
      <mat-icon>edit</mat-icon> Conta
    </button>
    <button *ngIf="fatura.faturaId > 0 && tpFatura == 0" (click)="EnviaEmail(faturaCadastroForm)" mat-menu-item>
      Enviar Fatura por E-mail</button>
    <button *ngIf="fatura.faturaId > 0 && fatura.faturaStatus != 99" (click)="cancelarFatura(fatura.faturaId)"
      mat-menu-item>Cancelar
    </button>
  </mat-menu>
  <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar(faturaCadastroForm)" [disabled]="!faturaCadastroForm.valid || fatura.faturaStatus > 0">
    <mat-icon>save</mat-icon>&ensp;Salvar Fatura
  </button>
</div>
<mat-dialog-content>
  <div class="md-dialog-content floating">
    <div class="row">
      <form [formGroup]="faturaCadastroForm">
        <div class="col-md-12">
          <br>
          <div class="row">
            <div class="col-md-12 form-group">
              <h3>Dados Gerais da Fatura</h3>
            </div>
            <br>
            <mat-form-field class="col-md-12 col-xs-12 form-group">
              <mat-label>Descrição</mat-label>
              <input type="text" matInput formControlName="descricao" required>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="col-md-4 col-xs-12 form-group">
              <mat-label>Emitente</mat-label>
              <mat-select formControlName="empresaId" aria-required="true" [required]="true" required>
                 <mat-option *ngFor="let empresa of empresas" [value]="empresa.empresaId" (blur)="selecionaCadastroFinanceiroEmpresa(empresa)">
                   {{empresa.nomeFantasia}}
                 </mat-option>
              </mat-select>
             </mat-form-field>
            <mat-form-field class="col-md-4 col-xs-12 form-group">
              <mat-label>Parceiro</mat-label>
              <input type="text" matInput [matAutocomplete]="auto" formControlName="parceiroAutoComplete" required>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let parceiro of parceiros" [value]="parceiro.nomeFantasia"
                  (click)="DeterminaParceiro(parceiro)" required>
                  CNPJ/CPF: {{parceiro.cnpj == null || parceiro.cnpj == undefined || parceiro.cnpj == '' ?
                  parceiro.cpf : parceiro.cnpj}} - {{parceiro.razaoSocial}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="col-md-4 col-xs-12 form-group">
              <mat-checkbox formControlName="isSocCancela">Sempre Substituir com SOC</mat-checkbox>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-12 form-group">
              <hr>
              <h3>Itens da Fatura</h3>
            </div>
            <div class="col-md-12">
              <app-itens
                [getProdutoPorNomeEEmpresasService]="getProdutoPorNomeEEmpresasService.bind(this)"
                [empresas]="faturaEmpresas"
                [itens]="fatura.faturaItens ? fatura.faturaItens : []"
                (novosItens)="onItensChange($event)"
                [panelOpenState]="true"
                (valorTotalBruto)="onValorTotalBrutoChange($event)"
                (descontosAplicadosAosItens)="onDescontosAplicadosAosItensChange($event)"
                (valorTotalLiquido)="onValorTotalLiquidoChange($event)"></app-itens>
            </div>
          </div>
          <br>
          <hr>
          <div class="row">
            <div class="col-md-12 form-group">
              <h3>Dados Financeiros</h3>
            </div>
            <mat-form-field class="col-md-4 col-xs-12 form-group">
              <mat-label>Categoria de {{textoContaView}}</mat-label>
              <mat-select formControlName="grupoContaId">
                <mat-option *ngFor="let grupoConta of grupoContas" [value]="grupoConta.grupoContaId">
                  {{grupoConta.descricao}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-4 col-xs-12 form-group">
              <mat-label>Caixa (Fluxo de Caixa)</mat-label>
              <mat-select formControlName="caixaId">
                <mat-option *ngFor="let caixa of caixas" [value]="caixa.caixaId">
                  {{caixa.nome}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-4 col-xs-12 form-group">
              <mat-label>Centro de Custo</mat-label>
              <mat-select formControlName="centroCustoId">
                <mat-option *ngFor="let centroCusto of centroCustos" [value]="centroCusto.centroCustoId">
                  {{centroCusto.descricao}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col-md-12 col-mx-12 form-group" *ngIf="faturaCadastroForm.value">
              <app-calcula-parcelas fixed
                [valorTotal]="faturaCadastroForm.get('valorTotal').value"
                [parcelaIdNome]="'faturaParcelaId'"
                [panelOpenState]="true"
                [parcelasIniciais]="faturaParcelas ? faturaParcelas : []"
                (parcelasFinaisChange)="onParcelasFinaisChange($event)">
              </app-calcula-parcelas>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>

</mat-dialog-content>
