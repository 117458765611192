<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>Descontos Adicionais</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <br>
</mat-toolbar>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvarDescontosAdicionais()">
    <mat-icon>save</mat-icon>
  </button>
</div>
<br>

<form [formGroup]="DescontoOrcamentoDto">
  <div class="row">
    <mat-form-field class="col-md-4 col-xs-12 form-group" (input)="limitarValor()" >
      <mat-label>Desconto em Porcentagem</mat-label>
      <input (input)="changePorcentagem()" type="number" maxlength="3" min="0" max="100"  matInput
        formControlName="DescontoEmPorcentagem"
        [(ngModel)]="DescontoOrcamentoDto.value.DescontoEmPorcentagem">
    </mat-form-field>
    <mat-form-field class="col-md-4 col-xs-12 form-group" (input)="limitarValor()">
      <mat-label>Desconto em Reais</mat-label>
      <input type="number" (input)="changeEmReal()" matInput formControlName="DescontoValorEmReal"
         [(ngModel)]="DescontoOrcamentoDto.value.DescontoValorEmReal">
    </mat-form-field>
  </div>
</form>

<mat-card>
  <mat-card-header>
    <mat-card-title>Descontos</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class=" col-md-12 col-xs-12 form-group">
        <p class="col-md-12 col-xs-12 form-group">
          Novo valor c/ desconto: {{valorBruto - DescontoOrcamentoDto.value.DescontoValorEmReal | currency:'BRL'}}
        </p>
      </div>
    </div>
  </mat-card-content>
</mat-card>
