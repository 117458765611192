<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>Contato {{contatoCadastroForm.value.contatoId}}</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()">
    <mat-icon>save</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <br />
  <form [formGroup]="contatoCadastroForm">
    <div class="row">
      <div class="col-md-4 col-xs-12 form-group" *ngIf="contatoCadastroForm.value.contatoId > 0">
        <mat-checkbox formControlName="ativo">Ativo?</mat-checkbox>
      </div>
    </div>
    <div class="row">
      <mat-form-field class="col-md-4 col-xs-12 form-group">
        <mat-label>Nome</mat-label>
        <input type="text" matInput formControlName="nome">
      </mat-form-field>
      <mat-form-field class="col-md-4 col-xs-12 form-group">
        <mat-label>Sobrenome</mat-label>
        <input type="text" matInput formControlName="sobreNome">
      </mat-form-field>
      <mat-form-field class="col-md-4 col-xs-12 form-group">
        <mat-label>Data de Nascimento</mat-label>
        <input matInput [matDatepicker]="picker1" formControlName="dataNascimento">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col-md-4 col-xs-12 form-group">
        <mat-label>Cargo</mat-label>
        <input type="text" matInput formControlName="cargo">
      </mat-form-field>
      <mat-form-field class="col-md-4 col-xs-12 form-group">
        <mat-label>Departamento</mat-label>
        <input type="text" matInput formControlName="departamento">
      </mat-form-field>
      <mat-form-field class="col-md-4 col-xs-12 form-group">
        <mat-label>Responsável Por</mat-label>
        <input type="text" matInput formControlName="responsavelPor">
        <mat-hint>Exemplo: Vendas, Compras, Contas a Receber</mat-hint>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col-md-3 col-xs-12 form-group">
        <mat-label>WhatApp</mat-label>
        <input type="text" matInput formControlName="whatsApp">
        <span class="fa fa-whatsapp" matSuffix></span>
      </mat-form-field>
      <mat-form-field class="col-md-3 col-xs-12 form-group">
        <mat-label>Celular</mat-label>
        <input type="text" matInput formControlName="celular">
      </mat-form-field>
      <mat-form-field class="col-md-3 col-xs-12 form-group">
        <mat-label>Telefone Fixo</mat-label>
        <input type="text" matInput formControlName="telefone">
      </mat-form-field>
      <mat-form-field class="col-md-3 col-xs-12 form-group">
        <mat-label>E-mail</mat-label>
        <input type="email" matInput formControlName="email">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col-md-4 col-xs-12 form-group">
        <mat-label>CEP</mat-label><input type="text" matInput placeholder="Cep" formControlName="cep"
          (blur)="consultarCep(contatoCadastroForm.value.cep)">
      </mat-form-field>
      <mat-form-field class="col-md-4 col-xs-12 form-group">
                          <mat-label>Logradouro</mat-label>
<input type="text" matInput  formControlName="logradouro">
      </mat-form-field>
      <mat-form-field class="col-md-4 col-xs-12 form-group">
                          <mat-label>Número</mat-label>
<input type="text" matInput formControlName="numero">
      </mat-form-field>
      <mat-form-field class="col-md-4 col-xs-12 form-group">
                          <mat-label>Complemento</mat-label>
<input type="text" matInput  formControlName="complemento">
      </mat-form-field>
      <mat-form-field class="col-md-4 col-xs-12 form-group">
                          <mat-label>Bairro</mat-label>
<input type="text" matInput formControlName="bairro">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col-md-4 col-xs-12 form-group">
        <mat-label>País</mat-label>
        <mat-select formControlName="paisId">
          <mat-option *ngFor="let pais of paises" [value]="pais.paisId">
            {{pais.descPais}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-4 col-xs-12 form-group">
        <mat-label>UF</mat-label>
        <mat-select formControlName="ufId">
          <mat-option *ngFor="let uf of ufs" [value]="uf.ufId">
            {{uf.descUF}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-4 col-xs-12 form-group">
        <mat-label>Município</mat-label>
        <mat-select formControlName="municipioId">
          <mat-option *ngFor="let municipio of municipios" [value]="municipio.municipioId">
            {{municipio?.descricao}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>