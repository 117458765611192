<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>Venda de Cartão</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button
        (click)="restaurarDialog()"
        class="botao-header"
        [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button
        (click)="maximizarDialog()"
        class="botao-header"
        [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()"><mat-icon>save</mat-icon></button>
</div>
<mat-dialog-content>
  <div class="md-dialog-content">
    <br />
    <form [formGroup]="contaCorrenteVendaForm">
      <div class="row">
        <mat-form-field class="col-md-6 col-xs-12 form-group">
          <mat-label>Descrição</mat-label>
          <mat-label>Descrição</mat-label><input type="text" matInput  formControlName="descricao">
        </mat-form-field>
        <div class="col-md-6 col-xs-12">
          <img [src]="'data:image/png;base64,' + cartao.logoByte" style="height: 15px;" />
          <label>{{contaCorrenteVendaForm.value.contaParcelaTipoPagamento == 1 ? 'Débito': 'Crédito'}}</label>
        </div>
      </div>
      <div class="row">
        <mat-form-field class="col-md-6 col-xs-12 form-group">
          <mat-label>Código de Autenticação</mat-label>
          <input type="text" matInput placeholder="codigoAutenticacao" formControlName="codigoAutenticacao">
        </mat-form-field>
        <mat-form-field class="col-md-6 col-xs-12 form-group">
          <mat-label>Número do Comprovante de Venda</mat-label>
          <input type="text" matInput placeholder="Número do Comprovante de Venda"
            formControlName="numeroDoComprovanteDeVenda">
        </mat-form-field>

      </div>
      <div class="row">
        <div class="form-group col-md-4">
          <mat-form-field>
            <mat-label>Data de Criação</mat-label>
            <input matInput [matDatepicker]="picker1" placeholder="Data de Criação" formControlName="dataCriacao">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-group col-md-4">
          <mat-form-field>
            <mat-label>Data da Venda</mat-label>
            <input matInput [matDatepicker]="picker2" placeholder="Data da Venda" formControlName="dataDaVenda">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-group col-md-4">
          <mat-form-field>
            <mat-label>Data do Primeiro Vencimento</mat-label>
            <input matInput [matDatepicker]="picker3" placeholder="Data do Primeiro Vencimento"
              formControlName="dataPrimeiroVencimento">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <mat-form-field class="col-md-3 col-xs-12 form-group">
          <mat-label>Valor da Venda</mat-label>
          <input currencyMask matInput placeholder="Valor da Venda" formControlName="valorVenda">
        </mat-form-field>
        <mat-form-field class="col-md-3 col-xs-12 form-group">
          <mat-label>Valor da Taxa</mat-label>
          <input currencyMask matInput placeholder="Valor da Taxa" formControlName="valorTaxa">
        </mat-form-field>
        <mat-form-field class="col-md-3 col-xs-12 form-group">
          <mat-label>Valor Líquido</mat-label>
          <input currencyMask matInput  formControlName="valorLiquido">
        </mat-form-field>
        <mat-form-field class="col-md-3 col-xs-12 form-group">
          <mat-label>Parcelas</mat-label>
          <input type="text" matInput placeholder="Parcelas" formControlName="numeroParcelas">
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="row rowmateria">
    <div class="col-md-12 col-xs-12 col-sm-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Parcelas</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col-md-12">
              <button mat-raised-button matTooltip="Adicionar Parcela" (click)="contaCorrenteVendaParcelaModal(0)"><mat-icon>add</mat-icon> Parcela</button>
            </div>
          </div>
          <div id="list" class="row">
            <div class="table-responsive col-md-12">
              <table class="table table-striped" cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <th>Ações</th>
                    <th>Parcelas</th>
                    <th>Data de Vencimento</th>
                    <th>Valor da Parcela</th>
                    <th>Valor da Taxa</th>
                    <th>Valor Líquido</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let contaCorrenteVendaParcela of contaCorrenteVendaParcelas">
                    <button mat-mini-fab matTooltip="Editar Parcela"
                      (click)="contaCorrenteVendaParcelaModal(contaCorrenteVendaParcela.contaCorrenteVendaParcelaId)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-mini-fab color="warn" matTooltip="Deletar Parcela"
                      (click)="excluirContaCorrenteVendaParcela(contaCorrenteVendaParcela.contaCorrenteVendaParcelaId)">
                      <mat-icon>delete</mat-icon>
                    </button>
                    <td>{{contaCorrenteVendaParcela.numeroParcela}}</td>
                    <td>{{contaCorrenteVendaParcela.dataVencimento | date:'dd/MM/yyyy'}}</td>
                    <td>{{contaCorrenteVendaParcela.valorVenda | currency}}</td>
                    <td>{{contaCorrenteVendaParcela.valorTaxa | currency}}</td>
                    <td>{{contaCorrenteVendaParcela.valorLiquido | currency}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</mat-dialog-content>
