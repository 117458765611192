'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, switchMap } from "rxjs/operators";

import { ClienteServicoService } from '../shared/cliente-servico.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';

import { ClienteServico } from '../shared/cliente-servico.model';
import { ClienteService } from '../shared/cliente.service';
import { ClienteMetodoPagamento } from '../shared/cliente-metodo-pagamento.model';
import { ClienteMetodoPagamentoService } from '../shared/cliente-metodo-pagamento.service';
import { ClienteMetodoPagamentoComponent } from '../cliente-metodo-pagamento/cliente-metodo-pagamento.component';

export interface DialogData {
    clienteServicoId: number;
    clienteId: number;
}

@Component({
    selector: 'cliente-servico',
    templateUrl: 'cliente-servico.component.html',
})

export class ClienteServicoComponent {

    clienteServicoForm: FormGroup;
    clienteServicoMetodoPagamentoForm: FormGroup;
    maximizado: boolean;
    produtos: any = [];
    clienteServicoHistoricos: any = [];
    empresas: any = [];
    utilizaGetNetPlano: boolean = false;
    clienteMetodoPagamentos: any = [];

    constructor(
        public dialog: MatDialog,
    private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<ClienteServicoComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public IClienteServico: DialogData,
        private clienteServicoService: ClienteServicoService,
        private clienteService: ClienteService,
        private clienteMetodoPagamentoService: ClienteMetodoPagamentoService) {
    }

    ngOnInit() {

        this.maximizado = false;

        this.clienteServicoForm = this._formBuilder.group(new ClienteServico());
        this.clienteServicoForm.addControl('produtoAutoComplete', new FormControl(''));

        this.clienteServicoForm.get('clienteServicoId').setValue(this.IClienteServico.clienteServicoId);
        this.clienteServicoForm.get('clienteId').setValue(this.IClienteServico.clienteId);

        this.clienteServicoMetodoPagamentoForm = this._formBuilder.group(new ClienteMetodoPagamento());
        this.clienteServicoMetodoPagamentoForm.get('clienteId').setValue(this.IClienteServico.clienteId);

        if (this.IClienteServico.clienteServicoId > 0) {

            this.buscarClienteServico(this.IClienteServico.clienteServicoId);
        }

        this.buscarClienteMetodoPagamentos();

        this.buscarEmpresas();

        this.restaurarDialog();

        this.onChanges();
    }

    onChanges(): void {

        this.clienteServicoForm
            .get('produtoAutoComplete')
            .valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => value?.length >= 3 ?
                    this.clienteServicoService.getProdutoPorNome({
                        id: value,
                        tipoProduto: 1//Serviço
                    }) : []
                )
            )
            .subscribe(data =>
                this.produtos = data.body
            );
    }

    onChangeValor() {

        let valorUnitario = this.clienteServicoForm.get('valorUnitario').value;
        let quantidade = this.clienteServicoForm.get('quantidade').value;
        let valorTotal = Math.round((quantidade * valorUnitario) * 100) / 100;
        this.clienteServicoForm.get('valorTotal').setValue(valorTotal.toFixed(2));
    }

    fecharDialog() {
        this.dialogRef.close();
    }

    maximizarDialog() {
        this.dialogRef.updateSize('100%', '100%');
        this.maximizado = true;
    }

    restaurarDialog() {
        let innerWidth = window.innerWidth;

        if (innerWidth < 1024) {
            this.dialogRef.updateSize('90%', '100vh');
        }
        else {
            this.dialogRef.updateSize('60%', '100vh');
        }
        this.maximizado = false;
    }

    buscarEmpresas() {

        this.clienteService.getEmpresas().subscribe(data => {

            this.empresas = data.body;

        });
    }

    buscarClienteMetodoPagamentos() {

        this.clienteMetodoPagamentoService.getPorclienteId(this.IClienteServico.clienteId).subscribe(data => {

            this.clienteMetodoPagamentos = data.body;

        });
    }

    excluirClienteMetodoPagamento(clienteMetodoPagamentoId: number) {

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                titulo: "Confirmação",
                mensagem: "Deseja realmente excluir esse método de pagamento?"
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                this.clienteMetodoPagamentoService.delete(clienteMetodoPagamentoId).subscribe(data => {

                    this.buscarClienteMetodoPagamentos();

                });
            }
        });
    }

    modalClienteMetodoPagamento(clienteMetodoPagamentoId) {

        const dialogRef = this.dialog.open(ClienteMetodoPagamentoComponent, {
            data: {
                clienteMetodoPagamentoId: clienteMetodoPagamentoId,
                clienteId: this.IClienteServico.clienteId
            },
            maxHeight: '100vh'
        });

        dialogRef.afterClosed().subscribe(result => {

            this.buscarClienteMetodoPagamentos();

        });

    }


    definirComoPadrao(clienteMetodoPagamentoId: number) {

        this.clienteServicoForm.get('clienteMetodoPagamentoId').setValue(clienteMetodoPagamentoId);

        this.salvar();
    }

    buscarClienteServico(clienteServicoId: number) {

        this.clienteServicoService.getClienteServico(clienteServicoId).subscribe(data => {

            this.InsereDados(data.body);

        })
    }

    InsereDados(data: any) {

        this.clienteServicoForm.patchValue(data);

        this.buscarProduto(data.produtoId);

        this.CarregaClienteServicoHistoricos();
    }

    buscarProduto(produtoId: number) {

        this.clienteServicoService.getProduto(produtoId).subscribe(data => {

            this.clienteServicoForm.get('produtoAutoComplete').setValue(data.body.descricao);

        })

    }

    salvar() {

        let clienteSevico = this.clienteServicoForm.value;

        if (this.IClienteServico.clienteServicoId == 0) {

            this.clienteServicoService.postClienteServico(clienteSevico).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.buscarClienteServico(data.body.clienteServicoId);

            });
        }
        else {

            this.clienteServicoService.putClienteServico(clienteSevico, clienteSevico.clienteServicoId).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.fecharDialog();


            });
        }
    }

    CarregaClienteServicoHistoricos() {

        if (this.clienteServicoForm.value.clienteServicoId > 0) {

            this.clienteServicoService.getClienteServicoHistoricos(this.clienteServicoForm.value.clienteServicoId).subscribe(data => {

                this.clienteServicoHistoricos = data.body;

            });
        }
    }

    DeterminaprodutoId(produto: any) {

        this.clienteServicoForm.get('produtoId').setValue(produto.produtoId);
        this.clienteServicoForm.get('valorUnitario').setValue(produto.vlrComercial);

        this.utilizaGetNetPlano = produto.utilizaGetNetPlano;

        if (this.utilizaGetNetPlano) {
            this.clienteServicoForm.get('quantidade').setValue(1);
            this.onChangeValor();
            this.clienteServicoForm.get('clienteServicoRecorrencia').setValue(produto.Recorrencia);
        }
    }
}
