'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ContaParcelaService } from '../shared/conta-parcela.service';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';

import { ContaParcela } from '../shared/conta-parcela.model';
import { ContaCadastroComponent } from '../conta-cadastro/conta-cadastro.component';
import { ContaService } from '../shared/conta.service';
import { AnexoComponent } from 'src/app/anexo/anexo/anexo.component';
import { OperacaoContabilService } from 'src/app/contabil/shared/operacao-contabil.service';
import { LogComponent } from 'src/app/log/log/log.component';
import { CaixaMovimentoComponent } from '../caixa-movimento/caixa-movimento.component';
import { ContaCorrenteVendaComponent } from 'src/app/conta-corrente/conta-corrente-venda/conta-corrente-venda.component';
import { ContaParcelaBaixaComponent } from '../conta-parcela-baixa/conta-parcela-baixa.component';

export interface DialogData {
  contaParcelaId: number;
  tpConta: number;
  contaId: number;
}

@Component({
  selector: 'conta-parcela',
  templateUrl: 'conta-parcela.component.html',
})

export class ContaParcelaComponent {

  contaParcelaForm: FormGroup;
  maximizado: boolean;
  permiteEdicao: boolean = true;
  tipoOperacao: string = '';
  contaParcela: any = {};
  boleto: any = {};
  operacoesContabeis: any = [];

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ContaParcelaComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public IContaParcela: DialogData,
    private contaParcelaService: ContaParcelaService,
    private contaService: ContaService,
    private operacaoContabilService: OperacaoContabilService) {
  }

  ngOnInit() {

    this.maximizado = false;

    this.contaParcelaForm = this._formBuilder.group(new ContaParcela());

    this.contaParcelaForm.get('contaId').setValue(this.IContaParcela.contaId);

    if (this.IContaParcela.contaParcelaId > 0) {

      this.buscarContaParcela(this.IContaParcela.contaParcelaId);

    } else {
      this.tipoOperacao = "Incluir";
    }

    this.onChanges();

    this.restaurarDialog();
  }

  onChanges(): void {

    this.contaParcelaForm
      .get('tipoPagamento')
      .valueChanges
      .subscribe(data => {
        if (data === 8) {
          this.contaParcelaForm.get('contaParcelaTipo').setValue(2);
          this.operacaoContabilService.getOperacoesContabeis().subscribe(data => {

            this.operacoesContabeis = data.body;

          }, (error: HttpErrorResponse) => {

            const dialogRef = this.dialog.open(InfoDialogComponent, {
              data: {
                titulo: "Erro",
                erro: error
              }
            });
          });

        } else {
          this.contaParcelaForm.get('contaParcelaTipo').setValue(this.IContaParcela.tpConta);
        }

      });
  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {

    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  buscarContaParcela(contaParcelaId: number) {

    this.tipoOperacao = "Atualizar";

    this.contaParcelaService.getContaParcela(contaParcelaId).subscribe(data => {

      this.contaParcelaForm.patchValue(data.body);

      if (data.body.boletoId > 0) {

        let parcelas = [];

        let _parcela = data.body;

        let _conta = {
          contaId: _parcela.contaId,
          tpConta: this.IContaParcela.tpConta,
        };

        _parcela.conta = _conta;

        parcelas.push(data.body);

        let boletoIds: any = [];

        parcelas.forEach(parcela => {
          boletoIds.push(parcela.boletoId);
        })

        this.contaService.postVisualizaBoletoPorContaParcela(boletoIds).subscribe(boletoResult => {

          this.boleto = boletoResult.body[0];

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });

      }

    }, (error: HttpErrorResponse) => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          erro: error
        }
      });
    })
  }

  salvar(): void {

    if (this.tipoOperacao == "Incluir") {

      this.contaParcelaForm.get('dataCriacao').setValue(new Date());

      this.contaParcelaService.postContaParcela(this.contaParcelaForm.value).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

        this.fecharDialog();

      });

    }
    else {
      this.contaParcelaService.putContaParcela(this.contaParcelaForm.value.contaParcelaId, this.contaParcelaForm.value).subscribe(data => {

                this._snackBar.open("Registro Salvo com Sucesso");

        this.fecharDialog();

      });
    }

  }

  corrigirParcela() {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Corrigir",
        mensagem: "Essa opção apenas irá corrigir a parcela e não irá movimentar o fluxo de caixa. Caso deseje movimentar o fluxo de caixa clique no botão Salvar ao invés do botão Corrigir Parcela. Deseja continuar?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.contaParcelaService.putContaParcelaCorrigir(this.contaParcelaForm.value.contaParcelaId, this.contaParcelaForm.value).subscribe(data => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Sucesso",
              mensagem: "Registro Corrigido com Sucesso"
            }
          });

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });

  };

  abreModalConta(contaId) {

    const dialogRef = this.dialog.open(ContaCadastroComponent, {
      data: {
        contaId: contaId,
        tpConta: this.IContaParcela.tpConta,
        tipoParceiro: 0
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    this.fecharDialog();

  };

  abreModalAnexo() {

    const dialogRef = this.dialog.open(AnexoComponent, {
      data: {
        documentoIdReferenciado: this.contaParcelaForm.value.contaParcelaId,
        documentoTipo: 10,
        saguiClienteId: this.contaParcelaForm.value.saguiClienteId
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });
  }

  abrirLogs() {

    const dialogRef = this.dialog.open(LogComponent, {
      data: {
        entidadeId: this.contaParcelaForm.value.contaParcelaId,
        logTipo: 0
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

  }

  definirComoPago() {

    if (this.contaParcelaForm.value.statusPagamento == 1)

      if (this.contaParcelaForm.value.valorPagamento == 0) {

        this.contaParcelaForm.get('valorPagamento').setValue(this.contaParcelaForm.value.valorParcela);

      }

    if (
      this.contaParcelaForm.get('dataPagamento').value == undefined ||
      this.contaParcelaForm.get('dataPagamento').value == null) {

      let dataAtual = new Date();
      this.contaParcelaForm.get('dataPagamento').setValue(dataAtual);

    }
  }

  caixaMovimentoModal() {

    const dialogRef = this.dialog.open(CaixaMovimentoComponent, {
      data: {
        caixaMovimentoId: this.contaParcelaForm.value.caixaMovimentoId,
        caixaId: this.contaParcelaForm.value.caixaId,
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });
  };

  contaCorrenteVendaModal(contaCorrenteVendaId: number){
    
    const dialogRef = this.dialog.open(ContaCorrenteVendaComponent, {
      data: {
        contaCorrenteVendaId: contaCorrenteVendaId,
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });
  }

  retornaStatusPagamento(statusPagamento: number) {

    switch (statusPagamento) {
      case 0:
        return 'Ativo';
      case 1:
        return 'Quitado';
      case 2:
        return 'Cancelado';
    }
  }

  baixarParcela(contaParcelaId: number) {

    const dialogRef = this.dialog.open(ContaParcelaBaixaComponent, {
      data: {
        contaParcelaId: contaParcelaId
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {

      this.buscarContaParcela(contaParcelaId);

    });

  };

  cancelarParcela(cancelarContaParcelas) {

    let parcelas = [];

    parcelas.push(cancelarContaParcelas);

    this.cancelarParcelas(parcelas);
  };

  cancelarParcelas(cancelarContaParcelas) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja Cancelar essa parcela"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.contaParcelaService.postCancelarContaParcelas(cancelarContaParcelas).subscribe(data => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Sucesso",
              mensagem: "Registro Cancelado com Sucesso"
            }
          });

          this.buscarContaParcela(this.IContaParcela.contaParcelaId);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  };

}
