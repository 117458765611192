import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { FaturaService } from '../shared/fatura.service';
import { InfoDialogComponent } from 'src/app/core/dialogs/info-dialog/info-dialog.component';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';


export interface DialogData {
  erro: HttpErrorResponse;
  mensagem: string;
  titulo: string;
  faturaFormTemp: any;
}

@Component({
  selector: 'app-enviar-email-dialog',
  templateUrl: './enviar-email-dialog.component.html',
  styleUrls: ['./enviar-email-dialog.component.css']
})


export class EnviarEmailDialogComponent implements OnInit {

  emailFile: any;
  fileName: string = "";

  constructor(
    private location: Location,
    public dialogReff: MatDialogRef<EnviarEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private faturaService: FaturaService,
    public dialog: MatDialog,) {

  }

  ngOnInit() {

    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogReff.updateSize('80%');
    }
    else {
      this.dialogReff.updateSize('20%');
    }

    if (this.data.erro != null) {

      if (this.data.erro.status == 401) {

        this.data.mensagem = "Sessão Expirada. Acesse novamente o sistema";

        this.location.go("login");
      }
      else {

        this.data.mensagem = "Erro ao processar requisição";

        if (this.data.erro.error != null) {
          if (this.data.erro.error.message != null) {
            this.data.mensagem = this.data.erro.error.message;
          } else {

            if (this.data.erro.error.errors != null) {
              const mensagens = Object.values(this.data.erro.error.errors).join('\n');
              this.data.mensagem = mensagens.replace(/\[(.*)\]/g, '$1').replace(/"/g, '');
            }
            else {
              this.data.mensagem = this.data.erro.error;
            }
          }

          if (this.data.erro.error.ExceptionMessage != undefined && this.data.erro.error.ExceptionMessage != null) {
            this.data.mensagem = this.data.erro.error.ExceptionMessage;
          }

        }
        else {

          if (this.data.erro.message != undefined) {

            this.data.mensagem = this.data.erro.message;
          }
        }
      }
    }
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    this.emailFile = file;
    this.fileName = file.name;
    console.log(file)
  }

  onOkClick(): void {
    this.dialogReff.close();
  }

  EnviarEmail() {
    this.faturaService.postFaturaPorEmail(this.data.faturaFormTemp, this?.emailFile).pipe(
      tap(() => {
        const dialogReff = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Sucesso",
            mensagem: "Fatura agendada para ser enviada por e-mail"
          }
        });
      }),
      catchError(() => {
        const dialogReff = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Erro",
            mensagem: "Ocorreu um erro ao enviar a fatura por e-mail."
          }
        });
        return throwError('Erro ao enviar a fatura por e-mail.');
      })
    ).subscribe();

    this.dialogReff.close();
  }

}
