import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, switchMap } from "rxjs/operators";

import { VendaService } from '../shared/venda.service';
import { VendaItemComponent } from '../venda-item/venda-item.component';
import { MunicipioService } from '../../municipio/shared/municipio.service';

import { RemessaCadastroComponent } from '../../remessa/remessa-cadastro/remessa-cadastro.component';
import { DocumentoCadastroComponent } from '../../documento/documento-cadastro/documento-cadastro.component';
import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';
import { FaturaCadastroComponent } from '../../fatura/fatura-cadastro/fatura-cadastro.component';

import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';

import { Venda } from '../shared/venda.model';
import { Transportadora } from '../../transportadora/shared/transportadora.model';
import { VendaDevolucaoComponent } from '../venda-devolucao/venda-devolucao.component';
import { NfeCadastroComponent } from '../../fiscal/nfe-cadastro/nfe-cadastro.component';
import { NfeInboundImportarComponent } from '../../fiscal/nfe-inbound-importar/nfe-inbound-importar.component';
import { DocumentoService } from 'src/app/documento/shared/documento.service';
import { ClienteCadastroComponent } from 'src/app/cliente/cliente-cadastro/cliente-cadastro.component';
import { VendaParcela } from '../shared/venda-parcela.model';
import { ContratoCadastroComponent } from 'src/app/juridico/contrato-cadastro/contrato-cadastro.component';
import { VendaItem } from '../shared/venda-item.model';
import { Produto } from 'src/app/produto/shared/produto.model';
import { CfopService } from '../../cfop/shared/cfop.service';
import { OrdemServicoService } from 'src/app/produto/ordem-servico/ordem-servico.service';
import { VendaParcelaDataVencimentoDto } from '../shared/VendaParcelaDataVencimentoDto.model';
import { VendaConfigService } from '../shared/venda-config.service';
import { DescontoAdicionalComponent } from '../desconto-adicional/desconto-adicional.component';

export interface DialogData {
  vendaId: number;
  vendaTipo: number;
}

@Component({
  selector: 'venda-cadastro',
  templateUrl: 'venda-cadastro.component.html',
})

export class VendaCadastroComponent {

  venda: any = {};
  empresa: any = {};
  transportadora: Transportadora;
  cliente: any = {};
  localEntrega: any = {};
  localRetirada: any = {};
  empresas: any = [];
  vendedores: any = [];
  vendas: any = [];
  transportadoras: Transportadora[];
  clientes: any[];
  locaisRetirada: any[];
  locaisEntrega: any[];
  vendaCadastroForm: FormGroup;
  vendaItemForm: FormGroup;
  maximizado: boolean;
  vendaParcelas: any = [];
  produtos: any = [];
  cfops: any = [];
  listaServicos: any = [];
  panelOpenState = true;
  IsExistente: boolean = false;
  dataVencimentoParcela: FormGroup;
  currentDate = new Date();
  parceirosEncontrados: boolean = true;
  vendaConfig: any;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<VendaCadastroComponent>,
    private _formBuilder: FormBuilder,
    private htmlPrinterService: HtmlPrinterService,
    @Inject(MAT_DIALOG_DATA) public IVenda: DialogData,
    private vendaService: VendaService,
    private municipioService: MunicipioService,
    private documentoService: DocumentoService,
    private cfopService: CfopService,
    private OrdemServicoService: OrdemServicoService,
    private vendaConfigService: VendaConfigService) {
  }

  ngOnInit() {
    const brTime = this.currentDate.toLocaleString("en-US", {timeZone: "America/Sao_Paulo"});
    this.currentDate = new Date(brTime);
    this.maximizado = false;

    this.dataVencimentoParcela = new FormGroup({
      dataVencimentoParcela: new FormControl(new Date())
    });

    this.vendaCadastroForm = this._formBuilder.group(new Venda());
    this.vendaItemForm = this._formBuilder.group(new VendaItem());

    this.vendaItemForm.addControl('produtoAutoComplete', new FormControl(''));

    this.vendaItemForm.get('vendaId').setValue(this.venda.vendaId);

    this.vendaItemForm.get('orig').setValue(0);

    if (this.IVenda.vendaTipo == 1) {
      this.vendaItemForm.get('produtoTipo').setValue(1);
    }

    if (this.IVenda.vendaId > 0) {

      this.vendaItemForm.get('vendaId').setValue(this.IVenda.vendaId);

      this.buscarVenda(this.IVenda.vendaId);

    } else {
      this.venda = {};
      this.venda.vendaId = 0;
      this.venda.dataVenda = new Date();
      this.venda.dataPrevista = new Date();
      this.venda.vendaTipo = this.IVenda.vendaTipo;

      if (this.venda.vendaTipo == 0) {
        this.vendaCadastroForm.get('modNFe').setValue(55);//NF-e
      }
      else {
        this.vendaCadastroForm.get('modNFe').setValue(99);//Serviço
      }
      this.vendaCadastroForm.get('vendaTipo').setValue(this.venda.vendaTipo);
      this.vendaCadastroForm.get('indPag').setValue(2);
      this.vendaCadastroForm.get('indFinal').setValue(0);
      this.vendaCadastroForm.get('indPres').setValue(0);
      this.vendaCadastroForm.get('modFrete').setValue(0);
    }

    this.buscarEmpresas();

    this.buscarFuncionarios();

    this.buscarTransportadoras();

    this.vendaCadastroForm.addControl('clienteAutoComplete', new FormControl(''));

    this.vendaCadastroForm.addControl('localRetiradaAutoComplete', new FormControl(''));

    this.vendaCadastroForm.addControl('localEntregaAutoComplete', new FormControl(''));
    this.onChanges();

    this.maximizarDialog();

  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  onChanges(): void {

    this.vendaItemForm
      .get('produtoAutoComplete')
      .valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => value?.length >= 3 ?
          this.vendaService.getProdutoPorNome({
            id: value,
            tipoProduto: this.vendaItemForm.get('produtoTipo').value
          }) : []
        )
      )
      .subscribe(data =>
        this.produtos = data.body
      );

    this.vendaCadastroForm
      .get('clienteAutoComplete')
      .valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => value?.length >= 3 ?
          this.vendaService.getClientePorNome(value) : []
        )
      )
      .subscribe(data =>
        this.putDataInParceiros(data)
      );

    this.vendaCadastroForm
      .get('localRetiradaAutoComplete')
      .valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => value?.length >= 3 ?
          this.vendaService.getClientePorNome(value) : []
        )
      )
      .subscribe(data =>
        this.locaisRetirada = data.body
      );

    this.vendaCadastroForm
      .get('localEntregaAutoComplete')
      .valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => value?.length >= 3 ?
          this.vendaService.getClientePorNome(value) : []
        )
      )
      .subscribe(data => this.locaisEntrega = data.body);

    this.vendaCadastroForm
      .get('issRetido')
      .valueChanges
      .subscribe(data => this.calculaValorSemRetencoes());

    this.vendaCadastroForm
      .get('pisRetido')
      .valueChanges
      .subscribe(data => this.calculaValorSemRetencoes());

    this.vendaCadastroForm
      .get('cofinsRetido')
      .valueChanges
      .subscribe(data => this.calculaValorSemRetencoes());

    this.vendaCadastroForm
      .get('csllRetido')
      .valueChanges
      .subscribe(data => this.calculaValorSemRetencoes());

    this.vendaCadastroForm
      .get('irRetido')
      .valueChanges
      .subscribe(data => this.calculaValorSemRetencoes());

    this.vendaCadastroForm
      .get('inssRetido')
      .valueChanges
      .subscribe(data => this.calculaValorSemRetencoes());

    this.vendaCadastroForm
      .get('empresaId')
      .valueChanges
      .subscribe(data => this.buscaCfop(data));
  }

  getVendaConfigByEmpresaId(venda){

    this.vendaConfigService.getByEmpresaId(venda.empresaId).subscribe(data => {
        this.vendaConfig = data?.body
      });
  }

  buscarEmpresas() {

    this.vendaService.getEmpresa().subscribe(data => {
      this.empresas = data.body;

      if (this.empresas?.length > 0 && this.vendaCadastroForm?.value?.empresaId == 0){
        this.vendaCadastroForm.get('empresaId').setValue(this.empresas[0].empresaId);

        this.buscaListaServico(this.empresas[0].empresaId);
      }

    });
  }

  putDataInParceiros(data){

    this.clientes = data.body;
    this.parceirosEncontrados = true;
    if(this?.clientes?.length == 0){
      this.parceirosEncontrados = false;
    }
}

  buscarFuncionarios() {

    this.vendaService.getVendedores().subscribe(data => {

      this.vendedores = data.body;

    });


  }

  buscarTransportadoras() {

    this.vendaService.getTransportadoras().subscribe(data => {

      this.transportadoras = data.body;

    });
  }

  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  buscaListaServico(empresaId) {
    this.vendaService.getListaServicoCadastrados(empresaId).subscribe(data => {
      this.listaServicos = data.body;
    });
  }

  buscarVenda(vendaId: number) {

    this.vendaService.getVenda(vendaId).subscribe(data => {

      this.InsereDadosVenda(data.body);
      this.getVendaConfigByEmpresaId(data.body);

      if (data.body.vendaTipo == 1) {
        this.buscaListaServico(data.body.empresaId);
      }


    }, (error: HttpErrorResponse) => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          erro: error
        }
      });
    })


    this.OrdemServicoService.checkIfOrdemServicoExistsByVendaId(vendaId).subscribe(data => {
      this.IsExistente = data.body;
    })
  }


  InsereDadosVenda(data: any) {


    this.vendaCadastroForm.patchValue(data);

    this.vendaCadastroForm.get('clienteAutoComplete').setValue(data.cliente.nomeFantasia);
    if(data?.vendaParcelas?.length > 0){
      this.dataVencimentoParcela.get('dataVencimentoParcela').setValue(new Date(data.vendaParcelas[0].dataVencimento));

    }

    if (data.cliente.logradouroEntrega != undefined && data.cliente.logradouroEntrega != null) {
      this.localEntrega.logradouro = data.cliente.logradouroEntrega;
      this.localEntrega.numero = data.cliente.numeroEntrega;
      this.localEntrega.bairro = data.cliente.bairroEntrega;
      this.localEntrega.cep = data.cliente.cepEntrega;

      if (data.cliente.municipioEntregaId > 0) {

        this.municipioService.getMunicipio(data.cliente.municipioEntregaId).subscribe(dataMunicipio => {

          this.localEntrega.municipio = dataMunicipio.body;

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        })
      }

    }
    this.venda.idlocalEntrega = data.idLocalEntrega;



    const promiselocalEntrega = new Promise<void>((resolve, reject) => {

      if (this.venda.idlocalEntrega) {
        this.vendaService.getClientePorId(this.venda.idlocalEntrega).subscribe(data => {

          this.localEntrega = data.body;
          this.vendaCadastroForm.get('localEntregaAutoComplete').setValue(this.localEntrega.nomeFantasia);

          resolve();

        }, (error: HttpErrorResponse) => {

          resolve();
          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
      else {
        resolve();
      }
    });

    const promiselocalRetirada = new Promise<void>((resolve, reject) => {

      if (this.venda.idlocalRetirada) {
        this.vendaService.getClientePorId(this.venda.idlocalRetirada).subscribe(data => {

          this.localRetirada = data.body;
          this.vendaCadastroForm.get('localRetiradaAutoComplete').setValue(this.localRetirada.nomeFantasia);
          resolve();

        }, (error: HttpErrorResponse) => {
          resolve();
          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
      else {
        resolve();
      }
    });

    Promise.all([promiselocalEntrega, promiselocalRetirada]).then(values => {

      this.venda = data;
      this.venda.dataVenda = new Date(data.dataVenda);
      this.venda.dataPrevista = new Date(data.dataPrevista);
      this.venda.indPag = data.indPag.toString();
      this.cliente = this.venda.cliente;
      this.empresa = data.empresa;
      this.transportadora = data.transportadora;

      this.venda.totalVenda = 0;
      this.venda.totalDescontos = 0;
      this.venda.totalGeral = 0;

      var arrayLength = this.venda.vendaItens.length;

      for (var i = 0; i < arrayLength; i++) {

        this.venda.totalVenda = this.venda.totalVenda + this.venda.vendaItens[i].valorTotal;
        this.venda.totalDescontos = this.venda.totalDescontos + this.venda.vendaItens[i].valorDesconto;
      }

      this.venda.totalGeral = this.venda.totalVenda - this.venda.totalDescontos;

      let modalidadeFrete = "";

      switch (this.venda.modFrete) {
        case 0:
          modalidadeFrete = "Contratação do frete por conta do remetente(CIF)";
          break;
        case 1:
          modalidadeFrete = "Contratação do frete por conta do destinatário(FOB)";
          break;
        case 2:
          modalidadeFrete = "Transporte próprio por conta do remetente";
          break;
        case 3:
          modalidadeFrete = "Transporte próprio por conta do destinatário";
          break;
        case 9:
          modalidadeFrete = "Sem ocorrência de transporte";
          break;
      }

      this.venda.modalidadeFrete = modalidadeFrete;
      this.vendas = [];
      this.vendas.push(this.venda);

      let parcelas = this.venda.vendaParcelas;

      let parcelasLength = parcelas.length;

      this.vendaParcelas = [];

      for (var i = 0; i < parcelasLength; i++) {

        let parcela: VendaParcela = new VendaParcela();

        parcela.dataVencimento = new Date(parcelas[i].dataVencimento);
        parcela.vendaParcelaId = parcelas[i].vendaParcelaId;
        parcela.valorParcela = parcelas[i].valorParcela;
        parcela.vendaId = this.vendaCadastroForm.get('vendaId').value;

        this.vendaParcelas.push(parcela);

      }

      if (this.venda.vendaStatus == 4) {
        this.vendaCadastroForm.disable();
      }

      if (this.venda.vendaTipo > 2) {

        this.vendaCadastroForm.disable();
      }

    });

  }

  determinaCliente(cliente: any) {
    this.vendaCadastroForm.get('clienteId').setValue(cliente.clienteId);
    this.vendaCadastroForm.get('issRetido').setValue(cliente.retidoIssqn);
    this.vendaCadastroForm.get('pisRetido').setValue(cliente.retidoPis);
    this.vendaCadastroForm.get('cofinsRetido').setValue(cliente.retidoCofins);
    this.vendaCadastroForm.get('csllRetido').setValue(cliente.retidoCsll);
    this.vendaCadastroForm.get('irRetido').setValue(cliente.retidoIrrf);
    this.vendaCadastroForm.get('inssRetido').setValue(cliente.retidoInss);
  }

  DeterminalocalEntregaId(id: number) {
    this.vendaCadastroForm.get('idlocalEntrega').setValue(id);
  }

  DeterminalocalRetiradaId(id: number) {
    this.vendaCadastroForm.get('idlocalRetirada').setValue(id);
  }
  criarOrdemServico(vendaId: number) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          titulo: "Confirmação",
          mensagem: "Deseja realmente criar uma Ordem de Serviço?"
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.OrdemServicoService.postByVendaId(vendaId).subscribe(data => {

            const dialogRef = this.dialog.open(InfoDialogComponent, {
              data: {
                titulo: "Sucesso",
                mensagem: "Ordem de Serviço criada com sucesso"
              },
            });
            dialogRef.afterClosed().subscribe(result => {
              this.ngOnInit();}
            );
          }, (error: HttpErrorResponse) => {

            const dialogRef = this.dialog.open(InfoDialogComponent, {
              data: {
                titulo: "Erro",
                erro: error
              }
            });
          })

        }
      });

  }
  cancelarVenda(cancelarId) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja realmente cancelar essa venda?"
      }
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.vendaService.deleteVenda(cancelarId).subscribe(data => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Sucesso",
              mensagem: "Registro Cancelado com Sucesso"
            }
          });

          this.buscarVenda(cancelarId);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  };

  salvar(): void {

    if (this.vendaCadastroForm.value.clienteId == 0) {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          mensagem: "Você deve selecionar o cliente"
        }
      });

      return;
    }

    if (this.vendaCadastroForm.value.vendaId == 0) {

      this.vendaService.postVenda(this.vendaCadastroForm.value).subscribe(data => {

        this.buscarVenda(data.body.vendaId);

      });

    } else {

      let vendaTemp = this.vendaCadastroForm.value;

      vendaTemp.vendaParcelas = this.vendaParcelas;

      this.vendaService.putVenda(vendaTemp, vendaTemp.vendaId).subscribe(data => {

        this._snackBar.open("Registro Salvo com Sucesso");

        this.buscarVenda(this.vendaCadastroForm.value.vendaId);


      });
    }

  };

  geraNFe(venda): void {

    this.vendaService.postGeraNFe(venda.value).subscribe(data => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Sucesso",
          mensagem: "NF-e Gerada com Sucesso"
        }
      });

      this.VisualizaNFe(data.body.nfeId);

    });
  }

  geraRemessa(venda): void {

    this.vendaService.postGeraRemessa(venda.value).subscribe(data => {

      this.exibeRemessa(data.body.remessaId);

    });
  }

  geraDevolucao(vendaForm): void {

    let vendaTemp = vendaForm.value;

    const dialogRef = this.dialog.open(VendaDevolucaoComponent, {
      width: '300px',
      data: {
        empresaId: vendaTemp.empresaId,
        vendaTipo: this.IVenda.vendaTipo,
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        vendaTemp.cfop = result;
        vendaTemp.vendaItens = this.venda.vendaItens;

        this.vendaService.postGeraDevolucao(vendaTemp).subscribe(data => {

          this.exibeDevolucao(data.body.vendaId);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });

  }


  GeraDevolucaoEstoque(venda): void {

    this.vendaService.postGeraDevolucaoEstoque(venda.value).subscribe(data => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Sucesso",
          mensagem: "Entrada de Estoque realizada"
        }
      });

    });
  }

  exibeDevolucao(vendaId): void {

    const dialogRef = this.dialog.open(VendaCadastroComponent, {
      data: {
        vendaId: vendaId,
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscarVenda(this.venda.vendaId);
    });

  }

  exibeRemessa(remessaId): void {

    const dialogRef = this.dialog.open(RemessaCadastroComponent, {
      data: {
        remessaId: remessaId,
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscarVenda(this.venda.vendaId);
    });

  }

  imprimir(): void {
     let titulo = "SaguiControl - Pedido de Venda";

    this.htmlPrinterService.printDiv('printVenda', titulo);
  }

  faturar(): void {

    let mensagem = "Deseja realmente faturar essa Venda?";

    if (this.vendaCadastroForm.value.vendaTipo == 0 && this.vendaCadastroForm.value.nfeId == 0) {
      mensagem += " Recomendamos gerar a NF-e antes de Faturar";
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: mensagem
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        this.vendaService.postGeraFatura(this.vendaCadastroForm.value).subscribe(data => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Sucesso",
              mensagem: "Venda Faturada"
            }
          });

          this.VisualizaFatura(data.body.faturaId);

          this.buscarVenda(this.venda.vendaId);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  }

  PreencherTabela(cliente: any) {

    if (cliente != undefined) {
      this.venda.clienteId = cliente.clienteId;
    }
  }

  PreencherTabelalocalRetirada(localRetirada: any) {

    if (localRetirada != undefined) {
      this.venda.idlocalRetirada = localRetirada.clienteId;
    }
  }

  PreencherTabelalocalEntrega(localEntrega) {

    if (localEntrega != undefined) {
      this.venda.idlocalEntrega = localEntrega.clienteId;
    }
  }

  modalItem(vendaItemId: number): void {

    const dialogRef = this.dialog.open(VendaItemComponent, {
      data: {
        vendaItemId: vendaItemId,
        venda: this.vendaCadastroForm.value,
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscarVenda(this.venda.vendaId);
    });

  }

  deleteVendaItem(vendaItemId: number) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja realmente excluir esse item?"
      }
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.vendaService.deleteVendaItem(vendaItemId).subscribe(data => {

          this.buscarVenda(this.venda.vendaId);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  };

  DeleteVendaParcela(vendaParcelaId: number) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: "Deseja realmente excluir essa parcela?"
      }
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.vendaService.deleteVendaParcela(vendaParcelaId).subscribe(data => {

          this.buscarVenda(this.venda.vendaId);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  };

  VisualizaNFe(nfeId: number) {

    const dialogRef = this.dialog.open(NfeCadastroComponent, {
      data: {
        nfeId: nfeId,
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscarVenda(this.venda.vendaId);
    });

  }

  VisualizaFatura(faturaId: number) {

    const dialogRef = this.dialog.open(FaturaCadastroComponent, {
      data: {
        faturaId: faturaId,
        faturaTipo: 0
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

  }

  calculaValorSemRetencoes() {

    if (this.vendaCadastroForm.get('valorBruto').value > 0) {
      var valorSemRetencoes = this.vendaCadastroForm.get('valorBruto').value;

      if (this.vendaCadastroForm.get('issRetido').value) {
        valorSemRetencoes = valorSemRetencoes - this.vendaCadastroForm.get('valorIss').value;
      }

      if (this.vendaCadastroForm.get('pisRetido').value) {
        valorSemRetencoes = valorSemRetencoes - this.vendaCadastroForm.get('valorPis').value;
      }

      if (this.vendaCadastroForm.get('cofinsRetido').value) {
        valorSemRetencoes = valorSemRetencoes - this.vendaCadastroForm.get('valorCofins').value;
      }

      if (this.vendaCadastroForm.get('csllRetido').value) {
        valorSemRetencoes = valorSemRetencoes - this.vendaCadastroForm.get('valorCsll').value;
      }

      if (this.vendaCadastroForm.get('irRetido').value) {
        valorSemRetencoes = valorSemRetencoes - this.vendaCadastroForm.get('valorIr').value;
      }

      if (this.vendaCadastroForm.get('inssRetido').value) {
        valorSemRetencoes = valorSemRetencoes - this.vendaCadastroForm.get('valorInss').value;
      }

      this.vendaCadastroForm.get('valorAposRetencoes').setValue(valorSemRetencoes);
    }
  }

  visualizaFluxoDocumentos(): void {

    const dialogRef = this.dialog.open(DocumentoCadastroComponent, {
      data: {
        tipoOrigem: 1,
        idDocumento: this.venda.vendaId,
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

  }

  VerificaVendaTipo(vendaTipo: number) {

    switch (vendaTipo) {

      case 0:
        return 'Venda Produto';
      case 1:
        return 'Pedido de Venda';
      case 2:
        return 'Devolução Produto';
      case 3:
        return "Devolução Serviço";
      default:
        return 'Venda Produto';
    }
  }

  VerificavendaStatus(vendaStatus: number) {

    switch (vendaStatus) {

      case 0:
        return 'Em Criação';
      case 1:
        return 'Aguardando Compra';
      case 2:
        return 'Aguardando Produção';
      case 3:
        return "Concluída";
      case 4:
        return "Com Devolução";
      case 99:
        return "Cancelada";
      default:
        return 'Em criação';
    }
  }

  GeraNFeDevolucao(venda) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '300px',
      data: {
        titulo: "Confirmação",
        mensagem: "Essa opção é apenas quando o seu cliente não tiver gerado uma NF-e de Devolução. Deseja realmente gerar uma NF-e de Entrada de Devolução?"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.vendaService.postGeraNFeDevolucao(venda.value).subscribe(data => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Sucesso",
              mensagem: "NF-e Gerada com Sucesso"
            }
          });

          this.VisualizaNFe(data.body.nfeId);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  }

  ReceberNFeDevolucaoCliente(venda) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '300px',
      data: {
        titulo: "Confirmação",
        mensagem: "Essa opção é apenas quando o seu cliente emitir uma NF-e de Devolução. Você será redirecionado para a funcionalidade NF-e inbound. Deseja continuar?"
      }
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        const dialogRef = this.dialog.open(NfeInboundImportarComponent, {
          maxHeight: '100vh',
          maxWidth: '100vw',
          data: {
            compraId: 0,
            vendaId: venda.value.vendaId
          }
        });

        dialogRef.afterClosed().subscribe(result => {

          this.buscarVenda(this.venda.vendaId);

        });

      }
    });
  }

  movimentaEstoque() {

    let parametros = {
      idOrigem: this.venda.vendaId,
      tipoOrigem: 1
    }

    this.documentoService.getVisualizaDocumento(parametros).subscribe(data => {

      let estoqueExistente = data.body;

      if (estoqueExistente.length > 0) {

        const dialogRef = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Movimentar Estoque",
            mensagem: "Já existe uma saída de estoque para essa venda"
          }
        });

      } else {

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            titulo: "Movimentar Estoque",
            mensagem: "Deseja criar uma nova saída de estoque para essa venda?"
          }
        });

        dialogRef.afterClosed().subscribe(result => {

          if (result) {
            this.enviarMovimentacaoEstoque();
          }

        });
      }
    }, (error: HttpErrorResponse) => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          erro: error
        }
      })
    });
  }

  enviarMovimentacaoEstoque() {

    this.vendaService.postMovimentaEstoque(this.vendaCadastroForm.value).subscribe(data => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Sucesso",
          mensagem: "Saídas de Estoque realizadas!"
        }
      })

    }, (error: HttpErrorResponse) => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          erro: error
        }
      })
    });
  }

  clienteModal(clienteId: number, clienteNome: string) {

    const dialogRef = this.dialog.open(ClienteCadastroComponent, {
      data: {
        clienteId: clienteId,
        clienteNome: clienteNome
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {
        this.vendaCadastroForm.get('parceiroAutoComplete').setValue(result.nomeFantasia);
        this.vendaCadastroForm.get('parceiroId').setValue(result.clienteId);

      this.parceirosEncontrados = true;
    });
  };

  gerarParcelas(venda) {

    const vendaParcelaDataVencimentoDto = new VendaParcelaDataVencimentoDto();

    vendaParcelaDataVencimentoDto.venda = venda;
    vendaParcelaDataVencimentoDto.dataVencimento = this.dataVencimentoParcela.get('dataVencimentoParcela').value;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Gerar Novas Parcela",
        mensagem: "Esse procedimento irá excluir as parcelas atuais e irá criar novas. Deseja continuar?"
      }
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.vendaService.postGerarParcelas(vendaParcelaDataVencimentoDto).subscribe(data => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Sucesso",
              mensagem: "Parcelas geradas com sucesso"
            }
          });

          this.buscarVenda(this.venda.vendaId);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  }

  adicionarNovaParcelaVazia() {

    let parcela: VendaParcela = new VendaParcela();

    parcela.dataVencimento = new Date();
    parcela.valorParcela = 0;
    parcela.vendaId = this.vendaCadastroForm.get('vendaId').value;

    this.vendaParcelas.push(parcela);

  }

  gerarContrato() {

    let mensagem = "Deseja realmente gerar um novo contrato para esta venda? " +
      "Se você já gerou um contrato anteriormente para essa Venda, um novo contrato será gerado.";

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        titulo: "Confirmação",
        mensagem: mensagem
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        this.vendaService.postGerarContrato(this.vendaCadastroForm.value).subscribe(data => {

          this.visualizarContrato(data.body.contratoId);

        }, (error: HttpErrorResponse) => {

          const dialogRef = this.dialog.open(InfoDialogComponent, {
            data: {
              titulo: "Erro",
              erro: error
            }
          });
        });
      }
    });
  }

  visualizarContrato(contratoId: number) {

    const dialogRef = this.dialog.open(ContratoCadastroComponent, {
      data: {
        contratoId: contratoId,
      },
      maxHeight: '100vh',
      maxWidth: '100vw'
    });

  }

  determinaProdutoId(produto: Produto) {

    this.vendaItemForm.get('produtoId').setValue(produto.produtoId);
    this.vendaItemForm.get('codProd').setValue(produto.codProd);
    this.vendaItemForm.get('descricao').setValue(produto.descricao);
    this.vendaItemForm.get('ncm').setValue(produto.ncm);
    this.vendaItemForm.get('ean').setValue(produto.ean);
    this.vendaItemForm.get('eanTrib').setValue(produto.eanTrib);
    this.vendaItemForm.get('unTributaria').setValue(produto.unTributaria);
    this.vendaItemForm.get('vlrTributario').setValue(produto.vlrTributario);
    this.vendaItemForm.get('unComercial').setValue(produto.unComercial);
    this.vendaItemForm.get('vlrComercial').setValue(produto.vlrComercial);
    this.vendaItemForm.get('extipi').setValue(produto.extipi);
    this.vendaItemForm.get('infAdProd').setValue(produto.infAdProd);
    this.vendaItemForm.get('pesoLiquido').setValue(produto.pesoLiquido);
    this.vendaItemForm.get('pesoBruto').setValue(produto.pesoBruto);
    this.vendaItemForm.get('cest').setValue(produto.cest);
    this.vendaItemForm.get('indTot').setValue(1);
    this.vendaItemForm.get('listaServico').setValue(produto.cListServ);
  }

  buscaCfop(empresaId: number) {

    if (this.vendaCadastroForm.value.vendaTipo == 0 || this.vendaCadastroForm.value.vendaTipo == 2) {

      const parametros: any = {};

      parametros.empresaId = empresaId;

      if (this.vendaCadastroForm.value.vendaTipo == 0) {
        parametros.direcao = 1;
      }
      else {
        parametros.direcao = 2;
      }

      this.cfopService.getCfops(parametros).subscribe(data => {
        this.cfops = data.body;

        if (this.cfops.length > 0 && this.vendaItemForm.get('cfop').value == 0)
          this.vendaItemForm.get('cfop').setValue(this.cfops[0].codCfop);

      });

    }

  }

  onChangeValorComercial() {

    let vlrComercial = this.vendaItemForm.get('vlrComercial').value;
    let qtdComercial = this.vendaItemForm.get('qtdComercial').value;
    let valorTotal = Math.round((qtdComercial * vlrComercial) * 100) / 100;
    this.vendaItemForm.get('valorTotal').setValue(valorTotal.toFixed(2));
    this.vendaItemForm.get('qtdTributaria').setValue(qtdComercial);
    this.vendaItemForm.get('vlrTributario').setValue(vlrComercial);

  }

  openDialogDescontoAdicional(){

    let venda = this.vendaCadastroForm.value;

    const dialogRef = this.dialog.open(DescontoAdicionalComponent, {
      data: {
        orcamentoId: 0,
        vendaId: venda.vendaId,
        venda: venda,

      },
      height: '55vh',
      width: '45vw'
    });

    dialogRef.afterClosed().subscribe( () => {
      this.ngOnInit();
    });

}

  adicionarItem() {

    if (this.vendaCadastroForm.value.vendaId == 0) {

      this.vendaService.postVenda(this.vendaCadastroForm.value).subscribe(data => {

        this.vendaCadastroForm.get('vendaId').setValue(data.body.vendaId);

        this.vendaItemForm.get('vendaId').setValue(data.body.vendaId);

        this.calculaImposto();

      });

    } else {

      this.calculaImposto();

    }
  }

  calculaImposto(): void {

    let valorTotal = this.vendaItemForm.get('valorTotal').value;
    this.vendaItemForm.get('valorBruto').setValue(valorTotal);
    this.vendaItemForm.get('valorServicos').setValue(valorTotal);
    this.vendaItemForm.get('vendaId').setValue(this.vendaCadastroForm.get('vendaId').value);
    this.vendaService.postCalculaImposto(this.vendaItemForm.value).subscribe(data => {

      if (this.venda.vendaTipo == 1) {
        this.vendaItemForm.get('aliquotaIssqn').setValue(data.body.aliquotaIssqn);
        this.vendaItemForm.get('valorPis').setValue(data.body.valorPis);
        this.vendaItemForm.get('valorCofins').setValue(data.body.valorCofins);
        this.vendaItemForm.get('valorIr').setValue(data.body.valorIr);
        this.vendaItemForm.get('valorInss').setValue(data.body.valorInss);
        this.vendaItemForm.get('valorCsll').setValue(data.body.valorCsll);
        this.vendaItemForm.get('baseCalculo').setValue(data.body.baseCalculo);
        this.vendaItemForm.get('valorLiquidoNfse').setValue(data.body.valorLiquidoNfse);
        this.vendaItemForm.get('valorIss').setValue(data.body.valorIss);
        this.vendaItemForm.get('valorIssRetido').setValue(data.body.valorIssRetido);
        this.vendaItemForm.get('issRetido').setValue(data.body.issRetido);
      }
      else {
        this.vendaItemForm.get('cofinsSTpCofins').setValue(data.body.cofinsSTpCofins);
        this.vendaItemForm.get('cofinsSTqUnid').setValue(data.body.cofinsSTqUnid);
        this.vendaItemForm.get('cofinsSTvBC').setValue(data.body.cofinsSTvBC);
        this.vendaItemForm.get('cofinsSTvCofins').setValue(data.body.cofinsSTvCofins);
        this.vendaItemForm.get('cofinsSTvUnid').setValue(data.body.cofinsSTvUnid);
        this.vendaItemForm.get('cofinspCofins').setValue(data.body.cofinspCofins);
        this.vendaItemForm.get('cofinsqUnid').setValue(data.body.cofinsqUnid);
        this.vendaItemForm.get('cofinsvBC').setValue(data.body.cofinsvBC);
        this.vendaItemForm.get('cofinsvCofins').setValue(data.body.cofinsvCofins);
        this.vendaItemForm.get('cofinsvUnid').setValue(data.body.cofinsvUnid);
        this.vendaItemForm.get('icmsSTmodBCST').setValue(data.body.icmsSTmodBCST);
        this.vendaItemForm.get('icmsSTpIcmsST').setValue(data.body.icmsSTpIcmsST);
        this.vendaItemForm.get('icmsSTpMVAST').setValue(data.body.icmsSTpMVAST);
        this.vendaItemForm.get('icmsSTpRedBCST').setValue(data.body.icmsSTpRedBCST);
        this.vendaItemForm.get('icmsSTvBCST').setValue(data.body.icmsSTvBCST);
        this.vendaItemForm.get('icmsSTvIcmsST').setValue(data.body.icmsSTvIcmsST);
        this.vendaItemForm.get('icmspCredSN').setValue(data.body.icmspCredSN);
        this.vendaItemForm.get('icmspIcms').setValue(data.body.icmspIcms);
        //this.vendaItemForm.get('icmspIcmsInter').setValue(data.body.icmspIcmsInter);
        this.vendaItemForm.get('icmspRedBC').setValue(data.body.icmspRedBC);
        this.vendaItemForm.get('icmsvBC').setValue(data.body.icmsvBC);
        this.vendaItemForm.get('icmsvCredIcmsSN').setValue(data.body.icmsvCredIcmsSN);
        this.vendaItemForm.get('icmsvIcms').setValue(data.body.icmsvIcms);
        this.vendaItemForm.get('ipiCNPJProd').setValue(data.body.ipiCNPJProd);
        this.vendaItemForm.get('ipicEnq').setValue(data.body.ipicEnq);
        this.vendaItemForm.get('ipiclEnq').setValue(data.body.ipiclEnq);
        //this.vendaItemForm.get('ipicodSelo').setValue(data.body.ipicodSelo);
        this.vendaItemForm.get('ipipIpi').setValue(data.body.ipipIpi);
        this.vendaItemForm.get('ipiqUnid').setValue(data.body.ipiqUnid);
        this.vendaItemForm.get('ipivBC').setValue(data.body.ipivBC);
        this.vendaItemForm.get('ipivIpi').setValue(data.body.ipivIpi);
        this.vendaItemForm.get('ipivUnid').setValue(data.body.ipivUnid);
        this.vendaItemForm.get('icmsDifal').setValue(data.body.icmsDifal);
        this.vendaItemForm.get('pisSTpPis').setValue(data.body.pisSTpPis);
        //this.vendaItemForm.get('pisSTqUnid').setValue(data.body.pisSTqUnid);
        this.vendaItemForm.get('pisSTvBC').setValue(data.body.pisSTvBC);
        this.vendaItemForm.get('pisSTvPis').setValue(data.body.pisSTvPis);
        //this.vendaItemForm.get('pisSTvUnid').setValue(data.body.pisSTvUnid);
        this.vendaItemForm.get('pispPis').setValue(data.body.pispPis);
        this.vendaItemForm.get('pisqUnid').setValue(data.body.pisqUnid);
        this.vendaItemForm.get('pisvBC').setValue(data.body.pisvBC);
        this.vendaItemForm.get('pisvPis').setValue(data.body.pisvPis);
        this.vendaItemForm.get('pisvUnid').setValue(data.body.pisvUnid);
        this.vendaItemForm.get('cstCofins').setValue(data.body.cstCofins);
        this.vendaItemForm.get('cstIcms').setValue(data.body.cstIcms);
        this.vendaItemForm.get('cstIpi').setValue(data.body.cstIpi);
        this.vendaItemForm.get('cstPis').setValue(data.body.cstPis);
        this.vendaItemForm.get('infAdProd').setValue(data.body.infAdProd);
      }

      this.vendaService.postIncluiVendaItem(this.vendaItemForm.value).subscribe(data => {

        this.vendaItemForm.get('produtoAutoComplete').setValue('');
        this.vendaItemForm.get('vlrComercial').setValue(0);
        this.vendaItemForm.get('qtdComercial').setValue(0);
        this.vendaItemForm.get('valorTotal').setValue(0);

        this.buscarVenda(this.vendaCadastroForm.value.vendaId);

      });

    });
  }
}
