import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class ClienteServicoService {

    getClienteServico(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/clienteservico/visualiza/' + itemId, { observe: 'response' })
    }

    postClienteServico(item: any): Observable<HttpResponse<any>> {
        return this.http.post(
            environment.webApiSaguiControlUrl + '/api/clienteservico/inclui/', item, { observe: 'response' })
    };

    putClienteServico(item: any, itemId: any): Observable<HttpResponse<any>> {
        return this.http.put(
            environment.webApiSaguiControlUrl + '/api/clienteservico/atualiza/' + itemId, item, { observe: 'response' })
    };

    deleteClienteServico(itemId: any): Observable<HttpResponse<any>> {
        return this.http.delete(
            environment.webApiSaguiControlUrl + '/api/clienteservico/exclui/' + itemId, { observe: 'response' })
    }

    getProdutoPorNome(parametros: any): Observable<HttpResponse<any>> {

        const params = {
            id: parametros.id,
            tipoProduto: parametros.tipoProduto,
        }

        return this.http.get(environment.webApiSaguiControlUrl + '/api/clienteservico/autocompleteproduto', { observe: 'response', params })
    }

    getProduto(produtoId: any): Observable<HttpResponse<any>> {

        return this.http.get(environment.webApiSaguiControlUrl + '/api/clienteservico/produto/' + produtoId, { observe: 'response'})
    }

    getClienteServicoHistoricos(itemId: any): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/clienteservicohistorico/visualizaporclienteservico/' + itemId, { observe: 'response' })
    }

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
