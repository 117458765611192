<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>Imprimir DANFE</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button class="sagui-button" mat-raised-button (click)="imprime()" matTooltip="Imprimir DANFE">
    <mat-icon>print</mat-icon> DANFE
  </button>
  <button class="sagui-button" mat-raised-button *ngIf="nfe.statusProcessamento == 100 || nfe.statusProcessamento == 135" (click)="enviarPorEmail()" matTooltip="Enviar E-mail">
    <mat-icon>mail</mat-icon> Enviar para Destinatário
  </button>
</div>
<mat-dialog-content>
  <br>
  <div class="md-dialog-content" *ngIf="nfe.nfeId > 0">
    <div style="display:flex; justify-content: center; background-color: #c3c3c3; height: 35cm;">
      <div class="danfe" id="printDanfe">
        <!--ng-repeat="page in Pages"-->
        <div class="page">
          <div class="bloco">
            <!--ng-if="page.isFirstPage"-->
            <table id="canhoto">
              <tr>
                <td class="campo recebemos" colspan="2">
                  <div class="conteudo fonte-5-pontos">RECEBEMOS DE {{empresa.razaoSocial}} OS PRODUTOS CONSTANTES DA
                    NOTA FISCAL INDICADA AO LADO</div>
                </td>
                <td class="campo numero-nfe" rowspan="2">
                  <div class="conteudo centralizado negrito">NF-e</div>
                  <div class="conteudo negrito">Nr: {{nfe.nNF}}</div>
                  <div class="conteudo negrito">Série: {{nfe.serie}}</div>
                </td>
              </tr>
              <tr>
                <td class="campo data-recebimento">
                  <div class="conteudo fonte-5-pontos">DATA DE RECEBIMENTO</div>
                </td>
                <td class="campo assinatura">
                  <div class="conteudo fonte-5-pontos">IDENTIFICAÇÃO E ASSINATURA</div>
                </td>
              </tr>
            </table>
            <hr class="picote" />
          </div>
          <div id="watermark" *ngIf="nfe.tpAmb == 2">Sem Valor Fiscal</div>
          <div class="bloco">
            <table id="dados-nfe" class="tabelaDanfe">
              <thead>
                <tr>
                  <td rowspan="4" class="campo quadro-logo" style="width: 25mm;">
                    <div class="conteudo">
                      <!-- <img ng-src="data:image/JPEG;base64,{{empresa.logoByte}}" id="logodanfe"> -->
                      <img [src]="'data:image/JPEG;base64,'+ empresa.logoByte" id="logodanfe">
                    </div>
                  </td>
                  <td rowspan="4" class="campo quadro-identificacao-emitente" style="width: 60mm;">
                    <div class="conteudo negrito">
                      <h4>{{empresa.razaoSocial}}</h4>
                    </div>
                    <div class="conteudo">
                      {{empresa.logradouro}}, {{empresa.numero}} <br />
                      {{empresa.bairro}}<br />
                      {{empresaMunicipio}} {{empresaUf}} <br />
                      {{empresa.cep}} <br />
                      Tel: {{empresa.telefone}}
                    </div>
                  </td>
                  <td rowspan="4" class="campo quadro-descricao-danfe" style="width: 30mm;">
                    <div class="conteudo negrito centralizado">
                      DANFE
                    </div>
                    <div class="conteudo negrito centralizado fonte-7-pontos">
                      DOCUMENTO AUXILIAR DA NOTA FISCAL ELETRÔNICA
                    </div>
                    <div class="conteudo">
                      <div class="conteudo duas-coluna fonte-5-pontos">
                        0 - ENTRADA <br />
                        1 - SAÍDA
                      </div>
                      <div class="conteudo duas-coluna centralizado negrito">
                        <span class="caixa">{{nfe.tpNF}}</span>
                      </div>
                    </div>
                    <div class="conteudo negrito fonte-7-pontos">
                      Nr: {{nfe.nNF}}<br />
                      Série: {{nfe.serie}}<br />
                      Folha: 1/1 <br />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="campo quadro-codigo-de-barras-da-chave" style="width: 85mm;">
                    <ngx-barcode6 [bc-value]="nfe.chaveAcesso" [bc-width]="1" [bc-height]="30" [bc-display-value]="false" style="height:1.48cm;" class="conteudo centralizado barcode128h"></ngx-barcode6>
                  </td>
                </tr>
                <tr>
                  <td class="campo codigo-de-barras-da-chave">
                    <div class="conteudo negrito fonte-5-pontos">
                      CHAVE DE ACESSO DA NF-e
                    </div>
                    <div class="conteudo negrito" style="font-size:8pt">
                      {{chaveAcessoQuatro}}
                    </div>
                </tr>
                <tr>
                  <td class="campo codigo-de-barras-da-chave">
                    <div class="conteudo centralizado fonte-7-pontos">
                      Consulta de autenticidade no portal nacional da NF-e www.nfe.fazenda.gov.br/portal ou
                      no site da Sefaz autorizadora
                    </div>
                  </td>
                </tr>
              </thead>
            </table>
            <table class="sem-borda-superior">
              <tr>
                <td colspan="3" class="campo natureza-de-operacao">
                  <div class="conteudo fonte-5-pontos">NATUREZA DA OPERAÇÃO</div>
                  <div class="conteudo">{{nfe.natOp}}</div>
                </td>
                <td class="campo codigo-de-barras-da-chave">
                  <div class="conteudo fonte-5-pontos">PROTOCOLO DE AUTORIZAÇÃO DE USO</div>
                  <div class="conteudo">{{nfe.nProt}}</div>
                </td>
              </tr>
            </table>
            <table class="sem-borda-superior">
              <tr>
                <td class="campo inscricao-estadual-emitente">
                  <div class="conteudo fonte-5-pontos">INSCRIÇÃO ESTADUAL</div>
                  <div class="conteudo">{{empresa.inscEstadual}}</div>
                </td>
                <td class="campo inscricao-estadual-st-emitente">
                  <div class="conteudo fonte-5-pontos">INSCR.ESTADUAL SUBSTITUTO</div>
                  <div class="conteudo"></div>
                </td>
                <td class="campo cnpj-emitente">
                  <div class="conteudo fonte-5-pontos">CNPJ</div>
                  <div class="conteudo">{{empresa.cnpj}}</div>
                </td>
              </tr>
            </table>
          </div>
          <div class="bloco">
            <div class="conteudo negrito fonte-7-pontos">DESTINATÁRIO/REMETENTE</div>
            <table>
              <tr>
                <td colspan="3" class="campo razao-social">
                  <div class="conteudo fonte-5-pontos">NOME / RAZÃO SOCIAL</div>
                  <div class="conteudo">
                    {{cliente.razaoSocial}}
                  </div>
                </td>
                <td class="campo cnpj-destinatario">
                  <div class="conteudo fonte-5-pontos">CNPJ / CPF</div>
                  <div class="conteudo">
                    {{cliente.cnpj == null || cliente.cnpj == undefined || cliente.cnpj == '' ? cliente.cpf :
                    cliente.cnpj }}
                  </div>
                </td>
                <td class="campo data-emissao">
                  <div class="conteudo fonte-5-pontos">DATA DE EMISSÃO</div>
                  <div class="conteudo">
                    {{nfe.dhEmi | date:'dd/MM/yyyy'}}
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2" class="campo endereco-destinatario">
                  <div class="conteudo fonte-5-pontos">ENDEREÇO</div>
                  <div class="conteudo">
                    {{cliente.logradouro}},
                    {{cliente.numero}}
                  </div>
                </td>
                <td class="campo bairro-destinatario">
                  <div class="conteudo fonte-5-pontos">BAIRRO / DISTRITO</div>
                  <div class="conteudo">
                    {{cliente.bairro}}
                  </div>
                </td>
                <td class="campo cep-destinatario">
                  <div class="conteudo fonte-5-pontos">CEP</div>
                  <div class="conteudo">
                    {{cliente.cep}}
                  </div>
                </td>
                <td class="campo data-entrada-destinatario">
                  <div class="conteudo fonte-5-pontos">DATA ENTRADA / SAÍDA</div>
                  <div class="conteudo">
                    {{nfe.dhSaiEnt | date:'dd/MM/yyyy'}}
                  </div>
                </td>
              </tr>
              <tr>
                <td class="campo municipio-destinatario">
                  <div class="conteudo fonte-5-pontos">MUNICÍPIO</div>
                  <div class="conteudo">
                    {{clienteMunicipio}}
                  </div>
                </td>
                <td class="campo fone-fax-destinatario">
                  <div class="conteudo fonte-5-pontos">TELEFONE</div>
                  <div class="conteudo">
                    {{cliente.telefone}}
                  </div>
                </td>
                <td class="campo uf-destinatario">
                  <div class="conteudo fonte-5-pontos">UF</div>
                  <div class="conteudo">
                    {{clienteUf}}
                  </div>
                </td>
                <td class="campo inscricao-destinatario">
                  <div class="conteudo fonte-5-pontos">INSCRIÇÃO ESTADUAL</div>
                  <div class="conteudo">
                    {{cliente.inscEstadual}}
                  </div>
                </td>
                <td class="campo hora-entrada-saida">
                  <div class="conteudo fonte-5-pontos">HORA ENTRADA / SAÍDA</div>
                  <div class="conteudo">
                    {{nfe.dhSaiEnt | date:'hh:mm:ss'}}
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <!-- <div class="bloco">
            <div class="conteudo negrito fonte-7-pontos" *ngIf="false">FATURAS</div>
            <table class="sem-bordas-laterais">
              <tr>
                <td>
                  <table class="sem-bordas">
                    <tr *ngFor="let duplicata of Page.nfeProc.nfe.infNFe.cobr.dup">
                      <td class="campo sem-bordas">
                        <div class="conteudo tres-colunas">
                          <div class="conteudo fonte-5-pontos">NÚMERO</div>
                          <div class="conteudo fonte-7-pontos">{{duplicata.nDup}}</div>
                        </div>
                        <div class="conteudo tres-colunas">
                          <div class="conteudo fonte-5-pontos">NÚMERO</div>
                          <div class="conteudo fonte-7-pontos">{{duplicata.dVenc}}</div>
                        </div>
                        <div class="conteudo tres-colunas">
                          <div class="conteudo fonte-5-pontos">NÚMERO</div>
                          <div class="conteudo fonte-7-pontos">{{duplicata.vDup}}</div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div> -->
          <div class="bloco">
            <div class="conteudo negrito fonte-7-pontos">CÁLCULO DO IMPOSTO</div>
            <table>
              <tr>
                <td class="campo base-calculo-icms">
                  <div class="conteudo fonte-5-pontos">BASE DE CÁLCULO ICMS</div>
                  <div class="conteudo">
                    {{vBC | number: '1.2-2'}}
                  </div>
                </td>
                <td class="campo valor-do-icms">
                  <div class="conteudo fonte-5-pontos">VALOR DO ICMS</div>
                  <div class="conteudo">
                    {{vICMS | number: '1.2-2'}}
                  </div>
                </td>
                <td class="campo base-de-cálculo-do-icms-st">
                  <div class="conteudo fonte-5-pontos">BASE DE CÁLCULO ICMS ST</div>
                  <div class="conteudo">
                    {{vBCST | number: '1.2-2'}}
                  </div>
                </td>
                <td class="campo valor-do-icms-st">
                  <div class="conteudo fonte-5-pontos">VALOR DO ICMS ST</div>
                  <div class="conteudo">
                    {{vST | number: '1.2-2'}}
                  </div>
                </td>
                <td class="campo valor-total-dos-produtos">
                  <div class="conteudo fonte-5-pontos">VALOR TOTAL DOS PRODUTOS</div>
                  <div class="conteudo">
                    {{vProd | number: '1.2-2'}}
                  </div>
                </td>
              </tr>
            </table>
            <table class="sem-borda-superior">
              <tr>
                <td class="campo valor-do-frete">
                  <div class="conteudo fonte-5-pontos">VALOR DO FRETE</div>
                  <div class="conteudo">
                    {{vFrete | number: '1.2-2'}}
                  </div>
                </td>
                <td class="campo valor-do-seguro">
                  <div class="conteudo fonte-5-pontos">VALOR DO SEGURO</div>
                  <div class="conteudo">
                    {{vSeg | number: '1.2-2'}}
                  </div>
                </td>
                <td class="campo desconto">
                  <div class="conteudo fonte-5-pontos">DESCONTO</div>
                  <div class="conteudo">
                    {{vDesc | number: '1.2-2'}}
                  </div>
                </td>
                <td class="campo outras-despesas-acessórias">
                  <div class="conteudo fonte-5-pontos">OUTRAS DESPESAS ACESSÓRIAS</div>
                  <div class="conteudo">
                    {{vOutro | number: '1.2-2'}}
                  </div>
                </td>
                <td class="campo valor-do-ipi">
                  <div class="conteudo fonte-5-pontos">VALOR DO IPI</div>
                  <div class="conteudo">
                    {{vIPI | number: '1.2-2'}}
                  </div>
                </td>
                <td class="campo valor-total-da-nota">
                  <div class="conteudo fonte-5-pontos">VALOR TOTAL DA NOTA</div>
                  <div class="conteudo">
                    {{vNF | number: '1.2-2'}}
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="bloco">
            <!--ng-if="page.isFirstPage"-->
            <div class="conteudo negrito fonte-7-pontos">TRANSPORTADOR / VOLUMES TRANSPORTADOS</div>
            <table>
              <tr>
                <td class="campo razao-social-transportador">
                  <div class="conteudo fonte-5-pontos">NOME / RAZÃO SOCIAL</div>
                  <div class="conteudo">
                    {{transportadora.razaoSocial}}
                  </div>
                </td>
                <td class="campo frete-por-conta-transportador">
                  <div class="conteudo fonte-5-pontos">FRETE POR CONTA</div>
                  <div class="conteudo">
                    {{verificarFreteConta(nfe.modFrete)}}
                  </div>
                </td>
                <td class="campo codigo-antt-transportador">
                  <div class="conteudo fonte-5-pontos">CÓDIGO ANTT</div>
                  <div class="conteudo">
                  </div>
                </td>
                <td class="campo placa-veiculo-transportador">
                  <div class="conteudo fonte-5-pontos">PLACA DO VEÍCULO</div>
                </td>
                <td class="campo uf-placa-transportador">
                  <div class="conteudo fonte-5-pontos">UF</div>
                </td>
                <td class="campo Cnpj-transportador">
                  <div class="conteudo fonte-5-pontos">CNPJ / CPF</div>
                  <div class="conteudo">
                    {{transportadora.cnpj == null || transportadora.cnpj == undefined || transportadora.cnpj == '' ?
                    transportadora.cpf : transportadora.cnpj }}
                  </div>
                </td>
              </tr>
              <tr>
                <td class="campo endereco-transportador">
                  <div class="conteudo fonte-5-pontos">ENDEREÇO</div>
                  <div class="conteudo">
                    {{transportadora.logradouro}} &nbsp;
                    {{transportadora.numero}}
                  </div>
                </td>
                <td colspan="3" class="campo municipio-transportador">
                  <div class="conteudo fonte-5-pontos">MUNICÍPIO</div>
                  <div class="conteudo">
                    {{transportadoraMunicipio}}
                  </div>
                </td>
                <td class="campo uf-transportador">
                  <div class="conteudo fonte-5-pontos">UF</div>
                  <div class="conteudo">
                    {{transportadoraUf}}
                  </div>
                </td>
                <td class="campo inscricao-transportador">
                  <div class="conteudo fonte-5-pontos">INSCRIÇÃO ESTADUAL</div>
                  <div class="conteudo">
                    {{transportadora.inscricaoEstadual}}
                  </div>
                </td>
              </tr>
            </table>
            <table class="sem-borda-superior">
              <tr>
                <td class="campo quantidade-transportador">
                  <div class="conteudo fonte-5-pontos">QUANTIDADE</div>
                  <div class="conteudo">
                    {{nfe.qVol}}
                  </div>
                </td>
                <td class="campo especie-transportador">
                  <div class="conteudo fonte-5-pontos">ESPÉCIE</div>
                  <div class="conteudo">
                    {{nfe.especie}}
                  </div>
                </td>
                <td class="campo marca-transportador">
                  <div class="conteudo fonte-5-pontos">MARCA</div>
                  <div class="conteudo">
                    {{nfe.marca}}
                  </div>
                </td>
                <td class="campo numero-transportador">
                  <div class="conteudo fonte-5-pontos">NÚMERO</div>
                  <div class="conteudo">
                  </div>
                </td>
                <td class="campo peso-bruto-transportador">
                  <div class="conteudo fonte-5-pontos">PESO BRUTO</div>
                  <div class="conteudo">
                    {{nfe.pesoB | number: '1.3-3'}}
                  </div>
                </td>
                <td class="campo peso-liquido-transportador">
                  <div class="conteudo fonte-5-pontos">PESO LÍQUIDO</div>
                  <div class="conteudo">
                    {{nfe.pesoL | number: '1.3-3'}}
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="bloco">
            <div class="conteudo negrito fonte-7-pontos">DADOS DOS PRODUTOS / SERVIÇOS</div>
            <table id="dados-produtos">
              <thead>
                <tr>
                  <th class="campo">
                    <div class="conteudo fonte-5-pontos">CÓDIGO</div>
                  </th>
                  <th class="campo">
                    <div class="conteudo fonte-5-pontos">DESCRIÇÃO DOS PRODUTOS / SERVIÇOS</div>
                  </th>
                  <th class="campo">
                    <div class="conteudo fonte-5-pontos">NCM/SH</div>
                  </th>
                  <th class="campo">
                    <div class="conteudo fonte-5-pontos">CST</div>
                  </th>
                  <th class="campo">
                    <div class="conteudo fonte-5-pontos">CFOP</div>
                  </th>
                  <th class="campo">
                    <div class="conteudo fonte-5-pontos">UNID</div>
                  </th>
                  <th class="campo">
                    <div class="conteudo fonte-5-pontos">QUANT</div>
                  </th>
                  <th class="campo">
                    <div class="conteudo fonte-5-pontos">VLR.UNIT.</div>
                  </th>
                  <th class="campo">
                    <div class="conteudo fonte-5-pontos">VLR.TOTAL</div>
                  </th>
                  <th class="campo">
                    <div class="conteudo fonte-5-pontos">BC.ICMS</div>
                  </th>
                  <th class="campo">
                    <div class="conteudo fonte-5-pontos">VL.ICMS</div>
                  </th>
                  <th class="campo">
                    <div class="conteudo fonte-5-pontos">VLR I.P.I</div>
                  </th>
                  <th class="campo">
                    <div class="conteudo fonte-5-pontos">
                      Aliq
                      icms
                    </div>
                  </th>
                  <th class="campo">
                    <div class="conteudo fonte-5-pontos">
                      Aliq
                      ipi
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of nfe.nfeItens">
                  <!--<td class="campo sem-borda-superior-inferior">-->
                  <td class="campo ">
                    <div class="conteudo fonte-7-pontos">
                      {{item.codProd}}
                    </div>
                  </td>
                  <!--<td class="campo sem-borda-superior-inferior">-->
                  <td class="campo ">
                    <div class="conteudo fonte-7-pontos">
                      {{item.descricao}}
                    </div>
                  </td>
                  <!--<td class="campo sem-borda-superior-inferior">-->
                  <td class="campo ">
                    <div class="conteudo fonte-7-pontos">
                      {{item.ncm}}
                    </div>
                  </td>
                  <!--<td class="campo sem-borda-superior-inferior">-->
                  <td class="campo ">
                    <div class="conteudo fonte-7-pontos">
                      {{validaOrigemMercadoria(item)}}{{item.cstIcms}}
                    </div>
                  </td>
                  <!--<td class="campo sem-borda-superior-inferior">-->
                  <td class="campo ">
                    <div class="conteudo fonte-7-pontos">
                      {{item.cfop}}
                    </div>
                  </td>
                  <!--<td class="campo sem-borda-superior-inferior">-->
                  <td class="campo ">
                    <div class="conteudo fonte-7-pontos">
                      {{item.unComercial}}
                    </div>
                  </td>
                  <!--<td class="campo sem-borda-superior-inferior">-->
                  <td class="campo ">
                    <div class="conteudo fonte-7-pontos">
                      {{item.qtdComercial | number: '1.4-4'}}
                    </div>
                  </td>
                  <!--<td class="campo sem-borda-superior-inferior">-->
                  <td class="campo ">
                    <div class="conteudo fonte-7-pontos">
                      {{item.vlrComercial | number: '1.2-2'}}
                    </div>
                  </td>
                  <!--<td class="campo sem-borda-superior-inferior">-->
                  <td class="campo ">
                    <div class="conteudo fonte-7-pontos">
                      {{item.vProd | number: '1.2-2'}}
                    </div>
                  </td>
                  <!--<td class="campo sem-borda-superior-inferior">-->
                  <td class="campo ">
                    <div class="conteudo fonte-7-pontos">
                      {{validaicmsvBC(item) | number: '1.2-2'}}
                    </div>
                  </td>
                  <!--<td class="campo sem-borda-superior-inferior">-->
                  <td class="campo ">
                    <div class="conteudo fonte-7-pontos">
                      {{validaicmsvIcms(item) | number: '1.2-2'}}
                    </div>
                  </td>
                  <!--<td class="campo sem-borda-superior-inferior">-->
                  <td class="campo ">
                    <div class="conteudo fonte-7-pontos">
                      {{validaipivIpi(item.nfeItemImpostos) | number: '1.2-2'}}
                    </div>
                  </td>
                  <!--<td class="campo sem-borda-superior-inferior">-->
                  <td class="campo ">
                    <div class="conteudo fonte-7-pontos">
                      {{validaicmspIcms(item) | number: '1.2-2'}}
                    </div>
                  </td>
                  <!--<td class="campo sem-borda-superior-inferior">-->
                  <td class="campo ">
                    <div class="conteudo fonte-7-pontos">
                      {{validaipipIpi(item.nfeItemImpostos) | number: '1.2-2'}}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="bloco rodape">
            <!--ng-if="page.isLastPage"-->
            <table id="dados_adicionais">
              <tr>
                <td class="campo">
                  <div class="conteudo negrito">DADOS ADICIONAIS</div>
                </td>
                <td class="campo">
                  <div class="conteudo negrito">RESERVADO AO FISCO</div>
                </td>
              </tr>
              <tr>
                <td class="campo dados-adicionais">
                  <div class="conteudo">
                    {{verificarObsHom(nfe.tpAmb)}}
                    {{nfe.infCpl}}
                    <br />
                    {{nfe.infAdFisco}}
                  </div>
                </td>
                <td class="campo reservado-fisco">
                  <div class="conteudo">

                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
