import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class HeaderService {

    getBuscaImagemAzure(): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/usuario/buscaimagemazure', { observe: 'response' })
    }

    getUsuarioModuloFuncionalidades(): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/usuario/visualizamodulosefuncionalidades', { observe: 'response' })
    }

    getLogo(): Observable<HttpResponse<any>> {
        return this.http.get(
            environment.webApiSaguiControlUrl + '/api/saguiCliente/buscalogo', { observe: 'response' })
    }
    
    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

    }
}
