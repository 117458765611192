import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpClientModule } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class CaixaMovimentoService {

  getCaixaMovimento(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/caixamovimento/visualiza/' + itemId, { observe: 'response' })
  }

  getCaixaMovimentos(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/caixamovimento/visualizatodasporfiltro', item, { observe: 'response' })
  }

  postCaixaMovimento(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/caixamovimento/inclui/', item, { observe: 'response' })
  };

  putCaixaMovimento(item: any, itemId: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/caixamovimento/atualiza/' + itemId, item, { observe: 'response' })
  };

  deleteCaixaMovimento(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/caixamovimento/exclui/' + itemId, { observe: 'response' })
  }

  postCaixaMovimentoTransferencia(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/caixamovimento/transferir', item, { observe: 'response' })
  };

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
