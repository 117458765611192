import { Injectable } from '@angular/core';
import { LoaderService } from '../loader/loader.service';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loaderService: LoaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Verificar se o cabeçalho personalizado está presente
    const skipLoader = request.headers.has('X-Skip-Loader');

    if (!skipLoader) {
      this.loaderService.show();
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (!skipLoader) {
          this.loaderService.hide();
        }
      }),
    );

  }
}