import { Component } from '@angular/core';
import { title } from 'process';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent {
  showNotifications = false;
  notifications = [ ];

  get unreadNotificationsCount() {
    const count = this.notifications.filter(notification => !notification.lida).length;
    return count > 9 ? '+9' : count;
  }

  toggleNotifications() {
    this.showNotifications = !this.showNotifications;
  }

  marcarComoLida(notification) {
    notification.lida = true;
    // Atualiza a contagem de notificações não lidas
    this.notifications = [...this.notifications]; // Força a atualização da view
  }

  marcarTodasComoLida() {
      this.notifications.forEach(notification => notification.lida = true);
  }
}