import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';

@Injectable()
export class ExportaZipService {

  exporta(data, filename) {

    let zip = new JSZip();
    zip.file(filename, data, { base64: true });

    zip.generateAsync({ type: "blob" })
      .then(function (content) {
        FileSaver.saveAs(content, filename);
      });

  }

  constructor() {

  }
}
