<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>NF-e Inbound</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="importar()" matTooltip="Salvar">
    <mat-icon>save</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="md-dialog-content">
    <br />
    <div class="row">
      <div class="col-md-12">
        <input class="form-control" type="file" (change)="fileChanged($event)" />
        Importe o arquivo XML da NF-e.
      </div>
    </div>
  </div>
</mat-dialog-content>
