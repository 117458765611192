<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>
      {{textoclienteTipo}} {{clienteCadastroForm.value.clienteId}}</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()"
    [disabled]="clienteCadastroForm.value.clienteStatus == 99">
    <mat-icon>save</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <br />
  <div class="md-dialog-content">
    <h2>{{clienteCadastroForm.value.razaoSocial}}</h2>
    <div class="row">
      <form [formGroup]="clienteCadastroForm">
        <div class="col-md-12">
          <mat-tab-group (selectedTabChange)="matTabMudouAba($event)">
            <mat-tab label="Geral">
              <br />
              <div class="row">
                <div class="col-md-4 col-xs-12 form-group" *ngIf="clienteCadastroForm.value.clienteId > 0">
                  <mat-checkbox formControlName="ativo">Ativo?</mat-checkbox>
                </div>
                <div class="col-md-4 col-xs-12 form-group">
                  <mat-radio-group aria-label="Tipo de Pessoa" formControlName="pessoaFisica">
                    <mat-radio-button [value]="true">Pessoa Física</mat-radio-button>
                    <mat-radio-button [value]="false">Pessoa Jurídica</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <hr />
              <div class="row">
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Empresa</mat-label>
                  <mat-select formControlName="clienteEmpresas" multiple required>
                    <mat-option *ngFor="let empresa of empresas" [value]="empresa">
                      {{empresa.nomeFantasia}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>
              <div class="row">
                <mat-form-field class="col-md-4 col-xs-12 form-group"
                  *ngIf="!pessoaFisica && clienteCadastroForm.value.clienteId == 0">
                  <mat-label>UF</mat-label>
                  <mat-select formControlName="ufSigla">
                    <mat-option *ngFor="let uf of ufs" [value]="uf.siglaUF" (click)="definieUf(uf)">
                      {{uf.siglaUF}} - {{uf.descUF}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-12 form-group" *ngIf="!pessoaFisica">
                  <mat-label>CNPJ do Cliente</mat-label>
                  <input type="text" matInput formControlName="cnpj" (blur)="buscarParceiro()">
                </mat-form-field>
                <div *ngIf="!pessoaFisica">
                  <mat-form-field class="col-md-4 col-xs-12 form-group">
                    <mat-label>Inscrição Estadual do Cliente</mat-label>
                    <input type="text" matInput formControlName="inscEstadual" (disabled)="isento">
                  </mat-form-field>
                  <div class="form-group col-md-4">
                    <mat-checkbox formControlName="isento">Isento?</mat-checkbox>
                  </div>
                </div>
                <mat-form-field class="col-md-4 col-xs-12 form-group" *ngIf="pessoaFisica">
                  <mat-label>CPF do Cliente</mat-label>
                  <input type="text" matInput formControlName="cpf" (blur)="buscarParceiro()">
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-12 form-group" *ngIf="pessoaFisica">
                  <mat-label>RG</mat-label>
                  <input type="text" matInput formControlName="rg">
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="col-md-6 col-xs-12 form-group">
                  <mat-label>Razão Social (Nome)</mat-label>
                  <input type="text" matInput formControlName="razaoSocial">
                </mat-form-field>
                <mat-form-field class="col-md-6 col-xs-12 form-group" *ngIf="!pessoaFisica">
                  <mat-label>Nome Fantasia</mat-label>
                  <input type="text" matInput formControlName="nomeFantasia">
                </mat-form-field>
                <mat-form-field class="col-md-6 col-xs-12 form-group" *ngIf="pessoaFisica">
                  <mat-label>Data de Nascimento</mat-label>
                  <input matInput [matDatepicker]="picker1" formControlName="dataNascimento" name="dataNascimento">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="row" *ngIf="!pessoaFisica">
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Inscrição Municipal</mat-label>
                  <input type="text" matInput formControlName="inscMunicipial">
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Inscrição Suframa</mat-label>
                  <input type="text" matInput formControlName="inscSuframa">
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-12 form-group" *ngIf="!pessoaFisica">
                  <mat-label>Código de Regime Tributário</mat-label>
                  <mat-select formControlName="codRegimeTributario" name="codRegimeTributario">
                    <mat-option [value]="1">1 - Simples Nacional</mat-option>
                    <mat-option [value]="2">2 - Simples Nacional, excesso sublimite de receita bruta</mat-option>
                    <mat-option [value]="3">3 - Regime Normal</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>


              <div class="row">
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>CEP</mat-label>
                  <input type="text" matInput formControlName="cep"
                    (blur)="consultarCep(clienteCadastroForm.value.cep, false)">
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Logradouro</mat-label>
                  <input type="text" matInput formControlName="logradouro">
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Número</mat-label>
                  <input type="text" matInput formControlName="numero">
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Complemento</mat-label>
                  <input type="text" matInput formControlName="complemento">
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Bairro</mat-label>
                  <input type="text" matInput formControlName="bairro">
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>País</mat-label>
                  <mat-select formControlName="paisId">
                    <mat-option *ngFor="let pais of paises" [value]="pais.paisId">
                      {{pais.descPais}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>UF</mat-label>
                  <mat-select formControlName="ufId">
                    <mat-option *ngFor="let uf of ufs" [value]="uf.ufId">
                      {{uf.descUF}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Município</mat-label>
                  <mat-select formControlName="municipioId">
                    <mat-option *ngFor="let municipio of municipios" [value]="municipio.municipioId">
                      {{municipio?.descricao}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Telefone</mat-label>
                  <input type="text" matInput formControlName="telefone">
                </mat-form-field>
              </div>
              <div class="row">

                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>E-mail</mat-label>
                  <input type="email" matInput formControlName="email">
                </mat-form-field>
                <div class="row">
                  <mat-form-field class="col-md-8 col-xs-12 form-group">
                    <mat-label>Indicação</mat-label>
                    <input type="text" matInput formControlName="indicacao">
                  </mat-form-field>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-6 col-xs-12 form-group">
                  <mat-checkbox formControlName="localEntregaAtivo">Local de Entrega diferente?</mat-checkbox>
                </div>
              </div>
              <hr />
              <div *ngIf="clienteCadastroForm.value.localEntregaAtivo">
                <div class="row">
                  <div class="col-md-6 col-xs-12 form-group">
                    <h2>Local Entrega</h2>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <mat-form-field class="col-md-4 col-xs-12 form-group">
                    <mat-label>CEP</mat-label>
                    <input type="text" matInput formControlName="cepEntrega"
                      (blur)="consultarCep(clienteCadastroForm.value.cepEntrega, true)">
                  </mat-form-field>
                  <mat-form-field class="col-md-4 col-xs-12 form-group">
                    <mat-label>Logradouro</mat-label>
                    <input type="text" matInput formControlName="logradouroEntrega">
                  </mat-form-field>
                  <mat-form-field class="col-md-4 col-xs-12 form-group">
                    <mat-label>Número</mat-label>
                    <input type="text" matInput formControlName="numeroEntrega">
                  </mat-form-field>
                  <mat-form-field class="col-md-4 col-xs-12 form-group">
                    <mat-label>Complemento</mat-label>
                    <input type="text" matInput formControlName="complementoEntrega">
                  </mat-form-field>
                  <mat-form-field class="col-md-4 col-xs-12 form-group">
                    <mat-label>Bairro</mat-label>
                    <input type="text" matInput formControlName="bairroEntrega">
                  </mat-form-field>
                </div>
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>UF</mat-label>
                  <mat-select formControlName="ufEntregaId">
                    <mat-option *ngFor="let uf of ufs" [value]="uf.ufId">
                      {{uf.descUF}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Município</mat-label>
                  <mat-select formControlName="municipioEntregaId">
                    <mat-option *ngFor="let municipio of localEntregaMunicipios" [value]="municipio.municipioId">
                      {{municipio?.descricao}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-tab>
            <mat-tab label="Fiscal e Contábil" *ngIf="clienteCadastroForm.value.clienteId > 0">

              <br />
              <div class="container">
                <div class="row">
                  <div class="col-md-12 col-xs-12 form-group">
                    <mat-card >
                      <mat-card-content>
                        <p>Quais notas fiscais serão emitidas para esse cliente?</p>
                        <div style="display: flex; gap: 16px;">
                          <mat-checkbox [(ngModel)]="fiscalCheckbox.nfse" [ngModelOptions]="{standalone: true}">NFS-e</mat-checkbox>
                          <mat-checkbox [(ngModel)]="fiscalCheckbox.nfe" [ngModelOptions]="{standalone: true}">NF-e</mat-checkbox>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
              </div>
              <div class="container" >
                <div class="row">
                  <div class="col-md-6 col-xs-12 form-group" *ngIf="fiscalCheckbox.nfse" >
                    <mat-card>
                      <mat-card-header>
                        <mat-card-title>NFS-E</mat-card-title>
                      </mat-card-header>
                      <mat-card-content>

                        <table mat-table [dataSource]="dataSourceNfse" class="mat-table">

                          <!-- Retido Column -->
                          <ng-container matColumnDef="retido">
                            <th mat-header-cell *matHeaderCellDef> Retido? </th>
                            <td mat-cell *matCellDef="let element">
                              <mat-checkbox [(ngModel)]="element.Retido" [disabled]="element.Empresas.length == 0"
                                [ngModelOptions]="{standalone: true}"></mat-checkbox>
                            </td>
                          </ng-container>

                          <!-- TipoImposto Column -->
                          <ng-container matColumnDef="tipoImposto">
                            <th mat-header-cell *matHeaderCellDef> Imposto </th>
                            <td mat-cell *matCellDef="let element"> {{enumImpostoEmString(element.TipoImposto)}} </td>
                          </ng-container>

                          <!-- Empresas Column -->
                          <ng-container matColumnDef="empresas">
                            <th mat-header-cell *matHeaderCellDef> Empresas </th>
                            <td mat-cell *matCellDef="let element">
                              <mat-form-field>
                                <mat-select multiple [(ngModel)]="element.Empresas"
                                  [ngModelOptions]="{standalone: true}">
                                  <mat-option *ngFor="let empresa of empresas"
                                    [value]="empresa.empresaId">{{empresa.nomeFantasia}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <div class="col-md-6 col-xs-12 form-group" *ngIf="fiscalCheckbox.nfe" >
                    <mat-card>
                      <mat-card-header>
                        <mat-card-title>NF-E</mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <table mat-table [dataSource]="dataSourceNfe" class="mat-table">

                          <!-- Retido Column -->
                          <ng-container matColumnDef="retido">
                            <th mat-header-cell *matHeaderCellDef> Retido? </th>
                            <td mat-cell *matCellDef="let element">
                              <mat-checkbox [(ngModel)]="element.Retido" [disabled]="element.Empresas.length == 0"
                                [ngModelOptions]="{standalone: true}"></mat-checkbox>
                            </td>
                          </ng-container>

                          <!-- TipoImposto Column -->
                          <ng-container matColumnDef="tipoImposto">
                            <th mat-header-cell *matHeaderCellDef> Imposto </th>
                            <td mat-cell *matCellDef="let element"> {{enumImpostoEmString(element.TipoImposto)}} </td>
                          </ng-container>

                          <!-- Empresas Column -->
                          <ng-container matColumnDef="empresas">
                            <th mat-header-cell *matHeaderCellDef> Empresas </th>
                            <td mat-cell *matCellDef="let element">
                              <mat-form-field>
                                <mat-select multiple [(ngModel)]="element.Empresas"
                                  [ngModelOptions]="{standalone: true}">
                                  <mat-option *ngFor="let empresa of empresas"
                                    [value]="empresa.empresaId">{{empresa.nomeFantasia}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
              </div>
              <form [formGroup]="clienteCadastroForm"></form>
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Contábil</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="row">
                    <mat-form-field class="col-md-12 col-xs-12 form-group">
                      <mat-label>Conta Contábil</mat-label>
                      <input type="text" matInput [matAutocomplete]="auto" formControlName="contaContabilAutoComplete">
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let contaContabil of contasContabeis" [value]="contaContabil.descricao"
                          (click)="determinarContaContabil(contaContabil)">
                          {{contaContabil.numero}} - {{contaContabil.descricao}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </mat-card>
              <div class="col-md-6">
              </div>
              <form [formGroup]="clienteContadorForm">
                <div class="row">
                  <div class="form-group col-md-12">
                    <mat-checkbox formControlName="contadorCheckbox">
                      Deseja armazenar os dados do contador desse cliente?
                    </mat-checkbox>
                  </div>
                </div>

                <mat-card *ngIf="clienteContadorForm.get('contadorCheckbox').value">
                  <mat-card-header>
                    <mat-card-title>Dados do Contador</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div class="row">
                      <mat-form-field class="col-md-4 col-xs-12 form-group">
                        <mat-label>CNPJ</mat-label>
                        <input type="text" matInput formControlName="cnpj" (blur)="buscarParceiroContador()">
                      </mat-form-field>
                      <mat-form-field class="col-md-4 col-xs-12 form-group">
                        <mat-label>Telefone</mat-label>
                        <input type="text" matInput formControlName="telefone">
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field class="col-md-4 col-xs-12 form-group">
                        <mat-label>Razão Social (Nome)</mat-label>
                        <input type="text" matInput formControlName="razaoSocial">
                      </mat-form-field>
                      <mat-form-field class="col-md-4 col-xs-12 form-group">
                        <mat-label>Nome Fantasia</mat-label>
                        <input type="text" matInput formControlName="nomeFantasia">
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field class="col-md-4 col-xs-12 form-group">
                        <mat-label>País</mat-label>
                        <mat-select formControlName="paisId">
                          <mat-option *ngFor="let pais of paises" [value]="pais.paisId">
                            {{pais.descPais}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="col-md-4 col-xs-12 form-group">
                        <mat-label>UF</mat-label>
                        <mat-select formControlName="ufId">
                          <mat-option *ngFor="let uf of ufs" [value]="uf.ufId">
                            {{uf.descUF}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="col-md-4 col-xs-12 form-group">
                        <mat-label>Município</mat-label>
                        <mat-select formControlName="municipioId">
                          <mat-option *ngFor="let municipio of municipios" [value]="municipio.municipioId">
                            {{municipio?.descricao}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </mat-card-content>
                </mat-card>
                <hr />
              </form>
            </mat-tab>
            <mat-tab label="Contatos" *ngIf="clienteCadastroForm.value.clienteId > 0">
              <br />
              <div class="row">
                <div class="col-md-12">
                  <button (click)="modalContato(0)" mat-raised-button matTooltip="Adicionar Contato">
                    <mat-icon>add</mat-icon> Contato
                  </button>
                </div>
              </div>
              <hr>
              <div id="list" class="row">
                <div class="table-responsive col-md-12">
                  <table class="table table-striped" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th>Ações</th>
                        <th>Nome</th>
                        <th>Sobrenome</th>
                        <th>WhatsApp</th>
                        <th>Celular</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let contato of contatos">
                        <td class="actions">
                          <a mat-mini-fab (click)="modalContato(contato.contatoId)" matTooltip="Editar Contato">
                            <mat-icon>edit</mat-icon>
                          </a>
                          <a mat-mini-fab (click)="confirmarExclusaoContato(contato.contatoId)" color="warn"
                            matTooltip="Deletar Contato">
                            <mat-icon>delete</mat-icon>
                          </a>
                        </td>
                        <td>{{contato.nome}}</td>
                        <td>{{contato.sobreNome}}</td>
                        <td>{{contato.whatsApp}}
                          <a mat-mini-fab target="_blank" href="https://wa.me/55{{contato.whatsApp}}">
                            <span class="fa fa-whatsapp"></span>
                          </a>
                        </td>
                        <td>{{contato.celular}}
                          <a mat-mini-fab target="_blank" href="tel:0{{contato.celular}}">
                            <span class="fa fa-phone"></span>
                          </a>
                        </td>
                        <td>{{contato.email}}
                          <a mat-mini-fab target="_blank" href="mailto:{{contato.email}}">
                            <span class="fa fa-envelope-square"></span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Dados Bancários"
              *ngIf="clienteCadastroForm.value.clienteId > 0 && clienteCadastroForm.value.clienteTipo == 0">
              <br />
              <div class="row">
                <div class="col-md-12">
                  <button (click)="modalDadoBancario(0)" mat-raised-button matTooltip="Adicionar Dados Bancários">
                    <mat-icon>add</mat-icon> Dados Bancários
                  </button>
                </div>
              </div>
              <hr>
              <div id="list" class="row">
                <div class="table-responsive col-md-12">
                  <table class="table table-striped" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th>Ações</th>
                        <th>Banco</th>
                        <th>Agencia</th>
                        <th>Número</th>
                        <th>Tipo</th>
                        <th>Nome</th>
                        <th>CNPJ/CPF</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let dadoBancario of dadosBancarios">
                        <td class="actions">
                          <a mat-mini-fab (click)="modalDadoBancario(dadoBancario.dadoBancarioId)"
                            matTooltip="Editar Dado Bancário">
                            <mat-icon>edit</mat-icon>
                          </a>
                          <a mat-mini-fab (click)="confirmarExclusaoDadoBancario(dadoBancario.dadoBancarioId)"
                            matTooltip="Deletar Dado Bancário" color="warn">
                            <mat-icon>delete</mat-icon>
                          </a>
                        </td>
                        <td>{{dadoBancario.bancoDescricao}}</td>
                        <td>{{dadoBancario.agenciaNumero}}</td>
                        <td>{{dadoBancario.contaCorrenteNumero}}</td>
                        <td>{{dadoBancario.dadoBancarioTipo == 0 ? 'Conta Corrente' : 'Conta Poupança'}}</td>
                        <td>{{dadoBancario.favorecidoNome}}</td>
                        <td>{{dadoBancario.favorecidoCpf != undefined && dadoBancario.favorecidoCpf != ''?
                          dadoBancario.favorecidoCpf : dadoBancario.favorecidoCnpj }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Histórico" *ngIf="clienteCadastroForm.value.clienteId > 0">
              <br />
              <div class="row">
                <div class="col-md-12">
                  <button (click)="ModalClienteHistorico(0)" mat-raised-button color="accent"
                    matTooltip="Adicionar Cliente Histórico">
                    <mat-icon>add</mat-icon>
                    Histórico</button>
                </div>
              </div>
              <hr>
              <div id="list" class="row">
                <div class="table-responsive col-md-12">
                  <table class="table table-striped" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th>Ações</th>
                        <th>Usuário</th>
                        <th>Data</th>
                        <th>Título</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let clienteHistorico of clienteHistoricos">
                        <td class="actions">
                          <a mat-mini-fab (click)="ModalClienteHistorico(clienteHistorico.clienteHistoricoId)"
                            matTooltip="Editar Cliente Histórico">
                            <mat-icon>edit</mat-icon></a>
                        </td>
                        <td>{{clienteHistorico.usuario}}</td>
                        <td>{{clienteHistorico.dataCriacao | date:'dd/MM/yyyy'}}</td>
                        <td>{{clienteHistorico.titulo}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Assinaturas"
              *ngIf="clienteCadastroForm.value.clienteId > 0 && clienteCadastroForm.value.clienteTipo == 0">
              <br />
              <div class="row">
                <div class="col-md-12">
                  <button (click)="ModalClienteServico(0)" mat-raised-button color="accent"
                    matTooltip="Adicionar Cliente Serviço">
                    <mat-icon>add</mat-icon> Assinatura
                  </button>
                </div>
              </div>
              <hr>
              <div id="list" class="row">
                <div class="table-responsive col-md-12">
                  <table class="table table-striped" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th>Ações</th>
                        <th>Status</th>
                        <th>Serviço</th>
                        <th>Quantidade</th>
                        <th>Vlr Unit.</th>
                        <th>Vlr Total</th>
                        <th>Contratação</th>
                        <th>Último Faturamento</th>
                        <th>Próx. Faturamento</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let clienteServico of clienteServicos">
                        <td class="actions">
                          <a mat-mini-fab (click)="ModalClienteServico(clienteServico.clienteServicoId)"
                            matTooltip="Editar Cliente Serviço">
                            <mat-icon>edit</mat-icon></a>
                          <a mat-mini-fab *ngIf="clienteServico.clienteServicoStatus != 99"
                            (click)="confirmarExclusaoClienteServico(clienteServico.clienteServicoId)" color="warn"
                            matTooltip="Deletar Cliente Serviço">
                            <mat-icon>delete</mat-icon></a>
                        </td>
                        <td>{{clienteServico.clienteServicoStatus == 99 ? 'Cancelado': 'Ativo'}}</td>
                        <td>{{clienteServico.produtoDescricao}}</td>
                        <td>{{clienteServico.quantidade}}</td>
                        <td>{{clienteServico.valorUnitario | currency}}</td>
                        <td>{{clienteServico.valorTotal | currency}}</td>
                        <td>{{clienteServico.dataContratacao | date:'dd/MM/yyyy'}}</td>
                        <td>{{clienteServico.dataUltimaRenovacao | date:'dd/MM/yyyy'}}</td>
                        <td>{{clienteServico.dataProximaRenovacao | date:'dd/MM/yyyy'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="SOC"
              *ngIf="clienteCadastroForm.value.clienteId > 0 && clienteCadastroForm.value.clienteTipo == 0 && integracao">
              <br>
              <div class="row">
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Código da Empresa no SOC</mat-label>
                  <input type="number" matInput formControlName="codigo">
                  <mat-hint>Utilizado para integrações com outros sistemas como SOC.</mat-hint>
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-12 form-group">
                  <mat-label>Código da Unidade no SOC</mat-label>
                  <input type="number" matInput formControlName="codigoUnidadeSoc">
                  <mat-hint>Para identificar se a empresa é filial ou Matriz. Se não houver mais de uma empresa, não
                    altere.</mat-hint>
                </mat-form-field>
              </div>

            </mat-tab>
          </mat-tab-group>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
