import { DocumentoRelacionado } from "src/app/Components/documentos-relacionados/shared/documentos-relacionados.model";

export class Venda {
  vendaId: number = 0;

  vendaStatus: number = 0;

  faturaId: number = 0;

  remessaId: number = 0;

  indPag: number = 0;

  dataVenda: Date = new Date();

  dataCriacao: Date = new Date();

  comunicacaoInterna: string = '';

  dataPrevista: Date = new Date();

  idlocalEntrega: number = 0;

  idlocalRetirada: number = 0;

  empresaId: number = 0;

  saguiClienteId: number = 0;

  razaoSocialCliente: string = '';

  modFrete: number = 0;

  transportadoraId: number = 0;

  qVol: number = 0;

  especie: string = '';

  tipoEntrega: number = 0;

  pesoBruto: number = 0;

  pesoLiquido: number = 0;

  indFinal: number = 0;

  indPres: number = 0;

  modNFe: number = 0;

  pagoDinheiro: number = 0;

  pagoDinheiroTroco: number = 0;

  pagoCartaoDebito: number = 0;

  pagoCartaoCredito: number = 0;

  pagoBoleto: number = 0;

  pagoTransferencia: number = 0;

  pagoCheque: number = 0;

  pagoValeRefeicao: number = 0;

  clienteId: number = 0;

  tipoPagamento: number = 0;

  vendaTipo: number = 0;

  nfeId: number = 0;

  valorPis: number = 0;

  valorPisSt: number = 0;

  valorCofins: number = 0;

  valorCofinsSt: number = 0;

  valorInss: number = 0;

  valorIr: number = 0;

  valorCsll: number = 0;

  valorIss: number = 0;

  valorLiquido: number = 0;

  valorBruto: number = 0;

  valorAposRetencoes: number = 0;

  valorIcms: number = 0;

  valorIcmsSt: number = 0;

  valorIpi: number = 0;

  issRetido: boolean = false;

  pisRetido: boolean = false;

  cofinsRetido: boolean = false;

  csllRetido: boolean = false;

  irRetido: boolean = false;

  inssRetido: boolean = false;
  vendedorId: number = 0;
  cartaoCreditocontaCorrenteTerminalCartaoId: number = 0;
  cartaoCreditocodigoAutenticacao: string = '';
  cartaoCreditonumeroDoComprovanteDeVenda: string = '';
  cartaoCreditonumeroParcelas: number = 0;
  cartaoDebitocontaCorrenteTerminalCartaoId: number = 0;
  cartaoDebitocodigoAutenticacao: string = '';
  cartaoDebitonumeroDoComprovanteDeVenda: string = '';
  numeroParcelas: number = 1;
  diaVencimento: number = 1;
  valorTotal: number = 0;
  valorDesconto: number = 0;
  descontosItensEmReais: number = 0;
  descontosAdicionaisEmReais: number = 0;
  somaTotalDescontosEmReais: number = 0;
  descontosItensEmPorcentagem: number = 0;
  descontosAdicionaisEmPorcentagem: number = 0;
  somaTotalDescontosEmPorcentagem: number = 0;
  naturezaVenda: NaturezaVenda = 0;
  vendaEmpresas: any[] = [];
  vendaItens: any[] = [];
  vendaParcelas: any[] = [];
  documentos: DocumentoRelacionado[] = [];
}
//enum for naturezaVenda
export enum NaturezaVenda {
  novaVenda = 0,
  renovacao = 1,
}
