import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class EstoqueMovimentoService {

  getEstoqueMovimentos(parametros: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/estoquemovimento/visualizatodas/',parametros, { observe: 'response'})
  }

  getEstoqueMovimento(itemId: any): Observable<HttpResponse<any>> {
    return this.http.get(
      environment.webApiSaguiControlUrl + '/api/estoquemovimento/visualiza/' + itemId, { observe: 'response' })
  }

  postEstoqueMovimento(item: any): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.webApiSaguiControlUrl + '/api/estoquemovimento/inclui/', item, { observe: 'response' })
  };

  putEstoqueMovimento(item: any, itemId: any): Observable<HttpResponse<any>> {
    return this.http.put(
      environment.webApiSaguiControlUrl + '/api/estoquemovimento/atualiza/' + itemId, item, { observe: 'response' })
  };

  deleteEstoqueMovimento(itemId: any): Observable<HttpResponse<any>> {
    return this.http.delete(
      environment.webApiSaguiControlUrl + '/api/estoquemovimento/exclui/' + itemId, { observe: 'response' })
  }

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }
}
