<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>Fatura Item</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<form [formGroup]="faturaItemForm">
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()">
      <mat-icon>save</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="md-dialog-content">
      <div class="row">
        <div class="col-md-12">
          <br />
          <div class="row">
            <mat-form-field class="form-group col-md-12 col-xs-12">
              <mat-label>Descrição</mat-label>
              <input type="text" matInput formControlName="descricao">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="form-group col-md-2">
              <mat-label>Unidade de Medida</mat-label>
              <input matInput formControlName="unComercial">
            </mat-form-field>
            <mat-form-field class="form-group col-md-2 col-xs-12">
              <mat-label>Quantidade</mat-label>
              <input type="number" matInput formControlName="qtdComercial" (blur)="onChangeValorComercial()">
            </mat-form-field>
            <mat-form-field class="form-group col-md-4 col-xs-12">
              <mat-label>Valor Unitário</mat-label>
              <input currencyMask matInput formControlName="vlrComercial" (blur)="onChangeValorComercial()">
            </mat-form-field>
            <mat-form-field class="form-group col-md-4 col-xs-12">
              <mat-label>Valor Total</mat-label>
              <input currencyMask matInput formControlName="valorBruto">
            </mat-form-field>
            <mat-form-field class="form-group col-md-4 col-xs-12">
              <mat-label>Valor Desconto</mat-label>
              <input currencyMask matInput formControlName="valorDesconto">
            </mat-form-field>
            <mat-form-field class="form-group col-md-4 col-xs-12">
              <mat-label>Com Desconto</mat-label>
              <input currencyMask matInput formControlName="valorTotal">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</form>