<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>Baixar Parcela</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog(0)" class="botao-header">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div class="row">
  <div class="col-md-12">
    <div class="alerta" style="margin: 15px;" *ngIf="contaParcelaBaixaForm.value.tipoConta == 0">
      <div class="row">
        <div class="col-md-1" style="font-size: 30px; margin-top: 10px;">⚠️</div>
        <div class="col-md-11">
          <p>Para baixar a Conta Parcela manualmente, a forma de pagamento não pode ser boleto. Após alterar o tipo de pagamento, o botão "Salvar" será exibido.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()" *ngIf="contaParcelaBaixaForm.get('tipoPagamento').value != 3 || contaParcelaBaixaForm.value.tipoConta == 1">
    <mat-icon>save</mat-icon> Salvar
  </button>
</div>
<mat-dialog-content>
  <div class="md-dialog-content">
    <br />
    <div class="row">
      <form [formGroup]="contaParcelaBaixaForm">
        <div class="row">
          <mat-form-field class="col-md-6 col-xs-12 form-group">
            <mat-label>Caixa (Fluxo de Caixa)</mat-label>
            <mat-select formControlName="caixaId">
              <mat-option *ngFor="let caixa of caixas" [value]="caixa.caixaId">
                {{caixa.nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="col-md-3 col-xs-12 form-group">
            <mat-label>Valor Atual</mat-label>
            <input currencyMask matInput formControlName="valorParcela">
          </mat-form-field>
          <mat-form-field class="col-md-3 col-xs-12 form-group">
            <mat-label>Até o Vencimento</mat-label>
            <input currencyMask matInput formControlName="valorAteVenc">
          </mat-form-field>
          <mat-form-field class="col-md-3 col-xs-12 form-group">
            <mat-label>Data de Vencimento</mat-label>
            <input matInput [matDatepicker]="picker1" placeholder="Data de Vencimento" formControlName="dataVencimento">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="col-md-3 col-xs-12 form-group">
            <mat-label>Data de Pagamento</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="dataPagamento">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12 form-group">
            <mat-radio-group aria-label="Liquidar" formControlName="liquidar">
              <mat-radio-button [value]="true">Liquidar a Parcela Integralmente</mat-radio-button>
              <mat-radio-button [value]="false">Receber valor parcial e manter em aberto o valor restante
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="row" *ngIf="contaParcelaBaixaForm.value.liquidar == false">
          <mat-form-field class="col-md-6 col-xs-12 form-group">
            <mat-label>Valor da Dívida que ficará Ativa (R$)</mat-label>
            <input currencyMask matInput formControlName="valorQueFicaraAtivo" (blur)="atualizarValorAPagar(contaParcelaBaixaForm.value.valorQueFicaraAtivo)">
          </mat-form-field>
        </div>
        <div class="row">
          <div class="col-md-2 col-xs-12 form-group">
            <mat-checkbox formControlName="comjuros">Com Juros</mat-checkbox>
          </div>
          <mat-form-field class="col-md-3 col-xs-12 form-group">
            <mat-label>Valor Pago</mat-label>
            <input currencyMask matInput placeholder="Valor Pago" formControlName="valorPagamento">
            <mat-hint>Incluindo Juros e Multa</mat-hint>
          </mat-form-field>
          <mat-form-field class="col-md-4 col-xs-12 form-group">
            <mat-label>Tipo De Pagamento</mat-label>
            <mat-select formControlName="tipoPagamento">
              <mat-option [value]="0">Dinheiro</mat-option>
              <mat-option [value]="1">Cartão de Débito</mat-option>
              <mat-option [value]="2">Cartão de Crédito</mat-option>
              <mat-option [value]="3">Boleto</mat-option>
              <mat-option [value]="11">PIX</mat-option>
              <mat-option [value]="4">Transferência</mat-option>
              <mat-option [value]="5">Cheque</mat-option>
              <mat-option [value]="6">Vale Refeição</mat-option>
              <mat-option [value]="7">Banco</mat-option>
              <mat-option [value]="8">Prestação de Contas</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row"
          *ngIf="contaParcelaBaixaForm.value.tipoConta == 0 && (contaParcelaBaixaForm.value.tipoPagamento == 2 || contaParcelaBaixaForm.value.tipoPagamento == 1)">
          <mat-form-field class="col-md-6 col-xs-12 form-group">
            <mat-label>Terminal</mat-label>
            <mat-select #contaCorrenteTerminal [(value)]="selectContaCorrenteTerminal"
              (selectionChange)="defineContaCorrenteTerminal(contaCorrenteTerminal.value)">
              <mat-option *ngFor="let contaCorrenteTerminal of contaCorrenteTerminais" [value]="contaCorrenteTerminal">
                {{contaCorrenteTerminal.descricao}}
              </mat-option>
            </mat-select>
            <mat-hint>Maquininha de Cartão ou Ecommerce</mat-hint>
          </mat-form-field>
          <mat-form-field class="col-md-6 col-xs-12 form-group">
            <mat-label>Cartões</mat-label>
            <mat-select formControlName="contaCorrenteTerminalCartaoId">
              <mat-option *ngFor="let cartao of contaCorrenteTerminalCartoes"
                [value]="cartao.contaCorrenteTerminalCartaoId">
                {{cartao.cartao.descricao}}
                <img [src]="'data:image/png;base64,' + cartao.cartao.logoByte" style="height: 10px;" />
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-md-3 col-xs-12 form-group">
            <mat-label>Código de Autenticação</mat-label>
            <input type="string" matInput formControlName="codigoAutenticacao">
          </mat-form-field>
          <mat-form-field class="col-md-3 col-xs-12 form-group">
            <mat-label>Comprovante de Venda</mat-label>
            <input type="string" matInput placeholder="Comprovante de Venda"
              formControlName="numeroDoComprovanteDeVenda">
            <mat-hint>CV - Nº do Comprovante de Venda</mat-hint>
          </mat-form-field>
          <mat-form-field class="col-md-3 col-xs-12 form-group" *ngIf="contaParcelaBaixaForm.value.tipoPagamento == 2">
            <mat-label>Número de Parcelas</mat-label>
            <mat-select formControlName="numeroParcelas">
              <mat-option [value]="1">1</mat-option>
              <mat-option [value]="2">2</mat-option>
              <mat-option [value]="3">3</mat-option>
              <mat-option [value]="4">4</mat-option>
              <mat-option [value]="5">5</mat-option>
              <mat-option [value]="6">6</mat-option>
              <mat-option [value]="7">7</mat-option>
              <mat-option [value]="8">8</mat-option>
              <mat-option [value]="9">9</mat-option>
              <mat-option [value]="10">10</mat-option>
              <mat-option [value]="11">11</mat-option>
              <mat-option [value]="12">12</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row" *ngIf="contaParcelaBaixaForm.value.tipoPagamento == 8">
          <mat-form-field class="col-md-6 col-xs-12 form-group">
            <mat-label>Selecione o Adiantamento (*)</mat-label>
            <mat-select formControlName="adiantamentoContaId">
              <mat-option *ngFor="let adiantamento of adiantamentos" [value]="adiantamento.contaId">
                {{adiantamento.descricaoConta}} - Saldo: {{adiantamento.saldo | currency}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row" *ngIf="contaParcelaBaixaForm.value.tipoPagamento == 8">
          <mat-form-field class="col-md-6 col-xs-12 form-group">
            <mat-label>Selecione a Operação (*)</mat-label>
            <mat-select formControlName="operacaoContabilId">
              <mat-option *ngFor="let operacaoContabil of operacoesContabeis"
                [value]="operacaoContabil.operacaoContabilId">
                {{operacaoContabil.descricao}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="form-group col-md-6">
            <mat-label>Observação do Pagamento</mat-label>
            <textarea matInput rows="5" placeholder="Observação de Pagamento"
              formControlName="observacaoPagamento"></textarea>
          </mat-form-field>
          <div class="col-md-6">
            <label>Importar comprovante de Pagamento</label>
            <br>
            <input #arquivoInput type="file" onclick="this.value=null" (change)="fileChanged($event)" accept="*" />
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>