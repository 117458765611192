'use strict';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, startWith, switchMap } from "rxjs/operators";

import { RemessaService } from '../shared/remessa.service';

import { EmbalagemCadastroComponent } from '../../embalagem/embalagem-cadastro/embalagem-cadastro.component';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';
import { ConfirmDialogComponent } from '../../core/dialogs/confirm-dialog/confirm-dialog.component';

import { HtmlPrinterService } from '../../core/html-printer/html-printer.service';

import { Remessa } from '../shared/remessa.model';
import { Transportadora } from '../../transportadora/shared/transportadora.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
    remessaId: number;
}

@Component({
    selector: 'remessa-cadastro',
    templateUrl: 'remessa-cadastro.component.html',
})

export class RemessaCadastroComponent {

    remessa: any = {};
    empresa: any = {};
    cliente: any = {};
    transportadora: any = {};
    localEntrega: any = {};
    empresas: any = [];
    remessas: any = [];
    clientes: any = [];
    transportadoras: Transportadora[];
    fornecedores: any[];
    locaisEntrega: any[];
    remessaCadastroForm: FormGroup;
    maximizado: boolean;
    atualizandoRemessa: boolean = false;

    constructor(
        public dialog: MatDialog,
    private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<RemessaCadastroComponent>,
        private _formBuilder: FormBuilder,
        private htmlPrinterService: HtmlPrinterService,
        @Inject(MAT_DIALOG_DATA) public IRemessa: DialogData,
        private remessaService: RemessaService) {
    }

    ngOnInit() {

        this.maximizado = false;

        this.buscarEmpresas();
        
        this.buscarTransportadoras();

        this.remessaCadastroForm = this._formBuilder.group(new Remessa());

        this.remessaCadastroForm.addControl('localRetiradaAutoComplete', new FormControl(''));

        this.remessaCadastroForm.addControl('localEntregaAutoComplete', new FormControl(''));

        this.remessaCadastroForm.addControl('clienteAutoComplete', new FormControl(''));

        if (this.IRemessa.remessaId > 0) {

            this.buscarRemessa(this.IRemessa.remessaId);

        } else {
            this.remessa = {};
            this.remessa.dataRemessa = new Date();
            this.remessa.dataPrevista = new Date();
            this.onChanges();
        }

        this.restaurarDialog();
    }

    onChanges(): void {
        this.remessaCadastroForm
            .get('clienteAutoComplete')
            .valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => value?.length >= 3 && !this.atualizandoRemessa ?
                    this.remessaService.getClientePorNome(value) : []
                )
            )
            .subscribe(data =>
                this.clientes = data.body
            );

        this.remessaCadastroForm
            .get('localEntregaAutoComplete')
            .valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => value?.length >= 3 && !this.atualizandoRemessa ?
                    this.remessaService.getClientePorNome(value) : []
                )
            )
            .subscribe(data =>
                this.locaisEntrega = data.body
            );
    }

    buscarEmpresas() {

        this.remessaService.getEmpresa().subscribe(data => {

            this.empresas = data.body;

        });
    }

    buscarTransportadoras() {

        this.remessaService.getTransportadoras().subscribe(data => {

            this.transportadoras = data.body;

        });
    }

    fecharDialog() {
        this.dialogRef.close();
    }

    maximizarDialog() {
        this.dialogRef.updateSize('100%', '100%');
        this.maximizado = true;
    }

    restaurarDialog() {
        let innerWidth = window.innerWidth;

        if (innerWidth < 1024) {
            this.dialogRef.updateSize('90%', '100vh');
        }
        else {
            this.dialogRef.updateSize('60%', '100vh');
        }
        this.maximizado = false;
    }

    buscarRemessa(remessaId: number) {

        this.atualizandoRemessa = true;

        this.remessaService.getRemessa(remessaId).subscribe(data => {

            this.InsereDadosRemessa(data.body);

        }, (error: HttpErrorResponse) => {

            this.atualizandoRemessa = false;

                const dialogRef = this.dialog.open(InfoDialogComponent, {
                    data: {
                        titulo: "Erro",
                        erro: error
                    }
            });
        })
    }

    InsereDadosRemessa(data: any) {

        this.remessaCadastroForm.patchValue(data);

        this.cliente = data.cliente;
        this.empresa = data.empresa;
        this.remessa = data;

        this.remessaCadastroForm.get('clienteAutoComplete').setValue(this.cliente.nomeFantasia);

        const promiselocalEntrega = new Promise<void>((resolve, reject) => {

            if (this.remessa.idlocalEntrega > 0) {

                this.remessaService.getClientePorId(this.remessa.idlocalEntrega).subscribe(data => {

                    this.localEntrega = data.body;
                    this.remessaCadastroForm.get('localEntregaAutoComplete').setValue(this.localEntrega.nomeFantasia);                    
                    
                    resolve();

                }, (error: HttpErrorResponse) => {

                    resolve();

                    const dialogRef = this.dialog.open(InfoDialogComponent, {
                        data: {
                            titulo: "Erro",
                            erro: error
                        }
                    });
                });
            }
            else {
                resolve();
            }
        });

        Promise.all([promiselocalEntrega]).then(values => {

            let modalidadeFrete = "";

            switch (this.remessa.modFrete) {
                case 0:
                    modalidadeFrete = "Contratação do frete por conta do remetente(CIF)";
                    break;
                case 1:
                    modalidadeFrete = "Contratação do frete por conta do destinatário(FOB)";
                    break;
                case 2:
                    modalidadeFrete = "Transporte próprio por conta do remetente";
                    break;
                case 3:
                    modalidadeFrete = "Transporte próprio por conta do destinatário";
                    break;
                case 9:
                    modalidadeFrete = "Sem ocorrência de transporte";
                    break;
            }

            this.remessa.modalidadeFrete = modalidadeFrete;

            this.remessas.push(this.remessa);


        }).finally(() => {
            this.atualizandoRemessa = false;
            this.onChanges();
        });

    }

    DeterminafornecedorId(id: number) {
        this.remessaCadastroForm.get('fornecedorId').setValue(id);
    }

    DeterminalocalEntregaId(id: number) {
        this.remessaCadastroForm.get('idlocalEntrega').setValue(id);
    }

    DeterminalocalRetiradaId(id: number) {
        this.remessaCadastroForm.get('idlocalRetirada').setValue(id);
    }

    cancelarRemessa(cancelarId: number) {

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                titulo: "Confirmação",
                mensagem: "Deseja realmente Cancelar essa remessa?"
            }
        });


        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                this.remessaService.deleteRemessa(cancelarId).subscribe(data => {

                    this._snackBar.open("Registro Cancelado com Sucesso");

                    this.buscarRemessa(cancelarId);

                });
            }
        });
    };

    salvar(): void {

        if (this.remessaCadastroForm.value.remessaId == 0) {

            this.remessaService.postRemessa(this.remessaCadastroForm.value).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.buscarRemessa(data.body.remessaId);


            }, (error: HttpErrorResponse) => {

                    const dialogRef = this.dialog.open(InfoDialogComponent, {
                        data: {
                            titulo: "Erro",
                            erro: error
                        }
                });
            });

        }
        else {
            this.remessaService.putRemessa(this.remessaCadastroForm.value, this.remessaCadastroForm.value.remessaId).subscribe(data => {

                        this._snackBar.open("Registro Salvo com Sucesso");

                this.buscarRemessa(this.remessaCadastroForm.value.remessaId);


            }, (error: HttpErrorResponse) => {

                    const dialogRef = this.dialog.open(InfoDialogComponent, {
                        data: {
                            titulo: "Erro",
                            erro: error
                        }
                });
            });
        }

    };

    processaPicking () {

        this.remessaService.postProcessaPicking(this.remessaCadastroForm.value).subscribe(data => {

            const dialogRef = this.dialog.open(InfoDialogComponent, {
                data: {
                    titulo: "Sucesso",
                    mensagem: "Picking realizado com Sucesso. Saídas de estoques realizadas"
                }
            });

            this.buscarRemessa(this.remessaCadastroForm.value.remessaId);

        });
    };

    processaPacking () {

        this.remessaService.postProcessaPacking(this.remessaCadastroForm.value).subscribe(data => {

            const dialogRef = this.dialog.open(InfoDialogComponent, {
                data: {
                    titulo: "Sucesso",
                    mensagem: "Packing realizado com Sucesso"
                }
            });

            this.buscarRemessa(this.remessaCadastroForm.value.remessaId);

        });
    };

    processaEntrega() {

        this.remessaService.postProcessaEntregue(this.remessaCadastroForm.value).subscribe(data => {

            const dialogRef = this.dialog.open(InfoDialogComponent, {
                data: {
                    titulo: "Sucesso",
                    mensagem: "Entrega Realizada"
                }
            });

            this.buscarRemessa(this.remessaCadastroForm.value.remessaId);

        });
    };

    determinaCliente(cliente: any) {

        this.remessaCadastroForm.get('clienteId').setValue(cliente.clienteId);
    
    }

    embalagemModal(id: number) {

        const dialogRef = this.dialog.open(EmbalagemCadastroComponent, {
            data: {
                embalagemId: id,
            },
            maxHeight: '100vh'
        });
    };

    imprimir(): void {

        let titulo = "Remessa";

        this.htmlPrinterService.printDiv('printRemessa', titulo);
    }
}
