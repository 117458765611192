import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicoService } from '../../produtoeservico/shared/servico.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-cad-grupo',
    templateUrl: './cad-grupo.component.html',
    styleUrls: ['./cad-grupo.component.css'],
})
export class CadGrupoComponent implements OnInit {
    servicoGrupoCadastroForm: FormGroup;
    produtoGrupoId: number;
    duplicar: boolean;
    maximizado: boolean;
    empresas: any;
    filteredEmpresas: any;
    constructor(
        private servicoService: ServicoService,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<CadGrupoComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}
    ngOnInit(): void {
        this.buscaEmpresas();
        this.maximizado = false;
        this.servicoGrupoCadastroForm = this._formBuilder.group({
            produtoGrupoEmpresa: ['', Validators.required],
            descricao: ['', Validators.required]
        });
        if (this.data.produtoGrupoId) {
            this.produtoGrupoId = this.data.produtoGrupoId; 
            this.buscarServicoGrupo(this.produtoGrupoId);
        }
        this.onChanges();
        this.restaurarDialog();
    }
    onChanges(): void {
        
    }
    fecharDialog() {
        this.dialogRef.close();
    }
    maximizarDialog() {
        this.dialogRef.updateSize('100%', '100%');
        this.maximizado = true;
    }
    restaurarDialog() {
        let innerWidth = window.innerWidth;

        if (innerWidth < 1024) {
            this.dialogRef.updateSize('90%', '100vh');
        } else {
            this.dialogRef.updateSize('60%', '100vh');
        }
        this.maximizado = false;
    }
    salvarGrupoServico(): void {
        if (this.servicoGrupoCadastroForm.valid) {
            const servico = this.criarObjetoServico();
            if (this.produtoGrupoId) {
                this.atualizarGrupoServico(servico);
            } else {
                this.criarNovoGrupoServico(servico);
            }
        } else {
            this._snackBar.open('Por favor, preencha todos os campos obrigatórios.', 'Fechar', {
                duration: 5000,
                panelClass: ['mat-toolbar', 'warn']
            });
        }
    }
    criarObjetoServico(): any {
        return {
            produtoGrupoId: this.produtoGrupoId, 
            produtoGrupoEmpresa: this.servicoGrupoCadastroForm.get('produtoGrupoEmpresa').value,
            descricao: this.servicoGrupoCadastroForm.get('descricao').value,
        };
    }
    atualizarGrupoServico(servico: any): void {
        this.servicoService.atualizaGrupoServico(servico, this.produtoGrupoId).subscribe(
            response => this.onSucesso(response, 'atualizado'),
            error => this.onErro(error, 'atualizar')
        );
    }
    criarNovoGrupoServico(servico: any): void {
        this.servicoService.criaGrupoServico(servico).subscribe(
            response => this.onSucesso(response, 'criado'),
            error => this.onErro(error, 'criar')
        );
    }
    onSucesso(response: any, acao: string): void {
        this._snackBar.open(`Grupo de serviço ${acao} com sucesso!`, 'Fechar', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'sucess']
        });
        this.dialogRef.close(response);
    }

    onErro(error: any, acao: string): void {
        let errorMessage = `Erro ao ${acao} o grupo de serviço.`;

        if (error.error && error.error.title) {
            errorMessage += ` ${error.error.title}`;
        }

        if (error.error && error.error.errors) {
            errorMessage += ' Detalhes do erro:';
            for (const key in error.error.errors) {
                if (error.error.errors.hasOwnProperty(key)) {
                    errorMessage += ` ${key}: ${error.error.errors[key]}`;
                }
            }
        }
        this._snackBar.open(errorMessage, 'Fechar', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'warn']
        });
        console.error(`Erro ao ${acao} grupo de serviço:`, error);
    }
    buscarServicoGrupo(servicoGrupoId: number) {
        this.servicoService.recuperaGrupoServico(servicoGrupoId).subscribe(
            data => {
                console.log('Dados recebidos:', data.body);
                if (data && data.body) {
                    this.servicoGrupoCadastroForm.patchValue(data.body);
                } else {
                    console.error('Formato inesperado dos dados recebidos:', data);
                }
            },
            error => {
                console.error('Erro ao buscar grupo de serviço:', error);
            }
        );
    }
    buscaEmpresas() {
        this.servicoService.getEmpresas().subscribe(
            data => {
                this.empresas = data.body;
            },
            error => {
                console.error('Erro ao recuperar empresas:', error);
            }
        );
    }
}