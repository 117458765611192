'use strict';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { InfoDialogComponent } from '../../core/dialogs/info-dialog/info-dialog.component';

import { NfeService } from '../shared/nfe.service';

export interface DialogData {
  compraId: number;
  vendaId: number;
}

@Component({
  selector: 'nfe-inbound-importar',
  templateUrl: 'nfe-inbound-importar.component.html',
})

export class NfeInboundImportarComponent {

  arquivo: any = {};
  maximizado: boolean;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NfeInboundImportarComponent>,
    @Inject(MAT_DIALOG_DATA) public INfeInbound: DialogData,
    private nfeService: NfeService) {
  }

  ngOnInit() {

    this.restaurarDialog();
  }


  fecharDialog() {
    this.dialogRef.close();
  }

  maximizarDialog() {
    this.dialogRef.updateSize('100%', '100%');
    this.maximizado = true;
  }

  restaurarDialog() {
    let innerWidth = window.innerWidth;

    if (innerWidth < 1024) {
      this.dialogRef.updateSize('90%', '100vh');
    }
    else {
      this.dialogRef.updateSize('60%', '100vh');
    }
    this.maximizado = false;
  }

  importar(): void {

    if (this.INfeInbound.compraId > 0) {
      this.arquivo.compraId = this.INfeInbound.compraId;
    }

    if (this.INfeInbound.vendaId > 0) {
      this.arquivo.vendaId = this.INfeInbound.vendaId;
    }

    if (this.arquivo.nomeArquivo == "") {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          mensagem: "É necessário selecionar um arquivo"
        }
      });

      return;

    }

    this.nfeService.postInboundXml(this.arquivo).subscribe(data => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Sucesso",
          mensagem: "XML da NF-e importado com sucesso"
        }
      });

      this.fecharDialog();


    }, (error: HttpErrorResponse) => {

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          titulo: "Erro",
          erro: error
        }
      });

    });

  }

  fileChanged(e) {

    this.arquivo.nomeArquivo = e.target.files[0].name;

    let fileReader = new FileReader();

    fileReader.onload = (e) => {
      this.arquivo.corpoArquivo = fileReader.result;
    }

    fileReader.readAsText(e.target.files[0]);

  }

}
