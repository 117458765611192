import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(public dialog: MatDialog) {
  }

  handleError(error: Error | HttpErrorResponse) {

    let deveValidar = true;



    if (error instanceof HttpErrorResponse) {

      let urls: any = [
        'conta/contasporperiodo',
        'empresa/visualizatodas',
        'usuario/verificaacessoaomodulo',
        'venda/vendasporperiodo',
        'compra/comprasporperiodo',
        'contaparcela/visualizaparcelasporfiltro',
        'conta/contasporperiodo',
        'caixamovimento/visualizasaldoporfiltro',
        'estoque/visualizaminimo',
        'caixa/visualizaporfiltro',
        'contacontabil/visualizaporfiltro'];

      urls.forEach((url: string) => {

        if (error.url.indexOf(url) > 0) {
          deveValidar = false;
        }

      });

      if (deveValidar) {
        console.log(error);
        const dialogRef = this.dialog.open(InfoDialogComponent, {
          data: {
            titulo: "Erro",
            erro: error
          }
        });
      }
    }
    else {
      console.log(error);
    }
  }
}
