export class ClienteHistorico {

    clienteHistoricoId: number = 0;

    dataCriacao: Date = new Date();

    descricao: string = '';

    titulo: string = '';

    usuario: string = '';

    clienteId: number = 0;

    saguiClienteId: number = 0;
}