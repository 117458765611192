import { Component, Input, OnInit, ViewChild, AfterViewInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, AfterViewInit {
  @Input() actions: Array<{ name: string, method: (id: number) => void }>;
  @Input() dataSource = new MatTableDataSource<any>([]);
  @Input() columns: Array<{ def: string, header: string }>;
  @Input() onDuplicar: (id: number) => void;
  @Input() onEditar: (id: number) => void;
  @Input() onExcluir: (id: number) => void;
  @Input() enableSelection: boolean = false;
  @Input() enablePagination: boolean = true; 
  @Input() itemIdProperty: string = 'id'; // Adicionado para especificar a propriedade do ID

  @Output() selectionChange = new EventEmitter<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[];
  selection = new SelectionModel<any>(true, []);

  ngOnInit(): void {
    this.displayedColumns = this.enableSelection ? ['select', ...this.columns.map(column => column.def)] : this.columns.map(column => column.def);

    //emitir um evento para o componente pai se o selection mudar
    this.selection.changed.subscribe((change) => {
      //emitir evento para o componente pai
      this.selectionChange.emit(this.getSelectedRows());
    });
  }

  ngOnChanges(changes: SimpleChanges): void {


}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  isAllSelected() {
    const numRows = this.dataSource.data?.length;
    const numSelected = this.selection.selected.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  toggleRow(row: any) {
    this.selection.toggle(row); 
  }

  getSelectedRows() {
    return this.selection.selected;
   
  }

  duplicar(item: any) {
    const id = item[this.itemIdProperty];
    if (this.onDuplicar) {
      this.onDuplicar(id);
    }
  }

  editar(item: any) {
    const id = item[this.itemIdProperty];
    if (this.onEditar) {
      this.onEditar(id);
    }
  }

  excluir(item: any) {
    const id = item[this.itemIdProperty];
    if (this.onExcluir) {
      this.onExcluir(id);
    }
  }
}
